import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  Input,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MobileCommonFilter from "../../Components/shared/Filter/MobileCommonFilter";
import Header from "../../Components/shared/Header";
import StoresListHeader from "../../Assets/Images/Headers/StoresListHeader.webp";
import MobStoresListHeader from "../../Assets/Images/Headers/MobStoresListHeader.webp";
import Footer from "../../Components/Footer";
import CommonFilter from "../../Components/shared/Filter/CommonFilter";
import { carouselImages, stores } from "../Stores/SingleStores/DummyStoreData";
import DiscountWow from "../../Assets/Images/Discount/DiscountWow.webp";
import DiscountOfferCard from "../../Components/shared/DiscountOfferCard";
import ReactPaginate from "react-paginate";
import StoresCollection from "./StoresCollection/StoresCollection";
import { useNavigate } from "react-router-dom";
import { getStores } from "../../Functions/GetApiCalls";
import { DistrictFiltering } from "./FilterLogics/StoresFilter";
import Breadcrumbs from "../../Components/shared/Breadcrumbs";
import Search from "../../Assets/Images/search.png";
import Close from "../../Assets/Images/close.png";
import LocationCheckbox from "../../Components/LocationCheckbox";

function StoresListScreen() {
  const navigate = useNavigate();
  const [storesData, setStoresData] = useState([]);
  let [locationStores, setLocationStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [filteredLinks, setFilteredLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [district, setDistrict] = useState([]);
  const [offerImages, setOfferImages] = useState([]);
  const [locationslist, setLocationslist] = useState([]);
  const [locationStoreClicked, setLocationStoreClicked] = useState(false);
  const [radius, setRadius] = useState(50);

  const handleRadiusChange = (event) => {
    const newRadius = parseInt(event.target.value); // Parse the selected value to an integer
    setRadius(newRadius); // Update the radius state with the new value
  };
  // serach
  const [query, setQuery] = useState("");
  const handleClear = () => {
    setQuery("");
  };
  // Function to extract and store locations from stores
  const extractLocations = () => {
    const allLocations = storesData.reduce((acc, curr) => {
      // Assuming the location is stored in the addressLine3 field
      if (curr.location) {
        acc.push(curr.location);
      }
      return acc;
    }, []);
    // Remove duplicate locations and set the state
    setLocationslist([...new Set(allLocations)]);
  };
  useEffect(() => {
    extractLocations();
  }, [storesData]);

  // Function to open single stores page
  const OpenStorePage = (item) => {
    window.scrollTo(0, 0);
    navigate(`/stores/${item.shopname}#singlestoreinfo`, {
      state: { item: item },
    });
  };

  // API call
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getStores(setStoresData);
      setLoading(false);
    };
    fetchData(); // Call the async function
  }, []);
  useEffect(() => {
    const filteredData = storesData
      .flatMap((item) => {
        return item?.offer?.filter((offer) => {
          return (
            new Date(offer.From) <= new Date() &&
            new Date() <= new Date(offer.To)
          );
        });
      })
      .map((offer) => offer.offerImage);

    setOfferImages(filteredData);
  }, [storesData]);
  useEffect(() => {
    DistrictFiltering(district, storesData, setStoresData, setFilteredStores);
  }, [district]);

  // ----------------------------Pagination Logics-----------------------------------
  const [pageNumber, setPageNumber] = useState(0);
  // let locationStores=[];
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const storesPerPage = 12;
  const pageCount = Math.ceil(
    !locationStoreClicked
      ? district.length == 0
        ? storesData.length / storesPerPage
        : filteredStores.length / storesPerPage
      : locationStores.length / storesPerPage
  );
  const pagesVisited = pageNumber * storesPerPage;

  // locationstore calculation
  locationStores = storesData
    .filter((store) => {
      // Split store location string into latitude and longitude
      const [storeLatitude, storeLongitude] = store.location
        .split(",")
        .map(parseFloat);

      // Check if any of the filtered links match the store's location
      return filteredLinks.some((link) => {
        // Compare latitude and longitude
        return (
          storeLatitude === link.latitude && storeLongitude === link.longitude
        );
      });
    })
    .slice(pagesVisited, pagesVisited + storesPerPage)
    .map((item, index) => {
      return (
        <Flex
          key={index}
          onClick={() => OpenStorePage(item)}
          cursor={"pointer"}
        >
          <StoresCollection
            src={item.shopprofileimage}
            heading={item.shopname}
            description={item.addressLine3}
          />
        </Flex>
      );
    });

  useEffect(() => {
    setLocationStores(locationStores);
  }, [locationStores.length]);
  // stores card

  const displayStores = !locationStoreClicked
    ? district.length === 0
      ? storesData
          .filter(
            (store) =>
              (store.shopname &&
                store.shopname.toLowerCase().includes(query.toLowerCase())) ||
              store.product.some((product) =>
                product.productname.toLowerCase().includes(query.toLowerCase())
              )
          )
          .slice(pagesVisited, pagesVisited + storesPerPage)
          .map((item, index) => (
            <Flex
              key={index}
              onClick={() => OpenStorePage(item)}
              cursor={"pointer"}
            >
              <StoresCollection
                src={item.shopprofileimage}
                heading={item.shopname}
                description={item.addressLine3}
              />
            </Flex>
          ))
      : filteredStores
          .filter(
            (store) =>
              (store.shopname &&
                store.shopname.toLowerCase().includes(query.toLowerCase())) ||
              store.product.some((product) =>
                product.productname.toLowerCase().includes(query.toLowerCase())
              )
          )
          .slice(pagesVisited, pagesVisited + storesPerPage)
          .map((item, index) => (
            <Flex
              key={index}
              onClick={() => OpenStorePage(item)}
              cursor={"pointer"}
            >
              <StoresCollection
                src={item.shopprofileimage}
                heading={item.shopname}
                description={item.addressLine3}
              />
            </Flex>
          ))
    : // for location
      locationStores;
  // location filter

  // Set initial value for filteredDesignerData
  // useEffect(() => {
  //   setFilteredStores(storesData);
  // }, [storesData]);
  // pagenumber
  useEffect(() => {
    if (displayStores.length === 0) {
      setPageNumber(0);
    }
  }, [displayStores]);

  function extractLatLong(location) {
    const regex = /\/([\d.]+),([\d.]+)/;
    const match = location.match(regex);
    if (match && match.length === 3) {
      return {
        latitude: parseFloat(match[1]),
        longitude: parseFloat(match[2]),
      };
    }
    return null;
  }

  // Find and display shop names whose location matches the filteredLinks

  const matchingShops = [];
  filteredLinks.forEach((link) => {
    storesData.forEach((shop) => {
      const shopLocation = extractLatLong(shop.location);
      if (
        shopLocation &&
        shopLocation?.latitude === link.latitude &&
        shopLocation?.longitude === link.longitude
      ) {
        matchingShops.push(shop.shopname);
      }
    });
  });

  return (
    <Flex
      flexDir={"column"}
      bg={"linear-gradient(180deg, #FFF 0%, #FFFAF2 29.69%, #D6AC85 100%)"}
    >
      {!locationStoreClicked && (
        <MobileCommonFilter
          MobDistrictFilter={1}
          district={district}
          setDistrict={setDistrict}
        />
      )}
      <Container id="stores" maxW="2000px" width={"100vw"} p={0}>
        <Header
          search={false}
          heading="Stores"
          image={StoresListHeader}
          imageMob={MobStoresListHeader}
        />
        {/* Search */}
        {!locationStoreClicked && (
          <Flex
            mb={{ base: "25%", md: "20%", lg: "7%" }}
            width={"100%"}
            mt={{ base: "-120px", md: "-180px", lg: "-180px" }}
          >
            {" "}
            <Flex mx={"auto"}>
              <Input
                height={{ base: "34px", md: "40px", lg: "40px" }}
                w={{ base: "210px", md: "300px", lg: "300px" }}
                color={"white"}
                type="text"
                placeholder="Search store / product names..."
                value={query}
                className="newsearch"
                onChange={(e) => setQuery(e.target.value)}
              ></Input>
              {!query && (
                <Button
                  height={{ base: "34px", md: "40px", lg: "40px" }}
                  backgroundColor={"#FFF3E7"}
                  _hover={{ backgroundColor: "white" }}
                  onClick={handleClear}
                  className="closeButton"
                >
                  <Image
                    objectFit={"contain"}
                    width={"20px"}
                    src={Search}
                  ></Image>
                </Button>
              )}
              {query && (
                <Button
                  height={{ base: "34px", md: "40px", lg: "40px" }}
                  _hover={{ backgroundColor: "white" }}
                  backgroundColor={"#FFF3E7"}
                  onClick={handleClear}
                  className="closeButton"
                >
                  <Image
                    objectFit={"contain"}
                    width={"20px"}
                    src={Close}
                  ></Image>
                </Button>
              )}
            </Flex>
          </Flex>
        )}
        {/* Search */}

        <Container maxW="1600px" width={"100vw"}>
          <Flex
            flexDir={{ base: "column", md: "column", lg: "row" }}
            ml={{ base: "0px", md: "2%", lg: "11%" }}
          >
            <Breadcrumbs />

            <Flex ml={{ base: "0px", md: "0px", lg: "10px" }}>
              <LocationCheckbox
                setFilteredLinks={setFilteredLinks}
                setLocationStoreClicked={setLocationStoreClicked}
                locationslist={locationslist}
                radius={radius}
              />
              <Select
                ml={"10px"}
                bg="green.300"
                borderColor="green.300"
                width={"100px"}
                height={"35px"}
                my={"auto"}
                onChange={handleRadiusChange}
                value={radius}
              >
                <option value="50">50km</option>
                <option value="40">40km</option>
                <option value="30">30km</option>
                <option value="20">20km</option>
                <option value="10">10km</option>
                {/* <option value="100">100km</option> */}
              </Select>
            </Flex>
          </Flex>
          <Flex
            width={"fit-content"}
            flexDir={{ base: "column", lg: "row" }}
            bg={
              "linear-gradient(180deg, #FFF2E7 0%, rgba(255, 242, 231, 0.00) 100%)"
            }
            mx={"auto"}
            boxShadow={"4px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            borderRadius={16}
            my={4}
            // px={"20px"}
            pb={10}
            // mx={{ base: 0, md: 4, lg: 0, xl: 10, "2xl": 20 }}
            // pl={{ base: 0, lg: 8, "2xl": 0 }}
            alignItems={{ base: "center", md: "center", lg: "flex-start" }}
            justifyContent={{ base: "center", md: "center", lg: "auto" }}
          >
            <Flex flexDir={"column"} maxWidth={"340px"} pt={4}>
              {offerImages.length > 0 && !locationStoreClicked && (
                <Flex
                 mb={"15px"}
                  flexDir={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  mt={{ base: 8, lg: 0 }}
                >
                  <Flex backgroundColor={"beige"}
        borderRadius={"5px"}
        boxShadow={"lg"} width={"90%"}  alignItems={"center"} mb={"10px"}>
                    <Image src={DiscountWow} width={"70px"} zIndex={0} />
                    <Text
                      fontWeight={"bold"}
                      fontSize={"15px"}
                      ml={"15px"}
                      zIndex={1}
                      color={"yellow.600"}
                    >
                      Lokolumo Kin Deals Corner
                    </Text>
                  </Flex>

                  <DiscountOfferCard
                    width={{ base: "350px", md: "550px", lg: "400px" }}
                    carouselImages={offerImages}
                  />
                </Flex>
              )}
              {!locationStoreClicked && (
                <CommonFilter
                  DistrictFilter={1}
                  district={district}
                  setDistrict={setDistrict}
                />
              )}
            </Flex>

            <Flex
              minH={{ base: "300px", md: "300px", lg: "800px" }}
              minW={{ base: "100%", md: "600px", lg: "800px" }}
              flexDir={"column"}
              // m={{ base: 0, md: 2, lg: 8 }}
              mx={{ base: 0, md: 2, lg: "30px" }}
              py={8}
              // px={{ base: 8, md: 8, lg: 8, xl: 10 }}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {district.length === 0 || filteredStores.length !== 0 ? (
                !loading ? (
                  <>
                    {" "}
                    <Grid
                      templateColumns={[
                        "repeat(1, 1fr)",
                        "repeat(2, 1fr)",
                        "repeat(2, 1fr)",
                        "repeat(2, 1fr)",
                        "repeat(3, 1fr)",
                      ]}
                      gap={2} // Adjust the gap between cards as needed
                    >
                      {displayStores}
                    </Grid>
                    <Flex
                      pt={"25px"}
                      alignItems={"center"}
                      justifyContent="center"
                      boxShadow={"lg"}
                      borderRadius="5px"
                      // m="20px"
                      width="fit-content"
                      bg={"whiteAlpha.700"}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        marginPagesDisplayed={0}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        forcePage={pageNumber}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                      />
                    </Flex>
                  </>
                ) : (
                  <Box
                    minW={{ lg: "480px", xl: "740px", "2xl": "890px" }}
                    align={"center"}
                  >
                    <Spinner />
                  </Box>
                )
              ) : (
                <Box
                  minW={{ lg: "480px", xl: "740px", "2xl": "890px" }}
                  align={"center"}
                >
                  No results found
                </Box>
              )}
            </Flex>
          </Flex>
        </Container>

        <Footer />
      </Container>
    </Flex>
  );
}

export default StoresListScreen;
