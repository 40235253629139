import React, { useState } from "react";

const PaginationLogic = (categoryStoresData) => {
  const [pageNumber, setPageNumber] = useState(0); // State to keep track of the current page number
  const collectionsPerPage = 12; // Number of collections to display per page
  const pagesVisited = pageNumber * collectionsPerPage; // Calculate the number of collections to skip based on the current page

  // Function to handle page change
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Calculate total number of pages for pagination
  const pageCount = Math.ceil(categoryStoresData?.length / collectionsPerPage);

  return { pagesVisited, pageCount, changePage, collectionsPerPage };
};

export default PaginationLogic;
