import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Flex,
} from "@chakra-ui/react";
import React from "react";

const FormInput = (props) => {
  const {
    require,
    name,
    label,
    type,
    inputFor,
    register,
    error,
    validator,
    handleBlur,
    handleFocus,
    style = {
      border: "1px solid #D0D5DD",
      maxHeight: "38px",
      backgroundColor: "white",
      borderRadius: "8px",
    },
    ...rest
  } = props;
  return (
    <FormControl isInvalid={error} pb={"15px"}>
      <FormLabel htmlFor={name} color="brand.font2" fontSize={"14px"}>
        <Flex direction={"row"}>
          {label}
          {require && <Text color={"red"}>*</Text>}
        </Flex>
      </FormLabel>
      <Input
        style={style}
        type={type}
        id={name}
        name={name}
        _focus={handleFocus}
        _focusVisible={handleFocus}
        {...register(inputFor, validator)}
        {...rest}
      />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormInput;
