import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";

function CommonModal(props) {
  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(10px) " />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (props.modalState) {
      onClose();
    }
  });

  return (
    <Flex display={props.display}>
      <Button
        p={0}
        bg={"transparent"}
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        _focus={{ bg: "transparent" }}
        onClick={() => {
          onOpen();
        }}
        {...props.btn}
      >
        {props.btnContent}
      </Button>

      <Modal
        closeOnOverlayClick={false}
        size={props.size}
        scrollBehavior={"outside"}
        isCentered={true}
        isOpen={isOpen}
        onClose={onClose}
        borderRadius={"1px"}
        // isCloseOnOverlayClick={true} 
        {...props.modalMainAttr}
      >
        <OverlayOne />
        <ModalContent
          minWidth="fit-content"
          height="fit-content"
          {...props.modalAttr}
          borderRadius={"10px"}
        >
          <ModalHeader>{props.modalName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={"40px"}>{props.modalContent}</ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default CommonModal;
