import { Flex, Grid, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import PaginationLogic from "./PaginationLogic";
import Collections from "../CollectionsCard/Collections";
import { getCategoryStores } from "../../../Functions/GetApiCalls";
import { CollectionTypeFiltering } from "../FilterLogics/CollectionFilters";

const CollectionsMaincontent = (props) => {
  const [loading, setLoading] = useState(false); // State to handle loading state
  const [categoryStoresData, setCategoryStoresData] = useState([]); // State to store fetched category stores data
  const [filteredCategoryData, setFilteredCategoryData] = useState([]); // State to store filtered category data

  // Destructure props
  const {
    selection,
    collectionType,
    style,
    subCollection,
    priceValue,
    color,
    texture,
    material,
    district,
  } = props;

  // Function to fetch data based on the selection
  const fetchData = async () => {
    setLoading(true);
    selection && (await getCategoryStores(setCategoryStoresData, selection));
    setLoading(false);
  };

  // Fetch data on selection change
  useEffect(() => {
    fetchData();
  }, [selection]);

  // Filter data based on the selected filters
  useEffect(() => {
    CollectionTypeFiltering(
      collectionType,
      categoryStoresData,
      setFilteredCategoryData,
      filteredCategoryData,
      selection,
      style,
      subCollection,
      priceValue,
      color,
      texture,
      material,
      district
    );
  }, [
    selection,
    collectionType,
    style,
    subCollection,
    priceValue,
    color,
    texture,
    material,
    district,
    categoryStoresData,
  ]);

  // Pagination logic
  const { pagesVisited, pageCount, changePage, collectionsPerPage } =
    PaginationLogic(categoryStoresData);

  // Function to display collections based on pagination and filters
  const displayCollections =
    collectionType.length === 0 &&
    style.length === 0 &&
    subCollection.length === 0 &&
    color.length === 0 &&
    texture.length === 0 &&
    material.length === 0 &&
    district.length === 0 &&
    priceValue.every((val, index) => val === [0, 100000][index])
      ? categoryStoresData
          ?.slice(pagesVisited, pagesVisited + collectionsPerPage)
          .map((item, index) => (
            <Collections
              selection={selection}
              key={index}
              src={item.shopprofileimage}
              heading={item.shopname}
              description={item.addressLine3}
              item={item}
              collectionType={collectionType}
              district={district}
              priceValue={priceValue}
              style={style}
              subCollection={subCollection}
              color={color}
              texture={texture}
              material={material}
            />
          ))
      : filteredCategoryData
          ?.slice(pagesVisited, pagesVisited + collectionsPerPage)
          .map((item, index) => (
            <Collections
              selection={selection}
              key={index}
              src={item.shopprofileimage}
              heading={item.shopname}
              description={item.addressLine3}
              item={item}
              collectionType={collectionType}
              district={district}
              priceValue={priceValue}
              style={style}
              subCollection={subCollection}
              color={color}
              texture={texture}
              material={material}
            />
          ));

  return (
    <Flex
      flexDir="column"
      m={{ base: 0, md: 2, lg: 8 }}
      mx={{ base: 0, md: 2, lg: 0, xl: 4, "2xl": 8 }}
      py={8}
      px={{ base: 8, md: 8, lg: 8, xl: 10 }}
      bg="white"
      borderRadius="8px"
      boxShadow="2px 4px 4px 0px rgba(0, 0, 0, 0.15) inset"
      alignItems="center"
      justifyContent="space-between"
      minW={{ md: "650px", xl: "800px", "2xl": "940px" }}
      minH={{ md: "1000px", "2xl": "1200px" }}
    >
      {/* Render category stores data or spinner based on loading state */}
      {!loading ? (
        // Render category stores data if available
        categoryStoresData?.length !== 0 && displayCollections?.length !== 0 ? (
          <>
            <Grid
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
              ]}
              gap={8}
            >
              {displayCollections}
            </Grid>
            <Flex
              pt={"25px"}
              alignItems={"center"}
              justifyContent="center"
              boxShadow={"lg"}
              borderRadius="5px"
              m="20px"
              width="fit-content"
            >
              <ReactPaginate
                marginPagesDisplayed={0}
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </Flex>
          </>
        ) : (
          <>
            {/* Render no data message if no data available */}
            No results found
          </>
        )
      ) : (
        // Render spinner while loading
        <Spinner />
      )}
    </Flex>
  );
};

export default CollectionsMaincontent;
