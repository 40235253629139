import { Text, Flex, Container, Image, Box } from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import Header from "../Components/shared/Header";
import React, { useEffect, useState } from "react";
import DeleteIcon from "../Assets/Images/Dashboard/deleticon.png";
import EditIcon from "../Assets/Images/Dashboard/editicon.png";
import { productsdata } from "../Screens/Dashboard/Shop/ViewProductdata.js";
import WishlistHeader from "../../src/Assets/Images/Headers/wishlistHeader.webp";
import WishlistHeaderMob from "../../src/Assets/Images/Headers/MobwishlistHeader.webp";
import FormatIndianRupees from "../../src/Functions/FormatIndianRupees.js";
import { useGetUserData } from "../Functions/queries.js";
import { useNumberOfUsers } from "../NumberOfUsersProvider";
import axios from "axios";
import { API_BASE_URL } from "../Config/index.js";
import { config } from "../Functions/Auth.js";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer.js";
function WishlistScreen() {
  const { numberOfDisplayedCarts, wishlistNumber, setWishlistNumber } =
    useNumberOfUsers();
  const navigate = useNavigate();
  const { user } = useGetUserData();
  
  const [pageNumber, setPageNumber] = useState(0);
  const [wishlistProducts, setWishListProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const usersPerPage = 6;
  const pagesVisited = pageNumber * usersPerPage;
  // TODO: updating wishlist after removal issue
  useEffect(() => {
    const fetchData = async () => {
      user?.data && wishProduct();
    };
    fetchData();
  }, [user]);
  const wishProduct = async () => {
    try {
      const wishlist = user?.data?.wishlist;

      if (!wishlist || wishlist.length === 0) {
        return;
      }
      setLoading(true);
      const wishlistProducts = await Promise.all(
        wishlist.map(async (wish) => {
          try {
            const response = await axios.get(
              `${API_BASE_URL}/shop/products/${wish}`,
              config()
            );
            return {
              product: response.data.product,
              shop: response.data.shop, // Adjust this based on your actual response structure
            }; // Assuming the product data is in response.data
          } catch (error) {
            console.error("Error fetching product:", error);
            return null; // Handle the error gracefully
          }
        })
      );
      setLoading(false);
      setWishListProducts(wishlistProducts);
    } catch (error) {
      console.error("Error fetching wishlist products:", error);
    }
  };
  const addToWishList = async (prodId) => {
    await axios.post(`${API_BASE_URL}/shop/wishlist`, { prodId }, config());
  };
  useEffect(() => {
    setWishlistNumber(wishlistProducts.length);
  }, [wishlistProducts]);

  // setWishlistNumber(wishlistProducts?.length );
  const displayUsers =
    user?.data &&
    wishlistProducts
      .slice(pagesVisited, pagesVisited + usersPerPage)
      .map((details, index) => {
        return (
          <Flex
         width={{base:"70%",md:"70%",lg:"fit-content"}}
            mt={"10px"}
            flexDir={{ base: "column", md: "row", lg: "row" }}
            fontSize={{ base: "13px", md: "13px", lg: "15px" }}
            alignItems={"center"}
            key={index}
            px={{ base: "1", md: "3", lg: "3" }}
            py={2}
            justifyContent={"center"}
            backgroundColor={"#F3F0ED"}
            borderRadius={"10px"}
            boxShadow={"lg"}
          >
            <Box width={{ base: "160px", md: "70px", lg: "120px" }}>
              <Image
                mx={"auto"}
                borderRadius={"10px"}
                width={"60px"}
                height={"60px"}
                objectFit={"cover"}
                src={details?.product?.productimage[0]}
                cursor={"pointer"}
                onClick={() => {
                  navigate(
                    `/stores/${details?.shop._id}/${details?.product?._id}`,
                    {
                      state: { item: details?.shop, product: details?.product },
                      replace: true,
                    }
                  );
                }}
              ></Image>
            </Box>
            <Flex >
              <Text
                fontWeight={"bold"}
                display={{ base: "Flex", md: "none", lg: "none" }}
                mt={{ base: "10px", md: "0", lg: "0" }}
                mr={"5px"}
              >
                Product:
              </Text>
              <Text
                textAlign={"center"}
                mt={{ base: "10px", md: "0", lg: "0" }}
                alignSelf={"center"}
                // ml={{base:"auto",md:"18px",lg:"18px"}}
                width={{ base: "", md: "70px", lg: "120px" }}
              >
                {details?.product?.productname}
                {/* {wish} */}
              </Text>
            </Flex>

            <Flex>
              <Text
                fontWeight={"bold"}
                display={{ base: "Flex", md: "none", lg: "none" }}
                // mt={{ base: "10px", md: "0", lg: "0" }}
                mr={"5px"}
              >
                Store:
              </Text>
              <Text
                textAlign={"center"}
                // mt={{ base: "10px", md: "0", lg: "0" }}
                width={{ base: "", md: "70px", lg: "120px" }}
              >
                {details?.shop?.shopname}
              </Text>
            </Flex>

            <Flex>
              <Text
                fontWeight={"bold"}
                display={{ base: "Flex", md: "none", lg: "none" }}
                mr={"5px"}
              >
                Contact No:
              </Text>
              <Text
                textAlign={"center"}
                width={{ base: "", md: "75px", lg: "120px" }}
              >
                {details?.shop?.phone}
              </Text>
            </Flex>

            <Flex>
              <Text
                fontWeight={"bold"}
                display={{ base: "Flex", md: "none", lg: "none" }}
                mr={"5px"}
              >
                Price:
              </Text>
              <Text
                textAlign={"center"}
                width={{ base: "", md: "70px", lg: "120px" }}
              >
                {details?.product?.price &&
                  FormatIndianRupees(Number(details?.product?.price))}
              </Text>
            </Flex>
            <Flex
              textAlign={"center"}
              width={{ base: "160px", md: "70px", lg: "120px" }}
            >
              <Image
                mx={"auto"}
                width={"25px"}
                height={"25px"}
                src={DeleteIcon}
                onClick={() => {
                  addToWishList(details?.product?._id);
                  const updatedWishlist = wishlistProducts.filter(
                    (product) => product?.product?._id !== details?.product?._id
                  );
                  // Update the state to reflect the removed item
                  setWishListProducts(updatedWishlist);
                }}
                cursor={"pointer"}
              ></Image>
            </Flex>
          </Flex>
        );
      });
  const pageCount = Math.ceil(wishlistProducts.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
useEffect(() => {
  setWishlistNumber(wishlistProducts.length );
}, [wishlistProducts.length]);
  return (
    <Flex
      flexDir={"column"}
      bg={
        "linear-gradient(180deg, #F2E1D0 20.87%, rgba(255, 255, 255, 0.99) 100%)"
      }
    >
      <Container maxW="2000px" width={"100vw"} p={0}>
        <Header
          heading="My Wishlist"
          image={WishlistHeader}
          imageMob={WishlistHeaderMob}
        />

        {/* Form area */}
        <Flex width={"100%"} flexDir={"column"}>
          <Flex
            fontSize={{ base: "13px", md: "13px", lg: "15px" }}
            display={{ base: "none", md: "flex", lg: "flex" }}
            justifyContent={"center"}
            width={"100%"}
            p={4}
          >
            <Flex justifyContent={"center"} fontFamily={"heading"}>
              <Text
                textAlign={"center"}
                width={{ base: "160px", md: "70px", lg: "120px" }}
              >
                Product
              </Text>
              <Text
                textAlign={"center"}
                width={{ base: "160px", md: "70px", lg: "120px" }}
              >
                Name
              </Text>
              <Text
                textAlign={"center"}
                width={{ base: "160px", md: "70px", lg: "120px" }}
              >
                Shop
              </Text>
              <Text
                textAlign={"center"}
                width={{ base: "160px", md: "70px", lg: "120px" }}
              >
                Contact No.
              </Text>
              <Text
                width={{ base: "160px", md: "70px", lg: "120px" }}
                textAlign={"center"}
              >
                Price
              </Text>
              <Text
                width={{ base: "160px", md: "70px", lg: "120px" }}
                textAlign={"center"}
              >
                Action
              </Text>
            </Flex>
          </Flex>
          <Flex
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mb={"20px"}
          >
            {displayUsers}
          </Flex>
          <Flex p={5} justifyContent={"center"} alignItems={"center"}>
            <Flex boxShadow={"lg"}>
              {" "}
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                marginPagesDisplayed={0}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </Flex>
          </Flex>
        </Flex>
      </Container>
      <Footer/>
    </Flex>
  );
}

export default WishlistScreen;
