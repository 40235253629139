import React, { useState } from "react";
import { Input, InputGroup, Flex } from "@chakra-ui/react";
import "../../Assets/FontAsset/Marko_One/MarkoOne-Regular.ttf";

function SearchBar(props) {
  const { width = "25%", height = "50px"} = props;
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query) => {
    // search logic
  };

  const handleChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    handleSearch(query); // Trigger the search as the user types
  };

  return (
    <Flex justify={"center"} width={width}>
      <InputGroup>
        <Input
          bg={"whiteAlpha.300"}
          border={"none"}
          type="text"
          placeholder="Search..."
          fontFamily={"'Marko One', serif"}
          value={searchQuery}
          onChange={handleChange}
          borderRadius={"40px"}
          height={height}
          width={width}
          fontSize={{ base: "12px", md: "20px" }}
          _placeholder={{
            opacity: 1,
            color: "whiteAlpha.700",
            fontSize: { base: "15px", md: "20px" },
          }}
          color={"white"}
        />
      </InputGroup>
    </Flex>
  );
}

export default SearchBar;
