import { Flex, Text } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  RangeSlider,
  RangeSliderMark,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Image,
  filter,
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useState } from "react";

// TODO change price filter to 1lakh plus
import { furnitureFilterObjects } from "./FilterObjects/FurnitureFilterObjects";
import { furnitureFilterMaterial } from "./FilterObjects/FurnitureFilterMaterial";
import { districtFilterObjects } from "./FilterObjects/DistrictFilterObjects";
import { colorFilterObjects } from "./FilterObjects/ColorFilterObjects";
import FormatIndianRupees from "../../../Functions/FormatIndianRupees";
import {
  clearAllChecked,
  clearAllCheckedDistricts,
  handleDistrictChange,
  handleFilterChange,
} from "./FilterFunctions/FilterFunctions";
import { useLocation } from "react-router-dom";
function CommonFilter(props) {
  const {
    // bg = 'brand.FilterBg',
    bg = "white",
    width = "300px",
    p = "5",
    // m = "5",
    borderRadius = "10px",
    FilterObjects = furnitureFilterObjects,
    MaterialObjects = furnitureFilterMaterial,
    material = [],
    setMaterial = () => {},
    collection = [],
    setCollection,
    subCollection = [],
    setSubCollection = () => {},
    FilterTitle = "Filter Stores",
    Filtersub1 = "Filter by Products",
    Filtersub2 = "Filter by Price",
    Filtersub3 = "Filter by Style",
    Filtersub4 = "Filter by District",
    Filtersub5 = "Filter by Profession",
    Productfilter,
    Pricefilter,
    priceValue = [0, 100000],
    setPriceValue = () => {},
    StyleFilter,
    MaterialFilter,
    TextureFilter,
    texture = [],
    setTexture = () => {},
    style = [],
    setStyle = () => {},
    DistrictFilter,
    ColorFilter,
    color = [],
    setColor = () => {},
    district = [],
    setDistrict = () => {},
    ProfessionFilter,
    profession = [],
    setProfession = () => {},
    selection,
    collectionStore,
  } = props;
  const [selectedItems, setSelectedItems] = useState([]);

  // get obtained filters from back button
  const location = useLocation();
  const filters = location?.state?.filters;

  // Toggle checkbox with sub checkbox
  const toggleSelection = (itemName) => {
    if (selectedItems.includes(itemName)) {
      setSelectedItems(selectedItems.filter((item) => item !== itemName));
    } else {
      setSelectedItems([...selectedItems, itemName]);
    }
  };
  // states to manage checked items in each category
  const [checkedColors, setCheckedColors] = useState({});
  const [checkedDistricts, setCheckedDistricts] = useState({});
  const [checkedCollections, setCheckedCollections] = useState({});
  const [checkedStyles, setCheckedStyles] = useState({});
  const [checkedProfession, setCheckedProfession] = useState({});
  const [checkedMaterial, setCheckedMaterial] = useState({});
  const [checkedTexture, setCheckedTexture] = useState({});
  const StyleObjects = [
    { name: "Classic" },
    { name: "Modern" },
    { name: "Ethnic" },
  ];
  const ProfessionObjects = [
    { name: "Artist" },
    { name: "Interior Designer" },
    { name: "Architect" },
  ];
  const TextureObjects = [{ name: "Glossy" }, { name: "Matt" }];
  useEffect(() => {
    // if not calling filter in collections single store and also filters is not passed (means not clicking back button)
    if (!collectionStore && !filters) {
      clearAllChecked(
        FilterObjects,
        setCheckedCollections,
        setCollection,
        subCollection,
        setSubCollection,
        setSelectedItems
      );
      clearAllChecked(colorFilterObjects, setCheckedColors, setColor);
      clearAllChecked(StyleObjects, setCheckedStyles, setStyle);
      clearAllChecked(districtFilterObjects, setCheckedDistricts, setDistrict);
      setPriceValue([0, 100000]);
      clearAllChecked(MaterialObjects, setCheckedMaterial, setMaterial);
      clearAllChecked(districtFilterObjects, setCheckedDistricts, setDistrict);
      clearAllChecked(texture, setCheckedTexture, setTexture);
    } else {
      collection.map((item, index) => {
        setCheckedCollections((prevCheckedItems) => ({
          ...prevCheckedItems,
          [item]: !prevCheckedItems[item],
        }));
      });
      subCollection.map((item, index) => {
        setCheckedCollections((prevCheckedItems) => ({
          ...prevCheckedItems,
          [item]: !prevCheckedItems[item],
        }));
      });
      style.map((item, index) => {
        setCheckedStyles((prevCheckedItems) => ({
          ...prevCheckedItems,
          [item]: !prevCheckedItems[item],
        }));
      });
      district.map((item, index) => {
        setCheckedDistricts((prevCheckedItems) => ({
          ...prevCheckedItems,
          [item]: !prevCheckedItems[item],
        }));
      });
      material.map((item, index) => {
        setCheckedMaterial((prevCheckedItems) => ({
          ...prevCheckedItems,
          [item]: !prevCheckedItems[item],
        }));
      });
      color.map((item, index) => {
        setCheckedColors((prevCheckedItems) => ({
          ...prevCheckedItems,
          [item]: !prevCheckedItems[item],
        }));
      });
      texture.map((item, index) => {
        setCheckedTexture((prevCheckedItems) => ({
          ...prevCheckedItems,
          [item]: !prevCheckedItems[item],
        }));
      });

      setSelectedItems(collection);
    }
  }, [selection, FilterObjects]);
  return (
    <Flex
      display={{ base: "none", md: "none", lg: "block" }}
      p={p}
      mx={5}
      boxShadow={"lg"}
      backgroundColor={bg}
      width={width}
      borderRadius={borderRadius}
      flexDirection={"column"}
    >
      <Flex mb={"8px"}>
        <Text as={"b"} fontFamily={"heading"} fontSize={"15.5px"}>
          {FilterTitle}
        </Text>
        <Button
          p="0"
          fontSize={"13px"}
          fontFamily={"heading"}
          bg="none"
          // as={"Text"}
          color={"#838383"}
          marginLeft={"auto"}
          // boxShadow={"inner"}
          _hover={{ bg: "none" }}
          height={"25px"}
          width={"10px"}
          onClick={() => {
            clearAllChecked(StyleObjects, setCheckedStyles, setStyle);
            setPriceValue([0, 100000]);
            clearAllChecked(
              FilterObjects,
              setCheckedCollections,
              setCollection,
              subCollection,
              setSubCollection,
              setSelectedItems
            );
            clearAllChecked(
              districtFilterObjects,
              setCheckedDistricts,
              setDistrict
            );
            clearAllChecked(colorFilterObjects, setCheckedColors, setColor);
            clearAllChecked(
              ProfessionObjects,
              setCheckedProfession,
              setProfession
            );
            clearAllChecked(MaterialObjects, setCheckedMaterial, setMaterial);
            clearAllChecked(TextureObjects, setCheckedTexture, setTexture);
          }}
        >
          Clear
        </Button>
        {/* <Text
          fontSize={"13px"}
          fontFamily={"heading"}
          as={"b"}
          color={"#838383"}
          marginLeft={"auto"}
        >
          Clear
        </Text> */}
      </Flex>
      <hr
        style={{
          color: "#838383",
          borderColor: "#838383",
        }}
      />
      <Accordion defaultIndex={[0]} allowMultiple>
        {/* Collection Filter */}

        {FilterObjects && Productfilter == 1 && (
          <Flex
            flexDir={"column"}
            style={{ display: Productfilter === 1 ? "flex" : "none" }}
            mt={"25px"}
          >
            {" "}
            <AccordionItem>
              <Flex width={"100%"} alignItems={"center"}>
                <AccordionButton width={"fit-content"}>
                  <AccordionIcon />
                </AccordionButton>
                <Text
                  ml={"5px"}
                  as={"b"}
                  fontFamily={"heading"}
                  fontSize={"13px"}
                  color={"#838383"}
                >
                  {Filtersub1}
                </Text>
                <Button
                  boxShadow={"inner"}
                  marginLeft={"auto"}
                  height={"15px"}
                  width={"10px"}
                  onClick={() =>
                    clearAllChecked(
                      FilterObjects,
                      setCheckedCollections,
                      setCollection,
                      subCollection,
                      setSubCollection,
                      setSelectedItems
                    )
                  }
                >
                  -
                </Button>
              </Flex>
              <AccordionPanel>
                {FilterObjects.map((item,index) => {
                  return (
                    <Flex key={index} flexDir={"column"} mt={"10px"}>
                      <Checkbox
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        value={item.name}
                        isChecked={checkedCollections[item.name]}
                        onChange={(e) => {
                          toggleSelection(e.target.value);
                          if (checkedCollections[item.name]) {
                            //An array of sub-collection keys
                            const subCollectionKeys = [
                              item.sub1,
                              item.sub2,
                              item.sub3,
                              item.sub4,
                            ];

                            //loop to set each sub-collection to false
                            subCollectionKeys.forEach((subKey) => {
                              checkedCollections[subKey] = false;
                            });

                            const newSubCollection = subCollection.filter(
                              (subItem) => !subCollectionKeys.includes(subItem)
                            );

                            // Update the subCollection array
                            setSubCollection(newSubCollection);
                          }
                          handleFilterChange(
                            e,
                            setCollection,
                            collection,
                            setCheckedCollections
                          );
                        }}
                      >
                        {item.name}
                      </Checkbox>
                      {/* Sub Checkboxes if present */}
                      {selectedItems.includes(item.name) && item.sub1 && (
                        <Checkbox
                          ml={5}
                          colorScheme="orange"
                          borderColor={"brand.btnBg"}
                          value={item.sub1}
                          isChecked={checkedCollections[item.sub1]}
                          onChange={(e) => {
                            handleFilterChange(
                              e,
                              setSubCollection,
                              subCollection,
                              setCheckedCollections
                            );
                          }}
                        >
                          {item.sub1}
                        </Checkbox>
                      )}

                      {selectedItems.includes(item.name) && item.sub2 && (
                        <Checkbox
                          ml={5}
                          colorScheme="orange"
                          borderColor={"brand.btnBg"}
                          value={item.sub2}
                          isChecked={checkedCollections[item.sub2]}
                          onChange={(e) => {
                            handleFilterChange(
                              e,
                              setSubCollection,
                              subCollection,
                              setCheckedCollections
                            );
                          }}
                        >
                          {item.sub2}
                        </Checkbox>
                      )}

                      {selectedItems.includes(item.name) && item.sub3 && (
                        <Checkbox
                          ml={5}
                          colorScheme="orange"
                          borderColor={"brand.btnBg"}
                          value={item.sub3}
                          isChecked={checkedCollections[item.sub3]}
                          onChange={(e) => {
                            handleFilterChange(
                              e,
                              setSubCollection,
                              subCollection,
                              setCheckedCollections
                            );
                          }}
                        >
                          {item.sub3}
                        </Checkbox>
                      )}

                      {selectedItems.includes(item.name) && item.sub4 && (
                        <Checkbox
                          ml={5}
                          colorScheme="orange"
                          borderColor={"brand.btnBg"}
                          value={item.sub4}
                          isChecked={checkedCollections[item.sub4]}
                          onChange={(e) => {
                            handleFilterChange(
                              e,
                              setSubCollection,
                              subCollection,
                              setCheckedCollections
                            );
                          }}
                        >
                          {item.sub4}
                        </Checkbox>
                      )}

                      {/* Sub Checkboxes if present  */}
                    </Flex>
                  );
                })}
              </AccordionPanel>
            </AccordionItem>
          </Flex>
        )}
        {/* Collection Filter */}
        {/* PriceFilter */}

        {Pricefilter == 1 && (
          <Flex
            flexDir={"column"}
            style={{ display: Pricefilter === 1 ? "flex" : "none" }}
            mt={"25px"}
          >
            <AccordionItem>
              <Flex width={"100%"} alignItems={"center"}>
                <AccordionButton width={"fit-content"}>
                  <AccordionIcon />
                </AccordionButton>
                <Text
                  ml={"5px"}
                  as={"b"}
                  fontFamily={"heading"}
                  fontSize={"13px"}
                  color={"#838383"}
                >
                  {Filtersub2}
                </Text>
                <Button
                  boxShadow={"inner"}
                  marginLeft={"auto"}
                  height={"15px"}
                  width={"10px"}
                  onClick={() => setPriceValue([0, 100000])}
                >
                  -
                </Button>
              </Flex>
              <AccordionPanel>
                <Flex py={3} pr={"0px"} flexDir={"column"} mt={"60px"}>
                  <RangeSlider
                    width={"202px"}
                    mx={"auto"}
                    aria-label={["min", "max"]}
                    step={1000}
                    defaultValue={priceValue}
                    onChange={(val) => setPriceValue(val)}
                    value={priceValue}
                    min={0}
                    colorScheme="orange"
                    max={100000}
                  >
                    {/* .... */}
                    <RangeSliderMark value={0} mt="1" ml="-2.5" fontSize="13px">
                      {FormatIndianRupees(0)}
                    </RangeSliderMark>
                    <RangeSliderMark
                      value={50000}
                      mt="1"
                      ml="-2.5"
                      fontSize="13px"
                    >
                      {FormatIndianRupees(50000)}
                    </RangeSliderMark>
                    <RangeSliderMark
                      value={100000}
                      mt="1"
                      ml="-49"
                      fontSize="13px"
                    >
                      {FormatIndianRupees(100000)}+
                    </RangeSliderMark>
                    <RangeSliderMark
                      fontSize="11px"
                      value={priceValue[0]}
                      textAlign="center"
                      bg="brand.btnBg"
                      borderRadius={"5px"}
                      color="white"
                      mt="-20px"
                      ml="-5"
                      w="12"
                    >
                      {FormatIndianRupees(priceValue[0])}
                    </RangeSliderMark>
                    <RangeSliderMark
                      fontSize="11px"
                      value={priceValue[1]}
                      textAlign="center"
                      bg="brand.btnBg"
                      borderRadius={"5px"}
                      color="white"
                      mt="-40px"
                      ml="-5"
                      w="12"
                    >
                      {FormatIndianRupees(priceValue[1])}
                    </RangeSliderMark>
                    {/* .... */}
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack />
                    </RangeSliderTrack>
                    <RangeSliderThumb backgroundColor={"#45270D"} index={0} />
                    <RangeSliderThumb backgroundColor={"#45270D"} index={1} />
                  </RangeSlider>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Flex>
        )}
        {/* PriceFilter */}
        {/* Style Filter */}

        {StyleFilter == 1 && (
          <Flex
            mt={"25px"}
            style={{ display: StyleFilter === 1 ? "flex" : "none" }}
            flexDirection={"column"}
          >
            {" "}
            <AccordionItem>
              <Flex width={"100%"} alignItems={"center"}>
                <AccordionButton width={"fit-content"}>
                  <AccordionIcon />
                </AccordionButton>
                <Text
                  ml={"5px"}
                  as={"b"}
                  fontFamily={"heading"}
                  fontSize={"13px"}
                  color={"#838383"}
                >
                  {Filtersub3}
                </Text>
                <Button
                  boxShadow={"inner"}
                  marginLeft={"auto"}
                  height={"15px"}
                  width={"10px"}
                  onClick={() =>
                    clearAllChecked(StyleObjects, setCheckedStyles, setStyle)
                  }
                >
                  -
                </Button>
              </Flex>
              <AccordionPanel>
                <Flex flexDir={"column"} mt={"10px"}>
                  <Checkbox
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                    value={"Classic"}
                    isChecked={checkedStyles["Classic"]}
                    onChange={(e) =>
                      handleFilterChange(e, setStyle, style, setCheckedStyles)
                    }
                  >
                    Classic
                  </Checkbox>
                  <Checkbox
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                    value={"Modern"}
                    isChecked={checkedStyles["Modern"]}
                    onChange={(e) =>
                      handleFilterChange(e, setStyle, style, setCheckedStyles)
                    }
                  >
                    Modern
                  </Checkbox>
                  <Checkbox
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                    value={"Ethnic"}
                    isChecked={checkedStyles["Ethnic"]}
                    onChange={(e) =>
                      handleFilterChange(e, setStyle, style, setCheckedStyles)
                    }
                  >
                    Ethnic
                  </Checkbox>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Flex>
        )}
        {/* Style Filter */}
        {/* District Filter */}

        {DistrictFilter == 1 && (
          <Flex
            mt={"25px"}
            style={{ display: DistrictFilter === 1 ? "flex" : "none" }}
            flexDirection={"column"}
          >
            {" "}
            <AccordionItem>
              <Flex width={"100%"} alignItems={"center"}>
                <AccordionButton width={"fit-content"}>
                  {" "}
                  <AccordionIcon />
                </AccordionButton>

                <Text
                  ml="5px"
                  as={"b"}
                  fontFamily={"heading"}
                  fontSize={"13px"}
                  color={"#838383"}
                >
                  {Filtersub4}
                </Text>
                <Button
                  boxShadow={"inner"}
                  marginLeft={"auto"}
                  height={"15px"}
                  width={"10px"}
                  onClick={() =>
                    clearAllChecked(
                      districtFilterObjects,
                      setCheckedDistricts,
                      setDistrict
                    )
                  }
                >
                  -
                </Button>
              </Flex>
              <AccordionPanel>
                {districtFilterObjects.map((itemd, index) => {
                  return (
                    <Flex key={index} flexDir={"column"} mt={"10px"}>
                      <Checkbox
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        isChecked={checkedDistricts[itemd.name]}
                        onChange={(e) =>
                          handleFilterChange(
                            e,
                            setDistrict,
                            district,
                            setCheckedDistricts
                          )
                        }
                        value={itemd.name}
                        name={"district"}
                      >
                        {itemd.name}
                      </Checkbox>
                    </Flex>
                  );
                })}{" "}
              </AccordionPanel>
            </AccordionItem>{" "}
          </Flex>
        )}
        {/* District Filter */}
        {/* DesignerFilter */}

        {ProfessionFilter == 1 && (
          <Flex
            mt={"25px"}
            style={{ display: ProfessionFilter === 1 ? "flex" : "none" }}
            flexDirection={"column"}
          >
            {" "}
            <AccordionItem>
              <Flex width={"100%"} alignItems={"center"}>
                <AccordionButton>
                  <AccordionIcon />
                </AccordionButton>
                <Text
                  ml={"5px"}
                  as={"b"}
                  minW={"160px"}
                  fontFamily={"heading"}
                  fontSize={"13px"}
                  color={"#838383"}
                >
                  {Filtersub5}
                </Text>
                <Button
                  boxShadow={"inner"}
                  marginLeft={"auto"}
                  height={"15px"}
                  width={"10px"}
                  onClick={() =>
                    clearAllChecked(
                      ProfessionObjects,
                      setCheckedProfession,
                      setProfession
                    )
                  }
                >
                  -
                </Button>
              </Flex>
              <AccordionPanel>
                <Flex flexDir={"column"} mt={"10px"}>
                  <Checkbox
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                    value={"Artist"}
                    isChecked={checkedProfession["Artist"]}
                    onChange={(e) =>
                      handleFilterChange(
                        e,
                        setProfession,
                        profession,
                        setCheckedProfession
                      )
                    }
                  >
                    Artist
                  </Checkbox>
                  <Checkbox
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                    value={"Interior Designer"}
                    isChecked={checkedProfession["Interior Designer"]}
                    onChange={(e) =>
                      handleFilterChange(
                        e,
                        setProfession,
                        profession,
                        setCheckedProfession
                      )
                    }
                  >
                    Interior Designer
                  </Checkbox>
                  <Checkbox
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                    value={"Architect"}
                    isChecked={checkedProfession["Architect"]}
                    onChange={(e) =>
                      handleFilterChange(
                        e,
                        setProfession,
                        profession,
                        setCheckedProfession
                      )
                    }
                  >
                    Architect
                  </Checkbox>
                </Flex>
              </AccordionPanel>
            </AccordionItem>{" "}
          </Flex>
        )}
        {/* Designer Filter */}

        {/* Color Filter */}

        {ColorFilter == 1 && (
          <Flex
            mt={"25px"}
            style={{ display: ColorFilter === 1 ? "flex" : "none" }}
            flexDirection={"column"}
          >
            <AccordionItem>
              <Flex width={"100%"} alignItems={"center"}>
                <AccordionButton width={"fit-content"}>
                  <AccordionIcon />
                </AccordionButton>
                <Text
                  ml={"5px"}
                  as={"b"}
                  fontFamily={"heading"}
                  fontSize={"13px"}
                  color={"#838383"}
                >
                  Filter by Colour
                </Text>
                <Button
                  boxShadow={"inner"}
                  marginLeft={"auto"}
                  height={"15px"}
                  width={"10px"}
                  onClick={() =>
                    clearAllChecked(
                      colorFilterObjects,
                      setCheckedColors,
                      setColor
                    )
                  }
                >
                  -
                </Button>
              </Flex>
              <AccordionPanel>
                {colorFilterObjects.map((itemc,index) => {
                  return (
                    <Flex key={index} flexDir={"row"} mt={"10px"}>
                      <Checkbox
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        isChecked={checkedColors[itemc.name]}
                        onChange={(e) =>
                          handleFilterChange(
                            e,
                            setColor,
                            color,
                            setCheckedColors
                          )
                        }
                        value={itemc.name}
                        name={"color"}
                      >
                        <Flex>
                          <Image
                            alignSelf={"center"}
                            width={"15px"}
                            height={"15px"}
                            src={itemc.colorimage}
                          ></Image>
                          <Text ml={"5px"}> {itemc.name}</Text>
                        </Flex>
                      </Checkbox>
                    </Flex>
                  );
                })}
              </AccordionPanel>
            </AccordionItem>
          </Flex>
        )}
        {/* Color Filter */}
        {/* Texture Filter */}

        {TextureFilter == 1 && (
          <Flex
            mt={"25px"}
            style={{ display: TextureFilter === 1 ? "flex" : "none" }}
            flexDirection={"column"}
          >
            <AccordionItem>
              <Flex width={"100%"} alignItems={"center"}>
                <AccordionButton width={"fit-content"}>
                  <AccordionIcon />
                </AccordionButton>
                <Text
                  ml={"5px"}
                  as={"b"}
                  fontFamily={"heading"}
                  fontSize={"13px"}
                  color={"#838383"}
                >
                  Filter by Texture
                </Text>
                <Button
                  boxShadow={"inner"}
                  marginLeft={"auto"}
                  height={"15px"}
                  width={"10px"}
                  onClick={() =>
                    clearAllChecked(
                      TextureObjects,
                      setCheckedTexture,
                      setTexture
                    )
                  }
                >
                  -
                </Button>
              </Flex>
              <AccordionPanel>
                <Flex flexDir={"column"} mt={"10px"}>
                  <Checkbox
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                    value={"Glossy"}
                    isChecked={checkedTexture["Glossy"]}
                    onChange={(e) =>
                      handleFilterChange(
                        e,
                        setTexture,
                        texture,
                        setCheckedTexture
                      )
                    }
                  >
                    Glossy
                  </Checkbox>
                  <Checkbox
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                    value={"Matt"}
                    isChecked={checkedTexture["Matt"]}
                    onChange={(e) =>
                      handleFilterChange(
                        e,
                        setTexture,
                        texture,
                        setCheckedTexture
                      )
                    }
                  >
                    Matt
                  </Checkbox>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Flex>
        )}
        {/* Texture Filter */}

        {/* Material Filter */}

        {MaterialObjects && MaterialFilter == 1 && (
          <Flex
            flexDir={"column"}
            style={{ display: MaterialFilter === 1 ? "flex" : "none" }}
            mt={"25px"}
          >
            {" "}
            <AccordionItem>
              <Flex width={"100%"} alignItems={"center"}>
                <AccordionButton width={"fit-content"}>
                  <AccordionIcon />
                </AccordionButton>

                <Text
                  ml={"5px"}
                  as={"b"}
                  fontFamily={"heading"}
                  fontSize={"13px"}
                  color={"#838383"}
                >
                  Filter by Material
                </Text>
                <Button
                  boxShadow={"inner"}
                  marginLeft={"auto"}
                  height={"15px"}
                  width={"10px"}
                  onClick={() =>
                    clearAllChecked(
                      MaterialObjects,
                      setCheckedMaterial,
                      setMaterial
                    )
                  }
                >
                  -
                </Button>
              </Flex>
              <AccordionPanel>
                {MaterialObjects.map((item,index) => {
                  return (
                    <Flex key={index} flexDir={"column"} mt={"10px"}>
                      <Checkbox
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        value={item.name}
                        isChecked={checkedMaterial[item.name]}
                        onChange={(e) =>
                          handleFilterChange(
                            e,
                            setMaterial,
                            material,
                            setCheckedMaterial
                          )
                        }
                      >
                        {item.name}
                      </Checkbox>
                    </Flex>
                  );
                })}
              </AccordionPanel>
            </AccordionItem>
          </Flex>
        )}
        {/* Material Filter */}
      </Accordion>
    </Flex>
  );
}

export default CommonFilter;
