
import { Flex, Grid, Image } from "@chakra-ui/react";
import React, { useState } from "react";

function GalleryContent(props) {
  const [zoomedIndex, setZoomedIndex] = useState(null);

  const toggleZoom = (index) => {
    setZoomedIndex(index === zoomedIndex ? null : index);
  };
  
  const { Gallery } = props;

  return (
    <Flex width="100%">
      <Grid
        templateColumns={[
          "repeat(4, 1fr)",
          "repeat(4, 1fr)",
          "repeat(4, 1fr)",
          "repeat(8, 1fr)",
        ]}
        gap={8}
        width="100%"
      >
        {Gallery?.map((item, index) => (
          <Image
            key={index}
            src={item.src}
            alt={`Image ${index}`}
            borderRadius="10px"
            height={{base:"100px",md:"150px",lg:"150px"}}
            width={{base:"100px",md:"150px",lg:"150px"}}
            objectFit="cover"
            onClick={() => toggleZoom(index)}
            cursor="pointer"
          />
        ))}
      </Grid>
      {zoomedIndex !== null && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          
        
          justifyContent="center"
          alignItems="center"
          bg="rgba(255, 255, 255, 0.2)"
          zIndex="999"
          onClick={() => setZoomedIndex(null)}
        >
          <Image
            src={Gallery[zoomedIndex].src}
            maxW="70%"
            maxH="70%"
            borderRadius="10px"
            objectFit="contain"
          />
        </Flex>
      )}
    </Flex>
  );
}

export default GalleryContent;

