import { Button, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";

function Breadcrumbs() {
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((x) => x);
  
  let breadcrumbPath = "";

  return (
    <Flex my={{base:"20px",md:"20px",lg:"30px"}}
    // ml={{base:"",md:"",lg:"15%"}}
    backgroundColor={"#EEDBC6"}
    width={"fit-content"}
    px={"10px"}
    py={"5px"}
    borderRadius={"5px"}
    boxShadow={"lg"}

    className="breadcrumbs">
      <Link className="breadlink" to="/home">Home</Link>
      {pathnames.map((name, index) => {
         const sanitizedName = name.replace(/%20/g, " "); // Replace spaces with a dash
        const breadcrumbPath = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <span className="bredname" key={breadcrumbPath}>/ {sanitizedName}</span>
        ) : (
          <span className="breadlink" key={breadcrumbPath}>
            / <Link  to={breadcrumbPath}>{sanitizedName}</Link>
          </span>
        );
      })}
    </Flex>
  );
}

export default Breadcrumbs;
