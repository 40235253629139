import {
  Box,
  Flex,
  IconButton,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import ReactStars from "react-rating-stars-component";

import Shop from "../../../Assets/DummyCollections/Shop.png";

import Mapicon from "../../../Assets/Images/map.png";
import Phoneicon from "../../../Assets/Images/phone.png";
import Addressicon from "../../../Assets/Images/address.png";
import Webicon from "../../../Assets/Images/web.png";
import Mailicon from "../../../Assets/Images/mail.png";

function AddressItem({ address }) {
  return (
    <Text
      fontSize={{ base: "12px", md: "14px", lg: "14px" }}
      fontWeight="light"
    >
      {address}
    </Text>
  );
}
function StoreInfoCard(props) {
  const {
    name = "Store Name",
    rating = 4,
    description = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    phone = "0000000000",
    mail = "shopMail@gmnail.com",
    website = "shop.com",
    address1 = "",
    address2 = "",
    address3 = "",
    address4 = "",
    address5 = "",
    shopprofileimage = Shop,
    subscription,
    phoneSub,
    location = "",
    locationlink,
  } = props;
  // Split the location string by comma
  // const [latitude, longitude] = location.split(',');

  // Convert latitude and longitude to floating-point numbers
  // const latitudeValue = parseFloat(latitude);
  // const longitudeValue = parseFloat(longitude);
  // const googleMapURL = `https://www.google.com/maps/dir//${latitudeValue},${longitudeValue}/@10.5086976,76.1954304,8z?entry=ttu`;
  return (
    <Flex
   
      zIndex={2}
      width={"100%"}
      justifyContent={{ md: "center", lg: "flex-start" }}
    >
      <Flex
    
        backdropFilter="auto"
        backdropBlur="6px"
        boxShadow={"0px 4px 55px 0px rgba(0, 0, 0, 0.25) inset"}
        borderRadius={"14px"}
        bg={
          "linear-gradient(180deg, rgba(255, 255, 255, 0.83) 0%, rgba(232, 210, 189, 0.80) 100%)"
        }
        width={{ base: "100%", md: "80%", lg: "60%" }}
        p={6}
        px={{ base: 4, md: 14 }}
        ml={{ base: 0, md: 0, lg: 8, xl: 20 }}
      >
        <Flex
          py={"10px"}
          flexDir={{ base: "column-reverse", md: "row" }}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Flex
            flexDir={"column"}
            width={{ base: "100%", md: "60%", lg: "60%" }}
          >
            <Text
              fontFamily={"heading"}
              fontWeight={"bold"}
              fontSize={{ base: "26px", md: "30px" }}
              display={{ base: "none", md: "flex" }}
            >
              {name}
            </Text>
            {/* <ReactStars
              count={5}
              value={rating}
              size={24}
              edit={false}
              activeColor="#ffd700"
            /> */}
            <Text
              p={"10px"}
              fontWeight={"light"}
              fontSize={{ base: "13px", md: "14px", lg: "14px" }}
              whiteSpace="pre-line"
            >
              {description}
            </Text>
            <Flex   flexDir={"column"} width={"100%"}>
              <SimpleGrid
             
                width={"100%"}
                fontSize={{ base: "13px", md: "15px", lg: "15px" }}
                columns={{ base: 1, md: 1, lg: 2 }}
                spacing={{ base: "0px", md: "0px", lg: "5px" }}
              >
                {/* Website */}
                <Flex py={1} width={"100%"}>
                  <Flex>
                    <Flex justifyContent="center">
                      <Image
                        mb="auto"
                        width={"25px"}
                        mr={"10px"}
                        objectFit={"contain"}
                        src={Webicon}
                      ></Image>
                    </Flex>
                    <Link my={"auto"} href={website} isExternal>
                    <Text
                      color={"blue.700"}
                      fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                      fontWeight="light"
                      maxW={{ base: "200px", md: "180px", lg: "200px" }}
                      my={"auto"}
                    >
                      {website}
                    </Text></Link>
                  </Flex>
                </Flex>

                {/* Email */}
                <Flex py={1} width={"100%"}>
                  <Flex>
                    <Flex justifyContent="center">
                      <Image
                        width={"25px"}
                        mr={"10px"}
                        objectFit={"contain"}
                        src={Mailicon}
                      ></Image>
                    </Flex>
                    <Text
                      fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                      fontWeight="light"
                      my={"auto"}
                    >
                      {mail}
                    </Text>
                  </Flex>
                </Flex>

                {/* Phone */}
                <Flex py={1} width={"100%"}>
                  <Flex>
                    <Flex justifyContent="center">
                      <Image
                        width={"25px"}
                        mr={"10px"}
                        objectFit={"contain"}
                        src={Phoneicon}
                      ></Image>
                    </Flex>
                    <Text
                      fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                      fontWeight="light"
                      my={"auto"}
                    >
                      {phone}
                    </Text>
                    {phoneSub && (
                      <Text
                        fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                        fontWeight="light"
                        my={"auto"}
                      >
                        ,{phoneSub}
                      </Text>
                    )}
                  </Flex>
                </Flex>

                {/* location */}
                <Flex py={1} width={"100%"}>
                  {locationlink && (
                    <Flex>
                      <Flex justifyContent="center">
                        <Image
                          width={"25px"}
                          mr={"10px"}
                          objectFit={"contain"}
                          src={Mapicon}
                        />
                      </Flex>
                      <Link my={"auto"} href={locationlink} isExternal>
                        <Text
                          width={"150px"}
                          color={"blue.700"}
                          fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                          fontWeight="light"
                        >
                          Google Map Location
                        </Text>
                      </Link>
                    </Flex>
                  )}
                </Flex>

                {/* address */}
                <Flex py={1} width={"100%"}>
                  <Flex>
                    <Flex justifyContent="center">
                      <Image
                        width={"25px"}
                        mb="auto"
                        mr={"10px"}
                        objectFit={"contain"}
                        src={Addressicon}
                      ></Image>
                    </Flex>
                    <Text
                      fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                      fontWeight="light"
                    >
                      <AddressItem address={address1} />
                      <AddressItem address={address2} />
                      <AddressItem address={address3} />
                      <AddressItem address={address4} />
                      <AddressItem address={address5} />
                    </Text>
                  </Flex>
                </Flex>
              </SimpleGrid>
            </Flex>
          </Flex>

          <Flex  width={{ base: "100%", md: "50%", lg: "30%" }}>
            <Flex mx={"auto"} flexDir={"column"} my={2} alignItems={"center"}>
              <Text
                fontFamily={"heading"}
                fontWeight={"bold"}
                fontSize={{ base: "26px", md: "30px" }}
                display={{ base: "flex", md: "none" }}
              >
                {name}
              </Text>
              <Box
                width="180px"
                height="180px"
                borderRadius="50%"
                overflow="hidden"
                border={"3px solid"}
                borderColor={"brand.cardBg"}
                boxShadow={"2px 4px 4px 0px rgba(135, 135, 135, 0.29)"}
              >
                <Image
                  src={shopprofileimage}
                  width={"100%"}
                  height={"100%"}
                  objectFit={"cover"}
                />
              </Box>
              <Flex
                bg={
                  subscription === "basic" || subscription === "default"
                    ? "#d4ddac"
                    : !subscription
                    ? ""
                    : "#C5ACDD"
                }
                borderRadius={"6px"}
                boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
                px={2}
                py={1}
                my={4}
              >
                <Text fontWeight={"bold"} color={"blackAlpha.500"}>
                  {subscription === "basic" || subscription === "default"
                    ? "Basic Membership"
                    : "Premium Membership"}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* <DiscountOfferCard carouselImages={offerImages}/> */}
    </Flex>
  );
}

export default StoreInfoCard;
