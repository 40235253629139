/**
 * Handles the change in a checkbox's state and updates the filter accordingly.
 * @param {object} e - The event object.
 * @param {function} setFilter - The function to update the array of filtering items
 * @param {array} filter - The current filtering items
 * @param {function} setCheckedItems - The function to update the checked items state to manage clearing Checked items.
 */
export const handleFilterChange = (e, setFilter, filter, setCheckedItems) => {
  const checkedValue = e.target.value;
  setCheckedItems &&
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [checkedValue]: !prevCheckedItems[checkedValue],
    }));
  const type = e.target.name;
  if (e.target.checked) {
    setFilter((filter) => [...filter, checkedValue]);
  } else setFilter(filter.filter((item) => item !== checkedValue));
};

/**
 * Clears all checked items in a checkbox group by updating the checked items state.
 * @param {array} itemFilterObjects - The array of filter objects passed
 * @param {function} setCheckedItems - The function to update the checked items state.
 * @param {function} setItem - The function to update the filtering items array to manage data.
 */
export const clearAllChecked = (
  itemFilterObjects = [],
  setCheckedItems = () => {},
  setItem = () => {},
  subItem = [],
  setSubItem = () => {},
  setSelectedItems = () => {}
) => {
  const updatedCheckedItems = {};
  for (const item of itemFilterObjects) {
    updatedCheckedItems[item.name] = false;
    setSelectedItems([]);
  }
  for (const sub of subItem) {
    updatedCheckedItems[sub] = false;
    setSelectedItems([]);
  }
  setCheckedItems(updatedCheckedItems);
  setItem([]);
  setSubItem([]);
};
