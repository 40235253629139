import {
  Text,
  Flex,
  Image,
  Box,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
import DeleteIcon from "../../../Assets/Images/Dashboard/deleticon.png";
import EditIcon from "../../../Assets/Images/Dashboard/editicon.png";
import { productsdata } from "./ViewProductdata.js";
import { getSingleStores } from "../../../Functions/GetApiCalls.js";
import { State } from "../../../Context/Context.js";
import AddProductForm from "./AddProduct/AddProductForm.js";
import CommonModal from "../../../Components/CommonModal/CommonModal.js";
import { useGetShopData } from "../../../Functions/queries.js";
import { DeleteConfirm } from "./DeleteConfirm.js";

function ViewProduct() {
  useGetShopData();
  const [storeData, setStoreData] = useState([]);
  const { shop } = State();
  const [loading, setLoading] = useState(false);
  const [modalClose, setModalClose] = useState();
  const edit = () => {
    return (
      <>
        <Image width={"25px"} height={"25px"} src={EditIcon}></Image>
      </>
    );
  };
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 6;
  const pagesVisited = pageNumber * usersPerPage;
  const displayUsers =
    shop && shop[0]?.product ? (
      shop[0]?.product
        .slice(pagesVisited, pagesVisited + usersPerPage)
        .map((productdataitem, index) => {
          return (
            <Flex
              mt={"10px"}
              flexDir={{ base: "column", md: "row", lg: "row" }}
              fontSize={{ base: "13px", md: "13px", lg: "15px" }}
              alignItems={"center"}
              key={index}
              borderRadius={"10px"}
              boxShadow={"lg"}
              px={{ base: "40px", md: "3", lg: "3" }}
              py={2}
              backgroundColor={"#F3F0ED"}
              justifyContent={"space-between"}
            >
              <Box width={{ base: "160px", md: "70px", lg: "120px" }}>
                <Image
                mx={{base:"auto",md:"0",lg:"0"}}
                  borderRadius={"10px"}
                  width={"60px"}
                  height={"60px"}
                  objectFit={"cover"}
                  src={productdataitem.productimage[0]}
                ></Image>
              </Box>
            
              <Text  textAlign={{base:"center",md:"left",lg:"left"}} width={{ base: "160px", md: "70px", lg: "80px" }}>
                {productdataitem.productname}
              </Text>
              <Text textAlign={{base:"center",md:"left",lg:"left"}} width={{ base: "160px", md: "70px", lg: "80px" }}>
                {productdataitem.type}
              </Text >
              <Text borderBottom="1px solid #6E5034" display={{base:"block",md:"none",lg:"none"}} fontWeight="bold"  textAlign={{base:"center",md:"left",lg:"left"}} width={{ base: "160px", md: "70px", lg: "80px" }}>
               3D Model/ Texture
              </Text >
            <Flex><Text display={{base:"flex",md:"none",lg:"none"}}>Ordered:</Text>
              <Text  
                  textAlign={"center"} width={{ base: "", md: "70px", lg: "80px" }}>
                {productdataitem.generate3d || productdataitem.generatetexture
                  ? "✅"
                  : "❌"}
              </Text></Flex>

              <Flex><Text display={{base:"flex",md:"none",lg:"none"}}>Paid:</Text>
              <Text  
                  textAlign={"center"} width={{ base: "", md: "70px", lg: "80px" }}>
                {productdataitem.payment ? "✅" : "❌"}
              </Text></Flex>

              <Flex><Text display={{base:"flex",md:"none",lg:"none"}}>Uploaded by:</Text>
              {productdataitem.endDate ? (
                <Text  
                
                textAlign={"center"} width={{ base: "", md: "120px", lg: "80px" }}>
                  {new Date(productdataitem.endDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
                </Text>
              ) : (
                <Text   
                textAlign={"center"} width={{ base: "", md: "120px", lg: "80px" }}>
                  -
                </Text>
              )}</Flex>
              <Flex
          
                width={{ base: "160px", md: "120px", lg: "150px" }}
                justifyContent={{base:"center",md:"flex-end",lg:"flex-end"}}
                alignItems={"center"}
              >
                <CommonModal
                  modalState={modalClose}
                  btn={{
                    text: "red",
                    bg: "transparent",
                    hoverBg: "transparent",
                  }}
                  btnContent={edit()}
                  modalContent={
                    <AddProductForm
                      onClose={setModalClose}
                      productdataitem={productdataitem}
                    />
                  }
                />
                <Text mx={"6px"} color={"#664121"}>
                  |
                </Text>
                <CommonModal
                  modalState={modalClose}
                  btn={{
                    text: "red",
                    bg: "transparent",
                    hoverBg: "transparent",
                  }}
                  modalContent={
                    <DeleteConfirm
                      onClose={setModalClose}
                      productdataitem={productdataitem}
                    />
                  }
                  btnContent={
                    <Image
                      width={"25px"}
                      height={"25px"}
                      src={DeleteIcon}
                    ></Image>
                  }
                />
              </Flex>
            </Flex>
          );
        })
    ) : (
      <>No results found</>
    );
  const pageCount = shop && Math.ceil(shop[0]?.product?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
          
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              View Product Details
              
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex flexDir={"column"}>
          <Flex
            fontSize={{ base: "13px", md: "13px", lg: "15px" }}
            display={{ base: "none", md: "flex", lg: "flex" }}
            justifyContent={"center"}
            width={"100%"}
            p={4}
          >
            <Flex justifyContent={"center"}>
              <Text
                mt={"auto"}
                width={{ base: "160px", md: "70px", lg: "120px" }}
              >
                Product
              </Text>
              <Text
                mt={"auto"}
                width={{ base: "160px", md: "70px", lg: "80px" }}
              >
                Name
              </Text>
              <Text
                mt={"auto"}
                width={{ base: "160px", md: "70px", lg: "80px" }}
              >
                Category
              </Text>
              <Flex  flexDir={"column"}>
                <Flex
                  borderBottom={"1px solid #6E5034"}
                  justifyContent={"center"}
                >
                  {" "}
                  <Text>3D Model / Texture</Text>
                </Flex>
                <Flex mt={"10px"}>
                  <Text
                
                  textAlign={"center"}
                  width={{ base: "160px", md: "70px", lg: "80px" }}
                  >
                    Ordered
                  </Text>
                  <Text 
                
                   textAlign={"center"}
                 width={{ base: "160px", md: "70px", lg: "80px" }}
                  >
                    Payment
                  </Text>
                  <Text 
                 
               
                  textAlign={"center"}
              
                  width={{ base: "160px", md: "120px", lg: "80px" }}
                 
                  >
                    Uploaded by
                  </Text>
                </Flex>
              </Flex>

              <Text
                mt={"auto"}
                width={{ base: "160px", md: "70px", lg: "150px" }}
                textAlign={"end"}
            
              >
                Actions
              </Text>
            </Flex>
          </Flex>
          {loading ? (
            <Flex width={"100%"} justifyContent={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              mb={"20px"}
            >
              {displayUsers}
            </Flex>
          )}

          <Flex p={5} justifyContent={"center"} alignItems={"center"}>
            <Flex boxShadow={"lg"}>
              {" "}
              <ReactPaginate
              marginPagesDisplayed={0}
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ViewProduct;
