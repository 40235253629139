import { Image } from "@chakra-ui/image";
import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import Furniture from "../../../../Assets/DummyCollections/Furniture.png";
import FormatIndianRupees from "../../../../Functions/FormatIndianRupees";
function ItemCollection(props) {
  const { image = Furniture, name = "Chair", price = 2400 } = props;
  return (
    <Flex
    mx={"auto"}
  
      position={"relative"}
      justifyContent={"flex-end"}
      width={"190px"}
      height={"250px"}
      flexDir={"column"}
      cursor={"pointer"}
    >
      <Flex
        flexDir={"column"}
        width={"200px"}
        minH={"200px"}
        bg={
          "linear-gradient(180deg, rgba(214, 206, 198, 0.15) 0%, rgba(193, 139, 88, 0.60) 100%)"
        }
        borderRadius={"16px"}
        boxShadow="lg"
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Image
          border={"1px solid white"}
          position={"absolute"}
          top={0}
          src={image}
          objectFit={"fit"}
          width={"180px"}
          height={"180px"}
          borderRadius={"20px"}
          boxShadow={"lg"}
        />
        <Flex
          width={"100%"}
          height={"50%"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          flexDir={"column"}
          py={4}
        >
          <Text zIndex={2} maxH={"50px"} mt={"20px"} overflow={"clip"}  textAlign={"center"} maxW={"200px"} fontWeight={"bold"} color={"#0A0909"} fontSize={{base:"14px",md:"14px",lg:"15px"}}>
            {name}
          </Text>
          <Flex width={"100%"} justifyContent={"flex-end"}>
            <Text
              backgroundColor={"#F4E7DA"}
              boxShadow={"inner"}
              borderRadius={"5px"}
              fontWeight={"bold"}
              color={"#0A0909"}
              fontSize={"10px"}
              p={1}
              mr={"10px"}
            >
                {FormatIndianRupees(Number(price))}
             
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ItemCollection;
