export const furnitureFilterMaterial = [
    { name: "Wood/Processed Wood" },
    { name: "Glass"},
    { name: "Fabric"},
    { name: "Leather" },
    { name: "Metal"},
    { name: "Plastic" },
    { name: "Rattan" },
    { name: "Stone" },
    { name: "Ceramic" },
    { name: "Other" },
 
  ];
  