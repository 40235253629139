export const furnitingFilterMaterial = [
    { name: "Cotton" },
    { name: "Velvet"},
    { name: "Linen"},
    { name: "Lace" },
    { name: "Silk"},
    { name: "Polyster" },
    { name: "Jute" },
    { name: "Crochet" },
    { name: "Wool" },
    { name: "Satin" },
    { name: "Microfiber" },
    { name: "Other" },
 
  ];
  