import { Button, chakra, Flex, useToast } from "@chakra-ui/react";

import React, { useState } from "react";

import FormInput from "../../Components/FormInputs/CreateFormInput";
import axiosInstance from "../../Config/axios";
import { API_BASE_URL } from "../../Config";

const ForgotPassword = ({ onClose }) => {
  const toast = useToast();
  const [btnIsLoading, setBtnIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    setBtnIsLoading(true);
    event.preventDefault();

    const { email } = event.target.elements;
    if (!email.value.trim()) {
      setBtnIsLoading(false);
      toast({
        title: "Email Is Required",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    try {
      const { data } = await axiosInstance.post(`${API_BASE_URL}/users/reset`, {
        email: email.value,
      });
      setBtnIsLoading(false);
      onClose();
      if (data) {
        toast({
          title: "Reset",
          description:
            data?.message || "Password reset completed and email sent.",
          status: "success",
          variant: "left-accent",
          position: "top",
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
      setBtnIsLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message || "Some error has been occurred.",
        status: "error",
        isClosable: true,
        position: "top",
        variant: "top-accent",
      });
    }
  };

  return (
    <Flex as={chakra.form} flexDir="column" gap="3" onSubmit={handleSubmit}>
      <FormInput
        id="email"
        type="email"
        inputMode="email"
        name="email"
        placeholder="eg: johndoe@email.com"
        isRequired
      >
        Enter Your Registered Email ID
      </FormInput>
      <Flex
        gap="2"
        width={"100%"}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
      >
        <Button
          colorScheme="yellow"
          alignSelf={"flex-end"}
          fontSize={"14px"}
          px="2"
          type="button"
          size="sm"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>

        <Button
          as={Button}
          colorScheme="blue"
          alignSelf={"flex-end"}
          fontSize={"14px"}
          isLoading={btnIsLoading}
          type="submit"
          px="2"
          size="sm"
        >
          Submit
        </Button>
      </Flex>
    </Flex>
  );
};

export default ForgotPassword;
