import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AOS from "aos";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import 'animate.css';
import ContextProvider from "./Context/Context";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById("root"));
AOS.init();
const queryClient = new QueryClient();
root.render(
  <GoogleOAuthProvider clientId="29825739528-cq9mhdfqnlgopv23tagi6kr4uthch87s.apps.googleusercontent.com">
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <ContextProvider>
     
        <App />
       
      </ContextProvider>
    </ChakraProvider>
  </QueryClientProvider>
  </GoogleOAuthProvider>
);
