import {
  Text,
  Flex,
  Box,
  Stack,
  Image,
  Button,
  SimpleGrid,
  Input,
  chakra,
  useToast,
  Link,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import FormInput from "../../../Components/FormInputs/FormInput";
import CommonButton from "../../../Components/shared/CommonButton";
import Close from "../../../Assets/Images/Dashboard/deleticon.png";
import { API_BASE_URL } from "../../../Config";
import axios from "axios";
import VideoInput from "../../../Components/FormInputs/VideoInput";
import { useGetDesignerData } from "../../../Functions/queries";
import Delete from "../../../Assets/Images/Dashboard/delet icon.png";
import { QueryClient, useQueryClient } from "@tanstack/react-query";

function DesignerVideo() {
  const { designer } = useGetDesignerData();
  const [videoFlexCount, setVideoFlexCount] = useState(1);
  const [video, setVideo] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();
   // for deleting from aws
 const [deletedvideo, setdeletedvideo] = useState([]);

 const handleDeletevideo = (index) => {
  deleteVideo(index);

    // Extract the image URL of the deleted image
    const deletedImageUrl = video[index];
  // Extract the file name from the URL
  const extractedprofileFileName = deletedImageUrl.split("/").pop();

  // Check if the file name already exists in deletedvideo
  if (!deletedvideo.includes(extractedprofileFileName)) {
    setdeletedvideo([...deletedvideo, extractedprofileFileName]);
  }
};


 const deleteImagesFromS3 = async () => {
  try {
    // Make a DELETE request to your deleteS3Route endpoint
    await axios.delete(`${API_BASE_URL}/delete/s3`, {
      data: deletedvideo, // Pass the deletedImages array as data
    });
  } catch (error) {
    console.error("Error deleting images from S3:", error);
  }
};

 // for deleting from aws ends
  const handleFileChange = (files) => {
    setSelectedFile(files);
  };

  useEffect(() => {
    setVideo(designer?.videos);
  }, [designer]);

  const handleVideoUpload = async () => {
    setLoading(true);

    // Validation checks
    const invalidFiles = selectedFile.filter((file) => {
      const isMP4 = file.type === "video/mp4";
      const isSizeValid = file.size / 1024 / 1024 < 50; // Convert bytes to MB
      return !(isMP4 && isSizeValid);
    });

    if (invalidFiles.length > 0) {
      toast({
        title: "Invalid Files",
        description: "Please select MP4 files that are less than 50MB in size.",
        status: "error",
        position: "top",
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    // Validation checks
    if (selectedFile.length + video.length > 3) {
      toast({
        title: "File limit exceeded",
        description: "Please select a maximum of 3 files for upload.",
        status: "warning",
        position: "top",
        // duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    const formData = new FormData();

    selectedFile.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    if (video) {
      video.forEach((videoItem, index) => {
        formData.append(`video${index}`, videoItem);
      });
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/upload/designer/videos/${designer._id}`,
        formData
      );
      setLoading(false);
      toast({
        title: "Success",
        description:
          selectedFile.length > 0
            ? "Videos Uploaded Successfully"
            : "Changes Added Successfully",
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      console.error("Error uploading videos:", error);
      setLoading(false);
      toast({
        title: "Upload Failed",
        description: "An error occurred while uploading videos.",
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
    setVideo();
    setSelectedFile();
    await queryClient.invalidateQueries("designer");
    deleteImagesFromS3();
  };

  const deleteVideo = (index) => {
    const updatedVideos = [...video];
    updatedVideos.splice(index, 1);
    setVideo(updatedVideos);
  };

  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Add Videos
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex
          flexDir={"column"}
          fontSize={{ base: "15px", md: "15px", lg: "16px" }}
          justifyContent={"center"}
          my={"5%"}
          mx={"auto"}
          width={{ base: "90%", md: "70%", lg: "60%" }}
        >
          <Flex flexDir={"column"}>
            <Text my={"20px"}>
              You may submit a maximum of 3 videos showcasing your creations.
            </Text>
            <Text fontSize={{ base: "12px", md: "13px", lg: "13px" }}>
              *(Only MP4 videos are supported upto a maximum file size of 50MB)
            </Text>
          </Flex>

          {/* Video Form */}
          <Flex
            border={"1px solid #917961"}
            flexDir={"column"}
            borderRadius={"10px"}
            boxShadow={"inner"}
            p={3}
          >
            <Flex
              width={"100%"}
              px={{ base: "0px", md: "20px", lg: "30px" }}
              mx={"auto"}
              flexDir={"column"}
            >
              <VideoInput
                id="doc"
                name="doc"
                onChange={(event) => handleFileChange(event)}
                labelProps={{ opacity: ".9" }}
                placeholder="Select File"
                multiple
                accept="video/*"
              ></VideoInput>
            </Flex>
            <Flex>
              {video &&
                video.map((video, index) => {
                  return (
                    <Flex
                      flexDir={"column"}
                      m={4}
                      width={"200px"}
                      p={4}
                      bg={"whiteAlpha.800"}
                      borderRadius={10}
                      alignItems={"center"}
                      gap={2}
                      key={index}
                    >
                      <Link href={video}>
                        <video width="100%">
                          <source src={video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </Link>
                      <Button
                        bg={"transparent"}
                        _hover={{ bg: "transparent" }}
                        // onClick={() => {
                        //   deleteVideo(index);
                        // }}
                        onClick={() =>
                          handleDeletevideo(index)
                        }
                      >
                        <Image width={"20px"} src={Delete}></Image>
                      </Button>
                    </Flex>
                  );
                })}
            </Flex>
          </Flex>
          <Flex
            mr={{ base: "20px", md: "20px", lg: "20px" }}
            justifyContent={"end"}
          >
            <CommonButton
              isLoading={loading}
              type={"submit"}
              height="34px"
              width="100px"
              mt="50px"
              fontSize="14px"
              btn={"Save"}
              onClick={handleVideoUpload}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default DesignerVideo;
