import {
  Text,
  Flex,
  Box,
  Stack,
  Image,
  Button,
  SimpleGrid,
  Input,
  chakra,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { postStoresDetails } from "../../../Functions/PostApiCalls.js";
import axios from "axios";
import CommonButton from "../../../Components/shared/CommonButton";
import ImageUploading from "react-images-uploading";
import FormInput from "../../../Components/FormInputs/FormInput.js";
import Delete from "../../../Assets/Images/Dashboard/delet icon.png";
import Update from "../../../Assets/Images/Dashboard/update icon.png";
import { ListItem, UnorderedList } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useGetDesignerData } from "../../../Functions/queries.js";
import { API_BASE_URL } from "../../../Config/index.js";
import { useQueryClient } from "@tanstack/react-query";
function DesignerGallery() {
  const queryClient = useQueryClient();
  const { designer } = useGetDesignerData();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const [error, setError] = useState(
    "You have reached the maximum limit of projects."
  );
  const [maxNumber, setMaxNumber] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([{ projectName: "", images: [] }]);
  const [subscription, setSubscription] = useState(null);
  const [projectLength, setProjectLength] = useState(30);
  const [totalImages, setTotalImages] = useState(0);

  const onChangeshop = (imageList, projectIndex) => {
    const updatedProjects = [...projects];
    updatedProjects[projectIndex].images = imageList;
    setProjects(updatedProjects);
  };

  // for deleting from aws
  const [deletedImages, setDeletedImages] = useState([]);

  const handleshopimagesDeleteImage = (index, indexImage) => {
    const deletedImageUrl = projects[index].projectImage[indexImage][0];
    removeProjectImage(index, indexImage);

    // Extract the image URL of the deleted image

    // Extract the file name from the URL
    const extractedFileName = deletedImageUrl.split("/").pop();

    // Check if the file name already exists in deletedImages
    if (!deletedImages.includes(extractedFileName)) {
      setDeletedImages([...deletedImages, extractedFileName]);
    }
  };
  // console.log("deleted added images:", deletedImages);
  const deleteImagesFromS3 = async () => {
    try {
      // Make a DELETE request to your deleteS3Route endpoint
      await axios.delete(`${API_BASE_URL}/delete/s3`, {
        data: deletedImages, // Pass the deletedImages array as data
      });
    } catch (error) {
      console.error("Error deleting images from S3:", error);
    }
  };

  // for deleting from aws ends

  // delet project
  const deleteProject = (index) => {
    // console.log("Deleting project", projects[index]);
    // Store the projectImage array of the project being deleted
    const deletedProjectImages = (projects[index].projectImage || []).flat();
    // Extract filenames from the links and store them in an array
    const deletedFilenames = deletedProjectImages.map((link) => {
      // Using URL constructor to extract the pathname and then split to get the filename
      const url = new URL(link);
      return url.pathname.split("/").pop();
    });

    // console.log("Deleted project images", deletedFilenames);
    // Create a new array with the project removed
    const deletupdatedProjects = [...projects];
    deletupdatedProjects.splice(index, 1);

    // Update the state with the new array
    setProjects(deletupdatedProjects);

    // Add extracted filenames to deletedImages if they don't already exist
    setDeletedImages((deletedImages ) => {
      const newDeletedImages = [...deletedImages];
      deletedFilenames.forEach((filename) => {
        if (!newDeletedImages.includes(filename)) {
          newDeletedImages.push(filename);
        }
      });
     
      return newDeletedImages;
    });
  };

  const addProject = () => {
    if (subscription === "premium") {
      setProjectLength(50);
      if (projects.length < 50) {
        setProjects([...projects, { projectName: "", images: [] }]);
      }
    } else {
      setProjectLength(30);
      if (projects.length < 30) {
        setProjects([...projects, { projectName: "", images: [] }]);
      }
    }
  };
  // Function to handle changes in the project name input
  const handleProjectNameChange = (index, value) => {
    const updatedProjects = [...projects];
    updatedProjects[index].projectName = value;
    setProjects(updatedProjects);
  };
  const handleprojectImageSaveClick = async () => {
    setLoading(true);
    try {
      if (totalImages > projectLength) {
        toast({
          title: "Error",
          description: "You have reached the maximum limit of projects.",
          status: "error",
          position: "top",
          isClosable: true,
        });
      } else {
        // projects.map((project) => {
        //   console.log(project.projectImage,project.projectName)
        //   if (project.projectImage && !project.projectName) {
        //     console.log("errr");
        //   }
        // });

        const formData = new FormData();
        // formData.append(
        //   "projectData",
        //   JSON.stringify(
        //     projects.map((project) => ({
        //       projectName: project.projectName,
        //       projectImage: project?.projectImage ? project.projectImage : null,
        //     }))
        //   )
        // );
        formData.append(
          "projectData",
          JSON.stringify(
            projects
              .filter((project) => project.projectName) // Filter out projects without a projectName
              .map((project) => ({
                projectName: project.projectName,
                projectImage: project.projectImage
                  ? project.projectImage
                  : null,
              }))
          )
        );

        // Append images for each project
        projects.forEach((project, index) => {
          if (project.images && project.images.length > 0) {
            project.images.forEach((image, imageIndex) => {
              formData.append(`file${index}_${imageIndex}`, image.file);
            });
          } else formData.append(`file${index}`, []);
        });
        const response = await axios.post(
          `${API_BASE_URL}/upload/designer/project/${designer._id}`,
          formData
        );
        setLoading(false);
        toast({
          title: "Success",
          description: "Project Details Added Successfully",
          status: "success",
          position: "top",
          isClosable: true,
        });
      }
      await queryClient.invalidateQueries("designer");
      deleteImagesFromS3();
    } catch (error) {
      console.error("Error uploading Cover image:", error);
      toast({
        title: "Error",
        description: error.response.data,
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
    setLoading(false);
  };
  // console.log("projects", projects);
  function removeProjectImage(indexProject, indexImage) {
    // Check if the index is valid
    if (indexProject >= 0 && indexProject < projects.length) {
      // Create a copy of the projects array
      const updatedProjects = [...projects];
      // Remove the projectImage property from the specified index in the copy
      if (
        indexImage >= 0 &&
        indexImage < updatedProjects[indexProject].projectImage.length
      ) {
        updatedProjects[indexProject].projectImage.splice(indexImage, 1);
      }
      if (updatedProjects[indexProject].projectImage.length === 0) {
        updatedProjects[indexProject].projectName = "";
        updatedProjects.splice(indexProject, 1);
      }
      // Update the state with the modified copy
      setProjects(updatedProjects);
    } else {
      console.error("Invalid index");
    }
  }
  useEffect(() => {
    // Assuming projects is the array of projects
    const newMaxNumber = projects.map((project) =>
      project?.projectImage ? 0 : 1
    );
    setTotalImages(
      projects.reduce(
        (total, project) =>
          total +
          (project?.projectImage ? project.projectImage.length : 0) +
          (project?.images ? project.images.length : 0),
        0
      )
    );
    setMaxNumber(newMaxNumber);
  }, [projects]);
  useEffect(() => {
    designer && designer.projects.length && setProjects(designer.projects);
    if (
      designer?.subscription?.to &&
      new Date(designer?.subscription?.to) > new Date()
    ) {
      if (designer?.subscription?.plan === "premium") {
        setSubscription("premium");
        setProjectLength(50);
      }
    }
  }, [designer]);
  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Add Project Images
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex
          fontSize={{ base: "15px", md: "15px", lg: "16px" }}
          justifyContent={"center"}
          my={"5%"}
          width={"100%"}
        >
          <Stack
            m={2}
            width={{ base: "100%", md: "100%", lg: "60%" }}
            as={chakra.form}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission behavior
              handleprojectImageSaveClick(); // Call your custom form submission logic
            }}
            spacing={3}
          >
            <Text fontSize={{ base: "13px", md: "13px", lg: "15px" }}>
              You can enter a maximum of{" "}
              {subscription === "premium" ? "50" : "30"} project images.
            </Text>

            <Text
              color={"red.600"}
              fontSize={{ base: "11px", md: "11px", lg: "13px" }}
            >
              *Project name is mandatory.
            </Text>

            {projects.map((project, index) => (
              <Flex
                key={index}
                border={"1px solid #917961"}
                flexDir={"column"}
                borderRadius={"10px"}
                boxShadow={"inner"}
                p={3}
              >
                <Flex width={"100%"}>
                  <Button
                    boxShadow={"lg"}
                    onClick={() => {
                      // console.log("Deleting project at index:", index);
                      deleteProject(index);
                    }}
                    height={"30px"}
                    width={"10px"}
                    ml={"auto"}
                  >
                    X
                  </Button>
                </Flex>
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"300px"}>
                    Project Name* :
                  </Text>

                  <FormInput
                    value={project.projectName}
                    name={`projectName[${index}]`}
                    inputFor={`projectName[${index}]`}
                    register={register}
                    error={errors[`projectName[${index}]`]}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    onChange={(e) =>
                      handleProjectNameChange(index, e.target.value)
                    }
                  />
                </Flex>
                <Flex key={index} flexDir={"column"}>
                  <Text
                    my={"10px"}
                    fontSize={{ base: "13px", md: "13px", lg: "15px" }}
                    pr={4}
                  >
                    Add Project Images :
                  </Text>
                  {/* ..................Offer Image upload............... */}
                  <Flex
                    maxW={{ base: "100%", md: "100%", lg: "800px" }}
                    backgroundColor={"white"}
                    boxShadow={"inner"}
                    padding={4}
                    borderRadius={"10px"}
                  >
                    <ImageUploading
                      multiple
                      value={project.images}
                      onChange={(imageList) => onChangeshop(imageList, index)}
                      // maxNumber={maxNumber[index]}
                      maxFileSize={5000000}
                      dataURLKey="data_url"
                      acceptType={["jpg", "png", "jpeg"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        // write your building UI

                        <Flex flexDir={"column"} width={"100%"}>
                          <Button
                            fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                            boxShadow={"lg"}
                            border={"1px dashed black"}
                            height={"100px"}
                            width={"100%"}
                            fontWeight={"bold"}
                            style={isDragging ? { color: "red" } : null}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Choose a file or Drag it here.
                          </Button>
                          <Text
                            fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                            my={"5px"}
                          >
                            *Upload a file, not exceeding 5 MB in size, in the
                            formats .jpeg, .png, or .jpg.
                          </Text>
                          {/* <button onClick={onImageRemoveAll}>
                      Remove all images
                    </button> */}
                          {errors && (
                            <Flex
                              p={2}
                              fontSize={"11px"}
                              backgroundColor={"#FDF5F1"}
                              border={"1px solid red"}
                            >
                              <UnorderedList>
                                <ListItem>
                                  Only files with the following extensions are
                                  allowed: png,jpg,jpeg.
                                </ListItem>
                                <ListItem>
                                  The number of files should not exceed the
                                  maximum number.
                                </ListItem>
                                <ListItem>
                                  The file size should not exceed 5MB.
                                </ListItem>
                              </UnorderedList>
                            </Flex>
                          )}
                          <SimpleGrid spacing={"5px"} columns={[2, null, 4]}>
                            {imageList?.map((shopimage, index1) => (
                              <Flex
                                boxShadow={"lg"}
                                borderRadius={"10px"}
                                backgroundColor={"#E1E1E1"}
                                mt={"10px"}
                                key={index1}
                              >
                                <Flex
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  flexDir={"column"}
                                >
                                  <Image
                                    mt={"10px"}
                                    objectFit={"cover"}
                                    borderRadius={"10px"}
                                    boxShadow={"lg"}
                                    src={shopimage.data_url}
                                    alt="images"
                                    width={"40%"}
                                  ></Image>
                                  <Flex p={1}>
                                    <Button
                                      height={{
                                        base: "30px",
                                        md: "20px",
                                        lg: "20px",
                                      }}
                                      width={{
                                        base: "5px",
                                        md: "20px",
                                        lg: "20px",
                                      }}
                                      onClick={() => onImageUpdate(index1)}
                                    >
                                      <Image src={Update}></Image>
                                    </Button>
                                    <Button
                                      height={{
                                        base: "30px",
                                        md: "20px",
                                        lg: "20px",
                                      }}
                                      width={{
                                        base: "5px",
                                        md: "20px",
                                        lg: "20px",
                                      }}
                                      onClick={() => onImageRemove(index1)}
                                    >
                                      <Image
                                        width={"15px"}
                                        src={Delete}
                                      ></Image>
                                    </Button>
                                  </Flex>
                                </Flex>
                              </Flex>
                            ))}
                            {project?.projectImage &&
                              project?.projectImage.map((image, indexImage) => {
                                return (
                                  <Flex
                                    boxShadow={"lg"}
                                    borderRadius={"10px"}
                                    backgroundColor={"#E1E1E1"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    flexDir={"column"}
                                    mt={"10px"}
                                    key={index}
                                  >
                                    <Image
                                      mt={"10px"}
                                      objectFit={"cover"}
                                      borderRadius={"10px"}
                                      boxShadow={"lg"}
                                      src={image}
                                      alt="images"
                                      height={"70px"}
                                      width="100px"
                                    ></Image>
                                    <Flex p={2}>
                                      <Button
                                        m={1}
                                        // onClick={() =>
                                        //   removeProjectImage(index, indexImage)
                                        // }
                                        onClick={() =>
                                          handleshopimagesDeleteImage(
                                            index,
                                            indexImage
                                          )
                                        }
                                      >
                                        <Image
                                          width={"15px"}
                                          src={Delete}
                                        ></Image>
                                      </Button>
                                    </Flex>
                                  </Flex>
                                );
                              })}
                          </SimpleGrid>
                        </Flex>
                      )}
                    </ImageUploading>
                  </Flex>
                </Flex>
              </Flex>
            ))}
            {totalImages >= projectLength && (
              <Text
                fontSize={{ base: "13px", md: "13px", lg: "13px" }}
                color="red"
                mt="10px"
              >
                {error}
              </Text>
            )}
            {totalImages < projectLength && (
              <CommonButton
                height="34px"
                width="fit-content"
                mt="20px"
                fontSize="14px"
                onClick={addProject}
                btn="Add more projects"
              />
            )}
            <Flex
              mr={{ base: "20px", md: "20px", lg: "0px" }}
              justifyContent={"end"}
            >
              <CommonButton
                isDisabled={totalImages > projectLength ? true : false}
                isLoading={loading}
                type={"submit"}
                height="34px"
                width="100px"
                mt="20px"
                fontSize="14px"
                btn={"Save"}
              />
            </Flex>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default DesignerGallery;
