/** @format */

import React, { useState } from "react";
import { Flex, Box, Center,Text, Image } from "@chakra-ui/react";

import animation from "../../../src/Assets/Images/MainPage/anime.mp4";
import 'animate.css';






function StyhomSplash() {
 
  const [anime, setAnime] = useState(false);
  setTimeout(() => {
    setAnime(true);
  }, 550);

  return (
 
      
    <Flex
  backgroundColor={"#ffff"}
      height={"100vh"}
      // bgGradient='linear(to-r, #005098, #000054)'
    >
      <Flex mx={"auto"} height={"fit-content"}  my={"auto"} >
       <video
       
      
        autoPlay
        muted
        playsInline
        src={animation}
        className="videolanding"
      ></video></Flex>
    </Flex>

 
  );
}

export default StyhomSplash;
