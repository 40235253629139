import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getItemFromLocalStorage } from "../utils/local-storage";
import { useGetDesignerData, useGetShopData } from "../Functions/queries";
import CommonModal from "./CommonModal/CommonModal";
import { LogoutConfirm } from "./LogoutConfirm";
import { useNavigate } from "react-router-dom";
import PasswordSettings from "../Screens/Dashboard/PasswordSettings";

export const UserAvatar = () => {
  const client = getItemFromLocalStorage("client");
  const { shop } = useGetShopData();
  const { designer } = useGetDesignerData();
  const [firstLetter, setFirstLetter] = useState("");
  const [modalClose, setModalClose] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!shop) {
      setFirstLetter(
        designer && designer?.name
          ? designer.name.charAt(0)
          : client && client.email
          ? client.email.charAt(0)
          : "A"
      );
    } else {
      setFirstLetter(
        shop && shop[0]?.shopname
          ? shop[0].shopname.charAt(0)
          : client && client.email
          ? client.email.charAt(0)
          : "A"
      );
    }
  }, [shop, designer]);

  return (
    <Menu>
      <MenuButton transition="all 0.2s">
        <Box
          width={"50px"}
          height={"50px"}
          borderRadius={"50%"}
          display="flex"
          border={"4px solid #C8A170"}
          bg={"transparent"}
          justifyContent={"center"}
          alignItems={"center"}
          boxShadow={"lg"}
        >
          <Center>
            <Flex
              width={"30px"}
              height={"30px"}
              borderRadius={"50%"}
              bg={"#C8A170"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text fontSize={20} fontWeight={"bold"}>
                {firstLetter.toUpperCase()}
              </Text>
            </Flex>
          </Center>
        </Box>
      </MenuButton>
      <MenuList
        bg={{
          base: "blackAlpha.700",
          md: "blackAlpha.700",
          lg: "blackAlpha.400",
        }}
        backdropFilter="blur(8px)"
        color={"brand.cardBg"}
        p={0}
        boxShadow="0px 4px 7px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        {client?.role != "user" ? (
          <Button
            bg={"transparent"}
            _hover={{ bg: "blackAlpha.300" }}
            _active={{ bg: "blackAlpha.300" }}
            _focus={{ bg: "blackAlpha.300" }}
            width={"100%"}
            justifyContent={"center"}
            color={"brand.cardBg"}
            borderBottom={"1px solid White"}
            borderBottomRadius={0}
            p={6}
            onClick={() => {
              localStorage.removeItem("activeLink");
              shop || client?.role === "shopOwner"
                ? navigate("/shop-dashboard")
                : designer || client?.role === "architect"
                ? navigate("/designer-dashboard")
                : navigate("/home");
            }}
          >
            Edit Profile
          </Button>
        ) : (
          <>
            <CommonModal
              modalState={modalClose}
              modalAttr={{ bg: "brand.cardBg" }}
              btn={{
                width: "100%",
                bg: "transparent",
                hoverbg: "blackAlpha.300",
                color: "#F2DCC7",
                p: 6,
              }}
              btnContent={"Settings"}
              modalContent={<PasswordSettings />}
            />
          </>
        )}
        <CommonModal
          modalState={modalClose}
          modalAttr={{ bg: "brand.cardBg" }}
          btn={{
            width: "100%",
            bg: "transparent",
            hoverbg: "blackAlpha.300",
            color: "#F2DCC7",
            p: 6,
          }}
          btnContent={"Logout"}
          modalContent={<LogoutConfirm onClose={setModalClose} />}
        />
      </MenuList>
    </Menu>
  );
};
