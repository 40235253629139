import {
  Text,
  Flex,
  Box,
  Stack,
  Image,
  Button,
  SimpleGrid,
  Input,
  chakra,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

function NotSubscribed() {
  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
      fontWeight={"bold"}
        justifyContent={"center"}
        borderRadius={"10px"}
        border={"2px solid #EDD0AE"}
        alignItems={"center"}
        width={"fit-content"}
        p={{base:"10px",md:"10px",lg:"30px"}}
        backgroundColor={"white"}
        height={"fit-content"}
        my={"auto"}
        mx={"auto"}
        boxShadow={"lg"}
      >
        <Text>This service is only available to premium members.</Text>
      </Flex>
    </Flex>
  );
}

export default NotSubscribed;
