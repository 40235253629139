import { Flex, Grid, Image } from "@chakra-ui/react";
import React from "react";

function VideoContent(props) {
  const { ShopVideo } = props;
  return (
    <Flex
      mt={{ base: "40%", md: "20%", lg: "10%" }}
      justifyContent={"center"}
      width={"100%"}
    >
      <video
        playsInline
        className="videobox"
        src={ShopVideo}
        style={{ borderRadius: "10px" }}
        autoPlay={true}
        muted={true}
        controls={true}
        loop
      />
    </Flex>
  );
}

export default VideoContent;
