import React, { useState, useEffect } from "react";
// import { Carousel } from "react-responsive-carousel";
import Carousel from "react-elastic-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Flex, Image, Text } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import ImageModal from "./ImageModal";
function DiscountOfferCard(props) {

  // carousel
  const carouselRef = React.useRef(null); // declare at state level
  let resetTimeout;
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];
  // carousel
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { carouselImages, width = "60%", height = "auto" } = props;
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  // update the images
  useEffect(() => {

    setImages(carouselImages);
  }, [carouselImages]);
  const handleImageClick = (image) => {
    setSelectedImage(image);
    onOpen();
  };
  return (
    <Flex

      width={{base:"340px",md:"550px",lg:"340px"}}
      height={"fit-content"}
      flexDir={"column"}
      alignItems={"center"}
      alignSelf={"center"}
    >
        {/* <div className="carousel-wrapper"> */}
          <Carousel
          
          showArrows={true}
          pagination={false}
          enableMouseSwipe={true}
          enableAutoPlay={true}
          autoPlaySpeed={3000}
          breakPoints={breakPoints}
          onNextEnd={({ index }) => {
            if(index===images.length-1){
              clearTimeout(resetTimeout);
              resetTimeout = setTimeout(() => {
                carouselRef?.current?.goTo(0);
              }, 5000); 
            } 
          }}
          ref={carouselRef}

            
           
          >
           {images.map((image, index) => (
            <Flex
        
            w={"100%"}
           
              mt={"auto"}
              mb={"auto"}
              key={index}
              onClick={() => handleImageClick(image)}
            >
              <Image
              objectFit={"contain"}
                borderRadius={"20px"}
                height={"250px"}
               mx={"auto"}
                src={image}
                alt={`Image ${index + 1}`}
              />
            </Flex>
          ))}
          </Carousel>
          <ImageModal isOpen={isOpen} onClose={onClose} image={selectedImage} />

    </Flex>
  );
}

export default DiscountOfferCard;
