export const decorFilterMaterial = [
    { name: "Ceramic" },
    { name: "Wood"},
    { name: "Metal"},
    { name: "Glass" },
    { name: "Terracotta"},
    { name: "Plastic"},
    { name: "Other" },
 
  ];
  