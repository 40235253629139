import { Container, Flex } from "@chakra-ui/layout";
import React from "react";
import CollectionSelection from "./Collections/CollectionSelection/CollectionSelection";
// import Collections from "../Components/shared/Collections";
import StoresCollection from "./Stores/StoresCollection/StoresCollection";
import ItemCollection from "./Stores/SingleStores/ItemCollection/ItemCollection";
import DesignersCollection from "./Designers/DesignersCollection";
import DesignsCollection from "./Designers/SingleDesigner/DesignsCollection";
import Footer from "../Components/Footer";

import { wallartFilterObjects } from "../Components/shared/Filter/FilterObjects/WallartFilterObjects";
import { bathroomFilterObjects } from "../Components/shared/Filter/FilterObjects/BathroomFilterObjects";
import { furnitureFilterObjects } from "../Components/shared/Filter/FilterObjects/FurnitureFilterObjects";
import DesignerHeaderCard from "./Designers/SingleDesigner/DesignerHeaderCard";
import SearchBar from "../Components/shared/SearchBar";


import CommonFilter from "../Components/shared/Filter/CommonFilter";
import Navbar from "../Components/Navbar";

import ShopGallery from "./Stores/ShopGallery/ShopGallery";
import Shop from "../Assets/DummyCollections/OIP.jpeg";
import MobileCommonFilter from "../Components/shared/Filter/MobileCommonFilter";
import AboutUs from "./MainPage/sections/AboutUs";
import DiscountOfferCard from "../Components/shared/DiscountOfferCard";
import Discount1 from "../Assets/Images/Discount/Discount Offer1.png";
import WhatWeDo from "./MainPage/sections/WhatWeDo";
function HomeScreen() {
  const carouselImages = [
    { src: Discount1 },
    { src: Discount1 },
    { src: Discount1 },
  ];
  return (
    <Container p={0} maxW="1600px" width={"100vw"} height={"100vh"}>
      <CollectionSelection />
      <ShopGallery shopImage={Shop} />
      <StoresCollection />
      <ItemCollection />
      <DesignersCollection
        facebookLink={"www.facebook.com"}
        instagramLink={"www.facebook.com"}
        linkedinLink={"www.facebook.com"}
      />
      <DesignsCollection />
      <DiscountOfferCard carouselImages={carouselImages} />
      <Footer />
      <CommonFilter
        FilterObjects={bathroomFilterObjects}
        Pricefilter={1}
        ProfessionFilter={1}
        Productfilter={1}
      />
      <DesignerHeaderCard />
    
      <Flex flexDir={"column"} width={"100%"}>
      <WhatWeDo/>
        <AboutUs />
       <Footer />
   
        <MobileCommonFilter
          MobProfessionFilter={1}
          MobDistrictFilter={1}
          MobStyleFilter={1}
          MobPricefilter={1}
          MobileFilterObjects={furnitureFilterObjects}
          MobProductfilter={1}
        />
     {" "}
      </Flex>
    </Container>
  );
}

export default HomeScreen;
