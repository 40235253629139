import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Stack,
  Show,
  Text,
  HStack,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { MenuItem, Menu } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Wishlist from "../Assets/Images/wishlist.png";
import Cart from "../Assets/Images/cart.png";
import { useNavigate } from "react-router-dom";
import StylhomLOGO from "../Assets/Images/StylHomLOGOpng.webp";
import "../Assets/FontAsset/Marko_One/MarkoOne-Regular.ttf";
import "../Assets/FontAsset/Sofia_Sans/SofiaSans-VariableFont_wght.ttf";
import { HashLink } from "react-router-hash-link";
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/react";
import CommonModal from "./CommonModal/CommonModal";
import Login from "../Screens/Login";
import { getItemFromLocalStorage } from "../utils/local-storage";
import { UserAvatar } from "./UserAvatar";
import { State } from "../Context/Context";
import { MenuButton } from "@chakra-ui/react";
import { useNumberOfUsers } from "../NumberOfUsersProvider";
import axios from "axios";
import { API_BASE_URL } from "../Config/index.js";
import { config } from "../Functions/Auth.js";
import { useGetUserData } from "../Functions/queries.js";
import { useGetShopData } from "../../src/Functions/queries";

function Navbar() {
  const { numberOfDisplayedCarts, wishlistNumber, setWishlistNumber, setnumberOfDisplayedCarts } = useNumberOfUsers();
  const navigate = useNavigate();
  const { user } = useGetUserData();
  const currentRoute = window.location.pathname.toLowerCase();
  const [wishlistProducts, setWishListProducts] = useState([]);
  const { shop } = useGetShopData();
  const [loading, setLoading] = useState(false);
  const handleNavigation = (path) => {
    navigate(path);
  };

  // cart count
  useEffect(() => {
    const calculatedNumberOfUsers = shop && shop[0]?.product ? shop[0]?.product.reduce((count, productdataitem) => {
        if (!productdataitem.payment && (productdataitem.generate3d || productdataitem.generatetexture)) {
          return count + 1;
        }
        return count;
      }, 0) : 0; 
    setnumberOfDisplayedCarts(calculatedNumberOfUsers);
  }, [setnumberOfDisplayedCarts,shop]);
  // cart count
// wishlist count
useEffect(() => {
  const fetchData = async () => {
    user?.data && wishProduct();
  };
  fetchData();
}, [user]);
const wishProduct = async () => {
  try {
    const wishlist = user?.data?.wishlist;

    if (!wishlist || wishlist.length === 0) {
      return;
    }
    setLoading(true);
    const wishlistProducts = await Promise.all(
      wishlist.map(async (wish) => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/shop/products/${wish}`,
            config()
          );
          return {
            product: response.data.product,
            shop: response.data.shop, // Adjust this based on your actual response structure
          }; // Assuming the product data is in response.data
        } catch (error) {
          console.error("Error fetching product:", error);
          return null; // Handle the error gracefully
        }
      })
    );
    setLoading(false);
    setWishListProducts(wishlistProducts);
  } catch (error) {
    console.error("Error fetching wishlist products:", error);
  }
};

useEffect(() => {
 
  setWishlistNumber(wishlistProducts.length );
}, [wishlistProducts]);
// wishlistcount
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpenM, onOpenM, onCloseM } = useDisclosure();
  const btnRef = React.useRef();
  const [modalClose, setModalClose] = useState();
  const { token, setToken } = State();
  let client = getItemFromLocalStorage("client");
  const LoginBtn = () => {
    return (
      <Button
      className="LOG"
        backgroundColor="#C69C6D"
        _hover={{ bg: "#E7B379", color: "black" }}
        color="white"
        variant="solid"
        // fontFamily={"'Marko One', serif"}
        fontSize={{ base: "14px", md: "14px", lg: "14px" }}
        size={["sm", "md"]}
        borderRadius={7}
        id="SignBtn"
        h={"35px"}
        w={{ base: "200px", md: "100px", lg: "120px" }}
        // boxShadow="inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25), inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        boxShadow={"lg"}
        as={Button}
      >
        LOGIN / SIGN UP
      </Button>
    );
  };

  return (
    <Flex zIndex={1} width={"100%"}>
      <Box px={6} width={"100%"}>
        <Flex
          mt={{ base: "5px", lg: "10px" }}
          h={16}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <HStack zIndex={3}>
            <Link to="/">
              <Image
                objectFit={"contain"}
                src={StylhomLOGO}
                alt="logo"
                display={{ base: "block", md: "block", lg: "block" }}
                width={{ base: "120px", md: "170px", lg: "210px" }}
                mt={{ base: "30px", md: "50px", lg: "45px" }}
              />
            </Link>
          </HStack>

          <Flex
            display={{ base: "none", md: "none", lg: "block" }}
            // alignItems={"center"}
            width={"87%"}
            // justifyContent={"space-between"}
          >
            <HStack
              fontSize={{ base: "", md: "13px", lg: "14px" }}
              spacing={0}
              alignItems={"center"}
            >
              <HStack
                ml={"auto"}
                as={"nav"}
                spacing={0}
                display={{ base: "none", md: "flex", lg: "flex" }}
                id="myDIV"
              >
                {/* home */}

                <Menu>
                  <Popover trigger={"hover"} placement={"bottom-start"}>
                    <PopoverTrigger>
                      <MenuButton
                        className={
                          currentRoute.includes("home") ? "NavActive" : ""
                        }
                        onClick={() => handleNavigation("/home")}
                      >
                        <Text
                          width={"100px"}
                          textAlign={"center"}
                         
                          px={5}
                          color={"#FEFBF4"}
                          style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                        >
                          Home
                        </Text>
                      </MenuButton>
                    </PopoverTrigger>
                    <PopoverContent
                      className="popup"
                      maxW="200px"
                      border={"none"}
                    >
                      <Stack color={"white"}>
                        <HashLink
                          _hover={{
                            textDecoration: "none",
                          }}
                          smooth="true"
                          to="/home#AboutUs"
                          className="NavHov"
                        >
                          <MenuItem
                            p={2}
                            bg={"transparent"}
                            _hover={{ bg: "blackAlpha.300" }}
                            _active={{ bg: "blackAlpha.300" }}
                            _focus={{ bg: "blackAlpha.300" }}
                          >
                            About Us
                          </MenuItem>
                        </HashLink>
                      </Stack>
                    </PopoverContent>
                  </Popover>
                </Menu>

                {/* collections */}
                <NavLink
                  className={
                    currentRoute.includes("collections") ? "NavActive" : ""
                  }
                  onClick={() => handleNavigation("/collections/furniture/")}
                  to="/collections/furniture/#collections"
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  <Text
                    width={"100px"}
                    textAlign={"center"}
                    color={"#FEFBF4"}
                    style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                    px={2}
                  >
                    Collections
                  </Text>
                </NavLink>

                {/* Stores */}
                <NavLink
                  className={currentRoute.includes("stores") ? "NavActive" : ""}
                  onClick={() => handleNavigation("/stores")}
                  smooth="true"
                  to="/stores/#stores"
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  <Text
                    width={"100px"}
                    textAlign={"center"}
                    color={"#FEFBF4"}
                    style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                    px={5}
                  >
                    Stores
                  </Text>
                </NavLink>

                {/* Designers */}
                <NavLink
                  className={
                    currentRoute.includes("creators") ? "NavActive" : ""
                  }
                  onClick={() => handleNavigation("/creators")}
                  smooth="true"
                  to="/creators/#designers"
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  <Text
                    width={"100px"}
                    textAlign={"center"}
                    color={"#FEFBF4"}
                    style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                    px={5}
                  >
                    Creators
                  </Text>
                </NavLink>

                {/* Contact us */}
                <NavLink
                  className={
                    currentRoute.includes("contactus") ? "NavActive" : ""
                  }
                  onClick={() => handleNavigation("/contactus")}
                  smooth="true"
                  to="/contactUs/#contactus"
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  <Text
                    width={"100px"}
                    textAlign={"center"}
                    color={"#FEFBF4"}
                    style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                    px={5}
                  >
                    Help
                  </Text>
                </NavLink>

                {/* FAQ */}
                <NavLink
                  className={currentRoute.includes("faq") ? "NavActive" : ""}
                  onClick={() => handleNavigation("/faq")}
                  smooth="true"
                  to="/faq/#faq"
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  <Text
                    width={"100px"}
                    textAlign={"center"}
                    color={"#FEFBF4"}
                    style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                    px={5}
                  >
                    FAQ
                  </Text>
                </NavLink>
              </HStack>
              <Flex ml={"10px"} alignItems={"center"} justifyContent={"center"}>
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction={"row"}
                  spacing={7}
                >
                  {token && (
                    <>
                      {" "}
                      <NavLink to="/wishlist">
                        <Button
                          background={"#C69C6D"}
                          _hover={{ bg: "#E7B379", color: "black" }}
                        >
                          <Image width={"20px"} src={Wishlist}></Image>

                          {wishlistNumber >0 && (
                            <Text
                              backgroundColor={"#F2544E"}
                              fontSize={"10px"}
                              px={2}
                              py={1.5}
                              borderRadius={"50%"}
                              color={"white"}
                            >
                              {wishlistNumber}
                            </Text>
                          )}
                        </Button>
                      </NavLink>
                      {client?.role === "shopOwner" ? (
                        <NavLink to="/cart">
                          <Button
                            background={"#C69C6D"}
                            _hover={{ bg: "#E7B379", color: "black" }}
                          >
                            <Image width={"20px"} src={Cart}></Image>
                            {numberOfDisplayedCarts >0 && (
                              <Text
                                backgroundColor={"#F2544E"}
                                fontSize={"10px"}
                                px={2}
                                py={1.5}
                                borderRadius={"50%"}
                                color={"white"}
                              >
                                {numberOfDisplayedCarts}
                              </Text>
                            )}
                          </Button>
                        </NavLink>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {token ? (
                    <UserAvatar />
                  ) : (
                    <CommonModal
                      modalState={modalClose}
                      btn={{
                        text: "red",
                        bg: "transparent",
                        hoverbg: "transparent",
                      }}
                      btnContent={LoginBtn()}
                      modalContent={<Login setModalClose={setModalClose} />}
                    />
                  )}
                </Stack>
              </Flex>
            </HStack>
          </Flex>

          {/* mobile navbar */}
          <Flex
            display={{ base: "block", md: "block", lg: "none" }}
            zIndex={2}
            pt={5}
            position={"absolute"}
            color={"white"}
          >
            {/* hamberger icon */}
            <Button
              ml={{ base: "76vw", md: "87vw", lg: "0" }}
              style={{ outline: "none" }}
              ref={btnRef}
              backgroundColor={"#EDD9C5"}
              onClick={onOpen}
              aria-label="mobilenavbar hamberger"
            >
              <HamburgerIcon />
            </Button>

            {/* opening drawer */}
            <Drawer
              color={"white"}
              isOpen={isOpen}
              placement="right"
              size="full"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent backdropFilter="auto" backdropBlur="8px" bg={""}>
                <DrawerCloseButton color={"brand.Brown"} bg={"#EDD9C5"} />
                <DrawerHeader
                  bg={"brand.btnBg"}
                  borderBottomWidth="2px"
                  borderColor={"white"}
                >
                  <Image ml={"auto"} mr={"auto"} src={StylhomLOGO} />
                </DrawerHeader>

                <DrawerBody color={"whiteAlpha.900"}>
                  <Menu className="heading">
                    {/* home */}
                    <NavLink
                      to="/home"
                      _hover={{
                        textDecoration: "none",
                      }}
                    >
                      <MenuItem
                        borderBottomWidth="1px"
                        borderColor={"rgba(250,250,250,0.8)"}
                        justifyContent={"center"}
                        p={4}
                        color={"#FEFBF4"}
                        style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                        _hover={{ bg: "rgb(0,113,215,01)" }}
                      >
                        Home
                      </MenuItem>
                    </NavLink>

                    {/* Collections */}
                    <NavLink
                      to="/collections/furniture/#collections"
                      _hover={{
                        textDecoration: "none",
                      }}
                    >
                      <MenuItem
                        borderBottomWidth="1px"
                        borderColor={"rgba(250,250,250,0.8)"}
                        justifyContent={"center"}
                        p={4}
                        _hover={{ bg: "rgb(0,113,215,01)" }}
                        color={"#FEFBF4"}
                        style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                      >
                        Collections
                      </MenuItem>
                    </NavLink>
                    {/* Stores */}
                    <NavLink
                      to="/stores/#stores"
                      _hover={{
                        textDecoration: "none",
                      }}
                    >
                      <MenuItem
                        borderBottomWidth="1px"
                        borderColor={"rgba(250,250,250,0.8)"}
                        justifyContent={"center"}
                        p={4}
                        color={"#FEFBF4"}
                        style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                        _hover={{ bg: "rgb(0,113,215,01)" }}
                      >
                        Stores
                      </MenuItem>
                    </NavLink>
                    {/* Architects */}
                    <NavLink
                      to="/creators/#designers"
                      _hover={{
                        textDecoration: "none",
                      }}
                    >
                      <MenuItem
                        borderBottomWidth="1px"
                        borderColor={"rgba(250,250,250,0.8)"}
                        justifyContent={"center"}
                        p={4}
                        color={"#FEFBF4"}
                        style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                        _hover={{ bg: "rgb(0,113,215,01)" }}
                      >
                        Creators
                      </MenuItem>
                    </NavLink>
                    {/* Contact Us*/}
                    <NavLink
                      to="/contactUs/#contactus"
                      _hover={{
                        textDecoration: "none",
                      }}
                    >
                      <MenuItem
                        borderBottomWidth="1px"
                        borderColor={"rgba(250,250,250,0.8)"}
                        justifyContent={"center"}
                        p={4}
                        color={"#FEFBF4"}
                        style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                        _hover={{ bg: "rgb(0,113,215,01)" }}
                      >
                        Help
                      </MenuItem>
                    </NavLink>

                    {/* FAQ */}

                    <NavLink
                      to="/faq/#faq"
                      _hover={{
                        textDecoration: "none",
                      }}
                    >
                      <MenuItem
                        borderBottomWidth="1px"
                        borderColor={"rgba(250,250,250,0.8)"}
                        justifyContent={"center"}
                        p={4}
                        color={"#FEFBF4"}
                        style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
                        _hover={{ bg: "rgb(0,113,215,01)" }}
                      >
                        FAQ
                      </MenuItem>
                    </NavLink>
                    {/* Login button */}

                    <MenuItem
                      borderBottomWidth="1px"
                      borderColor={"rgba(250,250,250,0.8)"}
                      justifyContent={"center"}
                      p={4}
                      _hover={{ bg: "rgb(0,113,215,01)" }}
                    >
                      {/* <CommonModal
                        modalState={modalClose}
                        btn={{
                          text: "red",
                          bg: "transparent",
                          hoverBg: "transparent",
                        }}
                        btnContent={LoginBtn()}
                        modalContent={<Login setModalClose={setModalClose} />}
                      /> */}
                      {token ? (
                        <UserAvatar />
                      ) : (
                        <CommonModal
                          modalState={modalClose}
                          btn={{
                            text: "red",
                            bg: "transparent",
                            hoverBg: "transparent",
                          }}
                          btnContent={LoginBtn()}
                          modalContent={<Login setModalClose={setModalClose} />}
                        />
                      )}
                    </MenuItem>
                    {/* Wishlist */}
                    {/* Login button */}
                    {token && (
                      <>
                        <MenuItem
                          borderBottomWidth="1px"
                          borderColor={"rgba(250,250,250,0.8)"}
                          justifyContent={"center"}
                          p={4}
                          _hover={{ bg: "rgb(0,113,215,01)" }}
                        >
                          <NavLink to="/wishlist">
                            {" "}
                            <Button
                              h={"35px"}
                              w={"200px"}
                              color={"white"}
                              boxShadow={"lg"}
                              fontSize={{ base: "14px" }}
                              background={"#C69C6D"}
                              _hover={{ bg: "#E7B379", color: "black" }}
                            >
                              {" "}
                              <Image
                                mr={"15px"}
                                width={"20px"}
                                src={Wishlist}
                              ></Image>
                              Wishlist  
                              {wishlistNumber >0 && (
                            <Text
                            ml={"5px"}
                              backgroundColor={"#F2544E"}
                              fontSize={"10px"}
                              px={2}
                              py={1.5}
                              borderRadius={"50%"}
                              color={"white"}
                            >
                              {wishlistNumber}
                            </Text>
                          )}
                            </Button>
                          </NavLink>
                        </MenuItem>

                        {client?.role === "shopOwner" ? (
                          <MenuItem
                            borderBottomWidth="1px"
                            borderColor={"rgba(250,250,250,0.8)"}
                            justifyContent={"center"}
                            p={4}
                            _hover={{ bg: "rgb(0,113,215,01)" }}
                          >
                            <NavLink to="/cart">
                              {" "}
                              <Button
                                h={"35px"}
                                w={"200px"}
                                color={"white"}
                                boxShadow={"lg"}
                                fontSize={{ base: "14px" }}
                                background={"#C69C6D"}
                                _hover={{ bg: "#E7B379", color: "black" }}
                              >
                                {" "}
                                <Image
                                  ml={"-20px"}
                                  width={"20px"}
                                  src={Cart}
                                  mr={"20px"}
                                ></Image>
                                Cart 
                                {numberOfDisplayedCarts >0 && (
                              <Text
                              ml={"5px"}
                                backgroundColor={"#F2544E"}
                                fontSize={"10px"}
                                px={2}
                                py={1.5}
                                borderRadius={"50%"}
                                color={"white"}
                              >
                                {numberOfDisplayedCarts}
                              </Text>
                            )}
                              </Button>
                            </NavLink>
                          </MenuItem>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Menu>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Navbar;
