import { Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Products } from "../DummyStoreData";
import circle from "../../../../Assets/Images/StorePage/circle.png";
import ProductSection from "../../../../Components/ProductSection";
import RelatedProducts from "../../../../Components/RelatedProducts";
import { State } from "../../../../Context/Context";
import { useLocation, useParams } from "react-router-dom";
import BackButton from "../../../../Components/shared/BackButton";
import Breadcrumbs from "../../../../Components/shared/Breadcrumbs";
function SingleProductScreen(props) {
  const location = useLocation();
  const product = location?.state?.product;
  return (
    <Flex  width={"100%"} mx={"auto"} flexDir={"column"}>
      {/* <Flex my={{base:"20px",md:"20px",lg:"30px"}} ml={{base:"0px",md:"0px",lg:"-6%"}}> */}
      <BackButton/>
      {/* </Flex> */}
      <ProductSection product={product} />
      <RelatedProducts />
      <Image
        position={"absolute"}
        mt={{ base: "220%", md: "60%", lg: "41%" }}
        zIndex={-1}
        src={circle}
        width={{ base: "200px", md: "300px", lg: "300px" }}
      ></Image>
    </Flex>
  );
}

export default SingleProductScreen;
