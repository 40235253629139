import { Flex, Text } from "@chakra-ui/layout";
import React, { useState } from "react";
import Design from "../../../Assets/DummyCollections/Shop.png";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";

import { transform } from "lodash";

function DesignsCollection(props) {
  const [zoomedIndex, setZoomedIndex] = useState(null);

  const toggleZoom = (index) => {
    setZoomedIndex(index === zoomedIndex ? null : index);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { image = Design, designName = "My Design" } = props;
  const imagesArray = Array.isArray(image) ? image : [image];
  // popup
  const [file, setFile] = useState(null);

  return (
    <Flex >
      <Flex
        cursor={"pointer"}
        onClick={() => setFile(imagesArray)}
        _hover={{ transform: "scale(1.02)" }}
      >
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          onClick={onOpen}
        >
          <Image
            borderRadius={"10px"}
            objectFit={"cover"}
            width={{ base: "220px", md: "270px", lg: "320px" }}
            height={{ base: "240px", md: "270px", lg: "340px" }}
            zIndex={0}
            src={image[0] ? image[0] : Design}
          ></Image>
          <Flex
            borderRadius={"10px"}
            position={"absolute"}
            bg={
              "linear-gradient(180deg, rgba(255, 255, 255, 0.00) -16.12%, rgba(29, 29, 29, 0.00) 13.42%, rgba(0, 0, 0, 0.80) 80.56%)"
            }
            width={{ base: "220px", md: "270px", lg: "320px" }}
            height={{ base: "240px", md: "270px", lg: "340px" }}
          ></Flex>
          <Text
            fontSize={{ base: "14px", md: "15px", lg: "17px" }}
            mt={{ base: "200px", md: "220px", lg: "300px" }}
            position={"absolute"}
            textAlign={"center"}
            color={"white"}
          >
            {designName}
          </Text>
        </Flex>
      </Flex>
      {/* <Flex display={file ? "block" : "none"} className="popup-media">
        <span onClick={() => setFile(null)}>&times;</span>
        <Image className="popupimage" src={imagesArray}></Image>
      </Flex> */}
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="whiteAlpha.400"   backdropFilter="blur(8px)" >
          <ModalHeader backgroundColor={"#EEDBC6"}> {designName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
          {imagesArray && imagesArray[0]!=0 && imagesArray.length > 0 && (
            <SimpleGrid spacing={"9px"}   columns={[3, null, 8]} >
            {imagesArray.map((imagess, index) => (
              <Image

              _hover={{
                transform: "scale(1.05)",
                transition: "transform 0.3s ease",
              }}
              border={"1px solid white"}
              borderRadius={"5px"}
              onClick={() => toggleZoom(index)}
           
              key={index}
              mx={"auto"}
              objectFit={"cover"}
                width={{ base: "100px", md: "150px", lg: "150px" }}
                height={{ base: "100px", md: "150px", lg: "150px" }}
                src={imagess}
              ></Image>
              ))}
            </SimpleGrid>
          )}
            {zoomedIndex !== null && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          justifyContent="center"
          alignItems="center"
          bg="rgba(255, 255, 255, 0.2)"
          zIndex="999"
          onClick={() => setZoomedIndex(null)}
        >
          <Image
            src={imagesArray[zoomedIndex]}
            maxW="70%"
            maxH="70%"
            borderRadius="10px"
            objectFit="contain"
          />
        </Flex>
      )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default DesignsCollection;
