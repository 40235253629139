import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import Collections from "../../Collections/CollectionsCard/Collections";
import Shop from "../../../Assets/DummyCollections/Shop.png";
import { Image } from "@chakra-ui/image";
function StoresCollection(props) {
  const {
    src = Shop,
    bgSize = "280px 280px",
    imageWidth = { base: "260px", lg: "210px", "2xl": "260px" },
    imageHeight = { base: "260px", lg: "230px", "2xl": "260px" },
    borderRadius = "6px",
    boxShadow = "lg",
    nameCardBg = "linear-gradient(179deg, rgba(168, 123, 78, 0.81) 59.75%, rgba(227, 183, 139, 0.58) 95.49%)",
    nameCardP = 0,
    headingSize = "14px",
    descriptionSize = "9px",
    heading = "Shop Name",
    description = "Ernakulam",
    headingHeight = "18px",
  } = props;
  return (
    <Flex
      height={{ base: "220px", md: "220px", lg: "220px" }}
      flexDir={"column"}
      width={"fit-content"}
      m={2}
    >
      <Flex
        width={{ base: "250px" }}
        bgSize={bgSize}
        bgRepeat={"no-repeat"}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        flexDir={"column"}
        height={imageHeight}
      >
        <Image
          borderRadius={borderRadius}
          objectFit={"cover"}
          height={"220px"}
          w={"250px"}
          src={src}
        ></Image>
        <Flex
          position={"absolute"}
          height={"220px"}
          w={"250px"}
          flexDir={"column"}
          bg={"blackAlpha.500"}
          borderRadius={borderRadius}
          justifyContent={"flex-end"}
        >
          <Flex
            m={2}
            p={nameCardP}
            bg={nameCardBg}
            borderRadius={borderRadius}
            boxShadow={"lg"}
          >
            <Flex px={4} py={1} flexDir={"column"}>
              <Text
                height={headingHeight}
                fontWeight={"semibold"}
                color={"white"}
                fontSize={headingSize}
              >
                {heading}
              </Text>
              <Text color={"white"} fontSize={descriptionSize}>
                {description}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default StoresCollection;
