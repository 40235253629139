import { Flex, Text } from "@chakra-ui/layout";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Divider,
  Checkbox,
  RangeSlider,
  RangeSliderMark,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Image,
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { FaFilter } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { furnitureFilterObjects } from "./FilterObjects/FurnitureFilterObjects";
import { colorFilterObjects } from "./FilterObjects/ColorFilterObjects";
import { wallartFilterObjects } from "./FilterObjects/WallartFilterObjects";
import { bathroomFilterObjects } from "./FilterObjects/BathroomFilterObjects";
import { decorFilterObjects } from "./FilterObjects/DecorFilterObjects";
import { lightingFilterObjects } from "./FilterObjects/LightingFilterObjects";
import { tileFilterObjects } from "./FilterObjects/TileFilterObjects";
import { districtFilterObjects } from "./FilterObjects/DistrictFilterObjects";
import FormatIndianRupees from "../../../Functions/FormatIndianRupees";
import { handleFilterChange } from "./FilterFunctions/FilterFunctions";
import { furnitureFilterMaterial } from "./FilterObjects/FurnitureFilterMaterial";
function MobileCommonFilter(props) {
  const {
    bg = "brand.FilterBg",
    width = "300px",
    p = "1",
    m = "1",
    ml = 0,
    FilterTitle = "Filter Stores",
    borderRadius = "10px",
    MobileFilterObjects = furnitureFilterObjects,
    MobileMaterialObjects = furnitureFilterMaterial,
    material = [],
    setMaterial = () => {},
    collection = [],
    setCollection = () => {},
    subCollection = [],
    setSubCollection = () => {},
    MobProductfilter,
    MobMaterialfilter,
    MobPricefilter,
    priceValue = [0, 100000],
    setPriceValue = () => {},
    MobStyleFilter,
    style = [],
    setStyle = () => {},
    MobDistrictFilter,
    district = [],
    setDistrict = () => {},
    MobProfessionFilter,
    MobTextureFilter,
    profession = [],
    setProfession = () => {},
    texture = [],
    setTexture = () => {},
    MobColorFilter,
    color = [],
    setColor = () => {},
    selection,
  } = props;
  const [sliderValue, setSliderValue] = useState([0, 100000]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const toggleSelection = (itemName, sub1, sub2, sub3, sub4) => {
    if (selectedItems.includes(itemName)) {
      setSelectedItems(selectedItems.filter((item) => item !== itemName));
    } else {
      setSelectedItems([...selectedItems, itemName]);
      setSubCollection(
        subCollection.filter(
          (sub) => sub !== sub1 && sub !== sub2 && sub !== sub3 && sub !== sub4
        )
      );
    }
  };
  useEffect(() => {
    if (selectedItems.length !== collection.length) {
      setSelectedItems(collection);
    }
  }, [collection]);
  // TODO clear all for mobile filter / closebutton functionality
  return (
    <Flex display={{ base: "block", md: "block", lg: "none" }}>
      <Button
        m={1}
        ml={0}
        borderRadius={"30%"}
        position="fixed"
        bottom="0"
        left={2}
        zIndex="1"
        boxShadow={"dark-lg"}
        border={"3px solid white"}
        width={{ base: "60px", md: "75px" }}
        height={{ base: "60px", md: "75px" }}
        color={"white"}
        ref={btnRef}
        backgroundColor={"brand.btnBg"}
        onClick={onOpen}
        fontSize={{ base: "10px", md: "13px" }}
      >
        <Flex alignItems={"center"} flexDir={"column"}>
          <Text>Filter </Text>
          <FaFilter size={13} />
        </Flex>
      </Button>

      <Drawer
        size={"full"}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor={"#FFF7EE"}>
          <DrawerCloseButton />
          <DrawerHeader
            backgroundColor={"#EDD9C5"}
            color={"brand.Brown"}
            fontFamily={"heading"}
          >
            {FilterTitle}
          </DrawerHeader>

          <DrawerBody>
            <Accordion defaultIndex={[0]} allowMultiple>
              {/*Cateogry filter starts  */}

              <Flex
                style={{ display: MobProductfilter === 1 ? "flex" : "none" }}
                flexDir={"column"}
                width={"100%"}
              >
                {" "}
                <AccordionItem>
                  <AccordionButton>
                    <AccordionIcon />
                    <Text
                      ml="5px"
                      color={"brand.Brown"}
                      fontSize={{ base: "14px", md: "16px" }}
                      fontFamily={"heading"}
                    >
                      Filter by Product
                    </Text>
                  </AccordionButton>
                  <AccordionPanel>
                    {MobileFilterObjects &&
                      MobileFilterObjects.map((item,index) => {
                        return (
                          <Flex key={index} flexDir={"column"} mt={"10px"}>
                            <Checkbox
                              size="sm"
                              colorScheme="orange"
                              borderColor={"brand.btnBg"}
                              value={item.name}
                              onChange={(e) => {
                                toggleSelection(
                                  e.target.value,
                                  item.sub1,
                                  item.sub2,
                                  item.sub3,
                                  item.sub4
                                );
                                handleFilterChange(
                                  e,
                                  setCollection,
                                  collection
                                );
                              }}
                              defaultChecked={collection.includes(item.name)}
                            >
                              {item.name}
                            </Checkbox>

                            {/* Sub Checkboxes if present */}
                            {selectedItems.includes(item.name) && item.sub1 && (
                              <Checkbox
                                size="sm"
                                ml={5}
                                colorScheme="orange"
                                borderColor={"brand.btnBg"}
                                value={item.sub1}
                                defaultChecked={subCollection.includes(
                                  item.sub1
                                )}
                                onChange={(e) => {
                                  handleFilterChange(
                                    e,
                                    setSubCollection,
                                    subCollection
                                  );
                                }}
                              >
                                {item.sub1}
                              </Checkbox>
                            )}

                            {selectedItems.includes(item.name) && item.sub2 && (
                              <Checkbox
                                size="sm"
                                ml={5}
                                colorScheme="orange"
                                borderColor={"brand.btnBg"}
                                value={item.sub2}
                                defaultChecked={subCollection.includes(
                                  item.sub2
                                )}
                                onChange={(e) => {
                                  handleFilterChange(
                                    e,
                                    setSubCollection,
                                    subCollection
                                  );
                                }}
                              >
                                {item.sub2}
                              </Checkbox>
                            )}

                            {selectedItems.includes(item.name) && item.sub3 && (
                              <Checkbox
                                size="sm"
                                ml={5}
                                colorScheme="orange"
                                borderColor={"brand.btnBg"}
                                value={item.sub3}
                                defaultChecked={subCollection.includes(
                                  item.sub3
                                )}
                                onChange={(e) => {
                                  handleFilterChange(
                                    e,
                                    setSubCollection,
                                    subCollection
                                  );
                                }}
                              >
                                {item.sub3}
                              </Checkbox>
                            )}

                            {selectedItems.includes(item.name) && item.sub4 && (
                              <Checkbox
                                ml={5}
                                size="sm"
                                colorScheme="orange"
                                borderColor={"brand.btnBg"}
                                value={item.sub4}
                                defaultChecked={subCollection.includes(
                                  item.sub4
                                )}
                                onChange={(e) => {
                                  handleFilterChange(
                                    e,
                                    setSubCollection,
                                    subCollection
                                  );
                                }}
                              >
                                {item.sub4}
                              </Checkbox>
                            )}

                            {/* Sub Checkboxes if present  */}
                          </Flex>
                        );
                      })}
                  </AccordionPanel>
                </AccordionItem>
              </Flex>
              {/* Category Filter Ends */}

              {/* Price Filter Strts */}

              <Flex
                mt={"15px"}
                style={{ display: MobPricefilter === 1 ? "flex" : "none" }}
                flexDir={"column"}
                width={"100%"}
              >
                <AccordionItem>
                  <AccordionButton>
                    <AccordionIcon />
                    <Text
                      ml="5px"
                      color={"brand.Brown"}
                      fontSize={{ base: "14px", md: "16px" }}
                      fontFamily={"heading"}
                    >
                      Filter by Price
                    </Text>
                  </AccordionButton>

                  <AccordionPanel>
                    <Flex
                      p={3}
                      px={7}
                      flexDir={"column"}
                      mt={"60px"}
                      display={MobPricefilter === 1 ? "flex" : "none"}
                    >
                      <RangeSlider
                        aria-label={["min", "max"]}
                        step={1000}
                        defaultValue={priceValue}
                        // onChange={(val) => setSliderValue(val)}
                        min={0}
                        colorScheme="orange"
                        max={100000}
                        onChange={(val) => setPriceValue(val)}
                      >
                        {/* .... */}
                        <RangeSliderMark
                          value={0}
                          mt="1"
                          ml="-2.5"
                          fontSize="13px"
                        >
                          {FormatIndianRupees(0)}
                        </RangeSliderMark>
                        <RangeSliderMark
                          value={50000}
                          mt="1"
                          ml="-2.5"
                          fontSize="13px"
                        >
                          {FormatIndianRupees(50000)}
                        </RangeSliderMark>
                        <RangeSliderMark
                          value={100000}
                          mt="1"
                          ml="-22.5"
                          fontSize="13px"
                        >
                          {FormatIndianRupees(100000)}+
                        </RangeSliderMark>
                        <RangeSliderMark
                          fontSize="13px"
                          value={priceValue[0]}
                          textAlign="center"
                          bg="brand.btnBg"
                          borderRadius={"5px"}
                          color="white"
                          mt="-20px"
                          ml="-5"
                          w="12"
                        >
                          {FormatIndianRupees(priceValue[0])}
                        </RangeSliderMark>
                        <RangeSliderMark
                          fontSize="13px"
                          value={priceValue[1]}
                          textAlign="center"
                          bg="brand.btnBg"
                          borderRadius={"5px"}
                          color="white"
                          mt="-40px"
                          ml="-5"
                          w="12"
                        >
                          {FormatIndianRupees(priceValue[1])}
                        </RangeSliderMark>
                        {/* .... */}
                        <RangeSliderTrack>
                          <RangeSliderFilledTrack />
                        </RangeSliderTrack>
                        <RangeSliderThumb
                          backgroundColor={"#45270D"}
                          index={0}
                        />
                        <RangeSliderThumb
                          backgroundColor={"#45270D"}
                          index={1}
                        />
                      </RangeSlider>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Flex>
              {/* Price filter ends */}
              {/*   Style Filter starts*/}

              <Flex
                mt={"15px"}
                style={{ display: MobStyleFilter === 1 ? "flex" : "none" }}
                flexDir={"column"}
                width={"100%"}
              >
                {" "}
                <AccordionItem>
                  <AccordionButton>
                    <AccordionIcon />
                    <Text
                      ml={"5px"}
                      color={"brand.Brown"}
                      fontSize={{ base: "14px", md: "16px" }}
                      fontFamily={"heading"}
                    >
                      Filter by Style
                    </Text>
                  </AccordionButton>
                  <AccordionPanel>
                    <Flex flexDir={"column"} mt={"10px"}>
                      <Checkbox
                        size="sm"
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        value={"Classic"}
                        onChange={(e) => handleFilterChange(e, setStyle, style)}
                        defaultChecked={style.includes("Classic")}
                      >
                        Classic
                      </Checkbox>
                      <Checkbox
                        size="sm"
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        value={"Modern"}
                        onChange={(e) => handleFilterChange(e, setStyle, style)}
                        defaultChecked={style.includes("Modern")}
                      >
                        Modern
                      </Checkbox>
                      <Checkbox
                        size="sm"
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        value={"Ethnic"}
                        onChange={(e) => {
                          handleFilterChange(e, setStyle, style);
                        }}
                        defaultChecked={style.includes("Ethnic")}
                      >
                        Ethnic
                      </Checkbox>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Flex>

              {/* Style filter ends */}
              {/* District filter starts */}

              <Flex
                mt={"15px"}
                style={{ display: MobDistrictFilter === 1 ? "flex" : "none" }}
                flexDir={"column"}
                width={"100%"}
              >
                {" "}
                <AccordionItem>
                  <AccordionButton>
                    <AccordionIcon />
                    <Text
                      ml="5px"
                      color={"brand.Brown"}
                      fontSize={{ base: "14px", md: "16px" }}
                      fontFamily={"heading"}
                    >
                      Filter by District
                    </Text>
                  </AccordionButton>
                  <AccordionPanel>
                    {districtFilterObjects.map((itemd,index) => {
                      return (
                        <Flex key={index} flexDir={"column"} mt={"10px"}>
                          <Checkbox
                            size={"sm"}
                            colorScheme="orange"
                            borderColor={"brand.btnBg"}
                            defaultChecked={district.includes(itemd.name)}
                            onChange={(e) =>
                              handleFilterChange(e, setDistrict, district)
                            }
                            value={itemd.name}
                            name={"district"}
                          >
                            {itemd.name}
                          </Checkbox>
                        </Flex>
                      );
                    })}
                  </AccordionPanel>
                </AccordionItem>{" "}
              </Flex>
              {/* District filter Ends */}
              {/* Designer Filter Starts */}

              <Flex
                mt={"15px"}
                style={{ display: MobProfessionFilter === 1 ? "flex" : "none" }}
                flexDir={"column"}
                width={"100%"}
              >
                {" "}
                <AccordionItem>
                  <AccordionButton>
                    <AccordionIcon />
                    <Text
                      ml={"5px"}
                      color={"brand.Brown"}
                      fontSize={{ base: "14px", md: "16px" }}
                      fontFamily={"heading"}
                    >
                      Filter by Profession
                    </Text>
                  </AccordionButton>
                  <AccordionPanel>
                    <Flex flexDir={"column"} mt={"10px"}>
                      <Checkbox
                        size={"sm"}
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        //
                        value={"Artist"}
                        name={"profession"}
                        defaultChecked={profession.includes("Artist")}
                        onChange={(e) => {
                          handleFilterChange(e, setProfession, profession);
                        }}
                        //
                      >
                        Artist
                      </Checkbox>
                      <Checkbox
                        size={"sm"}
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        //
                        value={"Interior Designer"}
                        name={"profession"}
                        defaultChecked={profession.includes(
                          "Interior Designer"
                        )}
                        onChange={(e) => {
                          handleFilterChange(e, setProfession, profession);
                        }}
                        //
                      >
                        Interior Designer
                      </Checkbox>
                      <Checkbox
                        size={"sm"}
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        //
                        value={"Architect"}
                        name={"profession"}
                        defaultChecked={profession.includes("Architect")}
                        onChange={(e) => {
                          handleFilterChange(e, setProfession, profession);
                        }}
                        //
                      >
                        Architect
                      </Checkbox>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Flex>
              {/* Designer Filter ends */}
              {/* Color Filter */}

              <Flex
                mt={"15px"}
                style={{ display: MobColorFilter === 1 ? "flex" : "none" }}
                flexDir={"column"}
                width={"100%"}
              >
                {" "}
                <AccordionItem>
                  <AccordionButton>
                    <AccordionIcon />
                    <Text
                      ml={"5px"}
                      color={"brand.Brown"}
                      fontSize={{ base: "14px", md: "16px" }}
                      fontFamily={"heading"}
                    >
                      Filter by Colour
                    </Text>
                  </AccordionButton>
                  <AccordionPanel>
                    {colorFilterObjects.map((itemc,index) => {
                      return (
                        <Flex key={index} flexDir={"row"} mt={"10px"}>
                          <Checkbox
                            size={"sm"}
                            colorScheme="orange"
                            borderColor={"brand.btnBg"}
                            value={itemc.name}
                            name={"color"}
                            defaultChecked={color.includes(itemc.name)}
                            onChange={(e) =>
                              handleFilterChange(e, setColor, color)
                            }
                          >
                            <Flex>
                              <Image
                                alignSelf={"center"}
                                width={"15px"}
                                height={"15px"}
                                src={itemc.colorimage}
                              ></Image>
                              <Text ml={"5px"}> {itemc.name}</Text>
                            </Flex>
                          </Checkbox>
                        </Flex>
                      );
                    })}
                  </AccordionPanel>
                </AccordionItem>
              </Flex>
              {/* Color Filter */}
              {/* Texture Filter */}

              <Flex
                mt={"15px"}
                style={{ display: MobTextureFilter === 1 ? "flex" : "none" }}
                flexDir={"column"}
                width={"100%"}
              >
                {" "}
                <AccordionItem>
                  <AccordionButton>
                    <AccordionIcon />
                    <Text
                      ml={"5px"}
                      color={"brand.Brown"}
                      fontSize={{ base: "14px", md: "16px" }}
                      fontFamily={"heading"}
                    >
                      Filter by Texture
                    </Text>
                  </AccordionButton>
                  <AccordionPanel>
                    <Flex flexDir={"column"} mt={"10px"}>
                      <Checkbox
                        size={"sm"}
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        value={"Glossy"}
                        name={"texture"}
                        defaultChecked={texture.includes("Glossy")}
                        onChange={(e) => {
                          handleFilterChange(e, setTexture, texture);
                        }}
                      >
                        Glossy
                      </Checkbox>
                      <Checkbox
                        size={"sm"}
                        colorScheme="orange"
                        borderColor={"brand.btnBg"}
                        value={"Matt"}
                        name={"texture"}
                        defaultChecked={texture.includes("Matt")}
                        onChange={(e) => {
                          handleFilterChange(e, setTexture, texture);
                        }}
                      >
                        Matt
                      </Checkbox>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Flex>
              {/* Texture Filter */}
              {/* Material Filter */}

              <Flex
                mt={"15px"}
                style={{ display: MobMaterialfilter === 1 ? "flex" : "none" }}
                flexDir={"column"}
                width={"100%"}
              >
                {" "}
                <AccordionItem>
                  <AccordionButton>
                    <AccordionIcon />
                    <Text
                      ml={"5px"}
                      color={"brand.Brown"}
                      fontSize={{ base: "14px", md: "16px" }}
                      fontFamily={"heading"}
                    >
                      Filter by Material
                    </Text>
                  </AccordionButton>
                  <AccordionPanel>
                    {MobileMaterialObjects &&
                      MobileMaterialObjects.map((itemm,index) => {
                        return (
                          <Flex key={index} flexDir={"column"} mt={"10px"}>
                            <Checkbox
                              size="sm"
                              colorScheme="orange"
                              borderColor={"brand.btnBg"}
                              value={itemm.name}
                              name={"material"}
                              defaultChecked={material.includes(itemm.name)}
                              onChange={(e) => {
                                handleFilterChange(e, setMaterial, material);
                              }}
                            >
                              {itemm.name}
                            </Checkbox>

                            {/* Sub Checkboxes if present  */}
                          </Flex>
                        );
                      })}
                  </AccordionPanel>
                </AccordionItem>{" "}
              </Flex>
              {/* Material Filter */}
            </Accordion>
          </DrawerBody>

          <DrawerFooter>
            {/* <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button> */}
            <Button
              color={"white"}
              onClick={onClose}
              backgroundColor={"brand.btnBg"}
            >
              Apply
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

export default MobileCommonFilter;
