import { Flex, Text } from "@chakra-ui/layout";
import { Image, Box, Link } from "@chakra-ui/react";
import React from "react";
import Designerimage from "../../../Assets/DummyCollections/designer.png";
import {

  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaHome,
} from "react-icons/fa";
import Phoneicon from "../../../Assets/Images/phone.png";
import Addressicon from "../../../Assets/Images/address.png";

import Mailicon from "../../../Assets/Images/mail.png";

function DesignerHeaderCard(props) {
  const {
    DesignerSubscription,
    DesignerImage = Designerimage,
    DesignerInitial = "",
    DesignerName = "George Joseph",
    DesignerP = "Architect",
    Designernumber = "000000000",
    DesignerMail = "gyugygygg@gmail.com",
    facebooklink,
    instalink,
    DesignerAddress,
    linkedInlink,
    Designersubnumber,
    artistSpecialization,
    DesignerDescription = "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
  } = props;
  return (
    <Flex width={"100%"} mt={"100px"} justifyContent={"center"}>
      <Flex
        flexDir={{ base: "column-reverse", md: "row", lg: "row" }}
        width={{ base: "100%", md: "90%", lg: "70%" }}
        p={{ base: "5px", md: "10px", lg: "10px" }}
        pb={{ base: "20px", md: "20px", lg: "20px" }}
        backgroundColor={"#F1DBC6"}
        borderRadius={20}
        boxShadow={"inner"}
      >
        <Flex
          width={{ base: "100%", md: "50%", lg: "50%" }}
          ml={{ base: "0", md: "40px", lg: "80px" }}
          flexDir={"column"}
        >
          <Box mt={"30px"} p={2}>
            <Text
              borderRadius={10}
              p={3}
              fontSize={{ base: "23px", md: "26px", lg: "35px" }}
              fontWeight={"bold"}
              bgColor={"rgba(255, 255, 255, 0.40)"}
              as="mark"
            >
              {DesignerInitial} {DesignerName}
            </Text>
          </Box>
          <Box mt={"10px"} p={2}>
            <Text
              borderRadius={10}
              p={3}
              fontSize={{ base: "20px", md: "24px", lg: "28px" }}
              fontWeight={"bold"}
              bgColor={"rgba(255, 255, 255, 0.40)"}
              as="mark"
            >
              {DesignerP}
            </Text>
          </Box>
          {artistSpecialization && (
            <Text
              borderRadius={10}
              my={2}
              mx={2}
              p={1}
              fontSize={{ base: "12px", md: "15px", lg: "18px" }}
              fontWeight={"semibold"}
              bgColor={"brand.Brown"}
              as="mark"
              width={"fit-content"}
              color={"brand.cardBg"}
            >
              {artistSpecialization}
            </Text>
          )}
          {DesignerSubscription && DesignerSubscription.to && new Date(DesignerSubscription.to) > new Date() &&
             (
              <Flex
                my={"10px"}
                bg={"#C5ACDD"}
                ml={"10px"}
                borderRadius={"6px"}
                // boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
                px={2}
                py={1}
                width={"fit-content"}
              >
                <Text fontWeight={"bold"} color={"blackAlpha.500"}>
                  Premium Member
                </Text>
              </Flex>
            )}
          <Text
            maxWidth={{ lg: "500px" }}
            fontSize={{ base: "18px", md: "18px", lg: "19px" }}
            p={3}
          >
            {DesignerDescription}
          </Text>
          <Flex mt={"15px"} px={3} flexDir={"row"}>
            <Box color={"white"}>
              <Image objectFit={"contain"} width={"25px"} src={Phoneicon}></Image>
            </Box>
            <Text ml={"5px"}>{Designernumber}</Text>
            {Designersubnumber && <Text>,{Designersubnumber}</Text>}
          </Flex>
          <Flex px={3} mt={"10px"} flexDir={"row"}>
            <Box color={"white"}>
            <Image objectFit={"contain"} width={"25px"} src={Mailicon}></Image>
            </Box>
            <Text ml={"5px"}>{DesignerMail}</Text>
          </Flex>
          {DesignerAddress && (
            <Flex
              maxW={{ base: "200px", md: "200px", lg: "300px" }}
              px={3}
              mt={"10px"}
              flexDir={"row"}
            >
              <Flex justifyContent="center">
              <Image objectFit={"contain"} width={"25px"} src={Addressicon}></Image>
              </Flex>
              <Text ml={"5px"}>{DesignerAddress}</Text>
            </Flex>
          )}
        </Flex>

        <Flex
          flexDir={"column"}
          width={{ base: "100%", md: "50%", lg: "50%" }}
          justifyContent={{ base: "center", md: "flex-start" }}
          mt={{ base: "-30px", md: "", lg: "-90px", xl: "-90px" }}
        >
          <Image
            ml={{ base: "auto" }}
            mr={{ base: "auto" }}
            objectFit={"cover"}
            border={"5px solid white"}
            width={{ base: "350px", md: "500px", lg: "500px" }}
            height={{
              base: "330px",
              md: "330px",
              lg: "400px",
              xl: "480px",
              "2xl": "480px",
            }}
            borderRadius={20}
            boxShadow={"lg"}
            src={DesignerImage}
          ></Image>
          {/* if subscription */}
          {DesignerSubscription && DesignerSubscription.to && new Date(DesignerSubscription.to) > new Date() ? (
  <Flex
    justifyContent={"end"}
    mb={{ base: "20px" }}
    ml={{ base: "auto" }}
    mr={{ base: "auto", md: "25%", lg: "35%" }}
    mt={"20px"}
  >
    {facebooklink && (
      <Link isExternal href={facebooklink}>
        {" "}
        <Flex color={"brand.btnBg"} px={3}>
          {" "}
          <FaFacebookSquare size={24} />{" "}
        </Flex>
      </Link>
    )}

    {instalink && (
      <Link isExternal href={instalink}>
        {" "}
        <Flex color={"brand.btnBg"} px={3}>
          {" "}
          <FaInstagramSquare size={24} />
        </Flex>{" "}
      </Link>
    )}
    {linkedInlink && (
      <Link isExternal href={linkedInlink}>
        {" "}
        <Flex color={"brand.btnBg"} px={3}>
          {" "}
          <FaLinkedin size={24} />
        </Flex>{" "}
      </Link>
    )}
  </Flex>
) : (
  <Flex
    justifyContent={"end"}
    mb={{ base: "20px" }}
    ml={{ base: "auto" }}
    mr={{ base: "auto", md: "25%", lg: "35%" }}
    mt={"20px"}
  >
    {facebooklink && (
      <Link>
        {" "}
        <Flex color={"#C9C9C9"} px={3}>
          {" "}
          <FaFacebookSquare size={24} />{" "}
        </Flex>
      </Link>
    )}

    {instalink && (
      <Link>
        {" "}
        <Flex color={"#C9C9C9"} px={3}>
          {" "}
          <FaInstagramSquare size={24} />
        </Flex>{" "}
      </Link>
    )}
    {linkedInlink && (
      <Link>
        {" "}
        <Flex color={"#C9C9C9"} px={3}>
          {" "}
          <FaLinkedin size={24} />
        </Flex>{" "}
      </Link>
    )}
  </Flex>
)}

        </Flex>
      </Flex>
    </Flex>
  );
}

export default DesignerHeaderCard;
