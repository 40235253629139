import { Button ,Image} from "@chakra-ui/react";
import React from "react";
import addpro from "../../Assets/Images/Dashboard/addpro.png";
function CommonButton(props) {
  const {
    className,
    isLoading,
    bg = "brand.btnBg",
    hoverBg = "#75624E",
    color = "white",
    type,
    alignSelf,
    border,
    width = "85px",
    height = "37px",
    p,
    fontSize = "10px",
    btn,
    icon,
    focusBg,
    activeBg,
    borderRadius = "6px",
    boxShadow = "2px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    onClick,
    ...rest
  } = props;
  return (
    <Button
    className={className}
      p={p}
      height={height}
      isLoading={isLoading}
      bg={bg}
      _hover={{ bg: hoverBg }}
      color={color}
      type={type}
      alignSelf={alignSelf}
      border={border}
      width={width}
      fontSize={fontSize}
      _focus={{ bg: focusBg }}
      _active={{ bg: activeBg }}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      onClick={onClick}
      {...rest}
    >
      {btn} 
      {icon && (<Image ml={"10px"} width={"20px"} src={addpro} ></Image>
       )}
    </Button>
  );
}

export default CommonButton;
