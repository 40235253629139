import { Container, Flex, Image } from "@chakra-ui/react";
import React from "react";
import Header from "../../../Components/shared/Header";
import StoreInfoCard from "./StoreInfoCard";
import StoreHeader from "../../../Assets/Images/Headers/Storeheader.webp";
import StoreHeaderBasic from "../../../Assets/Images/Headers/headerstore.webp";
import { Outlet, useLocation } from "react-router-dom";
import Overlayimg from "../../../Assets/Images/Headers/overlay.webp";
import Footer from "../../../Components/Footer";
function SingleStoresLayout() {
  const location = useLocation();
  const state = location.state?.item;
  
  return (
    <Flex
    id="singlestore"
      flexDir={"column"}
      bg={
        "linear-gradient(169deg, rgba(255, 219, 186, 0.16) 27.09%, rgba(255, 201, 153, 0.16) 46.19%, rgba(255, 255, 255, 0.00) 91.86%)"
      }
    >
      <Container id="singlestoreinfo" maxW="2000px" width={"100vw"} p={0}>
        <Header
          // image={}
          // imageMob={}
          height={{ base: "450px", md: "620px" }}
          bgSize={{ base: "100% 450px", md: "100% 620px" }}
        />

        <Image
          zIndex={-1}
          width={"100%"}
          objectFit={"cover"}
          mt={{ base: "-600px", md: "-700px", lg: "-700px" }}
          height={{ base: "400px", md: "320px", lg: "530px" }}
          src={
            state?.subscription?.plan === "premium"
              ? state?.shopcoverimage
              : StoreHeaderBasic
          }
          position={"absolute"}
        ></Image>
        <Image
          width={"100%"}
          objectFit={"cover"}
          zIndex={2}
          mt={{ base: "-600px", md: "-700px", lg: "-700px" }}
          height={{ base: "450px", md: "370px", lg: "630px" }}
          src={Overlayimg}
          // position={"absolute"}
        ></Image>

        <Container maxW="1600px" width={"100vw"}>
          <Flex  mt={{ base: "-200px", md: "-190px", lg: "-400px" }}>
            <StoreInfoCard
              shopprofileimage={state?.shopprofileimage}
              name={state?.shopname}
              description={state?.about}
              phone={state?.phone}
              phoneSub={state?.phoneSub}
              mail={state?.email}
              website={state?.weblink}
              address1={state?.addressLine1}
              location={state?.location}
              locationlink={state?.locationlink}
              address2={state?.addressLine2}
              address3={state?.addressLine3}
              address4={state?.addressLine4}
              subscription={state?.subscription?.plan}
            />
          </Flex>

          <Flex
    
            py={4}
            px={{ base: 0, md: 4, lg: 0, xl: 20, "2xl": 40 }}
            justifyContent={{
              base: "center",
              md: "center",
              lg: "space-between",
            }}
          >
            <Outlet />
          </Flex>
        </Container>
        <Footer />
      </Container>
    </Flex>
  );
}

export default SingleStoresLayout;
