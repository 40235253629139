import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useGetShopData } from "../../../Functions/queries";
import CommonModal from "../../../Components/CommonModal/CommonModal";
import { DeleteConfirm } from "./DeleteConfirm";
import DeleteIcon from "../../../Assets/Images/Dashboard/deleticon.png";
export const DeleteProductNOffer = () => {
  const [modalClose, setModalClose] = useState();
  const { shop } = useGetShopData();
  const displayUsers =
    shop && shop[0]?.product ? (
      shop[0]?.product.map((productdataitem, index) => {
        return (
          <Flex
            mt={"10px"}
            flexDir={{ base: "column", md: "row", lg: "row" }}
            fontSize={{ base: "13px", md: "13px", lg: "15px" }}
            alignItems={"center"}
            key={index}
            borderRadius={"10px"}
            boxShadow={"lg"}
            px={{ base: "40px", md: "3", lg: "3" }}
            py={2}
            backgroundColor={"#F3F0ED"}
            justifyContent={"space-between"}
          >
            <Box width={{ base: "160px", md: "70px", lg: "180px" }}>
              <Image
                borderRadius={"10px"}
                width={"60px"}
                height={"60px"}
                objectFit={"cover"}
                src={productdataitem.productimage[0]}
              ></Image>
            </Box>

            <Text width={{ base: "160px", md: "70px", lg: "180px" }}>
              {productdataitem.productname}
            </Text>
            <Text width={{ base: "160px", md: "70px", lg: "180px" }}>
              {productdataitem.type}
            </Text>
            <Text width={{ base: "160px", md: "70px", lg: "100px" }}>
              {productdataitem.price}
            </Text>
            <Flex
              width={{ base: "160px", md: "70px", lg: "" }}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <CommonModal
                modalState={modalClose}
                btn={{
                  text: "red",
                  bg: "transparent",
                  hoverBg: "transparent",
                }}
                modalContent={
                  <DeleteConfirm
                    onClose={setModalClose}
                    productdataitem={productdataitem}
                  />
                }
                btnContent={
                  <Image
                    width={"25px"}
                    height={"25px"}
                    src={DeleteIcon}
                  ></Image>
                }
              />
            </Flex>
          </Flex>
        );
      })
    ) : (
      <>No results found</>
    );
  return (
    <>
      {shop[0]?.product?.length > 30 && (
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          bg={"brand.FilterBg"}
          borderRadius={10}
          py={6}
        >
          <Text px={"10px"} color={"brand.Brown"} fontWeight={"bold"}>
            Please delete some of the products so that total products is 45
          </Text>
          <Flex 
            fontSize={{ base: "13px", md: "13px", lg: "15px" }}
            display={{ base: "none", md: "flex", lg: "flex" }}
            justifyContent={"center"}
            width={"100%"}
          
            p={4}
          >
            <Flex  justifyContent={"center"}>
              <Text width={{ base: "160px", md: "70px", lg: "180px" }}>
                Product
              </Text>
              <Text width={{ base: "160px", md: "70px", lg: "180px" }}>
                Name
              </Text>
              <Text width={{ base: "160px", md: "70px", lg: "180px" }}>
                Category
              </Text>
              <Text width={{ base: "160px", md: "70px", lg: "100px" }}>
                Price
              </Text>
              <Text
                width={{ base: "160px", md: "70px", lg: "" }}
                textAlign={"end"}
              >
                Actions
              </Text>
            </Flex>
          </Flex>
          <Flex
           pt={"00px"}
         
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            H={"250px"}
            overflowY={"scroll"}
            // pt={40}
            // pb={6}
          >
            {displayUsers}
          </Flex>
        </Flex>
      )}
      {shop[0]?.offer.length > 3 && (
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          bg={"brand.FilterBg"}
          alignItems={"center"}
          borderRadius={10}
          py={6}
          my={2}
          minW={"900px"}
        >
          <Text px={"10px"} color={"brand.Brown"} fontWeight={"bold"}>
            Please delete some of the offer posters so that total offers is 3{" "}
          </Text>
          <Flex
            flexDir={"column"}
            width={"100%"}
            // maxH={"250px"}
            // overflowY={"auto"}
            justifyContent={"center"}
            alignItems={"center"}
            // pt={"300px"}
            pb={6}
          >
            {shop[0]?.offer.map((offer) => {
              return (
                <Flex
                  width={"50%"}
                  p={4}
                  borderRadius={20}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  bg={"whiteAlpha.800"}
                  my={2}
                >
                  <Image
                    borderRadius={"50%"}
                    boxSize={20}
                    src={offer?.offerImage}
                  />
                  <Flex
                    px={4}
                    flexDir={"column"}
                    gap={4}
                    width={{ base: "65%", md: "30%", lg: "40%" }}
                    justifyContent={"center"}
                  >
                    <Flex
                      w={"100%"}
                      bg={"white"}
                      justifyContent={"center"}
                      boxShadow={"lg"}
                      borderRadius={"md"}
                      height={"35px"}
                      alignItems={"center"}
                    >
                      {new Date(offer.From).toLocaleDateString()}
                    </Flex>
                    <Flex
                      w={"100%"}
                      px={4}
                      bg={"white"}
                      justifyContent={"center"}
                      boxShadow={"lg"}
                      borderRadius={"md"}
                      height={"35px"}
                      alignItems={"center"}
                    >
                      {new Date(offer.To).toLocaleDateString()}
                    </Flex>
                  </Flex>
                  <CommonModal
                    modalState={modalClose}
                    btn={{
                      text: "red",
                      bg: "transparent",
                      hoverBg: "transparent",
                    }}
                    modalContent={
                      <DeleteConfirm
                        onClose={setModalClose}
                        offer={offer}
                      />
                    }
                    btnContent={
                      <Image
                        width={"25px"}
                        height={"25px"}
                        src={DeleteIcon}
                      ></Image>
                    }
                  />
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      )}
    </>
  );
};
