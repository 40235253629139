import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";

function CheckboxGroup(props) {
  const {
    require,
    name,
    label,
    inputFor,
    register,
    error,
    validator,
    options,
    optionTextColor,
    style = {
      backgroundColor: "white",
      borderRadius: "8px",
    },
    handleFocus,
    value,
    selectedColors,
    ...rest
  } = props;

  return (
    <FormControl isInvalid={error} mb={"15px"}>
      <Flex  width={{base:"220px",md:"250px",lg:"250px"}} backgroundColor={"#F9F0E6"} borderRadius={"10px"} direction="column">
        <SimpleGrid  width={"fit-content"} p={2}   columns={{ base: 2, md: 2, lg: 2 }} spacing={{ base: 0, md: 2, lg: 3 }}>
          {options.map((option) => (
            <Checkbox
         
              colorScheme='green'
              isChecked={selectedColors.includes(option.value)}
              key={option.value}
              name={name}
              {...register(inputFor, validator)}
              value={option.value}
              style={{
                ...style,
                color: optionTextColor,
              }}
              {...rest}
            >
              {option.label}
            </Checkbox>
          ))}
        </SimpleGrid>
      </Flex>
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}

export default CheckboxGroup;
