import { furnitureFilterObjects } from "../../../Components/shared/Filter/FilterObjects/FurnitureFilterObjects";
export const DistrictFiltering = (
  district,
  storesData,
  setStoresData,
  setFilteredStores
) => {
  district.length !== 0
    ? setFilteredStores(
        storesData.filter((item) => district.includes(item.addressLine3))
      )
    : setFilteredStores(storesData);
};

export const ProductsFiltering = (
  collection,
  products,
  setFilteredProduct,
  style,
  subCollection,
  priceValue
) => {
  let filteredProduct = products;
  if (style.length > 0) {
    filteredProduct = filteredProduct.filter((item) =>
      style.includes(item.productstyle)
    );
  }
  if (collection.length > 0) {
    filteredProduct = filteredProduct.filter((item) =>
      collection.includes(item.type)
    );
  }
  if (!priceValue.every((val, index) => val === [0, 100000][index])) {
    filteredProduct = filteredProduct.filter((item) => {
      return priceValue[0] <= item.price && item.price <= priceValue[1];
    });
  }
  setFilteredProduct(filteredProduct);
};
