import { Container, Flex } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../Components/shared/Header";
import HeadingLine from "../../Components/shared/HeadingLine";
import CollectionSelection from "./CollectionSelection/CollectionSelection";
import CollectionHeader from "../../Assets/Images/Headers/CollectionsHeader.webp";
import CollectionHeaderMob from "../../Assets/Images/Headers/CollectionsHeaderMob.webp";
import Footer from "../../Components/Footer";
import Breadcrumbs from "../../Components/shared/Breadcrumbs";

/**
 * Renders the main layout for the Collections screen.
 *
 * This component sets up the overall structure and styling for the Collections screen,
 * including the header, breadcrumbs, collection selection, and outlet for rendering
 * the specific collection content.
 */
function CollectionsLayout() {
  return (
    <Flex
      flexDir={"column"}
      bg={"linear-gradient(180deg, #FFF 0%, #FFFAF2 29.69%, #FFE9D4 82.81%)"}
    >
      <Container id="collections" maxW="2000px" width={"100vw"} p={0}>
        <Header
          collection={true}
          search={false}
          heading="Our Collections"
          image={CollectionHeader}
          imageMob={CollectionHeaderMob}
        />

        <Container maxW="1600px" width={"100vw"}>
          <HeadingLine title={"Stores Around You"} />
          <Flex ml={{ base: "0px", md: "5%", lg: "5%" }}>
            <Breadcrumbs />
          </Flex>
          <CollectionSelection />
          <Outlet />
        </Container>

        <Footer />
      </Container>
    </Flex>
  );
}

export default CollectionsLayout;
