import { Button } from "@chakra-ui/react";
import React from "react";

function UpgradeButton(props) {
  const {
    isLoading,
    bg = " linear-gradient(180deg, #92E584 0%, #42994D 54.17%, #227D41 100%)",
    hoverBg = "#215319",
    color = "white",
    type,
    alignSelf,
    border,
    width = "145px",
    height = "43px",
    p,
    fontSize = "13px",
    btn,
    onClick,
    focusBg,
    activeBg,
    borderRadius = "15px",
    boxShadow = "2px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    ...rest
  } = props;
  return (
    <Button
      fontWeight={"bold"}
      p={p}
      height={height}
      isLoading={isLoading}
      bg={bg}
      _hover={{ bg: hoverBg }}
      color={color}
      type={type}
      alignSelf={alignSelf}
      border={border}
      width={width}
      fontSize={fontSize}
      _focus={{ bg: focusBg }}
      _active={{ bg: activeBg }}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      onClick={onClick}
      {...rest}
    >
      {btn}
    </Button>
  );
}

export default UpgradeButton;
