import {
  Text,
  Flex,
  Container,
  Image,
  Box,
  Spinner,
  Button,
  useToast,
  SimpleGrid,
} from "@chakra-ui/react";

import React, { useState, useEffect } from "react";
import Header from "../../Components/shared/Header";
import Arrow from "../../../src/Assets/Images/arrow.png";
import WishlistHeader from "../../../src/Assets/Images/Headers/faqheader.webp";
import WishlistHeaderMob from "../../../src/Assets/Images/Headers/faqheadermob.webp";
import { getFaq } from "../../Functions/GetApiCalls";
import axiosInstance from "../../Config/axios";
import { API_BASE_URL } from "../../Config";
import axios from "axios";
import Footer from "../../Components/Footer";
function Faq() {
  const [openIndex, setOpenIndex] = useState(null);
  const [Faqdataaa, setFaqdata] = useState();
  const [loading, setLoading] = useState();
  const handleBoxClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getFaq(setFaqdata);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Flex>
      {/* header */}
      <Flex
        id="faq"
        flexDir={"column"}
        bg={
          "linear-gradient(180deg, #F2E1D0 20.87%, rgba(255, 255, 255, 0.99) 100%)"
        }
      >
        <Container maxW="2000px" width={"100vw"} p={0}>
          <Header
            heading="FAQ"
            image={WishlistHeader}
            imageMob={WishlistHeaderMob}
          />

          {/* Form area */}

          <Flex
            my={{ base: "20px", md: "30px", lg: "60px" }}
            flexDir={"column"}
            width={"100%"}
          >
            <SimpleGrid
              backgroundColor={"#FDFAF7"}
              p={{ base: "10px", md: "10px", lg: "40px" }}
              borderRadius={"10px"}
              width={{ base: "90%", md: "90%", lg: "60%" }}
              mx={"auto"}
              columns={[1, null, 1]}
              spacing="10px"
            >
              {Faqdataaa?.map((faqdata, index) => (
                <Flex
                  backgroundColor={"#FFF1E2"}
                  borderRadius={"10px"}
                  boxShadow={"lg"}
                  key={index}
                  flexDir={"column"}
                  borderLeft="5px solid #917961"
                  onClick={() => handleBoxClick(index)}
                  style={{
                    height: index === openIndex ? "250px" : "60px",
                    transition: "height 0.4s linear",
                  }}
                >
                  <Flex
                    mt={{ base: "10px", md: "10px", lg: "20px" }}
                    width={"100%"}
                  >
                    <Text
                      fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                      ml={"30px"}
                    >
                      {faqdata.question}
                    </Text>
                    <Image
                      mr={"30px"}
                      ml={"auto"}
                      objectFit={"contain"}
                      width={{ base: "12px", md: "13px", lg: "14px" }}
                      src={Arrow}
                    ></Image>
                  </Flex>

                  <Flex
                    style={{
                      opacity: index === openIndex ? 1 : 0,
                      transition: "opacity 0.1s linear",
                    }}
                  >
                    <Text
                      py={{ base: "20px", md: "20px", lg: "30px" }}
                      fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                      px={{ base: "30px", md: "30px", lg: "30px" }}
                      className="content_font"
                    >
                      {faqdata.answer}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </SimpleGrid>
          </Flex>
        </Container>
        <Footer/>
      </Flex>
   
    </Flex>
  );
}

export default Faq;
