import React, { useState } from "react";

import {
  Button,
  Divider,
  AbsoluteCenter,
  Text,
  Flex,
  Box,
  Stack,
  chakra,
  useToast,
} from "@chakra-ui/react";
import SignUpForm from "./SignUpForm";

function RoleForm({ setModalClose }) {
  const [show, setShow] = React.useState(true);
  const [selectedRole, setSelectedRole] = useState("");
  const handleRoleSelection = (role) => {
    setSelectedRole(role);
    setShow(false); // Show the next Flex when a role is selected
  };

  return (
    <Stack mx={"auto"}  width="80%">
      <Box height={{ base: "20px", md: "90px", lg: "90px" }}></Box>
      <Flex  width="100%" flexDir={"column"}>
        <Text
   
          mb={"20px"}
          ml={"auto"}
          mr={"auto"}
          fontFamily={"heading"}
          fontSize={{ base: "16px", md: "18px", lg: "22px" }}
          color={"#AB7B4D"}
        >
          Welcome to Lokolumo
        </Text>
        <Text
          mb={"20px"}
          ml={"auto"}
          mr={"auto"}
          fontSize={{ base: "12px", md: "16px", lg: "18px" }}
        >
          Register your account
        </Text>
        {show ? (
          <Flex
            width={"100%"}
            borderRadius={"10px"}
            boxShadow={"inner"}
            p={{ base: "10px", md: "25px", lg: "30px" }}
            backgroundColor={"rgba(242, 220, 199, 0.58)"}
            flexDir={"column"}
            mt={{ base: "40px", md: "50px", lg: "40px" }}
          >
            <Text
              fontSize={{ base: "12px", md: "14px", lg: "15px" }}
              my={"20px"}
            >
              Select user type:
            </Text>
            <Button
              fontSize={{ base: "13px", md: "16px", lg: "16px" }}
              boxShadow={"lg"}
              backgroundColor={"white"}
              onClick={() => handleRoleSelection("user")}
            >
              Consumer
            </Button>
            <Button
              fontSize={{ base: "13px", md: "16px", lg: "16px" }}
              mt={"20px"}
              boxShadow={"lg"}
              backgroundColor={"white"}
              onClick={() => handleRoleSelection("shopOwner")}
            >
              Seller
            </Button>

            <Button
              fontSize={{ base: "13px", md: "16px", lg: "16px" }}
              mt={"20px"}
              boxShadow={"lg"}
              backgroundColor={"white"}
              onClick={() => handleRoleSelection("architect")}
            >
              Creator
            </Button>
          </Flex>
        ) : (
          <SignUpForm setModalClose={setModalClose} role={selectedRole} />
        )}
        <Box height={{ base: "20px", md: "140px", lg: "130px" }}></Box>
      </Flex>
    </Stack>
  );
}

export default RoleForm;
