export const designernavbar = [
    {
      navname: "Profile Details",
      navnum: 1,
    },
    {
      navname: "Project Gallery",
      navnum: 2,
    },
    {
      navname: "Add Videos",
      navnum: 5,
    },
   
    {
      navname: "Change Password",
      navnum: 4,
    },
    {
      navname: "Orders",
      navnum: 6,
    },
 
   
  ];
  