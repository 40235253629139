import axios from "axios";
import { API_BASE_URL } from "../Config";
import { config } from "./Auth";

export const putStoresDetails = async (details, toast) => {
  try {
    const shopData = {
      shopname: details.shopname,
      about: details.about,
      weblink: details.weblink,
      email: details.email,
      addressLine1: details.addressLine1,
      addressLine2: details.addressLine2,
      addressLine3: details.addressLine3,
      addressLine4: details.addressLine4,
      location: details.location,
      locationlink: details.locationlink,
      phone: details.phone,
      phoneSub: details.phoneSub,
      shopcoverimage: details.shopCoverImage,
      shopimages: details.shopImages,
      category: details.category,
      product: details.product,
      offer: details.offer,
      prodCategory: details.prodCategory,
    };
    const response = await axios.put(
      `${API_BASE_URL}/shop/edit`,
      shopData,
      config()
    );
    toast &&
      toast({
        title: "Success",
        description: "Details Updated Successfully",
        status: "success",
        position: "top",
        isClosable: true,
      });
    return response;
  } catch (error) {
    console.log(error);
    toast &&
      toast({
        title: "Error",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
        isClosable: true,
      });
  }
};

export const putDesignerDetails = async (details, id, toast) => {
  try {
    const designersData = {
      name: details.name,
      profession: details.profession,
      artistSpecialization: details.artistSpecialization,
      about: details.about,
      district: details.district,
      phone: details.phone,
      designeraddress: details.designeraddress,
      linkedinlink: details.linkedinlink,
      instagramlink: details.instagramlink,
      facebooklink: details.facebooklink,
      phoneSub: details.phoneSub,
    };
    await axios.put(`${API_BASE_URL}/designer/${id}`, designersData, config());
    toast &&
      toast({
        title: "Success",
        description: "Details Updated Successfully",
        status: "success",
        position: "top",
        isClosable: true,
      });
  } catch (error) {
    console.log(error);
    toast &&
      toast({
        title: "Error",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
        isClosable: true,
      });
  }
};
