import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button, Text } from "@chakra-ui/react";

const LocationCheckbox = ({
  locationslist,
  setFilteredLinks,
  setLocationStoreClicked,
  radius,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationclicked, setLocationClicked] = useState(false);

  const handleButtonClick = () => {
    setLocationClicked(!locationclicked);
    setLocationStoreClicked(!locationclicked);
    if (locationclicked) {
      setCurrentLocation(null);
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setCurrentLocation({ latitude, longitude });
            alert(
              `Your Current location: Latitude ${latitude}, Longitude ${longitude}`
            );
          },
          (error) => {
            console.error("Error getting location:", error);
            alert(
              "Error getting location. Please make sure location services are enabled."
            );
          },
          {
            enableHighAccuracy: true 
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    }
  };

  const isWithinRadius = useCallback(
    (linkLocation, currentLocation) => {
      // console.log("currentLocation",currentLocation)9.401332504910096, 76.58224936336471
      const earthRadiusKm = 6371;
      const { latitude: lat1, longitude: lon1 } = currentLocation;
      const { latitude: lat2, longitude: lon2 } = linkLocation;

      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
          Math.cos(toRad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = earthRadiusKm * c;

      return distance <= radius;
    },
    [radius]
  );

  const toRad = (value) => (value * Math.PI) / 180;

  const linkLocations = useMemo(
    () =>
      locationslist.map((location) => {
        const [latitude, longitude] = location.split(",").map(parseFloat);
        return { latitude, longitude };
      }),
    [locationslist]
  );

  const filteredLinksH = useMemo(
    () =>
      linkLocations.filter((linkLocation) =>
        currentLocation ? isWithinRadius(linkLocation, currentLocation) : false
      ),
    [linkLocations, currentLocation, isWithinRadius]
  );
  useEffect(() => {
    setFilteredLinks(filteredLinksH);
  }, [filteredLinksH, setFilteredLinks]);

  return (
    <>
      <Button
      className="LOCA"
        height={"35px"}
        onClick={handleButtonClick}
        backgroundColor={"green.300"}
        my={"auto"}
      >
        {locationclicked ? "Reset" : "Get Shops near your location."}
      </Button>
      {filteredLinksH.length === 0 && locationclicked && currentLocation ? (
        <Text my={"auto"} ml={"20px"} color={"red.500"}>
          There are no registered shops nearby.
        </Text>
      ) : null}
    </>
  );
};

export default LocationCheckbox;
