export const bathroomFilterObjects = [
    { name: "Wash Basin" },
    { name: "Bath Tub"},
    { name: "Shower"},
    { name: "Health Faucet" },
    { name: "Water Closet"},
    { name: "Towel Rail" },
    { name: "Bath Storage" },
  
  ];
  