// add more contexts here
import { createContext, useContext, useState } from "react";
import { getItemFromLocalStorage } from "../utils/local-storage";
import { useParams } from "react-router-dom";

const Context = createContext();
const ContextProvider = ({ children }) => {
  // get user details state
  const [selection, setSelection] = useState();
  const [product, setProduct] = useState();
  const [shop, setShop] = useState();
  const [user, setUser] = useState();
  const [designer, setDesigner] = useState();
  const [token, setToken] = useState(getItemFromLocalStorage("styhomtoken"));
  return (
    <Context.Provider
      value={{
        selection,
        setSelection,
        product,
        setProduct,
        shop,
        setShop,
        user,
        setUser,
        designer,
        setDesigner,
        token,
        setToken,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export const State = () => {
  return useContext(Context);
};

export default ContextProvider;
