export const wallArts = [
  {
    shopName: "ABC",
    place: "Ernakulam",
  },
  {
    shopName: "DEF",
    place: "Ernakulam",
  },
  {
    shopName: "GHI",
    place: "Ernakulam",
  },
  {
    shopName: "JKL",
    place: "Ernakulam",
  },
];
