import { Text, Flex, Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getOrders } from "../../../Functions/GetApiCalls";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Invoice from "../Invoice";

function ShopOrdersInvoice() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const onOpen = (subscription) => {
    setSelectedSubscription(subscription);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedSubscription(null);
  };
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getOrders(setOrderData);
      setLoading(false);
    };
    fetchData(); // Call the async function
  }, []);
  console.log(orderData, "orderData for invoice");
  // date and time
  function formatDateTime(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  // date and amount convertion
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  function formatAmount(amount) {
    return (amount / 100).toFixed(2); // Convert to currency format
  }

  // storing all the model/texture orders
  let filteredModels = [];

  orderData.forEach((model) => {
    if (model.product.items.length !== 0) {
      filteredModels.push(model);
    }
  });

  // filter subscription data

  const filteredSubscription = orderData.filter((model) => model.subscription);
  console.log("filteredSubscription:", filteredSubscription);
  console.log("filteredModels:", filteredModels);

  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Your Orders
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex justifyContent={"center"} my={"5%"} width={"100%"}>
          <Flex width={"100%"} px="2%" flexDir={"column"} mr={"auto"}>
            <Text fontSize={{ base: "15px", md: "15px", lg: "17px" }} as="b">
              Subscription Details:
            </Text>
            {/* current subscription details */}
            <Flex
              overflow={"scroll"}
              width={"100%"}
              // width={{base:"100vw",md:"400px",lg:"500px"}}
              my={"20px"}
              p={"20px"}
              borderRadius={"5px"}
              boxShadow={"inner"}
              backgroundColor={"#FFFBF5"}
              flexDir={"column"}
            >
              <Text textDecorationLine={"underline"}>Current Plan:</Text>
              <TableContainer variant="simple" my={"20px"}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Order ID</Th>
                      <Th>Plan</Th>
                      <Th>Valid till</Th>
                      <Th>Payment Date</Th>
                      <Th>Amount</Th>
                      <Th>Invoice</Th>
                    </Tr>
                  </Thead>
                  <Tbody fontSize={{ base: "12px", md: "13px", lg: "14px" }}>
                    {filteredSubscription
                      .slice(-1)
                      .map((subscription, index) => (
                        <Tr key={index}>
                          <Td>{subscription?.razorpay?.orderId}</Td>
                          <Td>{subscription?.subscription?.plan}</Td>
                          <Td>{formatDate(subscription?.subscription?.to)}</Td>
                          <Td>
                            {formatDate(subscription?.subscription?.from)}
                          </Td>
                          <Td>{formatAmount(subscription?.amount)}</Td>
                          <Td>
                            <Button
                              backgroundColor={"#CAE1AF"}
                              fontSize={"13px"}
                              height={"30px"}
                              boxShadow={"md"}
                              onClick={() => onOpen(subscription)}
                            >
                              Generate Invoice
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>

            {/* invoice modal */}
            <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Invoice</ModalHeader>
                <ModalCloseButton />
                <ModalBody backgroundColor={"#E2E2E2"} p={"20px"}>
                  {selectedSubscription &&
                    selectedSubscription?.subscription && (
                      <Invoice
                        orderId={selectedSubscription?.razorpay?.orderId}
                        amount={formatAmount(selectedSubscription?.amount)}
                        invoiceDate={formatDate(
                          selectedSubscription?.subscription?.from
                        )}
                        description={selectedSubscription?.subscription?.plan}
                        invoiceNumber={selectedSubscription?.invoiceNumber}
                        invoiceDateTime={formatDateTime(
                          selectedSubscription?.subscription?.from
                        )}
                        name={selectedSubscription?.shopDetails?.shopname}
                        address={selectedSubscription?.shopDetails?.addressLine1}
                        ph={selectedSubscription?.shopDetails?.phone}
                        transactionId={
                          selectedSubscription?.razorpay?.paymentId
                        }
                        paymentMethod={selectedSubscription?.method}
                      />
                    )}

                  {selectedSubscription &&
                    !selectedSubscription?.subscription && (
                      <Invoice
                        orderId={selectedSubscription?.razorpay?.orderId}
                        amount={formatAmount(selectedSubscription?.amount)}
                        invoiceDate={formatDate(
                          selectedSubscription?.product?.paymentDate
                        )}
                        description={selectedSubscription?.product?.items}
                        prices={selectedSubscription?.product?.prices}
                        invoiceNumber={selectedSubscription?.invoiceNumber}
                        invoiceDateTime={formatDateTime(
                          selectedSubscription?.product?.paymentDate
                        )}
                        name={selectedSubscription?.shopDetails?.shopname}
                        address={selectedSubscription?.shopDetails?.addressLine1}
                        ph={selectedSubscription?.shopDetails?.phone}
                        transactionId={
                          selectedSubscription?.razorpay?.paymentId
                        }
                        paymentMethod={selectedSubscription?.method}
                      />
                    )}
                </ModalBody>
                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
            {/* recent details */}
            <Flex
              overflow={"scroll"}
              //  width={{base:"300px",md:"400px",lg:"500px"}}
              my={"20px"}
              p={"20px"}
              borderRadius={"5px"}
              boxShadow={"inner"}
              backgroundColor={"#FFFBF5"}
              flexDir={"column"}
            >
              <Text textDecorationLine={"underline"}>
                Previous Subscription Plans:
              </Text>
              <TableContainer variant="simple" my={"20px"}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Order ID</Th>
                      <Th>Plan</Th>
                      <Th>Valid till</Th>
                      <Th>Payment Date</Th>
                      <Th>Amount</Th>
                      <Th>Invoice</Th>
                    </Tr>
                  </Thead>
                  <Tbody fontSize={{ base: "12px", md: "13px", lg: "14px" }}>
                    {filteredSubscription
                      .slice(-4, -1)
                      .reverse() // Reverse the array to display in reverse order
                      .map((subscription, index) => (
                        <Tr key={index}>
                          <Td>{subscription?.razorpay?.orderId}</Td>
                          <Td>{subscription?.subscription?.plan}</Td>
                          <Td>{formatDate(subscription?.subscription?.to)}</Td>
                          <Td>
                            {formatDate(subscription?.subscription?.from)}
                          </Td>
                          <Td>{formatAmount(subscription?.amount)}</Td>
                          <Td>
                            <Button
                              backgroundColor={"#CAE1AF"}
                              fontSize={"13px"}
                              height={"30px"}
                              boxShadow={"md"}
                              onClick={() => onOpen(subscription)}
                            >
                              Generate Invoice
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>

            {/* 3d model / texture orders.... */}
            <Text fontSize={{ base: "15px", md: "15px", lg: "17px" }} as="b">
              3D Model/Texture Order Details:
            </Text>
            <Flex
              my={"20px"}
              p={"20px"}
              borderRadius={"5px"}
              boxShadow={"inner"}
              backgroundColor={"#FFFBF5"}
              flexDir={"column"}
            >
              <TableContainer variant="simple" my={"20px"}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Order ID</Th>
                      <Th>Item</Th>

                      <Th>Payment Date</Th>
                      <Th>Amount</Th>
                      <Th>Invoice</Th>
                    </Tr>
                  </Thead>
                  <Tbody fontSize={{ base: "12px", md: "13px", lg: "14px" }}>
                    {filteredModels.slice(-10)
                     .reverse() // Reverse the array to display in reverse order
                    .map((model, index) => (
                      <Tr key={index}>
                        <Td>{model?.razorpay.orderId}</Td>
                        <Td>
                          <Text
                            w={"100px"}
                            whiteSpace="normal"
                            wordBreak="break-word"
                          >
                            {Array.isArray(model?.product.items) ? (
                              <Text>{model?.product.items.join(", ")}</Text>
                            ) : (
                              model?.product.items
                            )}
                          </Text>
                        </Td>
                        <Td>
                          {model ? formatDate(model.product.paymentDate) : ""}
                        </Td>
                        <Td>{model ? formatAmount(model.amount) : ""}</Td>
                        <Td>
                          <Button
                            backgroundColor={"#CAE1AF"}
                            fontSize={"13px"}
                            height={"30px"}
                            boxShadow={"md"}
                            onClick={() => onOpen(model)}
                          >
                            Generate Invoice
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ShopOrdersInvoice;
