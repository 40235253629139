import { Flex, Text, Box, Container } from "@chakra-ui/layout";

import React from "react";
import whatwedo from "../../../Assets/Images/MainPage/whatwedo.png";

import { Swiper, SwiperSlide } from "swiper/react";


import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";

function PortfolioVideo(props) {
    const {
        DesignerVideo,
      } = props;
  return (
    <Flex
 
      width={"100%"}
      flexDirection={"column"}
      bgRepeat={"no-repeat"}
      backgroundImage={whatwedo}
      bgSize={"contain"}
    >
    
    
      <Flex width={"100%"} zIndex={2} flexDir={"column"}  >
    
       

     
          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
           
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwipervideodesigner"
          >
              {DesignerVideo.map((videoSrc, index) => (
            <SwiperSlide key={index}>
              <Flex my={{base:"10px",md:"20px",lg:"10px"}} mx={{base:"0px",md:"0px",lg:"0px"}} flexDir={"column"}>
                <Flex  borderRadius={"10px"} p={0} pb={"30px"}>
                  <div style={{ marginLeft:"auto", marginRight:"auto"}}>
                    <video
                      playsInline
                      className="videobox"
                      src={videoSrc}
                      style={{ borderRadius: "10px", height:"400px" }}
                      autoPlay={true}
                      muted={true}
                      controls={true}
                      loop
                    />
                  </div>
                </Flex>
               
              </Flex>
            </SwiperSlide>
  ))}
          </Swiper>
       
 
      </Flex>
    </Flex>
  );
}

export default PortfolioVideo;
