export const decorFilterObjects = [
    { name: "Vases" },
    { name: "Sculptures"},
    { name: "Clocks" },
    { name: "Wall Decor items" },
    { name: "Paintings"},
    { name: "Mirrors" },
    { name: "Other Decoratives" },
    { name: "Artificial Flowers" },
    { name: "Natural Plants" },
   
  ];
  