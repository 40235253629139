import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Text,
} from "@chakra-ui/react";

function ImageModal({ isOpen, onClose, image }) {
  return (
    <Modal size={{base:"sm",md:"lg",lg:"5xl"} } isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent 
   
        Width={{base:"fit-content",md:"fit-content",lg:"700px"}}
        height={{base:"fit-content",md:"fit-content",lg:"fit-content"}}
        >
        <ModalHeader>Offer Card</ModalHeader>
        <ModalCloseButton />
        {image && (  <ModalBody >
          <Image ml={"auto"} mr={"auto"} width={"100%"} objectFit={"contain"} src={image} alt={`Selected Image`} />
          <Text>{image.description}</Text>
        </ModalBody>)}
      </ModalContent>
    </Modal>
  );
}

export default ImageModal;
