// ?remember to change config
import { API_BASE_URL } from "../Config";
import axios from "axios";
import { config } from "./Auth";

// Function for posting store details to the server
export const postStoresDetails = async (details, toast, reset) => {
  try {
    const shopData = {
      shopname: details.shopname,
      about: details.about,
      weblink: details.weblink,
      email: details.email,
      addressLine1: details.addressLine1,
      addressLine2: details.addressLine2,
      addressLine3: details.addressLine3,
      addressLine4: details.addressLine4,
      location: details.location,
      locationlink: details.locationlink,
      phone: details.phone,
      phoneSub: details.phoneSub,
    };
    await axios.post(`${API_BASE_URL}/shop/add`, shopData, config());
    toast({
      title: "Success",
      description: "Details Added Successfully.",
      status: "success",
      position: "top",
      isClosable: true,
    });
    reset();
  } catch (error) {
    console.log(error);
    toast({
      title: "Error",
      description: error?.response?.data?.message,
      status: "error",
      position: "top",
      isClosable: true,
    });
  }
};

export const postDesignerDetails = async (details, toast, reset) => {
  try {
    const designersData = {
      name: details.name,
      profession: details.profession,
      artistSpecialization:details.artistSpecialization,
      about: details.about,
      district: details.district,
      phone: details.phone,
      designeraddress: details.designeraddress,
      linkedinlink: details.linkedinlink,
      instagramlink: details.instagramlink,
      facebooklink: details.facebooklink,
      phoneSub: details.phoneSub,
    };
    await axios.post(`${API_BASE_URL}/designer/add`, designersData, config());
    toast({
      title: "Success",
      description: "Details Added Successfully",
      status: "success",
      position: "top",
      isClosable: true,
    });
    reset();
  } catch (error) {
    console.log(error);
    toast({
      title: "Error",
      description: error?.response?.data?.message,
      status: "error",
      position: "top",
      isClosable: true,
    });
  }
};
