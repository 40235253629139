import { Flex, Image, Text, Container } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import WhatWeDo from "./sections/WhatWeDo";
import AboutUs from "./sections/AboutUs";
import Footer from "../../Components/Footer";
import PopularArtist from "./sections/PopularArtist";
import StoresAvailable from "./sections/StoresAvailable";
import ExploreCollections from "./sections/ExploreCollections";
import LandingSection from "./sections/LandingSection";
import LatestHappenings from "./sections/LatestHappenings";

function LandingScreen(props) {
  const { id } = props;

  return (
    <Flex
      bg={"linear-gradient(180deg, #FFF 0%, #FFFAF2 29.69%, #D6AC85 100%)"}
      flexDir={"column"}
    >
      
      <LandingSection/>
      <WhatWeDo />
      <ExploreCollections />
      <StoresAvailable />
      <PopularArtist />
      <LatestHappenings/>
      <AboutUs />
      <Footer />
    </Flex>
  );
}

export default LandingScreen;
