import { Button, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";

import { FaDownload } from "react-icons/fa6";
import React, { useRef, useState } from "react";
import Logo from "../../../src/Assets/Images/footerlogo 3.svg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import FormatIndianRupees from "../../Functions/FormatIndianRupees";
function Invoice(props) {
  const {
    orderId,
    amount,
    description,
    invoiceNumber,
    invoiceDate,
    name,
    address,
    ph,
    prices,
    invoiceDateTime,
    transactionId,
    paymentMethod,
  } = props;

  //  num to word
  function numberToWords(n) {
    if (n < 0) return "Invalid input";

    const single_digit = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const double_digit = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const below_hundred = [
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (n === 0) return "Zero";

    function translate(n) {
      let word = "";
      if (n < 10) {
        word = single_digit[n] + " ";
      } else if (n < 20) {
        word = double_digit[n - 10] + " ";
      } else if (n < 100) {
        let rem = translate(n % 10);
        word = below_hundred[Math.floor(n / 10) - 2] + " " + rem;
      } else if (n < 1000) {
        word =
          single_digit[Math.floor(n / 100)] + " Hundred " + translate(n % 100);
      } else if (n < 1000000) {
        word =
          translate(Math.floor(n / 1000)).trim() +
          " Thousand " +
          translate(n % 1000);
      } else if (n < 1000000000) {
        word =
          translate(Math.floor(n / 1000000)).trim() +
          " Million " +
          translate(n % 1000000);
      } else {
        word =
          translate(Math.floor(n / 1000000000)).trim() +
          " Billion " +
          translate(n % 1000000000);
      }
      return word;
    }

    return translate(n).trim();
  }

  const targetRef = useRef();
  const handleDownload = () => {
    html2canvas(targetRef.current, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: [canvas.width / 2, canvas.height / 2],
      });
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width / 2, canvas.height / 2);
      pdf.save("download.pdf");
    });
  };
  return (
    <>
      <Flex
        ref={targetRef}
        flexDir="column"
        p="20px"
        backgroundColor={"white"}
        borderRadius={"lg"}
      >
        <Image objectFit={"contain"} width="250px" src={Logo}></Image>
        <Flex
          mt={"50px"}
          fontSize={{ base: "13px", md: "13px", lg: "14px" }}
          width={"100%"}
        >
          <Flex flexDir={"column"}>
            <Text>Email: help@lokolumo.com</Text>
            <Text>Ph: +91 8921844256</Text>
          </Flex>
          <Text
            fontSize={{ base: "16px", md: "16px", lg: "17px" }}
            ml={"auto"}
            as="b"
          >
            INVOICE
          </Text>
        </Flex>

        <Flex
          mt={"50px"}
          fontSize={{ base: "13px", md: "13px", lg: "14px" }}
          width={"100%"}
        >
          <Flex flexDir={"column"}>
            <Text fontSize={{ base: "14px", md: "14px", lg: "15px" }} as="b">
              Buyer
            </Text>
            <Text>Name: {name}</Text>
            <Text>Address: {address}</Text>
            <Text>Ph: {ph}</Text>
          </Flex>
          <Flex ml={"auto"} flexDir={"column"}>
            <Text fontSize={{ base: "14px", md: "14px", lg: "15px" }} as="b">
              Order ID: {orderId}
            </Text>
            <Text fontSize={{ base: "14px", md: "14px", lg: "15px" }} as="b">
              Invoice number: {invoiceNumber}
            </Text>
            <Text fontSize={{ base: "14px", md: "14px", lg: "15px" }} as="b">
              Invoice date: {invoiceDate}
            </Text>
          </Flex>

          <Flex></Flex>
        </Flex>
        <Flex
          mt={"20px"}
          boxShadow={"md"}
          flexDir={"column"}
          border={"2px solid #9D9D9D"}
          borderRadius={"5px"}
        >
          <SimpleGrid width="100%" mx={"auto"} columns={3}>
            <Flex
              fontSize={{ base: "15px", md: "16px", lg: "16px" }}
              p={3}
              backgroundColor={"#E2C69D"}
              border={"1px solid #9D9D9D"}
            >
              <Text as={"b"}> Sl.No</Text>
            </Flex>
            <Flex
              fontSize={{ base: "15px", md: "16px", lg: "16px" }}
              p={3}
              backgroundColor={"#E2C69D"}
              border={"1px solid #9D9D9D"}
            >
              <Text as={"b"}>Description</Text>
            </Flex>
            <Flex
              fontSize={{ base: "15px", md: "16px", lg: "16px" }}
              p={3}
              backgroundColor={"#E2C69D"}
              border={"1px solid #9D9D9D"}
            >
              <Text as={"b"}>Amount in Rs.</Text>
            </Flex>

            <Flex
  flexDir={"column"}
  p={2}
  fontSize={{ base: "13px", md: "13px", lg: "14px" }}
  border={"1px solid #9D9D9D"}
>
  
  {Array.isArray(description) ? (
    description.map((item, index) => (
      <Text key={index}>
        {index + 1}
      </Text>
    ))
  ) : (
    <Text>
      {description === "premium" || description === "basic"
        ? 1
        : ''}
    </Text>
  )}
</Flex>

            <Flex
              flexDir={"column"}
              p={2}
              fontSize={{ base: "13px", md: "13px", lg: "14px" }}
              border={"1px solid #9D9D9D"}
            >
              {Array.isArray(description) ? (
                description.map((item, index) => (
                  <Text key={index}>
                    {item}
                    {index < description.length - 1}
                  </Text>
                ))
              ) : (
                <Text>
                  {description === "premium" || description === "basic"
                    ? `${description} subscription`
                    : description}
                </Text>
              )}
            </Flex>
            <Flex
              p={2}
              fontSize={{ base: "13px", md: "13px", lg: "14px" }}
              border={"1px solid #9D9D9D"}
              flexDir={"column"}
            >
               {Array.isArray(prices) ? (
                prices.map((item, index) => (
                  <Text key={index}>
                    {FormatIndianRupees(item)}
                    {index < prices.length - 1}
                  </Text>
                ))
              ) : (
                <Text>{FormatIndianRupees(Math.floor(amount))}</Text>
              )}
            </Flex>

            <Flex
              p={2}
              fontSize={{ base: "13px", md: "13px", lg: "14px" }}
            ></Flex>

            <Flex p={2} fontSize={{ base: "13px", md: "13px", lg: "14px" }}>
              <Text as="b">Total:</Text>
            </Flex>

            <Flex
              p={2}
              fontSize={{ base: "13px", md: "13px", lg: "14px" }}
              // border={"1px solid #9D9D9D"}
            >
              <Text as={"b"}>{FormatIndianRupees(Math.floor(amount))}</Text>
            </Flex>
          </SimpleGrid>
          <Flex
            p={2}
            fontSize={{ base: "13px", md: "13px", lg: "14px" }}
            borderTop={"1px solid #9D9D9D"}
          >
            <Text>
              Amount in Words: <br /> {numberToWords(amount)} only
            </Text>
          </Flex>

          <Flex
            p={2}
            fontSize={{ base: "13px", md: "13px", lg: "14px" }}
            borderTop={"1px solid #9D9D9D"}
          >
            <Flex ml={"auto"} flexDir={"column"}>
              <Text as={"b"}>For lokolumo</Text>
              <br />
              <Text as="b">Authorized Signatory</Text>
            </Flex>
          </Flex>
        </Flex>
        <SimpleGrid
          fontSize={{ base: "12px", md: "12px", lg: "13px" }}
          mx={"auto"}
          mt={"20px"}
          columns={2}
        >
          <Flex p={2} border={"1px solid #9D9D9D"}>
            Payment Transaction ID: {transactionId}
          </Flex>
          <Flex p={2} border={"1px solid #9D9D9D"}>
            Date and Time: {invoiceDateTime}
          </Flex>
          <Flex p={2} border={"1px solid #9D9D9D"}>
            Invoice Value: {amount}
          </Flex>
          <Flex p={2} border={"1px solid #9D9D9D"}>
            Mode of Payment: {paymentMethod}
          </Flex>
        </SimpleGrid>

        <Flex width={"100%"} mt={"20px"}>
          {" "}
          <Text
            fontSize={{ base: "12px", md: "12px", lg: "13px" }}
            mx={"auto"}
            color={"#8A7455"}
          >
            Thank You!
          </Text>
        </Flex>
      </Flex>
      <Flex width={"100%"}>
        <Button
          ml={"auto"}
          boxShadow={"lg"}
          backgroundColor={"#A8DCA1"}
          mt={"10px"}
          onClick={handleDownload}
        >
          <FaDownload />{" "}
          <Text as={"b"} ml={"10px"}>
            Download
          </Text>
        </Button>
      </Flex>
    </>
  );
}

export default Invoice;
