import {
  Text,
  Flex,
  chakra,
  Box,
  Stack,
  Image,
  Button,
  FormControl,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { API_BASE_URL } from "../../../Config";
import { config } from "../../../Functions/Auth";
import { ListItem, UnorderedList, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import CommonButton from "../../../Components/shared/CommonButton";
import Delete from "../../../Assets/Images/Dashboard/delet icon.png";
import Update from "../../../Assets/Images/Dashboard/update icon.png";
import ImageUploading from "react-images-uploading";
import { putStoresDetails } from "../../../Functions/PutApiCalls";
import { State } from "../../../Context/Context";
import { useGetShopData } from "../../../Functions/queries";
import { useQueryClient } from "@tanstack/react-query";

// TODO when navbar clicked and refresh goes to shop details
function ShopImagesForm() {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesEdit, setImagesEdit] = useState([]);
  const [coverImageEdit, setCoverImageEdit] = useState();
  const [profileImageEdit, setProfileImageEdit] = useState();
  const [shopimages, setshopImages] = useState([]);
  const [shopImagesList, setShopImagesList] = useState([]);
  const [coverImage, setCoverImage] = useState();
  const [profileImage, setProfileImage] = useState();
  const [subscription, setSubscription] = useState();
  const toast = useToast();
  const { shop } = useGetShopData();

  const queryClient = useQueryClient();

  // for deleting from aws
  const [deletedImages, setDeletedImages] = useState([]);

  const handleDeleteImage = (imageUrl) => {
    setProfileImageEdit();

    // Extract the file name from the URL
    const extractedprofileFileName = imageUrl.split("/").pop();

    // Check if the file name already exists in deletedImages
    if (!deletedImages.includes(extractedprofileFileName)) {
      setDeletedImages([...deletedImages, extractedprofileFileName]);
    }
  };
  const handleDeleteCoverImage = (imageUrl) => {
    setCoverImageEdit();

    // Extract the file name from the URL
    const extractedprofileFileName = imageUrl.split("/").pop();

    // Check if the file name already exists in deletedImages
    if (!deletedImages.includes(extractedprofileFileName)) {
      setDeletedImages([...deletedImages, extractedprofileFileName]);
    }
  };

  const handleshopimagesDeleteImage = (index) => {
    editImageDelete(index);

    // Extract the image URL of the deleted image
    const deletedImageUrl = imagesEdit[index];

    // Extract the file name from the URL
    const extractedFileName = deletedImageUrl.split("/").pop();

    // Check if the file name already exists in deletedImages
    if (!deletedImages.includes(extractedFileName)) {
      setDeletedImages([...deletedImages, extractedFileName]);
    }
  };

  // for deleting from aws ends
  const editImageDelete = (index) => {
    const newImageEdit = [...imagesEdit];
    newImageEdit.splice(index, 1);
    setImagesEdit(newImageEdit);
  };
  useEffect(() => {
    if (shop) {
      setCoverImageEdit(shop[0]?.shopcoverimage);
      setProfileImageEdit(shop[0]?.shopprofileimage);
      setImagesEdit(shop[0]?.shopimages);
      if (
        shop[0]?.subscription?.to &&
        new Date(shop[0]?.subscription?.to) > new Date()
      ) {
        shop[0]?.subscription?.plan === "premium" && setSubscription("premium");
      }
    }
  }, [shop]);
  // coverimage dragged
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  // profile image dragged
  const onChangeProfile = (imageList, addUpdateIndex) => {
    setProfileImage(imageList);
  };
  // shopimages dragged
  const onChangeshop = (imageList, addUpdateIndex) => {
    setshopImages(imageList);
  };
  // ......cover image validation...............
  const [coverImageError, setCoverImageError] = useState(false);
  let maxNumberCover = 0;
  coverImageEdit ? (maxNumberCover = 0) : (maxNumberCover = 1);

  // ......profile image validation..............
  const [profileImageError, setProfileImageError] = useState(false);
  let maxNumberProfile = 1;
  profileImageEdit ? (maxNumberProfile = 0) : (maxNumberProfile = 1);

  // ......shop image validation..............
  let maxNumberImage = 6 - imagesEdit?.length;
  const deleteImagesFromS3 = async () => {
    try {
      // Make a DELETE request to your deleteS3Route endpoint
      await axios.delete(`${API_BASE_URL}/delete/s3`, {
        data: deletedImages, // Pass the deletedImages array as data
      });
    } catch (error) {
      console.error("Error deleting images from S3:", error);
    }
  };
  const handleImageSaveClick = async () => {
    setLoading(true);
    // Check if the user has uploaded at least one image
    if (subscription === "premium" && images.length === 0 && !coverImageEdit) {
      setCoverImageError(true);
      setLoading(false);
      return;
      // Don't proceed with saving if no image is uploaded
    } else {
      setCoverImageError(false);
    }
    // Check if the user has uploaded at least one profile image
    if (!profileImage && !profileImageEdit) {
      setProfileImageError(true);
      setLoading(false);
      return;
      // Don't proceed with saving if no image is uploaded
    } else {
      setCoverImageError(false);
    }
    // .................
    // Map and upload all images to AWS
    const formData = new FormData();
    // cover image
    images?.length > 0 && formData.append("file0", images[0].file);
    // profile image
    profileImage?.length > 0 && formData.append("file1", profileImage[0].file);
    // shop images
    shopimages?.length > 0 &&
      shopimages?.map((shopImage, index) => {
        formData.append(`file${index + 2}`, shopImage.file);
      });
    // cover image edit
    coverImageEdit && formData.append("coverImageEdit", coverImageEdit);
    // profile image edit
    profileImageEdit && formData.append("profileImageEdit", profileImageEdit);
    // shop images edit
    if (imagesEdit?.length > 0) {
      for (let i = 0; i < imagesEdit?.length; i++) {
        formData.append("imagesEdit[]", imagesEdit[i]);
      }
    }
    try {
      const response = await axios.post(
        `${API_BASE_URL}/upload/shopcoverimage/${shop[0]?._id}`,
        formData
      );
      setLoading(false);
      toast({
        title: "Success",
        description: "Details Added Successfully",
        status: "success",
        position: "top",
        isClosable: true,
      });
      setImages();
      setCoverImage();
      setProfileImage();
      setshopImages();
      setCoverImageError(false);
      setProfileImageError(false);
      setImagesEdit();
      setCoverImageEdit();
      await queryClient.invalidateQueries("shop");
      deleteImagesFromS3();
    } catch (error) {
      console.error("Error uploading Cover image:", error);
      throw error;
      // toast('Image upload failed');
    }
  };
  // delet from aws
  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Add Store Images
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex
          fontSize={{ base: "15px", md: "15px", lg: "16px" }}
          justifyContent={"center"}
          my={"5%"}
          width={"100%"}
        >
          <Stack
            as={chakra.form}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission behavior
              handleImageSaveClick(); // Call your custom form submission logic
            }}
            width={{ base: "90%", md: "70%", lg: "60%" }}
            spacing={3}
          >
            <Flex>
              <Text
                fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                mt={"auto"}
                mb={"auto"}
                width={"300px"}
              >
                Store Profile Image*
                <br />
                (one file only)
              </Text>
              {/* ..................Profile Image upload............... */}
              <Flex
                maxW={{ base: "200px", md: "800px", lg: "800px" }}
                backgroundColor={"white"}
                boxShadow={"inner"}
                padding={4}
                borderRadius={"10px"}
              >
                <ImageUploading
                  value={profileImage}
                  onChange={onChangeProfile}
                  maxNumber={maxNumberProfile}
                  maxFileSize={5000000}
                  dataURLKey="data_url"
                  acceptType={["jpg", "png", "jpeg"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors,
                  }) => (
                    <div>
                      <Button
                        fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                        boxShadow={"lg"}
                        border={"1px dashed black"}
                        height={"100px"}
                        width={"100%"}
                        fontWeight={"bold"}
                        style={isDragging ? { color: "red" } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Choose a file or Drag it here.
                      </Button>
                      <Text
                        fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                        my={"5px"}
                      >
                        *Upload file not more than 5 MB, in the format . jpeg /
                        . png / .jpg
                      </Text>
                      {profileImageError && (
                        <Text
                          fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                          color={"red"}
                        >
                          *This field is required
                        </Text>
                      )}
                      {errors && (
                        <Flex
                          p={2}
                          fontSize={"11px"}
                          backgroundColor={"#FDF5F1"}
                          border={"1px solid red"}
                        >
                          <UnorderedList>
                            <ListItem>
                              Only files with the following extensions are
                              allowed: png,jpg,jpeg.
                            </ListItem>
                            <ListItem>
                              The number of files should not exceed the maximum
                              number.
                            </ListItem>
                            <ListItem>
                              The file size should not exceed 5MB.
                            </ListItem>
                          </UnorderedList>
                        </Flex>
                      )}
                      <Flex>
                        {imageList.map((image, index) => (
                          <Flex
                            boxShadow={"lg"}
                            borderRadius={"10px"}
                            backgroundColor={"#E1E1E1"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDir={"column"}
                            mt={"10px"}
                            key={index}
                          >
                            <Image
                              mt={"10px"}
                              objectFit={"cover"}
                              borderRadius={"10px"}
                              boxShadow={"lg"}
                              src={image.data_url}
                              alt="images"
                              height={"70px"}
                              width="100px"
                            ></Image>
                            <Flex p={2}>
                              <Button
                                m={1}
                                onClick={() => onImageUpdate(index)}
                              >
                                <Image width={"20px"} src={Update}></Image>
                              </Button>
                              <Button
                                m={1}
                                onClick={() => onImageRemove(index)}
                              >
                                <Image width={"15px"} src={Delete}></Image>
                              </Button>
                            </Flex>
                          </Flex>
                        ))}
                        {profileImageEdit && (
                          <Flex
                            boxShadow={"lg"}
                            borderRadius={"10px"}
                            backgroundColor={"#E1E1E1"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDir={"column"}
                            mt={"10px"}
                          >
                            <Image
                              mt={"10px"}
                              objectFit={"cover"}
                              borderRadius={"10px"}
                              boxShadow={"lg"}
                              src={profileImageEdit}
                              alt="images"
                              height={"70px"}
                              width="100px"
                            ></Image>
                            <Flex p={2}>
                              <Button
                                m={1}
                                // onClick={() => setProfileImageEdit()}
                                onClick={() =>
                                  handleDeleteImage(profileImageEdit)
                                }
                              >
                                <Image width={"15px"} src={Delete}></Image>
                              </Button>
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                    </div>
                  )}
                </ImageUploading>
              </Flex>
            </Flex>

            {/* Cover Image option for premium members */}
            {subscription === "premium" && (
              <Flex>
                <Text
                  fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                  mt={"auto"}
                  mb={"auto"}
                  width={"300px"}
                >
                  Store Cover Image*
                  <br />
                  (one file only)
                </Text>
                {/* ..................Cover Image upload............... */}
                <Flex
                  maxW={{ base: "200px", md: "800px", lg: "800px" }}
                  backgroundColor={"white"}
                  boxShadow={"inner"}
                  padding={4}
                  borderRadius={"10px"}
                >
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumberCover}
                    maxFileSize={5000000}
                    dataURLKey="data_url"
                    acceptType={["jpg", "png", "jpeg"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      // write your building UI
                      <div>
                        <Button
                          fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                          boxShadow={"lg"}
                          border={"1px dashed black"}
                          height={"100px"}
                          width={"100%"}
                          fontWeight={"bold"}
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Choose a file or Drag it here.
                        </Button>
                        <Text
                          fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                          my={"5px"}
                        >
                          *Upload file not more than 5 Mb, in th format . jpeg /
                          . png / .jpg
                        </Text>
                        {coverImageError && (
                          <Text
                            fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                            color={"red"}
                          >
                            *This field is required
                          </Text>
                        )}
                        {errors && (
                          <Flex
                            p={2}
                            fontSize={"11px"}
                            backgroundColor={"#FDF5F1"}
                            border={"1px solid red"}
                          >
                            <UnorderedList>
                              <ListItem>
                                Only files with the following extensions are
                                allowed: png,jpg,jpeg.
                              </ListItem>
                              <ListItem>
                                The number of files should not exceed the
                                maximum number.
                              </ListItem>
                              <ListItem>
                                The file size should not exceed 5MB.
                              </ListItem>
                            </UnorderedList>
                          </Flex>
                        )}
                        <Flex>
                          {imageList.map((image, index) => (
                            <Flex
                              boxShadow={"lg"}
                              borderRadius={"10px"}
                              backgroundColor={"#E1E1E1"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDir={"column"}
                              mt={"10px"}
                              key={index}
                            >
                              <Image
                                mt={"10px"}
                                objectFit={"cover"}
                                borderRadius={"10px"}
                                boxShadow={"lg"}
                                src={image.data_url}
                                alt="images"
                                height={"70px"}
                                width="100px"
                              ></Image>
                              <Flex p={2}>
                                <Button
                                  m={1}
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <Image width={"20px"} src={Update}></Image>
                                </Button>
                                <Button
                                  m={1}
                                  onClick={() => onImageRemove(index)}
                                >
                                  <Image width={"15px"} src={Delete}></Image>
                                </Button>
                              </Flex>
                            </Flex>
                          ))}
                          {coverImageEdit && (
                            <Flex
                              boxShadow={"lg"}
                              borderRadius={"10px"}
                              backgroundColor={"#E1E1E1"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDir={"column"}
                              mt={"10px"}
                            >
                              <Image
                                mt={"10px"}
                                objectFit={"cover"}
                                borderRadius={"10px"}
                                boxShadow={"lg"}
                                src={coverImageEdit}
                                alt="images"
                                height={"70px"}
                                width="100px"
                              ></Image>
                              <Flex p={2}>
                                {/* <Button
                                m={1}
                                onClick={() => onImageUpdate(index)}
                              >
                                <Image width={"20px"} src={Update}></Image>
                              </Button> */}
                                <Button
                                  m={1}
                                  // onClick={() => setCoverImageEdit()}
                                  onClick={() =>
                                    handleDeleteCoverImage(coverImageEdit)
                                  }
                                >
                                  <Image width={"15px"} src={Delete}></Image>
                                </Button>
                              </Flex>
                            </Flex>
                          )}
                        </Flex>
                      </div>
                    )}
                  </ImageUploading>
                </Flex>
              </Flex>
            )}
            {/* Cover Image option for premium members */}

            <Flex>
              <Text
                fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                mt={"auto"}
                mb={"auto"}
                width={"300px"}
              >
                Upload Images of your Store
                <br />
                (maximum 6)
              </Text>
              {/* ..................Shop Images upload............... */}
              <Flex
                maxW={{ base: "200px", md: "800px", lg: "800px" }}
                backgroundColor={"white"}
                boxShadow={"inner"}
                padding={4}
                borderRadius={"10px"}
              >
                <ImageUploading
                  multiple
                  value={shopimages}
                  onChange={onChangeshop}
                  maxNumber={maxNumberImage}
                  maxFileSize={5000000}
                  dataURLKey="data_url"
                  acceptType={["jpg", "png", "jpeg"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors,
                  }) => (
                    // write your building UI

                    <div>
                      <Button
                        fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                        boxShadow={"lg"}
                        border={"1px dashed black"}
                        height={"100px"}
                        width={"100%"}
                        fontWeight={"bold"}
                        style={isDragging ? { color: "red" } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Choose a file or Drag it here.
                      </Button>
                      <Text
                        fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                        my={"5px"}
                      >
                        *Upload file not more than 5 MB, in the format . jpeg /
                        . png / .jpg
                      </Text>
                      {/* <button onClick={onImageRemoveAll}>
                      Remove all images
                    </button> */}
                      {errors && (
                        <Flex
                          p={2}
                          fontSize={"11px"}
                          backgroundColor={"#FDF5F1"}
                          border={"1px solid red"}
                        >
                          <UnorderedList>
                            <ListItem>
                              Only files with the following extensions are
                              allowed: png,jpg,jpeg.
                            </ListItem>
                            <ListItem>
                              The number of files should not exceed the maximum
                              number.
                            </ListItem>
                            <ListItem>
                              The file size should not exceed 5MB.
                            </ListItem>
                          </UnorderedList>
                        </Flex>
                      )}
                      <SimpleGrid columns={[2, null, 3]} gap={2}>
                        {imageList.map((shopimage, index1) => (
                          <Flex
                            boxShadow={"lg"}
                            borderRadius={"10px"}
                            backgroundColor={"#E1E1E1"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDir={"column"}
                            mt={"10px"}
                            key={index1}
                          >
                            <Image
                              mt={"10px"}
                              objectFit={"cover"}
                              borderRadius={"10px"}
                              boxShadow={"lg"}
                              src={shopimage.data_url}
                              alt="images"
                              height={{
                                base: "50px",
                                md: "100px",
                                lg: "100px",
                              }}
                              width={{ base: "60px", md: "100px", lg: "100px" }}
                            ></Image>
                            <Flex p={2}>
                              <Button
                                height={{
                                  base: "30px",
                                  md: "20px",
                                  lg: "20px",
                                }}
                                width={{ base: "5px", md: "20px", lg: "20px" }}
                                onClick={() => onImageUpdate(index1)}
                              >
                                <Image src={Update}></Image>
                              </Button>
                              <Button
                                height={{
                                  base: "30px",
                                  md: "20px",
                                  lg: "20px",
                                }}
                                width={{ base: "5px", md: "20px", lg: "20px" }}
                                onClick={() => onImageRemove(index1)}
                              >
                                <Image width={"15px"} src={Delete}></Image>
                              </Button>
                            </Flex>
                          </Flex>
                        ))}
                        {imagesEdit?.map((shopimage, index1) => (
                          <Flex
                            boxShadow={"lg"}
                            borderRadius={"10px"}
                            backgroundColor={"#E1E1E1"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDir={"column"}
                            mt={"10px"}
                            key={index1}
                          >
                            <Image
                              mt={"10px"}
                              objectFit={"cover"}
                              borderRadius={"10px"}
                              boxShadow={"lg"}
                              src={shopimage}
                              alt="images"
                              height={{
                                base: "50px",
                                md: "100px",
                                lg: "100px",
                              }}
                              width={{ base: "60px", md: "100px", lg: "100px" }}
                            ></Image>
                            <Flex p={2}>
                              {/* <Button
                                height={{
                                  base: "30px",
                                  md: "20px",
                                  lg: "20px",
                                }}
                                width={{ base: "5px", md: "20px", lg: "20px" }}
                                onClick={() => onImageUpdate(index1)}
                              >
                                <Image src={Update}></Image>
                              </Button> */}
                              <Button
                                height={{
                                  base: "30px",
                                  md: "20px",
                                  lg: "20px",
                                }}
                                width={{ base: "5px", md: "20px", lg: "20px" }}
                                // onClick={() => editImageDelete(index1)}
                                onClick={() =>
                                  handleshopimagesDeleteImage(index1)
                                }
                              >
                                <Image width={"15px"} src={Delete}></Image>
                              </Button>
                            </Flex>
                          </Flex>
                        ))}
                      </SimpleGrid>
                    </div>
                  )}
                </ImageUploading>
              </Flex>
            </Flex>

            <Flex width={"100%"} justifyContent={"end"}>
              <CommonButton
                // onClick={handleImageSaveClick}
                isLoading={loading}
                type={"submit"}
                height="34px"
                width="100px"
                mt="20px"
                fontSize="14px"
                btn={"Save"}
              />
            </Flex>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ShopImagesForm;
