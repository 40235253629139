import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

function HeadingLine(props) {
  const { title } = props;
  return (
    <Flex m={2} mt={10} mb={8} justifyContent={"center"} alignItems={"center"}>
      <Flex alignItems="center" width="100%" justifyContent={"center"}>
        <Box
          flex="1"
          height="3px"
          bg="brand.LightBrown"
          mx="2"
          maxWidth="10%"
        />
        <Text as="h2" fontSize="30px">
          {title}
        </Text>
        <Box
          flex="1"
          height="3px"
          bg="brand.LightBrown"
          mx="2"
          maxWidth="10%"
        />
      </Flex>
    </Flex>
  );
}

export default HeadingLine;
