
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

let AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  let [token, setToken] = React.useState(null);
  let navigate = useNavigate();
  let signIn = (newToken, callback) => {
    return AuthChecker.signIn(() => {
      setToken(newToken);
      callback();
    });
  };

  let signOut = (callback) => {
    return AuthChecker.signOut(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('client');
      setToken(null);
      navigate('/login');
      callback();
    });
  };

  const AuthChecker = {
    isAuthenticated: false,
    signIn(callback) {
      AuthChecker.isAuthenticated = true;
      setTimeout(callback, 100); // fake async
    },
    signOut(callback) {
      AuthChecker.isAuthenticated = false;
      setTimeout(callback, 100);
    },
  };

  let value = { token, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  let token = window.localStorage.getItem('token');
  if (token) auth.token = token;

  if (!auth.token) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
}
