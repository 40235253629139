import {
  Text,
  Flex,
  Box,
  Stack,
  chakra,
  useToast,
  Checkbox,
  Image,
  Divider,
  Button,
  Link,
} from "@chakra-ui/react";
import { ListItem, UnorderedList, SimpleGrid } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormInput from "../../../../Components/FormInputs/FormInput.js";
import React, { useEffect, useState } from "react";
import CommonButton from "../../../../Components/shared/CommonButton.js";
import TextArea from "../../../../Components/FormInputs/TextArea.js";
import Selectform from "../../../../Components/FormInputs/Selectform.js";
import { postStoresDetails } from "../../../../Functions/PostApiCalls.js";
import ThreeD from "../../../../Assets/Images/Dashboard/3d.webp";
import Delete from "../../../../Assets/Images/Dashboard/delet icon.png";
import Update from "../../../../Assets/Images/Dashboard/update icon.png";
import ImageUploading from "react-images-uploading";
import FormatIndianRupees from "../../../../Functions/FormatIndianRupees.js";
import axios from "axios";
import {
  Sanitary,
  DecorMaterial,
  Decoratives,
  Furniting,
  FurnitingMaterial,
  Furniture,
  FurnitureMaterial,
  Lighting,
  Primary,
  Sofa,
  Table,
  Texture,
  Tile,
  TileMaterial,
  WallArt,
  color,
  styles,
  priceper,
} from "./FormFields.js";
import { getPrice } from "./Functions.js";
import { putStoresDetails } from "../../../../Functions/PutApiCalls.js";
import { useGetShopData } from "../../../../Functions/queries.js";
import { State } from "../../../../Context/Context.js";
import { API_BASE_URL } from "../../../../Config/index.js";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import CheckboxGroup from "../../../../Components/FormInputs/CheckboxGroup.js";

function AddProductForm(props) {
  // State variable to store selected colors
  const [selectedColors, setSelectedColors] = useState([]);
  const { productLimit, setProductLimit } = props;
  const { shop } = useGetShopData();
  const [addLoading, setAddLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [primaryValue, setPrimaryValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [styleValue, setStyleValue] = useState("");
  const [priceperValue, setPriceperValue] = useState("");
  const [ColorValue, setColorValue] = useState("");
  const [TextureValue, setTextureValue] = useState("");
  const [MaterialValue, setMaterialValue] = useState("");
  const [images, setImages] = useState([]);
  const [imageEdit, setImageEdit] = useState([]);
  const [generate3D, setGenerate3D] = useState(false);
  const [endDate, setendDate] = useState(false);
  const [generateTexture, setGenerateTexture] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [product, setProduct] = useState([]);
  const [productLength, setProductLength] = useState();
  const [generate, setGenerate] = useState(true);
  const [pendings, setPendings] = useState();
  const [orderComplete, setOrderComplete] = useState();
  const [imageError, setImageError] = useState();
  const queryClient = useQueryClient();
  // for deleting from aws
  const [deletedImages, setDeletedImages] = useState([]);

  const handleproductimagesDelete = (index) => {
    editImageDelete(index);

    // Extract the image URL of the deleted image
    const deletedImageUrl = imageEdit[index];

    // Extract the file name from the URL
    const extractedFileName = deletedImageUrl.split("/").pop();

    // Check if the file name already exists in deletedImages
    if (!deletedImages.includes(extractedFileName)) {
      setDeletedImages([...deletedImages, extractedFileName]);
    }
  };
  const deleteImagesFromS3 = async () => {
    try {
      // Make a DELETE request to your deleteS3Route endpoint
      await axios.delete(`${API_BASE_URL}/delete/s3`, {
        data: deletedImages, // Pass the deletedImages array as data
      });
    } catch (error) {
      console.error("Error deleting images from S3:", error);
    }
  };
  // for deleting from aws ends
  // coverimage
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const navigate = useNavigate();
  // .......................
  const handlePrimaryChange = (event) => {
    setPrimaryValue(event.target.value);
    // setTypeValue(0);
  };
  const handleTypeChange = (event) => {
    setTypeValue(event.target.value);
  };
  const handleStyleChange = (event) => {
    setStyleValue(event.target.value);
  };
  const handlePriceperChange = (event) => {
    setPriceperValue(event.target.value);
  };
  // const handleColorChange = (event) => {
  //   setColorValue(event.target.value);

  // };
  const handleColorChange = (event) => {
    const { value, checked } = event.target;

    // If the checkbox is checked, add the value to selectedColors, otherwise remove it
    if (checked) {
      setSelectedColors([...selectedColors, value]);
    } else {
      setSelectedColors(selectedColors.filter((color) => color !== value));
    }
  };

  const handleMaterialChange = (event) => {
    setMaterialValue(event.target.value);
  };
  const handleTextureChange = (event) => {
    setTextureValue(event.target.value);
  };
  const handleGenerate3DChange = (event) => {
    setGenerate3D(!generate3D);
  };
  const handleGenerateTextureChange = (event) => {
    setGenerateTexture(!generateTexture);
  };

  const editImageDelete = (index) => {
    const newImageEdit = [...imageEdit];
    newImageEdit.splice(index, 1);
    setImageEdit(newImageEdit);
  };
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  useEffect(() => {
    if (props.productdataitem) {
      const fields = [
        "productname",
        "productLink",
        "type",
        "subType",
        "material",
        "texture",
        "measurement",
        "productdesc",
        "price",
        "priceper",
        "productstyle",
        "productcolor",
        "category",
        "endDate",
      ];
      setSelectedColors(props.productdataitem.productcolor);
      fields.forEach((field) => setValue(field, props.productdataitem[field]));
      setPrimaryValue();
      setGenerate3D(props?.productdataitem.generate3d);
      setGenerateTexture(props?.productdataitem.generatetexture);
      setTypeValue(props?.productdataitem?.type);
      setPrimaryValue(props?.productdataitem?.category);
      setStyleValue(props?.productdataitem?.productstyle);
      setPriceperValue(props?.productdataitem?.priceper);
      setendDate(props?.productdataitem?.endDate);
    }
  }, []);
  useEffect(() => {
    props?.productdataitem &&
      setImageEdit(props?.productdataitem?.productimage);
  }, []);
  useEffect(() => {
    if (shop) {
      setProductLength(shop[0]?.product?.length);
      const pendingData = shop[0]?.product.filter(
        (product) =>
          (product.generate3d == true || product?.generatetexture == true) &&
          product?.orderComplete == false
      );
      setPendings(pendingData.length);
      if (
        shop[0]?.subscription?.plan === "basic" ||
        shop[0]?.subscription?.plan === "default"
      ) {
        pendingData.length >= 5 && setGenerate(false);
      } else if (shop[0]?.subscription?.plan === "premium") {
        pendingData.length >= 10 && setGenerate(false);
      }
      if (props?.productdataitem) {
        if (props?.productdataitem?.payment) {
          setOrderComplete(true);
        }
      }
    }
  }, [shop]);
  // form submit functionality
  const onSubmit = async (e) => {
    if (images?.length == 0 && imageEdit.length == 0) {
      setImageError(true);
      toast({
        title: "Error",
        description: "Please add product image",
        status: "error",
        position: "top",
        isClosable: true,
      });
    } else {
      submit ? setSubmitLoading(true) : setAddLoading(true);
      const modelPrice = getPrice(typeValue, primaryValue, styleValue);
      let modelTexturePrice = "";
      !generateTexture
        ? generate3D
          ? (modelTexturePrice = parseInt(modelPrice?.replace(/\D/g, ""), 10))
          : (modelTexturePrice = "")
        : (modelTexturePrice = 120);
      // Map and upload cover images to Cloudinary
      const formData = new FormData();
      images.map((image, index) => {
        image.file && formData.append(`file${index}`, image.file);
      });

      // const image = url.concat(imageEdit);
      if (formData.has("file0")) {
        var response = await axios.post(
          `${API_BASE_URL}/upload/product`,
          formData
        );
      }
      if (response?.data?.data) {
        if (imageEdit) {
          var image = response.data.data.concat(imageEdit);
        } else image = response?.data?.data;
      } else image = imageEdit;
      const singleProduct = {
        _id: props.productdataitem?._id,
        productname: e.productname || "",
        productLink: e.productLink || "",
        type: e.type || "",
        subType: e.subType || "",
        price: e.price || "",
        priceper: e.priceper || "",
        productstyle: e.productstyle || "",
        productcolor: selectedColors || "",
        material: e.material || "",
        productimage: image || "",
        measurement: e.measurement || "",
        productdesc: e.productdesc || "",
        texture: e.texture || "",
        endDate: e.endDate || "",
        generate3d: generate3D || false,
        generatetexture: generateTexture || false,
        category: e.category,
        modelTexturePrice: modelTexturePrice || "",
        payment: orderComplete,
      };
      setProduct(singleProduct);
      if (submit) {
        let details = { product: singleProduct, prodCategory: e.category };
        await putStoresDetails(details, toast);
        setSubmitLoading(false);
        props.onClose(true);
        setTimeout(() => {
          props.onClose(false);
          // window.location.reload();
        }, 2000);
        if (pendings) navigate("/cart");
      } else {
        let details = { product: singleProduct, prodCategory: e.category };
        await putStoresDetails(details);
        (generate3D || generateTexture) &&
          setPendings((pendings) => pendings + 1);
        if (
          shop[0]?.subscription?.plan === "basic" ||
          shop[0]?.subscription?.plan === "default"
        ) {
          pendings + 1 >= 5 && setGenerate(false);
        } else if (shop[0]?.subscription?.plan === "premium") {
          pendings + 1 >= 10 && setGenerate(false);
        }
        setProductLength((productLength) => productLength + 1);
        reset();
        setImages([]);
        setAddLoading(false);
        setPrimaryValue();
        setTypeValue();
        setStyleValue();
        setPriceperValue();
        setColorValue();
        setMaterialValue();
        setTextureValue();
        setGenerate3D();
        setendDate();
        setGenerateTexture();
        setSelectedColors([]);
      }
      await queryClient.invalidateQueries("shop");
      deleteImagesFromS3();
    }
  };
  return (
    <Flex width={{ base: "100%", md: "100%", lg: "100%" }} mx={"auto"}>
      {/* Form area */}
      <Flex
        fontSize={{ base: "15px", md: "15px", lg: "16px" }}
        justifyContent={"center"}
        my={"5%"}
        width={{ base: "100%", md: "100%", lg: "100%" }}
      >
        <Stack
          as={chakra.form}
          onSubmit={handleSubmit(onSubmit)}
          width={{ base: "100%", md: "100%", lg: "100%" }}
        >
          <Flex
            width={"100%"}
            flexDir={{ base: "column", md: "column", lg: "row" }}
          >
            <Flex
              fontSize={{ base: "12px", md: "13px", lg: "14px" }}
              p={4}
              width={{ base: "100%", md: "100%", lg: "50%" }}
              flexDirection={"column"}
            >
              <Flex>
                <Text mt={"auto"} mb={"auto"} width={"250px"}>
                  Product Name*
                </Text>
                <FormInput
                  name="productname"
                  inputFor="productname"
                  register={register}
                  error={errors.productname}
                  validator={{
                    required: "This field is required",
                  }}
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                  type="text"
                />
              </Flex>
              <Link
                mt={"10px"}
                fontSize={{ base: "10px", md: "10px", lg: "14px" }}
                isExternal
                color="teal.500"
                href="https://styhom-s3-demo.s3.ap-south-1.amazonaws.com/catalogue-1+(1).pdf"
              >
                <Text fontWeight={"bold"}>
                  {" "}
                  *IMPORTANT: Please follow the GUIDELINES attatched before
                  entering product's category and sub-categories.
                </Text>
              </Link>
              <Flex>
                <Text mt={"auto"} mb={"auto"} width={"250px"}>
                  Choose Product Category*
                </Text>

                <Selectform
                  isDisabled={orderComplete ? true : false}
                  options={Primary}
                  name="category"
                  inputFor="category"
                  register={register}
                  error={errors.category}
                  validator={{
                    required: "This field is required",
                  }}
                  style={{
                    border: "1px solid #D0D5DD",
                    borderBottom: "1px solid #D0D5DD",
                    maxHeight: "38px",
                    borderRadius: "10px",
                  }}
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                  type="text"
                  color="#848484"
                  placeholder="Select product's category"
                  onChange={handlePrimaryChange}
                />
                {/* Conditionally render the second Selectform based on primaryValue */}
              </Flex>

              {(primaryValue || props.productdataitem?.category) ===
                "furniture" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Product Type*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={Furniture}
                    name="type"
                    inputFor="type"
                    register={register}
                    error={errors.type}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product type"
                    onChange={handleTypeChange}
                  />
                </Flex>
              )}
              {(primaryValue || props.productdataitem?.category) ===
                "furniture" &&
                (typeValue || props.productdataitem?.type) === "Sofa" && (
                  <Flex>
                    <Text mt={"auto"} mb={"auto"} width={"250px"}>
                      Choose Product Subtype*
                    </Text>

                    <Selectform
                      isDisabled={orderComplete ? true : false}
                      options={Sofa}
                      name="subType"
                      inputFor="subType"
                      register={register}
                      error={errors.subType}
                      validator={{
                        required: "This field is required",
                      }}
                      style={{
                        border: "1px solid #D0D5DD",
                        borderBottom: "1px solid #D0D5DD",
                        maxHeight: "38px",
                        borderRadius: "10px",
                      }}
                      backgroundColor={"white"}
                      boxShadow={"lg"}
                      type="text"
                      color="#848484"
                      placeholder="Select your product subtype"
                    />
                  </Flex>
                )}

              {(primaryValue || props.productdataitem?.category) ===
                "furniture" &&
                (typeValue || props.productdataitem?.type) === "Table" && (
                  <Flex>
                    <Text mt={"auto"} mb={"auto"} width={"250px"}>
                      Choose Product Subtype*
                    </Text>

                    <Selectform
                      isDisabled={orderComplete ? true : false}
                      options={Table}
                      name="subType"
                      inputFor="subType"
                      register={register}
                      error={errors.subType}
                      validator={{
                        required: "This field is required",
                      }}
                      style={{
                        border: "1px solid #D0D5DD",
                        borderBottom: "1px solid #D0D5DD",
                        maxHeight: "38px",
                        borderRadius: "10px",
                      }}
                      backgroundColor={"white"}
                      boxShadow={"lg"}
                      type="text"
                      color="#848484"
                      placeholder="Select your product subtype"
                    />
                  </Flex>
                )}
              {(primaryValue || props.productdataitem?.category) ===
                "wallArt" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Product Type*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={WallArt}
                    name="type"
                    inputFor="type"
                    register={register}
                    error={errors.type}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product type"
                    onChange={handleTypeChange}
                  />
                </Flex>
              )}
              {(primaryValue || props.productdataitem?.category) ===
                "lighting" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Product Type*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={Lighting}
                    name="type"
                    inputFor="type"
                    register={register}
                    error={errors.type}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product type"
                    onChange={handleTypeChange}
                  />
                </Flex>
              )}
              {(primaryValue || props.productdataitem?.category) ===
                "decoratives" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Product Type*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={Decoratives}
                    name="type"
                    inputFor="type"
                    register={register}
                    error={errors.type}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product type"
                    onChange={handleTypeChange}
                  />
                </Flex>
              )}
              {(primaryValue || props.productdataitem?.category) ===
                "furnishing" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Product Type*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={Furniting}
                    name="type"
                    inputFor="type"
                    register={register}
                    error={errors.type}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product type"
                    onChange={handleTypeChange}
                  />
                </Flex>
              )}
              {(primaryValue || props.productdataitem?.category) === "tile" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Product Type*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={Tile}
                    name="type"
                    inputFor="type"
                    register={register}
                    error={errors.type}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product type"
                    onChange={handleTypeChange}
                  />
                </Flex>
              )}
              {(primaryValue || props.productdataitem?.category) ===
                "sanitary" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Product Type*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={Sanitary}
                    name="type"
                    inputFor="type"
                    register={register}
                    error={errors.type}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product type"
                    onChange={handleTypeChange}
                  />
                </Flex>
              )}
              {((typeValue || props.productdataitem?.type) === "Vases" ||
                (typeValue || props.productdataitem?.type) === "Sculptures" ||
                (typeValue || props.productdataitem?.type) === "Clocks" ||
                (typeValue || props.productdataitem?.type) ===
                  "Wall Decor items" ||
                (typeValue || props.productdataitem?.type) === "Paintings" ||
                (typeValue || props.productdataitem?.type) === "Mirrors" ||
                (typeValue || props.productdataitem?.type) ===
                  "Other Decoratives" ||
                (typeValue || props.productdataitem?.type) ===
                  "Artificial Flowers") && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Material*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={DecorMaterial}
                    name="material"
                    inputFor="material"
                    register={register}
                    error={errors.material}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product Material"
                    onChange={handleMaterialChange}
                  />
                </Flex>
              )}

              {((typeValue || props.productdataitem?.type) === "Curtains" ||
                (typeValue || props.productdataitem?.type) === "Cushions" ||
                (typeValue || props.productdataitem?.type) === "Pillows" ||
                (typeValue || props.productdataitem?.type) === "Bed Sheet" ||
                (typeValue || props.productdataitem?.type) === "Bedspread" ||
                (typeValue || props.productdataitem?.type) === "Carpets" ||
                (typeValue || props.productdataitem?.type) ===
                  "Table Runners" ||
                (typeValue || props.productdataitem?.type) === "Quilt" ||
                (typeValue || props.productdataitem?.type) ===
                  "Fabric Material") && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Material*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={FurnitingMaterial}
                    name="material"
                    inputFor="material"
                    register={register}
                    error={errors.material}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product Material"
                    onChange={handleMaterialChange}
                  />
                </Flex>
              )}
              {(primaryValue || props.productdataitem?.category) ===
                "furniture" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Material*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={FurnitureMaterial}
                    name="material"
                    inputFor="material"
                    register={register}
                    error={errors.material}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product Material"
                    onChange={handleMaterialChange}
                  />
                </Flex>
              )}
              {(primaryValue || props.productdataitem?.category) === "tile" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Material*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={TileMaterial}
                    name="material"
                    inputFor="material"
                    register={register}
                    error={errors.material}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product Material"
                    onChange={handleMaterialChange}
                  />
                </Flex>
              )}
              {(primaryValue || props.productdataitem?.category) === "tile" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Texture*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={Texture}
                    name="texture"
                    inputFor="texture"
                    register={register}
                    error={errors.texture}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product texture"
                    onChange={handleTextureChange}
                  />
                </Flex>
              )}
              {(primaryValue || props.productdataitem?.category) ===
                "veneer" && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Choose Texture*
                  </Text>

                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={Texture}
                    name="texture"
                    inputFor="texture"
                    register={register}
                    error={errors.texture}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product texture"
                    onChange={handleTextureChange}
                  />
                </Flex>
              )}
              <Flex>
                <Text mt={"auto"} mb={"auto"} width={"250px"}>
                  Product Images*
                  <br />
                  (Maximum 4)
                </Text>
                <Flex
                  width={"100%"}
                  backgroundColor={"#E8E2DC"}
                  boxShadow={"inner"}
                  padding={4}
                  borderRadius={"10px"}
                >
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={4}
                    maxFileSize={5000000}
                    dataURLKey="data_url"
                    acceptType={["jpg", "png", "jpeg"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      // write your building UI
                      <div>
                        <Button
                          fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                          boxShadow={"lg"}
                          border={"1px dashed black"}
                          height={"100px"}
                          width={"100%"}
                          fontWeight={"bold"}
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Choose a file or Drag it here.
                        </Button>
                        <Text
                          fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                          my={"5px"}
                        >
                          *Upload file not more than 5 MB, in the format . jpeg
                          / .png / .jpg. Try adding a product image with
                          dimensions displayed on its sides to enhance
                          visualization.
                        </Text>
                        {images?.length === 0 && imageEdit?.length === 0 && (
                          <Text
                            fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                            color={"red"}
                          >
                            *This field is required
                          </Text>
                        )}
                        {/* <button onClick={onImageRemoveAll}>
                      Remove all images
                    </button> */}
                        {errors && (
                          <Flex
                            p={2}
                            fontSize={"11px"}
                            backgroundColor={"#FDF5F1"}
                            border={"1px solid red"}
                          >
                            <UnorderedList>
                              <ListItem>
                                Only files with the following extensions are
                                allowed: png,jpg,jpeg.
                              </ListItem>
                              <ListItem>
                                The number of files should not exceed the
                                maximum number.
                              </ListItem>
                              <ListItem>
                                The file size should not exceed 5MB.
                              </ListItem>
                            </UnorderedList>
                          </Flex>
                        )}
                        <Flex>
                          {" "}
                          <SimpleGrid columns={[2, null, 2]}>
                            {imageList.map((image, index) => (
                              <Flex
                                boxShadow={"lg"}
                                borderRadius={"10px"}
                                backgroundColor={"#E1E1E1"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                flexDir={"column"}
                                mt={"10px"}
                                key={index}
                              >
                                <Image
                                  mt={"10px"}
                                  objectFit={"cover"}
                                  borderRadius={"10px"}
                                  boxShadow={"lg"}
                                  src={image.data_url}
                                  alt="images"
                                  height={"70px"}
                                  width="100px"
                                ></Image>
                                <Flex p={2}>
                                  <Button
                                    m={1}
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    <Image width={"20px"} src={Update}></Image>
                                  </Button>
                                  <Button
                                    m={1}
                                    onClick={() => onImageRemove(index)}
                                  >
                                    <Image width={"15px"} src={Delete}></Image>
                                  </Button>
                                </Flex>
                              </Flex>
                            ))}
                          </SimpleGrid>
                          <SimpleGrid columns={[2, null, 2]}>
                            {imageEdit?.map((image, index) => (
                              <Flex
                                boxShadow={"lg"}
                                borderRadius={"10px"}
                                backgroundColor={"#E1E1E1"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                flexDir={"column"}
                                mt={"10px"}
                                key={index}
                              >
                                <Image
                                  mt={"10px"}
                                  objectFit={"cover"}
                                  borderRadius={"10px"}
                                  boxShadow={"lg"}
                                  src={image}
                                  alt="images"
                                  height={"70px"}
                                  width="100px"
                                ></Image>
                                <Flex p={2}>
                                  <Button
                                    m={1}
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    <Image width={"20px"} src={Update}></Image>
                                  </Button>
                                  <Button
                                    m={1}
                                    // onClick={() => editImageDelete(index)}
                                    onClick={() =>
                                      handleproductimagesDelete(index)
                                    }
                                  >
                                    <Image width={"15px"} src={Delete}></Image>
                                  </Button>
                                </Flex>
                              </Flex>
                            ))}
                          </SimpleGrid>
                        </Flex>
                      </div>
                    )}
                  </ImageUploading>
                </Flex>
              </Flex>
              <Link
                mt={"10px"}
                fontSize={{ base: "10px", md: "10px", lg: "14px" }}
                isExternal
                color="teal.500"
                href="https://styhom-s3-demo.s3.ap-south-1.amazonaws.com/dimension-loko+(1).pdf"
              >
                <Text fontWeight={"bold"}>
                  {" "}
                  *IMPORTANT: Please follow the GUIDELINES attatched before
                  entering the dimension.
                </Text>
              </Link>
              <Flex>
                <Text mt={"auto"} mb={"auto"} width={"250px"}>
                  Basic Measurements*
                </Text>
                <Flex w={"100%"} flexDir={"column"}>
                  <FormInput
                    name="measurement"
                    inputFor="measurement"
                    register={register}
                    error={errors.measurement}
                    validator={{
                      required: "This field is required",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                  />
                </Flex>
              </Flex>

              <Flex>
                <Text mt={"auto"} mb={"auto"} width={"250px"}>
                  Product Description*
                </Text>
                <TextArea
                  name="productdesc"
                  inputFor="productdesc"
                  register={register}
                  error={errors.productdesc}
                  validator={{
                    required: "This field is required",
                    pattern: {
                      value: /^[\s\S]{0,500}$/,
                      message: "Exceeds 500 character limit",
                    },
                  }}
                  style={{
                    border: "1px solid #D0D5DD",
                    borderBottom: "1px solid #D0D5DD",
                    maxHeight: "38px",
                    borderRadius: "10px",
                    whiteSpace: "pre-wrap",
                  }}
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                  type="text"
                />
              </Flex>
            </Flex>
            <Flex
              p={4}
              width={{ base: "100%", md: "100%", lg: "50%" }}
              flexDirection={"column"}
            >
              <Flex flexDir={{ base: "column", md: "column", lg: "row" }}>
                <Text
                  mr={{ base: "0px", md: "0px", lg: "13px" }}
                  mt={"auto"}
                  mb={"auto"}
                  width={"230px"}
                >
                  Price (₹)*
                </Text>
                <Flex width={{ base: "100%", md: "100%", lg: "100%" }}>
                  <FormInput
                    name="price"
                    inputFor="price"
                    register={register}
                    error={errors.price}
                    validator={{
                      required: "This field is required",
                      pattern: {
                        value: /^[0-9.]*$/,
                        message: "Not a valid price",
                      },
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                  />

                  <Text
                    mt={"auto"}
                    ml={"10px"}
                    mb={"auto"}
                    // width={{base:"250px",md:"250px",lg:"70px"}}
                  >
                    per*
                  </Text>
                  <Selectform
                    //  width={{base:"100%",md:"100%",lg:"80px"}}

                    options={priceper}
                    name="priceper"
                    inputFor="priceper"
                    register={register}
                    error={errors.priceper}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    onChange={handleStyleChange}
                  />
                </Flex>
              </Flex>

              {((primaryValue || props.productdataitem?.category) ===
                "furniture" ||
                // (primaryValue || props.productdataitem?.category) ===
                //   "wallArt" ||
                (typeValue || props.productdataitem?.type) === "3D Panel" ||
                (typeValue || props.productdataitem?.type) === "Wall Paper" ||
                (typeValue || props.productdataitem?.type) ===
                  "Cladding Stone" ||
                (primaryValue || props.productdataitem?.category) ===
                  "sanitary" ||
                (primaryValue || props.productdataitem?.category) ===
                  "lighting" ||
                (typeValue || props.productdataitem?.type) === "Curtains" ||
                (typeValue || props.productdataitem?.type) === "Cushions" ||
                (typeValue || props.productdataitem?.type) === "Pillows" ||
                (typeValue || props.productdataitem?.type) === "Bed Sheet" ||
                (typeValue || props.productdataitem?.type) === "Bedspread" ||
                (typeValue || props.productdataitem?.type) === "Carpets" ||
                (typeValue || props.productdataitem?.type) ===
                  "Table Runners" ||
                (typeValue || props.productdataitem?.type) === "Quilt" ||
                (typeValue || props.productdataitem?.type) ===
                  "Fabric Material" ||
                (primaryValue || props.productdataitem?.category) === "tile" ||
                // (primaryValue || props.productdataitem?.category) ===
                //   "decoratives")

                (typeValue || props.productdataitem?.type) === "Vases" ||
                (typeValue || props.productdataitem?.type) === "Sculptures" ||
                (typeValue || props.productdataitem?.type) === "Clocks" ||
                (typeValue || props.productdataitem?.type) ===
                  "Wall Decor items" ||
                (typeValue || props.productdataitem?.type) === "Paintings" ||
                (typeValue || props.productdataitem?.type) === "Mirrors" ||
                (typeValue || props.productdataitem?.type) ===
                  "Other Decoratives") && (
                <Flex>
                  <Text mt={"auto"} mb={"auto"} width={"250px"}>
                    Product style*
                  </Text>
                  <Selectform
                    isDisabled={orderComplete ? true : false}
                    options={styles}
                    name="productstyle"
                    inputFor="productstyle"
                    register={register}
                    error={errors.productstyle}
                    validator={{
                      required: "This field is required",
                    }}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      maxHeight: "38px",
                      borderRadius: "10px",
                    }}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    type="text"
                    color="#848484"
                    placeholder="Select your product's style"
                    onChange={handleStyleChange}
                  />
                </Flex>
              )}
              <Flex>
                <Text mt={"auto"} mb={"auto"} width={"250px"}>
                  Colour*
                </Text>

                <CheckboxGroup
                  isDisabled={orderComplete ? true : false}
                  require={true} // Set to true if this field is required
                  label="Colour"
                  inputFor="productcolor"
                  register={register} // Assuming you're using react-hook-form
                  error={errors.productcolor}
                  validator={{
                    required: "This field is required",
                  }}
                  onChange={handleColorChange}
                  options={color}
                  optionTextColor="#000"
                  style={{
                    borderRadius: "0px",
                    paddingLeft: "5px",
                  }}
                  selectedColors={selectedColors}
                />
              </Flex>
              <Flex>
                <Text mt={"auto"} mb={"auto"} width={"250px"}>
                  Product Link
                </Text>
                <FormInput
                  name="productLink"
                  inputFor="productLink"
                  register={register}
                  error={errors.productLink}
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                  type="url"
                />
              </Flex>
              <Flex position="relative">
               
                {(generate || props?.productdataitem) && (
                  <>
                    {" "}
                    {/* ...............3D Model.............. */}
                    {((primaryValue || props.productdataitem?.category) ===
                      "furniture" ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "wallArt" &&
                        (typeValue || props.productdataitem?.type) ===
                          "3D Panel") ||
                      (primaryValue || props.productdataitem?.category) ===
                        "lighting" ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "furnishing" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Curtains") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "furnishing" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Cushions") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "furnishing" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Pillows") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "furnishing" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Bedspread") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "furnishing" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Bed Sheet") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "furnishing" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Carpets") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "furnishing" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Table Runners") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "furnishing" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Quilt") ||
                      (primaryValue || props.productdataitem?.category) ===
                        "sanitary") && (
                      <>
                       {/* Overlay to disable the content */}
                <Flex
               
               width="100%"
                 position="absolute"
                 top={0}
                 left={0}
                 right={0}
                 bottom={0}
                 backgroundColor="rgba(255, 255, 255, 0.8)" // Semi-transparent overlay
                 zIndex={1} // Ensure it appears in front of the Flex content
                 borderRadius={"20px"}
               >
                 <Text
                   mx="auto"
                   my={"auto"}
                   as="b"
                   color={"teal"}
                   fontSize={{ base: "12px", md: "13px", lg: "18px" }}
                   width="200px"
                 >
                   *You can order for 3D Models / Textures soon...
                 </Text>
               </Flex>
                        <Flex
                        width={"100%"}
                          zIndex={-1}
                          flexDir={"column"}
                          boxShadow={"lg"}
                          // ml={"30px"}
                          mt={"60px"}
                          borderRadius={"20px"}
                          p={4}
                          bg={
                            "linear-gradient(180deg, #FFF3E8 0%, rgba(182, 182, 182, 0.60) 100%)"
                          }
                        >
                          <Image
                            ml={"auto"}
                            mr={"auto"}
                            width={"250px"}
                            mt={"-70px"}
                            src={ThreeD}
                          ></Image>
                          <Flex
                            flexDir={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Flex
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <Checkbox
                                colorScheme="green"
                                isDisabled={
                                  orderComplete
                                    ? true
                                    : props?.productdataitem &&
                                      !props?.productdataitem?.generate3d &&
                                      !generate
                                    ? true
                                    : false
                                }
                                defaultChecked={
                                  props?.productdataitem?.generate3d
                                }
                                width={"30px"}
                                ml={5}
                                borderColor={"brand.btnBg"}
                                onChange={handleGenerate3DChange}
                              ></Checkbox>
                              <Text width={"100px"} ml={2}>
                                Generate 3D model
                              </Text>

                              <Flex
                                width={"100px"}
                                justifyContent={"center"}
                                ml={2}
                                boxShadow={"lg"}
                                borderRadius={"5px"}
                                p={2}
                                backgroundColor={"#D3C1C1"}
                              >
                                <Text>
                                  {getPrice(
                                    typeValue,
                                    primaryValue,
                                    styleValue
                                  )}
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Text
                            maxW={"400px"}
                            p={5}
                            mt={"20px"}
                            color={"teal"}
                            fontSize={{ base: "12px", md: "13px", lg: "13px" }}
                          >
                            *If you would like to generate a 3D model of your
                            product, please check the available options (Not
                            applicable for Kitchen accessories and Decoratives).
                          </Text>
                        </Flex>
                      </>
                    )}
                    {/* ............Texture............... */}
                    {(((primaryValue || props.productdataitem?.category) ===
                      "wallArt" &&
                      (typeValue || props.productdataitem?.type) ===
                        "Wall Paper") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "wallArt" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Cladding Stone") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "wallArt" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Texture Paint") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "decoratives" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Paintings") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "furnishing" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Fabric Material") ||
                      ((primaryValue || props.productdataitem?.category) ===
                        "furnishing" &&
                        (typeValue || props.productdataitem?.type) ===
                          "Leather Material") ||
                      (primaryValue || props.productdataitem?.category) ===
                        "tile" ||
                      (primaryValue || props.productdataitem?.category) ===
                        "veneer") && (
                      <>
                       {/* Overlay to disable the content */}
                <Flex
               
               width="100%"
                 position="absolute"
                 top={0}
                 left={0}
                 right={0}
                 bottom={0}
                 backgroundColor="rgba(255, 255, 255, 0.8)" // Semi-transparent overlay
                 zIndex={1} // Ensure it appears in front of the Flex content
                 borderRadius={"20px"}
               >
                 <Text
                   mx="auto"
                   my={"auto"}
                   as="b"
                   color={"teal"}
                   fontSize={{ base: "12px", md: "13px", lg: "18px" }}
                   width="200px"
                 >
                   *You can order for 3D Models / Textures soon...
                 </Text>
               </Flex>
                        <Flex
                        width={"100%"}
                          zIndex={-1}
                          flexDir={"column"}
                          boxShadow={"lg"}
                          // ml={"30px"}
                          mt={"60px"}
                          borderRadius={"20px"}
                          p={4}
                          bg={
                            "linear-gradient(180deg, #FFF3E8 0%, rgba(182, 182, 182, 0.60) 100%)"
                          }
                        >
                          <Image
                            ml={"auto"}
                            mr={"auto"}
                            width={"250px"}
                            mt={"-70px"}
                            src={ThreeD}
                          ></Image>
                          <Flex
                            flexDir={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Flex
                              justifyContent={"center"}
                              mt={"10px"}
                              alignItems={"center"}
                            >
                              <Checkbox
                                colorScheme="green"
                                isDisabled={orderComplete ? true : false}
                                defaultChecked={
                                  props?.productdataitem?.generatetexture
                                }
                                width={"30px"}
                                ml={5}
                                borderColor={"brand.btnBg"}
                                onChange={handleGenerateTextureChange}
                              ></Checkbox>
                              <Text width={"100px"} ml={2}>
                                Generate Texture
                              </Text>

                              <Flex
                                width={"100px"}
                                justifyContent={"center"}
                                ml={2}
                                boxShadow={"lg"}
                                borderRadius={"5px"}
                                p={2}
                                backgroundColor={"#D3C1C1"}
                              >
                                <Text>{FormatIndianRupees(120)}</Text>
                              </Flex>
                            </Flex>
                          </Flex>
                          <Text
                            maxW={"400px"}
                            p={5}
                            mt={"20px"}
                            color={"teal"}
                            fontSize={{ base: "12px", md: "13px", lg: "13px" }}
                          >
                            *If you would like to generate a 3D model of your
                            product, please check the available options (Not
                            applicable for Kitchen accessories and Decoratives).
                          </Text>
                        </Flex>
                      </>
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex width={"100%"} alignItems={"end"} flexDir={"column"}>
            {productLength >= productLimit && (
              <Text color={"red"} fontSize={14}>
                {productLimit === 45
                  ? "*Upgrade your plan to add more"
                  : productLimit === 100
                  ? "*You have reached the maximum product limit of 100."
                  : ""}
              </Text>
            )}
            <CommonButton
              isDisabled={productLength < productLimit ? false : true}
              display={props.productdataitem ? "none" : "flex"}
              isLoading={addLoading}
              type={"submit"}
              height="34px"
              width="200px"
              // mt="20px"
              fontSize="14px"
              btn={"Add More"}
              onClick={() => setSubmit(false)}
            />
            <CommonButton
              isLoading={submitLoading}
              type={"submit"}
              height="34px"
              width="200px"
              mt="20px"
              fontSize="14px"
              btn={props.productdataitem ? "Save" : "Save And Checkout"}
              onClick={() => setSubmit(true)}
            />
          </Flex>
        </Stack>
      </Flex>
    </Flex>
  );
}

export default AddProductForm;
