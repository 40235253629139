import { Flex, Image, Text } from "@chakra-ui/react";
import DiscountOfferCard from "../../../Components/shared/DiscountOfferCard";
import DiscountWow from "../../../Assets/Images/Discount/DiscountWow.webp";
import { useEffect, useState } from "react";
import { getStores } from "../../../Functions/GetApiCalls";

const DiscountSection = () => {
  const [offerImages, setOfferImages] = useState([]);
  const [storesData, setStoresData] = useState([]);

  // Fetch stores data on component mount
  useEffect(() => {
    const fetchData = async () => {
      await getStores(setStoresData);
    };
    fetchData(); // Call the async function
  }, []);

  // Filter offers based on the current date and set offer images
  useEffect(() => {
    const filteredData = storesData
      .flatMap((item) => {
        return item?.offer?.filter((offer) => {
          const currentDate = new Date();
          return (
            new Date(offer.From) <= currentDate &&
            currentDate <= new Date(offer.To)
          );
        });
      })
      .map((offer) => offer.offerImage);

    setOfferImages(filteredData);
  }, [storesData]);

  return (
    <Flex>
        {offerImages.length >0 && (  
      <Flex
      width={"100%"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        mb={{ base: 8 }}
      >
        {/* Discount header */}
        <Flex backgroundColor={"beige"}
        borderRadius={"5px"}
        boxShadow={"lg"} width={"90%"}  alignItems={"center"} mb={"10px"}>
          <Image src={DiscountWow} width={"70px"} zIndex={0} />
          <Text
            fontWeight={"bold"}
            fontSize={"15px"}
            ml={"5px"}
            zIndex={1}
            color={"yellow.600"}
          >
            Lokolumo Kin Deals Corner
          </Text>
        </Flex>

        {/* Discount offer card carousel */}
        <Flex>
        <Flex  minHeight={"300px"}>
          <DiscountOfferCard
            width={{ base: "350px", md: "550px", lg: "400px" }}
            carouselImages={offerImages}
          />
          
        </Flex>
        </Flex>
      </Flex>
        )}
    </Flex>
  );
};

export default DiscountSection;
