import {
  Text,
  Flex,
  Divider,
  Box,
  Stack,
  SimpleGrid,
  Checkbox,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CheckboxGroup } from "@chakra-ui/react";
import CommonButton from "../../../Components/shared/CommonButton";
import { furnitureFilterObjects } from "../../../Components/shared/Filter/FilterObjects/FurnitureFilterObjects.js";
import { wallartFilterObjects } from "../../../Components/shared/Filter/FilterObjects/WallartFilterObjects.js";
import { lightingFilterObjects } from "../../../Components/shared/Filter/FilterObjects/LightingFilterObjects.js";
import { decorFilterObjects } from "../../../Components/shared/Filter/FilterObjects/DecorFilterObjects.js";
import { furnishingFilterObjects } from "../../../Components/shared/Filter/FilterObjects/FurnishingFilterObjects.js";
import { tileFilterObjects } from "../../../Components/shared/Filter/FilterObjects/TileFilterObjects";
import { bathroomFilterObjects } from "../../../Components/shared/Filter/FilterObjects/BathroomFilterObjects.js";
import { putStoresDetails } from "../../../Functions/PutApiCalls";
import { useGetShopData } from "../../../Functions/queries.js";
function ShopCatalogForm() {
  const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);
  const [veneer, setVeneer] = useState(false);
  const [kitchen, setKitchen] = useState(false);
  const [furniture, setFurniture] = useState(false);
  const [wallArt, setWallArt] = useState(false);
  const [lighting, setLighting] = useState(false);
  const [decorative, setDecorative] = useState(false);
  const [furnishing, setFurnishing] = useState(false);
  const [tile, setTile] = useState(false);
  const [sanitary, setSanitary] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { shop } = useGetShopData();
  useEffect(() => {
    const category = shop && shop[0]?.category;
    const categoryStateMap = {
      furniture: setFurniture,
      veneer: setVeneer,
      kitchen: setKitchen,
      wallArt: setWallArt,
      lighting: setLighting,
      decoratives: setDecorative,
      furnishing: setFurnishing,
      tile: setTile,
      sanitary: setSanitary,
    };

    category?.forEach((cat) => {
      const categoryName = cat?.name;
      const setStateFunction = categoryStateMap[categoryName];

      if (setStateFunction) {
        setStateFunction(true);
      }
    });
  }, [shop]);
  const handleSaveButtonClick = async () => {
    const noCheckboxesSelected = !(
      veneer ||
      tile ||
      kitchen ||
      furnishing ||
      furniture ||
      wallArt ||
      decorative ||
      sanitary ||
      lighting
    );

    setIsErrorDisplayed(noCheckboxesSelected);
    if (!noCheckboxesSelected) {
      let trueCategories = [];
      const categoryList = [
        { condition: veneer, name: "veneer" },
        { condition: kitchen, name: "kitchen" },
        { condition: furniture, name: "furniture" },
        { condition: wallArt, name: "wallArt" },
        { condition: decorative, name: "decoratives" },
        { condition: furnishing, name: "furnishing" },
        { condition: tile, name: "tile" },
        { condition: sanitary, name: "sanitary" },
        { condition: lighting, name: "lighting" },
      ];
      trueCategories = categoryList
        .filter((category) => category.condition)
        .map((category) => ({ name: category.name }));
      setLoading(true);
      let details = { category: trueCategories };
      await putStoresDetails(details, toast);
      setLoading(false);
    }
  };
  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Catalogue
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex
          fontSize={{ base: "15px", md: "15px", lg: "16px" }}
          justifyContent={"center"}
          my={"5%"}
          width={"100%"}
        >
          <Stack mx={{ base: "5px", md: "10px", lg: "10px" }}>
            <Text>Check the items you have</Text>

            <Flex
              flexDir={"row"}
              backgroundColor={"#E7DED5"}
              boxShadow={"inner"}
              borderRadius={"10px"}
              p={5}
            >
              <SimpleGrid
                fontSize={{ base: "14px" }}
                columns={[2, null, 4]}
                spacing={{ base: "0px", md: "20px", lg: "20px" }}
              >
                <Flex flexDir={"column"}>
                  <Checkbox
                    value={"furniture"}
                    isChecked={furniture}
                    onChange={() => setFurniture(!furniture)}
                    mt={"20px"}
                    size="sm"
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                  >
                    FURNITURE & STORAGE
                  </Checkbox>
                  <Box px={10}>
                    {furnitureFilterObjects.map((furnitureitem, index1) => (
                      <Text>{furnitureitem.name}</Text>
                    ))}
                  </Box>
                  <Checkbox
                    value={"veneer"}
                    isChecked={veneer}
                    onChange={() => setVeneer(!veneer)}
                    mt={"20px"}
                    size="sm"
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                  >
                    VENEER / LAMINATE
                  </Checkbox>
                  <Checkbox
                    value={"kitchen"}
                    isChecked={kitchen}
                    onChange={() => setKitchen(!kitchen)}
                    size="sm"
                    colorScheme="orange"
                    borderColor={"brand.btnBg"}
                  >
                    DECORATIVE KITCHEN ACCESSORIES
                  </Checkbox>
                </Flex>

                <Flex flexDir={"column"}>
                  <Flex pl={5} flexDir={"column"}>
                    <Checkbox
                      value={"wallArt"}
                      isChecked={wallArt}
                      onChange={() => setWallArt(!wallArt)}
                      mt={"20px"}
                      size="sm"
                      colorScheme="orange"
                      borderColor={"brand.btnBg"}
                    >
                      WALL ART
                    </Checkbox>
                    {wallartFilterObjects.map((wallartitem, index2) => (
                      <Box px={6}>
                        <Text>{wallartitem.name}</Text>
                      </Box>
                    ))}
                  </Flex>

                  <Flex mt={"10px"} pl={5} flexDir={"column"}>
                    <Checkbox
                      value={"lighting"}
                      isChecked={lighting}
                      onChange={() => setLighting(!lighting)}
                      mt={"20px"}
                      size="sm"
                      colorScheme="orange"
                      borderColor={"brand.btnBg"}
                    >
                      LIGHTING & FAN
                    </Checkbox>
                    {lightingFilterObjects.map((lightingitem, index3) => (
                      <Box px={6}>
                        <Text>{lightingitem.name}</Text>
                      </Box>
                    ))}
                  </Flex>
                </Flex>

                <Flex flexDir={"column"}>
                  <Flex pl={5} flexDir={"column"}>
                    <Checkbox
                      value={"decorative"}
                      isChecked={decorative}
                      onChange={() => setDecorative(!decorative)}
                      mt={"20px"}
                      size="sm"
                      colorScheme="orange"
                      borderColor={"brand.btnBg"}
                    >
                      DECORATIVES
                    </Checkbox>
                    {decorFilterObjects.map((decoritem, index4) => (
                      <Box px={6}>
                        <Text>{decoritem.name}</Text>
                      </Box>
                    ))}
                  </Flex>

                  <Flex mt={"10px"} pl={5} flexDir={"column"}>
                    <Checkbox
                      value={"furnishing"}
                      isChecked={furnishing}
                      onChange={() => setFurnishing(!furnishing)}
                      mt={"20px"}
                      size="sm"
                      colorScheme="orange"
                      borderColor={"brand.btnBg"}
                    >
                      FURNISHING
                    </Checkbox>
                    {furnishingFilterObjects.map((furitem, index5) => (
                      <Box px={6}>
                        <Text>{furitem.name}</Text>
                      </Box>
                    ))}
                  </Flex>
                </Flex>

                <Flex flexDir={"column"}>
                  <Flex pl={5} flexDir={"column"}>
                    <Checkbox
                      value={"tile"}
                      isChecked={tile}
                      onChange={() => setTile(!tile)}
                      mt={"20px"}
                      size="sm"
                      colorScheme="orange"
                      borderColor={"brand.btnBg"}
                    >
                      TILE
                    </Checkbox>
                    {tileFilterObjects.map((tileitem, index6) => (
                      <Box px={6}>
                        <Text>{tileitem.name}</Text>
                      </Box>
                    ))}
                  </Flex>

                  <Flex mt={"10px"} pl={5} flexDir={"column"}>
                    <Checkbox
                      value={"sanitary"}
                      isChecked={sanitary}
                      onChange={() => setSanitary(!sanitary)}
                      mt={"20px"}
                      size="sm"
                      colorScheme="orange"
                      borderColor={"brand.btnBg"}
                    >
                      SANITARY
                    </Checkbox>
                    {bathroomFilterObjects.map((bathroomitem, index7) => (
                      <Box px={6}>
                        <Text>{bathroomitem.name}</Text>
                      </Box>
                    ))}
                  </Flex>
                </Flex>
              </SimpleGrid>
            </Flex>
            {isErrorDisplayed && (
              <Text
                fontSize={{ base: "15px", md: "15px", lg: "16px" }}
                color="red"
                mt={2}
              >
                *Please check at least one item.
              </Text>
            )}
            <Flex width={"100%"} justifyContent={"end"}>
              <CommonButton
                isLoading={loading}
                onClick={handleSaveButtonClick}
                height="34px"
                width="100px"
                mt="20px"
                fontSize="14px"
                btn={"Save"}
              />
            </Flex>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ShopCatalogForm;
