import {
  Container,
  Flex,
  Grid,
  Link,
  Image,
  Button,
  Input,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../../Components/shared/Header";
import Search from "../../../Assets/Images/search.png";
import Close from "../../../Assets/Images/close.png";
import ReactPaginate from "react-paginate";
import Footer from "../../../Components/Footer";
import StoreHeader from "../../../Assets/Images/Headers/Storeheader.webp";
import StoreInfoCard from "./StoreInfoCard";
import CommonFilter from "../../../Components/shared/Filter/CommonFilter";
import ItemCollection from "./ItemCollection/ItemCollection";
import MobileCommonFilter from "../../../Components/shared/Filter/MobileCommonFilter";
import { Products, carouselImages } from "./DummyStoreData.js";
import SingleProductScreen from "./SingleProduct/SingleProductScreen";
import DiscountOfferCard from "../../../Components/shared/DiscountOfferCard";
import ShopGallery from "../ShopGallery/ShopGallery";
import Shop from "../../../Assets/DummyCollections/Shop.png";
import { gallery } from "../ShopGallery/DummyGallery";
import { State } from "../../../Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductsFiltering } from "../FilterLogics/StoresFilter";
import Breadcrumbs from "../../../Components/shared/Breadcrumbs.js";
import ShopVideoGallery from "../ShopGallery/ShopVideoGallery.js";

// TODO if no offercard a space is left in designchange it
function SingleStoresScreen() {
  // serach
  const [query, setQuery] = useState("");
  const handleClear = () => {
    setQuery("");
  };

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state?.item;
  const [collection, setCollection] = useState([]);
  const [subCollection, setSubCollection] = useState([]);
  const [style, setStyle] = useState([]);
  const [priceValue, setPriceValue] = useState([0, 100000]);
  const { product, setProduct } = State();
  const [filteredProduct, setFilteredProduct] = useState([]); // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const ProductsPerPage = 9;
  const pageCount = Math.ceil(
    collection.length === 0 &&
      style.length === 0 &&
      priceValue.every((val, index) => val === [0, 100000][index])
      ? state?.product.length / ProductsPerPage
      : filteredProduct.length / ProductsPerPage
  );
  const pagesVisited = pageNumber * ProductsPerPage;
  const displayProducts =
    collection.length === 0 &&
    style.length === 0 &&
    priceValue.every((val, index) => val === [0, 100000][index])
      ? state?.product
          .filter(
            (store) =>
              store.productname &&
              store.productname.toLowerCase().includes(query.toLowerCase())
          )
          ?.slice(pagesVisited, pagesVisited + ProductsPerPage)
          .map((item) => {
            return (
              <Flex
                onClick={() => {
                  navigate(
                    `${window.location.pathname}/${item._id}#product-section`,
                    {
                      state: { item: state, product: item },
                    }
                  );
                }}
              >
                <ItemCollection
                  image={item.productimage[0]}
                  name={item.productname}
                  price={item.price}
                />
              </Flex>
            );
          })
      : filteredProduct
          .filter(
            (store) =>
              store.productname &&
              store.productname.toLowerCase().includes(query.toLowerCase())
          )
          ?.slice(pagesVisited, pagesVisited + ProductsPerPage)
          .map((item) => {
            return (
              <Flex
                onClick={() => {
                  setProduct(item.id);
                  navigate(
                    `${window.location.pathname}/${item.id}#product-section`,
                    {
                      state: { item: state, product: item },
                    }
                  );
                }}
              >
                <ItemCollection
                  image={item.productimage[0]}
                  name={item.productname}
                  price={item.price}
                />
              </Flex>
            );
          });
  const offerImages = [];
  state?.offer.map((item) => {
    const toDate = new Date(item?.To);
    const fromDate = new Date(item?.From);
    const currentDate = new Date();

    if (toDate >= currentDate && fromDate <= currentDate) {
      offerImages.push(item?.offerImage);
    }
  });
  const galleryImages = [];
  state?.shopimages.map((item) => {
    galleryImages.push({ src: item });
  });
  useEffect(() => {
    ProductsFiltering(
      collection,
      state?.product,
      setFilteredProduct,
      style,
      subCollection,
      priceValue
    );
  }, [collection, subCollection, style, priceValue]);

  // Set initial value for filteredProduct
  useEffect(() => {
    setFilteredProduct(state?.product);
  }, [state?.product]);
  // pagenumber
  useEffect(() => {
    if (displayProducts.length === 0) {
      setPageNumber(0);
    }
  }, [displayProducts]);

  return (
    <>
      <MobileCommonFilter
        MobPricefilter={1}
        priceValue={priceValue}
        setPriceValue={setPriceValue}
        MobStyleFilter={1}
        style={style}
        setStyle={setStyle}
        MobProductfilter={1}
        collection={collection}
        subCollection={subCollection}
        setCollection={setCollection}
        setSubCollection={setSubCollection}
      />
      <Flex flexDir={"column"} width={"100%"}>
        <Flex ml={{ base: "0%", md: "0%", lg: "2%" }}>
          <Breadcrumbs />
         
        </Flex>
           {/* Search */}

           <Flex my={"10px"} >
              {" "}
              <Flex mx={"auto"}>
                <Input
                  backgroundColor={"white"}
                  height={{ base: "34px", md: "40px", lg: "40px" }}
                  // w={{ base: "210px", md: "300px", lg: "300px" }}
                  color={"black"}
                  type="text"
                  placeholder="Search product names..."
                  value={query}
                  className="newsearch"
                  onChange={(e) => setQuery(e.target.value)}
                ></Input>
                {!query && (
                  <Button
                    height={{ base: "34px", md: "40px", lg: "40px" }}
                    backgroundColor={"#D4FFDE"}
                    _hover={{ backgroundColor: "#EEEFBB" }}
                    onClick={handleClear}
                    className="closeButton"
                  >
                    <Image
                      objectFit={"contain"}
                      width={"20px"}
                      src={Search}
                    ></Image>
                  </Button>
                )}
                {query && (
                  <Button
                    height={{ base: "34px", md: "40px", lg: "40px" }}
                    _hover={{ backgroundColor: "#EEEFBB" }}
                    backgroundColor={"#D4FFDE"}
                    onClick={handleClear}
                    className="closeButton"
                  >
                    <Image
                      objectFit={"contain"}
                      width={"20px"}
                      src={Close}
                    ></Image>
                  </Button>
                )}
              </Flex>
            </Flex>

            {/* Search */}
        {offerImages.length > 0 && (
          <Flex
            mb={"10px"}
            width={"100%"}
            backgroundColor={"#FDF1E2"}
            py={{ base: 1, md: 2, lg: 5 }}
            borderRadius={"10px"}
            boxShadow={"inner"}
            mx={"auto"}
            // display={{base:"flex",md:"none",lg:"none"}}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <DiscountOfferCard
              width={{ base: "100%", md: "100%", lg: "90%" }}
              carouselImages={offerImages}
            />
          </Flex>
        )}
        <Flex
          flexDir={{ base: "column-reverse", lg: "row" }}
          alignItems={{ base: "center", lg: "flex-start" }}
        >
          <Flex flexDir={"column"} maxWidth={"340px"} alignItems={"center"}>
            <CommonFilter
              Pricefilter={1}
              priceValue={priceValue}
              setPriceValue={setPriceValue}
              StyleFilter={1}
              style={style}
              setStyle={setStyle}
              collection={collection}
              subCollection={subCollection}
              setCollection={setCollection}
              setSubCollection={setSubCollection}
            />
          

            <ShopGallery
              shopImage={state.shopprofileimage}
              gallery={galleryImages}
            />
            {state.subscription.plan === "premium" && (
              <ShopVideoGallery ShopVideo={state.video} />
            )}
          </Flex>

          <Flex
            flexDir={"column"}
            mt={{ base: "10px", md: "30px", lg: "70px" }}
            px={{ base: "0", md: "0", lg: "30px" }}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderRadius={"10px"}
            // minW={{ xl: "700px" }}
          >
            {displayProducts?.length !== 0 ? (
              <>
                {" "}
                <Grid
                  templateColumns={[
                    "repeat(1, 1fr)",
                    "repeat(2, 1fr)",
                    "repeat(2, 1fr)",
                    "repeat(3, 1fr)",
                  ]}
                  gap={8}
                >
                  {displayProducts}
                </Grid>
                <Flex
                  pt={"25px"}
                  alignItems={"center"}
                  justifyContent="center"
                  boxShadow={"lg"}
                  borderRadius="5px"
                  m="20px"
                  width="fit-content"
                  bg={"whiteAlpha.900"}
                >
                  <ReactPaginate
                    marginPagesDisplayed={0}
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    forcePage={pageNumber}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </Flex>
              </>
            ) : (
              <>No results found</>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default SingleStoresScreen;
