import React from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    window.scrollTo(0, 0);
    navigate(-1); // Navigate back by one step in the history
  };

  return (
    <Button 
    _hover={{backgroundColor:"#BDA68C"}}
    width={"fit-content"}
    px={"20px"}
    py={"5px"}
    borderRadius={"5px"}
    boxShadow={"lg"}
fontWeight={"bold"}
fontSize={{base:"14px",md:"14px",lg:"14px"}}
    onClick={goBack} backgroundColor={"#EEDBC6"}>
      Back
    </Button>
  );
};

export default BackButton;
