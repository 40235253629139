// * config for adding auth token in headers

import { getItemFromLocalStorage } from "../utils/local-storage";

// ?remember to change token
export const config = () => {
  // TODO change dummy token
  let token = getItemFromLocalStorage("styhomtoken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return config;
};
