import { Container, Flex, Grid } from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../../../Components/shared/Header";
import DesignersHeader from "../../../Assets/Images/Headers/DesignersHeader.webp";
import DesignersHeaderMob from "../../../Assets/Images/Headers/DesignersHeaderMob.webp";
import DesignerHeaderCard from "./DesignerHeaderCard";
import HeadingLine from "../../../Components/shared/HeadingLine";
import ReactPaginate from "react-paginate";
import { designs } from "./DummyDesigns";
import DesignsCollection from "./DesignsCollection";
import Footer from "../../../Components/Footer";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../../Components/shared/Breadcrumbs";
import PortfolioVideo from "./PortfolioVideo";
import Design from "../../../Assets/DummyCollections/Shop.png";
function SingleDesigner() {
  const location = useLocation();
  const state = location.state;
  const [pageNumber, setPageNumber] = useState(0);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const designsPerPage = 6;
  const pageCount = Math.ceil(state?.projects.length / designsPerPage);
  const pagesVisited = pageNumber * designsPerPage;
  const displayDesigns =
    state?.projects.length != 0 ? (
      state?.projects
        .slice(pagesVisited, pagesVisited + designsPerPage)
        .map((item) => {
          return (
            <DesignsCollection
            image={item.projectImage ? item.projectImage : 0}
              designName={item.projectName}
              // subscription={item.subscription.plan}
            />
          );
        })
    ) : (
      <>No Projects Added</>
    );
  return (
    <Flex
      flexDir={"column"}
      bg={
        "linear-gradient(169deg, rgba(255, 219, 186, 0.16) 27.09%, rgba(255, 201, 153, 0.16) 46.19%, rgba(255, 255, 255, 0.00) 91.86%)"
      }
    >
      <Container id="singledesigner" maxW="2000px" width={"100vw"} p={0}>
        <Header image={DesignersHeader} imageMob={DesignersHeaderMob} />
        <Container maxW="1600px" width={"100vw"}>
          <Flex mt={{ base: "-200px", md: "-300px" }}>
            <DesignerHeaderCard
              DesignerSubscription={state.subscription}
              DesignerImage={state.designerPhoto}
              DesignerAddress={state.designeraddress}
              DesignerName={state.name}
              DesignerP={state.profession}
              DesignerDescription={state.about}
              Designernumber={state.phone}
              Designersubnumber={state.phoneSub}
              DesignerMail={state.email}
              facebooklink={state.facebooklink}
              instalink={state.instagramlink}
              linkedInlink={state.linkedinlink}
              artistSpecialization={state.artistSpecialization}
            />
          </Flex>
          <Flex ml={{ base: "2%", md: "6%", lg: "15%" }}>
            <Breadcrumbs />
          </Flex>

          {state.videos &&
            state.subscription &&
            new Date(state.subscription.to) > new Date() &&
             

            (
              <Flex flexDir={"column"}>
                <HeadingLine title={"My Portfolio Reel"} />

                <PortfolioVideo DesignerVideo={state.videos} />
              </Flex>
            )}
          <HeadingLine title={"My Projects"} />
          <Flex
            py={4}
            px={{ base: 0, md: 4, lg: 0, xl: 10, "2xl": 20 }}
            justifyContent={{ base: "center", md: "center", lg: "auto" }}
          >
            <Flex
              bg={
                "linear-gradient(180deg, rgba(241, 219, 198, 0.40) 0%, rgba(255, 255, 255, 0.00) 97.55%)"
              }
              flexDir={"column"}
              m={{ base: 0, md: 2, lg: 0 }}
              mx={{ base: 0, md: 2, lg: 0, xl: 4, "2xl": 8 }}
              p={{ base: 8, md: 8, lg: 8, xl: 10 }}
              alignItems={"center"}
              justifyContent={"space-between"}
              borderRadius={"10px"}
              boxShadow={"14px 4px 47px 0px rgba(0, 0, 0, 0.16)"}
            >
              <Grid
                templateColumns={[
                  "repeat(1, 1fr)",
                  "repeat(2, 1fr)",
                  "repeat(2, 1fr)",
                  "repeat(3, 1fr)",
                ]}
                gap={8}
              >
                {displayDesigns}
              </Grid>
              <Flex
                pt={"25px"}
                alignItems={"center"}
                justifyContent="center"
                boxShadow={"lg"}
                borderRadius="5px"
                m="20px"
                width="fit-content"
                bg={"whiteAlpha.900"}
              >
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </Flex>
            </Flex>
          </Flex>
        </Container>
        <Footer />
      </Container>
    </Flex>
  );
}

export default SingleDesigner;
