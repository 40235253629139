import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  VisuallyHidden,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { useRef, useState } from 'react';

const VideoInput = ({
  children,
  isRequired,
  labelProps,
  onChange,
  ...rest
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState([]);
  const fileInputRef = useRef(null);
  const handleChooseButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    if (onChange) {
      const files = event.target.files;
      setSelectedFiles([...selectedFiles, ...files]);
      const fileNames = Array.from(files).map((file) => file.name);
      setSelectedFileName([...selectedFileName, ...fileNames]);
      onChange([...selectedFiles, ...files]);
    }
  };

  const handleFileRemove = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    const updatedFileNames = [...selectedFileName];
    updatedFileNames.splice(index, 1);
    setSelectedFileName(updatedFileNames);
    if (onChange) {
      onChange(updatedFiles);
    }
  };
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel textTransform="capitalize" color="#000000" {...labelProps}>
        {children}
      </FormLabel>
      <VisuallyHidden>
        <Input
          type="file"
          onChange={handleFileChange}
          ref={fileInputRef}
          {...rest}
          multiple
        />
      </VisuallyHidden>
      <Flex>
        <Flex
          bg="white"
          w="full"
          borderRadius="0.8rem"
          borderRightRadius={'0'}
          _placeholder={{ opacity: 0.9, color: '#3D6B4C' }}
          pr={2}
          flexWrap="wrap"
          alignItems="center">
          {selectedFileName.map((file, index) => (
            <Flex key={index} alignItems="center" m={1} w="100%">
              <Input readOnly value={file} {...rest} />
              <Button
                onClick={() => handleFileRemove(index)}
                ml={2}
                variant="outline"
                colorScheme="red">
                X
              </Button>
            </Flex>
          ))}
        </Flex>
        <Button
          as="label"
          cursor="pointer"
          bg="#D2C9C9"
          color="brand.dark"
          borderRadius="0 0.8rem 0.8rem 0"
          onClick={handleChooseButtonClick}>
          Choose
        </Button>
      </Flex>
    </FormControl>
  );
};

VideoInput.propTypes = {
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  labelProps: PropTypes.object,
  onChange: PropTypes.func,
};

export default VideoInput;
