export const shopnavbar = [
 
  {
    navname: "Store Details",
    navnum: 1,
  },
  {
    navname: "Store Images",
    navnum: 2,
  },
  {
    navname: "Offer Posters",
    navnum: 3,
  },
  {
    navname: "Catalogue",
    navnum: 4,
  },
  {
    navname: "Add Videos",
    navnum: 8,
  },
  {
    navname: "View Products",
    navnum: 5,
  },
  {
    navname: "Change Password",
    navnum: 7,
  },
  {
    navname: "Orders",
    navnum: 9,
  },
 
];
