import { Flex, Text, Container, SimpleGrid } from "@chakra-ui/layout";
import { Box, Spinner } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState, useEffect } from "react";
import { Products } from "../Screens/Stores/SingleStores/DummyStoreData";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import ItemCollection from "../Screens/Stores/SingleStores/ItemCollection/ItemCollection";
import { useLocation, useNavigate } from "react-router-dom";
import { getRelatedProducts } from "../Functions/GetApiCalls";
function RelatedProducts(props) {
  const [relatedProducts, setRelatedProducts] = useState();

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const productType = location?.state?.product.type;
  const productId = location?.state?.product._id;
  const fetchData = async (id) => {
    setLoading(true);
    const relatedProduct = await getRelatedProducts(
      setRelatedProducts,
      productType,
      id || productId
    );
    setLoading(false);
    return relatedProduct;
  };
  useEffect(() => {
    fetchData();
  }, [productType]);
  return (
    <Flex   width="100%" flexDir={"column"}>
         {relatedProducts && relatedProducts.length > 0 && (
      <Flex
        mb={"40px"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
      >
        <Flex maxW={{ base: "50px", md: "90px" }}>
          {" "}
          <hr
            style={{
              width: "150px",
              border: "2px solid #E3B78B",
            }}
          />
        </Flex>
        <Text fontSize={{ base: "24px", md: "30px", lg: "34px" }} p={3}>
          Similar Products
        </Text>
        <Flex maxW={{ base: "50px", md: "90px" }}>
          {" "}
          <hr
            style={{
              width: "150px",
              border: "2px solid #E3B78B",
            }}
          />
        </Flex>
      </Flex>
         )}
      <Flex width={"100%"} justifyContent={"center"} alignItems={"center"}>
        {loading ? (
          <Spinner />
        ) : (
          <Flex  width={"100%"}   >
              {relatedProducts && relatedProducts.length > 0 && (
          <Flex   mx={"auto"} height={"400px"} width={{base:"100%",md:"100%",lg:"100%"}}>
            <Swiper
              className="related-products"
              // slidesPerView={4}
              navigation={true}
              pagination={true}
              mousewheel={false}
              keyboard={true}
              breakpoints={{
                1629: {
                  slidesPerView: 4,
                },
                1340: {
                  slidesPerView: 4,
                },

                1051: {
                  slidesPerView: 2,
                },
                833: {
                  slidesPerView: 2,
                },
                390: {
                  slidesPerView: 1,
                },
                375: {
                  slidesPerView: 1,
                },
              }}
              modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            >
              {relatedProducts?.map((item, index) => (
                <SwiperSlide className="slidebox" key={index}>
                  <Flex
                
                 
                    mt={"40px"}
                 
                    cursor={"pointer"}
                    onClick={async () => {
                      const newRelatedProducts = await fetchData(
                        item?.product?._id
                      );
                      setRelatedProducts(newRelatedProducts);
                     
                      navigate(
                        `/stores/${item._id}/${item.product.productname}#product-section`,
                        { state: { item: item, product: item.product } }
                      );
                    }}
                  >
                    <ItemCollection
                      image={item.product?.productimage[0]}
                      name={item.product?.productname}
                      price={item.product?.price}
                    />
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>
          </Flex>
              )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default RelatedProducts;
