export const furniture = [
    {
        shopName: "ABC",
        place:"KK",
    },
    {
        shopName: "ABC",
        place:"Ernakulam",
    },
    {
        shopName: "ABC",
        place:"Ernakulam",
    },
    {
        shopName: "ABC",
        place:"Ernakulam",
    },
    {
        shopName: "ABC",
        place:"Ernakulam",
    },
    {
        shopName: "ABC",
        place:"Ernakulam",
    },
    {
        shopName: "ABC",
        place:"Ernakulam",
    },
    {
        shopName: "ABC",
        place:"Ernakulam",
    },
    {
        shopName: "ABC",
        place:"Ernakulam",
    },
    {
        shopName: "ABC",
        place:"Ernakulam",
    },
    {
        shopName: "ABC",
        place:"Ernakulam",
    },
    {
        shopName: "ABC",
        place:"LL",
    },

]