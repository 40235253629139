import Furniture from "../../../Assets/Images/collectionObjects/Furniture.png";
import WallArt from "../../../Assets/Images/collectionObjects/WallArt.png";
import Lighting from "../../../Assets/Images/collectionObjects/Lighting.png";
import Decorative from "../../../Assets/Images/collectionObjects/Decopratives.png";
import Tile from "../../../Assets/Images/collectionObjects/Tile.png";
import Veneer from "../../../Assets/Images/collectionObjects/Veneer.png";
import Bathroom from "../../../Assets/Images/collectionObjects/Bathroom.png";
import Kitchen from "../../../Assets/Images/collectionObjects/Kitchen.png";
import Furniting from "../../../Assets/Images/collectionObjects/furniting.png";
import Mainfurniting from "../../../Assets/Images/collectionObjects/Mainfurniting.webp";
import Mainfurniture from "../../../Assets/Images/collectionObjects/Mainfurniture.webp";
import Mainwallart from "../../../Assets/Images/collectionObjects/Mainwallart.webp";
import Mainlighting from "../../../Assets/Images/collectionObjects/Mainlighting.webp";
import Maindecor from "../../../Assets/Images/collectionObjects/Maindecor.webp";
import Maintile from "../../../Assets/Images/collectionObjects/Maintile.webp";
import Mainveneer from "../../../Assets/Images/collectionObjects/Mainlaminate.webp";
import Mainkitchen from "../../../Assets/Images/collectionObjects/Mainkitchen.webp";
import Mainbathroom from "../../../Assets/Images/collectionObjects/Mainbathroom.webp";

export const collectionObjects = [
  { name: "Furniture & Storage", image: Furniture, mainimage: Mainfurniture,  id: "furniture" },
  { name: "Wall Art", image: WallArt, mainimage: Mainwallart, id: "wallArt" },
  { name: "Lighting & Fan", image: Lighting, mainimage: Mainlighting, id: "lighting" },
  { name: "Decoratives", image: Decorative, mainimage: Maindecor, id: "decoratives" },
  { name: "Furnishing", image: Furniting, mainimage: Mainfurniting, id: "furnishing" },
  { name: "Tile", image: Tile, mainimage: Maintile, id: "tile" },
  { name: "Veneer/ Laminate", image: Veneer, mainimage: Mainveneer, id: "veneer" },
  { name: "Sanitary", image: Bathroom, mainimage: Mainbathroom, id: "sanitary" },
  { name: "Kitchen Accessories", image: Kitchen, mainimage: Mainkitchen,  id: "kitchen" },
];
