import { Box,Link, Button, Flex, Image, Img, Text } from "@chakra-ui/react";
import React from "react";
import Navbar from "../../../Components/Navbar";
import Landing from "../../../Assets/Images/landing.webp";
import { HashLink } from "react-router-hash-link";

function LandingSection() {
  return (
    <Flex
      flexDir={"column"}
      backgroundImage={Landing}
      backgroundSize={"cover"}
      backgroundRepeat={"no-repeat"}
      height={"100vh"}
      width={"100%"}
    >
      <Flex
        position={"absolute"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDir={"column"}
        height={"100vh"}
        width={"100%"}
      >
        <Box
      
        borderRadius={"5px"}
          backdropFilter="auto"
          backdropBlur="8px"
          backgroundColor="rgba(161, 122, 94, 0.3)"
          height={{ base: "45%", md: "100vh", lg: "100vh" }}
          width={{ base: "90%", md: "50%", lg: "550px" }}
        ></Box>
        <Flex
        className="animate__animated animate__fadeIn"
          position={"absolute"}
          borderRadius={"10px"}
          boxShadow={"lg"}
          border={{base:"4px solid #FEFEFE",md:"8px solid #FEFEFE",lg:"10px solid #FEFEFE"}}
          height={"fit-content"}
          width={"fit-content"}
          p={{base:"25px",md:"35px",lg:"40px"}}
          fontSize={{ base: "37px", md: "60px", lg: "100px" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text
            //  color="rgba(161, 122, 94, 0.8)"
            color={"#907E6E"}
            className="landingheading animate__animated animate__slow animate__fadeInLeft "
          >
            L
          </Text>
          <Text
          ml={{base:"13px",md:"33px",lg:"55px"}}
            color={"#907E6E"}
            className="landingheading animate__animated animate__slow animate__fadeInLeft "
          >
            O
          </Text>
          <Text
              ml={{base:"13px",md:"33px",lg:"55px"}}
            color={"#907E6E"}
            className="landingheading animate__animated animate__slow animate__fadeInLeft animate__delay-1s"
          >
            K
          </Text>
          <Text
              ml={{base:"13px",md:"33px",lg:"55px"}}
            color={"#907E6E"}
            className="landingheading animate__animated animate__slow animate__fadeInLeft animate__delay-1s"
          >
            O
          </Text>
          <Text
              ml={{base:"13px",md:"33px",lg:"55px"}}
            color={"#907E6E"}
            className="landingheading animate__animated animate__slow animate__fadeInLeft animate__delay-2s"
          >
            L
          </Text>
          <Text
              ml={{base:"13px",md:"33px",lg:"55px"}}
            color={"#907E6E"}
            className="landingheading animate__animated animate__slow animate__fadeInLeft animate__delay-2s"
          >
          U
          </Text>
          <Text
              ml={{base:"13px",md:"33px",lg:"55px"}}
            color={"#907E6E"}
            className="landingheading animate__animated animate__slow animate__fadeInLeft animate__delay-3s"
          >
        M
          </Text>
          <Text
              ml={{base:"13px",md:"33px",lg:"55px"}}
            color={"#907E6E"}
            className="landingheading animate__animated animate__slow animate__fadeInLeft animate__delay-3s"
          >
         O
          </Text>
        </Flex>
        <Flex
          // maxW={"40%"}
          flexDir={"column"}
          mt={{base:"200px",md:"350px",lg:"420px"}}
          fontSize={{ base: "10px", md: "13px", lg: "20px" }}
          fontFamily={"heading"}
          color={"#FEFEFE"}
          position={"absolute"}
        >
          <Text className="animate__animated animate__slow animate__fadeIn" lineHeight={"20px"} letterSpacing={{base:"2px",md:"2px",lg:"4px"}}>
            Locate your Luminous style
          </Text>
          <HashLink   style={{ display: 'block', margin: '0 auto' }} smooth to={"/home#whatwedo"}>   
          <Button
       className="EXP"
            fontSize={{ base: "10px", md: "13px", lg: "15px" }}
            bg={
              "linear-gradient(180deg, #FFF 0%, rgba(239, 216, 180, 0.60) 52.08%, #C69C6D 100%)"
            }
            _hover={{ bg:"#9D6D40",color:"white"}}
            width={{ base: "100px", md: "120px", lg: "200px" }}
            height={{ base: "30px", md: "35px", lg: "40px" }}
            mt={"10px"}
            mx={"auto"}
           
          >
            Explore
          </Button></HashLink>
        </Flex>
      </Flex>

      <Navbar />
 
      <marquee bgcolor="#9C6E3E" behavior="scroll" direction="left" mt={"auto"} className="bar_content" fontSize={{base:"13px",md:"13px",lg:"15px"}} color={"white"}>🚚 Our service is currently available only in Kerala.</marquee>
    </Flex>
  );
}

export default LandingSection;
