import {
  Text,
  Flex,
  Box,
  Stack,
  InputGroup,
  InputLeftAddon,
  chakra,
  useToast,
  Link
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormInput from "../../../Components/FormInputs/FormInput.js";
import React, { useState, useEffect, useRef } from "react";
import CommonButton from "../../../Components/shared/CommonButton";
import TextArea from "../../../Components/FormInputs/TextArea.js";
import Selectform from "../../../Components/FormInputs/Selectform.js";
import { IoSearch, IoLocationSharp } from "react-icons/io5";
import { GMAP_API_KEY } from "../../../Config";
import { postStoresDetails } from "../../../Functions/PostApiCalls.js";
import { State } from "../../../Context/Context.js";
import { putStoresDetails } from "../../../Functions/PutApiCalls.js";
import { useGetShopData } from "../../../Functions/queries.js";
import DesignerSubscription from "../DesignerSubscription.js";
import { useQueryClient } from "@tanstack/react-query";

const options = [
  { value: "Alappuzha", label: "Alappuzha" },
  { value: "Ernakulam", label: "Ernakulam" },
  { value: "Idukki", label: "Idukki" },
  { value: "Kannur", label: "Kannur" },
  { value: "Kasaragod", label: "Kasaragod" },
  { value: "Kollam", label: "Kollam" },
  { value: "Kottayam", label: "Kottayam" },
  { value: "Kozhikode", label: "Kozhikode" },
  { value: "Malappuram", label: "Malappuram" },
  { value: "Palakkad", label: "Palakkad" },
  { value: "Pathanamthitta", label: "Pathanamthitta" },
  { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
  { value: "Thrissur", label: "Thrissur" },
  { value: "Wayanad", label: "Wayanad" },
];

// TODO add city and pin automatically when location is selected
const apiKey = GMAP_API_KEY;
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

function ShopDetailsForm() {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  // on form submit button click
  const onSubmit = async (e) => {
    setLoading(true);
    // if edit form
    if (shop) {
      await putStoresDetails(e, toast, reset);
      // reload page to show details filled in form fields
      setLoading(false);
      await queryClient.invalidateQueries("shop");
    } else await postStoresDetails(e, toast, reset);
    setLoading(false);
    await queryClient.invalidateQueries("shop");
  };

  const searchInput = useRef(null);
  const [address, setAddress] = useState({});

  // get shop details for edit form
  const { shop } = useGetShopData();

  // load map script after mounted
  useEffect(() => {
    // set fields for edit form
    if (shop) {
      const fields = [
        "shopname",
        "about",
        "location",
        "locationlink",
        "weblink",
        "addressLine1",
        "addressLine2",
        "addressLine3",
        "addressLine4",
        "phone",
        "phoneSub",
        "email",
      ];
      fields.forEach((field) => setValue(field, shop[0][field]));
    }
  }, [shop]);

  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Add Store Details
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex
          fontSize={{ base: "15px", md: "15px", lg: "16px" }}
          justifyContent={"center"}
          my={"5%"}
          width={"100%"}
        >
          <Stack
            as={chakra.form}
            onSubmit={handleSubmit(onSubmit)}
            width={{ base: "90%", md: "70%", lg: "60%" }}
            spacing={2}
          >
            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Store Name*
              </Text>
              <FormInput
                name="shopname"
                inputFor="shopname"
                register={register}
                error={errors.shopname}
                validator={{
                  required: "This field is required",
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Describe Store*
              </Text>
              <TextArea
                name="about"
                inputFor="about"
                register={register}
                error={errors.about}
                validator={{
                  required: "This field is required",
                  pattern: {
                    value: /^[\s\S]{0,500}$/,
                    message: "Exceeds 500 character limit",
                  },
                }}
                style={{
                  border: "0px",
                  borderBottom: "1px solid #D0D5DD",
                  maxHeight: "38px",
                  borderRadius: "10px",
                  whiteSpace: "pre-wrap",
                }}
               
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>
           
                    <Text fontWeight={"bold"}
                        mt={"10px"}
                        fontSize={{ base: "10px", md: "10px", lg: "14px" }}
                      
                        color="teal.500"
                    >
                      {" "}
                      <span style={{ fontSize: "0.8em", verticalAlign: "super" }}>#</span>NOTE: Please enter a valid website URL (e.g.https://www.example.com/).
                    </Text>
                
            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Website Link
              </Text>
              <FormInput
                name="weblink"
                inputFor="weblink"
                register={register}
                error={errors.weblink}
                validator={{
                  pattern: {
                    value: /https?:\/\/([\w.]+\/?)\S*/,
                    message: "Not a valid website link",
                  },
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Address Line1*
              </Text>
              <FormInput
                name="addressLine1"
                inputFor="addressLine1"
                register={register}
                error={errors.addressLine1}
                validator={{
                  required: "This field is required",
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Place*
              </Text>
              <FormInput
                name="addressLine2"
                inputFor="addressLine2"
                register={register}
                error={errors.addressLine2}
                validator={{
                  required: "This field is required",
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                District*
              </Text>

              <Selectform
                options={options}
                name="addressLine3"
                inputFor="addressLine3"
                register={register}
                error={errors.addressLine3}
                validator={{
                  required: "This field is required",
                }}
                style={{
                  border: "0px",
                  borderBottom: "1px solid #D0D5DD",
                  maxHeight: "38px",
                  borderRadius: "10px",
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
                color="#848484"
                placeholder="Select your district"
              />
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Pincode*
              </Text>
              <FormInput
                name="addressLine4"
                inputFor="addressLine4"
                register={register}
                error={errors.addressLine4}
                validator={{
                  required: "This field is required",
                  pattern: {
                    value: /^\d{6}$/,
                    message: "Not a valid pincode",
                  },
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>
            {/* location field */}
            <Link
                    mt={"10px"}
                    fontSize={{ base: "10px", md: "10px", lg: "14px" }}
                    isExternal
                    color="teal.500"
                    href="https://styhom-s3-demo.s3.ap-south-1.amazonaws.com/map+guide3.pdf"
                  >
                    <Text fontWeight={"bold"}>
                      {" "}
                      <span style={{ fontSize: "0.8em", verticalAlign: "super" }}>#</span>IMPORTANT: Please enter the latitude and longitude of the shop location in the format 9.787686, 76.768678 and also provide the location link from Google Maps. Follow the attached GUIDELINES.
                    </Text>
                  </Link>
           
                  
                
            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Location<span style={{ fontSize: "0.8em", verticalAlign: "super" }}>#</span>
              </Text>
              <FormInput
                name="location"
                inputFor="location"
                register={register}
                error={errors.location}
                validator={{}}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            {}
      {/* locationlink */}
      <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Location Link<span style={{ fontSize: "0.8em", verticalAlign: "super" }}>#</span>
              </Text>
              <FormInput
                name="locationlink"
                inputFor="locationlink"
                register={register}
                error={errors.locationlink}
                validator={{}}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Phone Number*
              </Text>
              <Flex w={"100%"} flexDir={"column"}>
                <InputGroup>
                  <InputLeftAddon
                    mt={"9.5px"}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    height={"36px"}
                  >
                    +91
                  </InputLeftAddon>
                  <FormInput
                    name="phone"
                    inputFor="phone"
                    register={register}
                    error={errors.phone}
                    validator={{
                      required: "This field is required",
                      pattern: {
                        value:/^(\+?\d{10,11})$/,
                        message: "Not a valid phone number",
                      },
                    }}
                    type="tel"
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                  />
                </InputGroup>
              </Flex>
            </Flex>
            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Alternate Phone Number
              </Text>
              <Flex w={"100%"} flexDir={"column"}>
                <InputGroup>
                  <InputLeftAddon
                    mt={"9.5px"}
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                    height={"36px"}
                  >
                    +91
                  </InputLeftAddon>
                  <FormInput
                    name="phoneSub"
                    inputFor="phoneSub"
                    register={register}
                    error={errors.phoneSub}
                    validator={{
                      pattern: {
                        value:/^(\+?\d{10,11})$/,
                        message: "Not a valid phone number",
                      },
                    }}
                    type="tel"
                    backgroundColor={"white"}
                    boxShadow={"lg"}
                  />
                </InputGroup>
              </Flex>
            </Flex>
            <Flex
              flexDir={"column"}
              width={"100%"}
              alignItems={"end"}
              justifyContent={"end"}
            >
              <CommonButton
                isLoading={loading}
                type={"submit"}
                height="34px"
                width="100px"
                mt="20px"
                fontSize="14px"
                btn={"Save"}
              />
            </Flex>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ShopDetailsForm;
