export const furnishingFilterObjects = [
  { name: "Curtains" },
  { name: "Cushions" },
  { name: "Pillows" },
  { name: "Bedspread" },
  { name: "Bed Sheet" },
  { name: "Carpets/Rugs" },
  { name: "Table Runners" },
  { name: "Quilt" },
  { name: "Fabric Material" },
  { name: "Leather Material" },
];
