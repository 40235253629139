import axios from "axios";
import { API_BASE_URL } from ".";
import { getItemFromLocalStorage } from "../utils/local-storage";

const isDevelopment = process.env.NODE_ENV === "development";
const axiosInstance = axios.create({
  API_BASE_URL,
  // timeout: 2500,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Get the token from local storage
    const token = getItemFromLocalStorage("styhomtoken");

    // If the token exists, set the authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Return the config object
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const axiosErrorHandler = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error(error.response.data, error.response.status);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error", error.message);
  }
  if (isDevelopment) {
    console.error(error.config);
  }
};

export default axiosInstance;
