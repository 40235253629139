import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import CommonModal from "../../../Components/CommonModal/CommonModal";
import { AiOutlineFileImage } from "react-icons/ai";
import CommonButton from "../../../Components/shared/CommonButton";
import GalleryContent from "../../../Assets/Images/Headers/headerstore.webp";
import { gallery } from "./DummyGallery";
import VideoContent from "./VideoContent";
function ShopVideoGallery(props) {
  // TODO fetch data from backend
  const { ShopVideo } = props;

  const ShopGalleryBtn = () => {
    return (
      <CommonButton
        width={"200px"}
        p={0}
        btn={
          <Flex
            px={8}
            width={"100%"}
            justifyContent={"space-between"}
            fontSize={"14px"}
          >
            <Text color={"white"}> View Video</Text>
            <AiOutlineFileImage color={"white"} />
          </Flex>
        }
      />
    );
  };
  return (
    <Flex
    mt={"20px"}
      width={"300px"}
      height={"100px"}
      bgImage={GalleryContent}
      bgSize={"cover"}
      borderRadius={"10px"}
    >
      <Flex
        width={"100%"}
        height={"100%"}
       
        bg={"blackAlpha.600"}
        borderRadius={"10px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CommonModal
          size={"lg"}
          btnContent={ShopGalleryBtn()}
          modalContent={<VideoContent ShopVideo={ShopVideo} />}
          modalAttr={{ width: "100vw", height: "80vh", bg: "whiteAlpha.400" }}
        />
      </Flex>
    </Flex>
  );
}

export default ShopVideoGallery;
