import White from "../../../../../src/Assets/Images/collectionObjects/white.png";
import Ash from "../../../../../src/Assets/Images/collectionObjects/ash.png";
import Black from "../../../../../src/Assets/Images/collectionObjects/black.png";
import Brown from "../../../../../src/Assets/Images/collectionObjects/brown.png";
import Red from "../../../../../src/Assets/Images/collectionObjects/red.png";
import Orange from "../../../../../src/Assets/Images/collectionObjects/orange.png";
import Green from "../../../../../src/Assets/Images/collectionObjects/green.png";
import Blue from "../../../../../src/Assets/Images/collectionObjects/blue.png";
import Beige from "../../../../../src/Assets/Images/collectionObjects/beige.png";
import Yellow from "../../../../../src/Assets/Images/collectionObjects/yellow.png";
import Purple from "../../../../../src/Assets/Images/collectionObjects/purple.png";
import Pink from "../../../../../src/Assets/Images/collectionObjects/pink.png";
import Wooden from "../../../../../src/Assets/Images/collectionObjects/wood.png";
import Multicolor from "../../../../../src/Assets/Images/collectionObjects/multicolor.png";
import Gold from "../../../../../src/Assets/Images/collectionObjects/gold.png";
import Silver from "../../../../../src/Assets/Images/collectionObjects/silver.png";
import Other from "../../../../../src/Assets/Images/collectionObjects/othercolor.png";
export const colorFilterObjects = [
  { name: "White", colorimage: White },
  { name: "Grey", colorimage: Ash },
  { name: "Black", colorimage: Black },
  { name: "Brown", colorimage: Brown },
  { name: "Red", colorimage: Red },
  { name: "Orange", colorimage: Orange },
  { name: "Green", colorimage: Green },
  { name: "Blue", colorimage: Blue },
  { name: "Beige", colorimage: Beige },
  { name: "Yellow", colorimage: Yellow },
  { name: "Purple", colorimage: Purple },
  { name: "Pink", colorimage: Pink },
  { name: "Wooden", colorimage: Wooden },
  { name: "Gold", colorimage: Gold },
  { name: "Silver", colorimage: Silver },
  { name: "Other color", colorimage: Other },
  { name: "Multicolor", colorimage: Multicolor },
];
