import { extendTheme, theme as base } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      // Apply custom scrollbar styles
      "::-webkit-scrollbar": {
        width: "2px",
        height: "3px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#917961",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#6b5b4f",
      },
    },
  },
  fonts: {
    // Specify your custom font and fallbacks
    body: "'Sofia Sans', sans-serif",
    heading: "'Marko One', sans-serif",
  },
  colors: {
    brand: {
      btnBg: "#917961",
      cardBg: "#F2DCC7",
      Brown: "#664121",
      LightBrown: "#E1B183",
      FilterBg: "#FFF1E2",
    },
  },
});

export default theme;
