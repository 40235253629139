import {
  Text,
  Flex,
  Box,
  Stack,
  Image,
  Button,
  SimpleGrid,
  Input,
  chakra,
  useToast,
  Link,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import FormInput from "../../../Components/FormInputs/FormInput";
import CommonButton from "../../../Components/shared/CommonButton";
import Close from "../../../Assets/Images/Dashboard/deleticon.png";
import { API_BASE_URL } from "../../../Config";
import axios from "axios";
import { useGetShopData } from "../../../Functions/queries";
import Delete from "../../../Assets/Images/Dashboard/delet icon.png";
import { useQueryClient } from "@tanstack/react-query";

function ShopVideo() {
  const { shop } = useGetShopData();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState();
  const toast = useToast();
  const queryClient = useQueryClient();
 // for deleting from aws
 const [deletedvideo, setdeletedvideo] = useState([]);

 const handleDeletevideo = (imageUrl) => {
  deleteVideo();

  // Extract the file name from the URL
  const extractedprofileFileName = imageUrl.split("/").pop();

  // Check if the file name already exists in deletedvideo
  if (!deletedvideo.includes(extractedprofileFileName)) {
    setdeletedvideo([...deletedvideo, extractedprofileFileName]);
  }
};
 const deleteImagesFromS3 = async () => {
  try {
    // Make a DELETE request to your deleteS3Route endpoint
    await axios.delete(`${API_BASE_URL}/delete/s3`, {
      data: deletedvideo, // Pass the deletedImages array as data
    });
  } catch (error) {
    console.error("Error deleting images from S3:", error);
  }
};

 // for deleting from aws ends
  const handleVideoUpload = async () => {
    setLoading(true);
    const selectedFile = fileInputRef.current.files[0];


    // validation
    if (selectedFile) {
      if (selectedFile.type !== 'video/mp4') {
        setLoading(false);
        return toast({
          title: "Invalid File",
          description: "Please select a video file in MP4 format.",
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
      if (selectedFile.size > 50 * 1024 * 1024) {
        setLoading(false);
        return toast({
          title: "File Too Large",
          description: "Please select a video file smaller than 50MB.",
          status: "error",
          position: "top",
          isClosable: true,
        });
      }}

    // 
    // Check if a file is selected
    const formData = new FormData();
    formData.append("file", selectedFile);
    // Perform upload logic here (e.g., send file to server)
    try {
      const response = await axios.post(
        `${API_BASE_URL}/upload/shop/videos/${shop[0]._id}`,
        formData
      );
      setLoading(false);
      toast({
        title: "Success",
        description: selectedFile
          ? "Video Uploaded Successfully"
          : "Changes Added Successfully",
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      throw error;
      // toast('Image upload failed');
    }
    setVideo();
    fileInputRef.current.value = null;
    await queryClient.invalidateQueries("shop");
    deleteImagesFromS3();
    // Reset selected file after upload
    setLoading(false);
  };

  const deleteVideo = () => {
    setVideo();
  };

  useEffect(() => {
    setVideo(shop[0]?.video);
  }, [shop]);

  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Add Videos
            </Text>
          </Flex>
        </Box>
        <Flex
          flexDir={"column"}
          fontSize={{ base: "15px", md: "15px", lg: "16px" }}
          justifyContent={"center"}
          my={"5%"}
          mx={"auto"}
          width={{ base: "90%", md: "70%", lg: "60%" }}
        >
          <Flex flexDir={"column"}>
            <Text my={"20px"}>
              You can share a video that displays your store and the things you
              sell.
            </Text>
            <Text fontSize={{ base: "12px", md: "13px", lg: "13px" }}>
            *(Only MP4 videos are supported upto a maximum file size of 50MB)
            </Text>
          </Flex>
          <Flex
            border={"1px solid #917961"}
            flexDir={"column"}
            borderRadius={"10px"}
            boxShadow={"inner"}
            p={3}
          >
            <Flex
              width={"100%"}
              px={{ base: "0px", md: "20px", lg: "30px" }}
              mx={"auto"}
              flexDir={"column"}
            >
              <Flex
                p={3}
                backgroundColor={"#CEC9C3"}
                flexDir={{ base: "column", md: "column", lg: "row" }}
                className="videoflex"
              >
                <Input
                  ref={fileInputRef}
                  name="video"
                  p={"10px"}
                  h={"50px"}
                  w={{ base: "100%", md: "100%", lg: "100%" }}
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                  type="file"
                  accept=".mp4"
                ></Input>
                <Flex mt={{ base: "10px", md: "10px", lg: "0px" }}>
                  <CommonButton
                    isLoading={loading}
                    my={{ base: "auto", md: "auto", lg: "auto" }}
                    ml={{ base: "0px", md: "0px", lg: "10px" }}
                    fontSize="13px"
                    btn="Save"
                    onClick={() => handleVideoUpload()}
                  />
                </Flex>
              </Flex>
              <Flex>
                {video && (
                  <Flex
                    flexDir={"column"}
                    m={4}
                    width={"200px"}
                    p={4}
                    bg={"whiteAlpha.800"}
                    borderRadius={10}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Link href={video}>
                      <video width="100%">
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Link>
                    <Button
                      bg={"transparent"}
                      _hover={{ bg: "transparent" }}
                      // onClick={() => {
                      //   deleteVideo();
                      // }}
                      onClick={() =>
                        handleDeletevideo(video)
                      }
                    >
                      <Image width={"20px"} src={Delete}></Image>
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ShopVideo;
