import { Text, Flex, Container, Divider } from "@chakra-ui/layout";
import {

  ListItem,

  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import WishlistHeader from "../../src/Assets/Images/Headers/policyHeader.webp";
import WishlistHeaderMob from "../../src/Assets/Images/Headers/MobPolicyHeader.webp";

import Header from "../Components/shared/Header";
import Footer from "../Components/Footer";
function TermsConditions() {
  return (
    <Flex flexDir={"column"} id="termsandconditions">
      <Container maxW="2000px" width={"100vw"} p={0}>
        <Header
        mt={{base:"10px",md:"10px",lg:"50px"}}
          heading="Terms and Conditions"
          image={WishlistHeader}
          imageMob={WishlistHeaderMob}
        />
        <Flex
          my={"20px"}
          fontSize={{ base: "13px", md: "13px", lg: "15px" }}
          flexDir={"column"}
          mx={"auto"}
          borderRadius={"10px"}
          boxShadow={"lg"}
          p={"20px"}
          backgroundColor={"#F2DCC7"}
          width={{ base: "95%", md: "95%", lg: "90%" }}
        >
          <Text>
            Please read these Terms of Use carefully before continuing and
            accepting it.
            <br />
            This document is an electronic record in accordance with the revised
            Information Technology Act of 2000 and the rules and regulations
            enacted thereunder. This document is issued in compliance with Rule
            3 (1) of the Information Technology Rules, 2011, which requires the
            publication of the Terms for accessing or using lokolumo services
            via the lokolumo website. This document satisfies the requirements
            and conditions outlined in Section 65B (2) of the Indian Evidence
            Act of 1872.
          </Text>
          <Text my={"10px"} fontWeight={"bold"}>
            1. YOUR ACCEPTANCE OF THIS AGREEMENT
          </Text>
          <Text>
            This is an agreement between you ("you" or "your") and lokolumo
            ("lokolumo" "we," or "our") that regulates your use of the services
            given by lokolumo through its website www.lokolumo.com. When you
            visit or use this website, you agree to be governed by these Terms
            and Conditions ("Terms").
          </Text>
          <Text my={"10px"} fontWeight={"bold"}>
            2. CHANGES
          </Text>
          <Text>
            We reserve the right to amend the Terms and Conditions without prior
            notice, and you are responsible for reviewing these Terms on a
            regular basis for updates. All modified Terms become effective upon
            our posting to the website, and your continued use of the site after
            such revisions have been made indicates your agreement to the
            changes.
          </Text>
          <Text my={"10px"} fontWeight={"bold"}>
            3. HOW YOU CAN USE OUR MATERIALS
          </Text>
          <Text>
            We use a wide variety of information, text, pictures, designs,
            graphics, images, sound and video recordings, animation, content,
            advertisements, and other materials and effects (collectively
            "Materials") for the services on the website. We make the material
            available on the website for your personal and non-commercial use
            only.
            <br /> While every effort has been made to ensure the authenticity
            of the website's content, lokolumo is not liable for any damages,
            losses, or actions arising directly or indirectly as a result of
            access and/or use of the content on the Website, including but not
            limited to decisions based on the content on the Website that result
            in any loss of data, revenue, profits, property, infection by
            viruses, and so on.
            <br /> As a result, you may access, use, copy, and distribute the
            Materials available on the Website solely for internal,
            non-commercial, informational reasons. You are not permitted to
            conduct data mining, scraping, crawling, or use any process that
            sends automated queries to lokolumo. You may not use the Website to
            create a collection of listings, including a competing listing
            product or service. You may not use the Website or any Materials to
            send unsolicited commercial e-mail. Except as expressly authorised
            in this paragraph, you are not granted a licence to any copyright,
            trademark, patent, or other intellectual property right in the
            Materials or the products, services, processes, or technology
            mentioned therein. All such rights are reserved by Lokolumo and/or
            any third-party owner of such rights.
          </Text>
          <Text my={"10px"} fontWeight={"bold"}>
            4. HOW YOU CAN USE OUR MARKS:
          </Text>
          <Text>
            The lokolumo brand, logo, and any related product and service names,
            design marks, and slogans are trademarks and service marks of
            lokolumo, which are used under licence. All other trademarks and
            service marks mentioned herein are the property of the respective
            owners. All reproductions of the Materials on any of the Website
            must include any copyright, trademark, or other proprietary notice
            found on the particular Website that corresponds to the material
            being copied. You are not permitted to use any lokolumo name or mark
            in any advertising, publicity, or other commercial context without
            the prior written approval of lokolumo. Requests for authorization
            should be sent to admin@lokolumo.com.
          </Text>
          <Text my={"10px"} fontWeight={"bold"}>
            5. HOW WE USE THE INFORMATION YOU PROVIDE TO US
          </Text>
          <Text>
            Please refrain from sending us any confidential or proprietary
            information. With the exception of personally identifiable
            information that we have agreed to keep confidential in accordance
            with our Privacy Policy, any materials, such as feedback, data,
            answers, questions, comments, suggestions, ideas, or similar content
            that you send to us will be treated as non-confidential and
            non-proprietary. We are under no obligation to protect confidential
            or proprietary information (excluding personally identifiable
            information) from disclosure and are free to reproduce, use, and
            distribute the information to others without any restrictions.
            Additionally, we have the freedom to utilize any ideas, concepts,
            know-how, or techniques contained in the information you provide for
            any purpose, including the development, manufacturing, and marketing
            of products and services incorporating such information.
          </Text>
          <Text my={"10px"} fontWeight={"bold"}>
            6. PRIVACY POLICY
          </Text>
          <Text>
            Lokolumo is dedicated to safeguarding the privacy and
            confidentiality of any personal information it may request and
            receive from clients, business partners, and other users of the
            Website. For more information on how we handle personal information,
            please refer to our Privacy Policy.
          </Text>
          <Text my={"10px"} fontWeight={"bold"}>
            7. CONTENT DISCLAIMER
          </Text>
          <Text>
            Lokolumo presents information that is provided and created by
            advertisers, software developers, publishers, marketing agents,
            employees, users, and other third parties. While we have made every
            effort to verify the authenticity of the content on our Website, we
            have no control over the content, its accuracy, integrity, or
            quality. The information on our pages may contain technical
            inaccuracies or typographical errors, and we cannot guarantee its
            authenticity, currency, content, quality, copyright compliance,
            legality, or compliance with other intellectual property rights. We
            cannot be held liable for any resulting loss or damage.
            <br /> Furthermore, we shall not be held responsible for any
            damages, losses, or actions that may arise directly or indirectly
            from any content, including errors or omissions in the content,
            access and/or use of the content on the Website. This includes but
            is not limited to decisions based on the content that result in data
            loss, revenue loss, profit loss, property loss, or virus infections.
            <br /> All information regarding products and promotions, such as
            prices and availability of products or services, is subject to
            change without prior notice by the party responsible for the product
            or promotion. It is advised to use caution while navigating the
            website.
            <br /> Lokolumo retains the right, at its discretion and without any
            obligation, to enhance or rectify any errors or omissions in any
            part of the website. We will strive to update information on the
            website promptly when necessary, but we cannot be held accountable
            for any inaccuracies.
            <br /> All rights, titles, and interests, including trademarks and
            copyrights related to the domain name and Website content hosted on
            the Website, are reserved by Lokolumo. Users are allowed to read,
            print, or download text, data, and/or graphics from the Website or
            any other Website for personal use only. Unauthorized access,
            reproduction, redistribution, transmission, and/or handling of any
            information from the website in any other way, whether in whole or
            in part, is strictly prohibited. Failure to comply will result in
            legal action being taken against such users.
            <br /> Links to external Internet sites may be included in the
            content of a website or other website for the convenience of users.
            The inclusion of an external link does not imply that lokolumo
            endorses the site. Lokolumo makes no claims about the availability
            or functionality of its website or any of the external websites to
            which we link. When you click on advertiser banners, links, or other
            external links on the website, your browser may immediately open a
            new browser window that Lokolumo does not host or manage .<br />
            Lokolumo and its affiliates bear no responsibility for the external
            sites' content, functioning, validity, or technological safety. We
            reserve the right to disable links to or from third-party sites to
            any of our Website, but we are not obligated to do so.
            <br /> Some external links may contain content that some people find
            distasteful, inappropriate, or offensive. We are not responsible for
            the truth, relevance, copyright compliance, legality, or decency of
            any material found on externally connected websites. We do not
            thoroughly screen or investigate business listing websites before or
            after including them in directory listings that become part of the
            Materials on our Website, and we make no representations or accept
            responsibility for the content that third parties submit to be
            listed in any of these directories.
            <br />
            Lokolumo additionally reserves the right to impose or amend the
            Website's access limitations, whether in terms of access price,
            timings, equipment, access restrictions, or otherwise, as posted
            from time to time under these terms and conditions. Users are
            responsible for referring to these terms and conditions each time
            they use the website.
            <br /> While every attempt has been made to ascertain the
            authenticity of the content in the Website, Lokolumo is not liable
            for any kind of damages, losses, or action arising directly or
            indirectly, due to access and/or use of the content in the Website,
            including but not limited to any decisions based on content in the
            Website resulting in loss of data, revenue, profits, property,
            infection by viruses.
          </Text>
          <Text my={"10px"} fontWeight={"bold"}>
            8. WARRANTY DISCLAIMER
          </Text>
          <Text>
            Any provider of products or services can register with Lokolumo.
            Lokolumo does not assess whether advertisers are good, trustworthy,
            or high-quality marketers of goods or services. Prior to using the
            terms of service, you must be satisfied with all essential aspects.
            Lokolumo has also not negotiated or discussed any conditions of
            engagement with any of its advertising. You should do the same.
            Purchasing goods or services from advertising is at your own risk.
            <br />
            We do not investigate, represent, or endorse the accuracy, legality,
            legitimacy, validity, or dependability of any products, services,
            deals, coupons, or other promotions or materials, including advice,
            ratings, and recommendations found on, distributed through, or
            linked to, downloaded, or accessed from the Website.
            <br /> References to third-party names, marks, products, or
            services, as well as hypertext links to third-party sites or
            information, do not constitute or imply our endorsement,
            sponsorship, or recommendation of the third party, or of the quality
            of any product or service, advice, information, or other materials
            displayed, purchased, or obtained by you as a result of an
            advertisement or any other information or offer on or through the
            Website.
            <br /> Your use of the Website, reliance on any Materials, and
            general use of the Internet are entirely at your own risk. Lokolumo
            accepts no responsibility or liability for the accuracy, content,
            completeness, legality, reliability, operability, or availability of
            information or material displayed in search results on the Website.
          </Text>

          <Text my={"10px"} fontWeight={"bold"}>
            9. DISCLAIMER for "Lokolumo Verified” Stamp
          </Text>
          <Text>
            "Lokolumo Verified" means, the information related to name, address,
            contact details of the business establishments have been verified as
            existing at the time of registering any advertiser with lokolumo.
            This verification is solely based on the documents as supplied by an
            advertiser/s or as per the details contained in E-Registration Form.
            Lokolumo strongly recommends to its users to verify all relevant
            details of vendors/services prior to availing any products/services
            from them. Lokolumo does not implicitly or explicitly endorse any
            product/s or services provided by the vendors/service providers.
          </Text>
          <Text my={"10px"} fontWeight={"bold"}>
            10. ADDITIONAL DISCLAIMER
          </Text>
          <Text>
            Users using any of Lokolumo service across internet,
            www.lokolumo.com are bound by this additional disclaimer wherein
            they are cautioned to make proper enquiry before they (Users) rely,
            act upon or enter into any transaction (any kind or any sort of
            transaction including but not limited to monetary transaction) with
            the Advertiser listed with Lokolumo.
            <br /> All the Users are cautioned that all and any information of
            whatsoever nature provided or received from the Advertiser/s is
            taken in good faith, without least suspecting the bonafides of the
            Advertiser/s and Lokolumo does not confirm, does not acknowledge, or
            subscribe to the claims and representation made by the Advertiser/s
            listed with Lokolumo. Further, Lokolumo is not at all responsible
            for any act of Advertiser/s listed at Lokolumo.
          </Text>

          <Text my={"10px"} fontWeight={"bold"}>
            11. LIMITATION OF LIABILITY
          </Text>
          <Text>
            In no event shall lokolumo be liable to any user on account of such
            user's use, misuse or reliance on the website for any damages
            whatsoever, including direct, special, punitive, indirect,
            consequential or incidental damages or damages for loss of profits,
            revenue, use, or data whether brought in warranty, contract,
            intellectual property infringement, tort (including negligence) or
            other theory, even if lokolumo are aware of or have been advised of
            the possibility of such damage, arising out of or connected with the
            use (or inability to use) or performance of the website, the
            materials or the internet generally, or the use (or inability to
            use), reliance upon or performance of any material contained in or
            accessed from any website. Lokolumo does not assume any legal
            liability or responsibility for the accuracy, completeness, or
            usefulness of any information, apparatus, product or process
            disclosed on the website or other material accessible from the
            website. <br />
            The user of the website assumes all responsibility and risk for the
            use of this website and the internet generally. The foregoing
            limitations shall apply notwithstanding any failure of the essential
            purpose of any limited remedy and to the fullest extent permitted
            under applicable law.
          </Text>

          <Text my={"10px"} fontWeight={"bold"}>
            12. MONITORING OF MATERIALS TRANSMITTED BY YOU
          </Text>
          <Text>
            We are under no obligation to monitor the material residing on or
            transmitted to the Website. However, anyone using the Website agrees
            that Lokolumo may monitor the Website contents periodically to (1)
            comply with any necessary laws, regulations or other governmental
            requests; (2) to operate the Website properly or to protect itself
            and its users. Lokolumo reserves the right to modify, reject or
            eliminate any material residing on or transmitted to its Website
            that it, in its sole discretion, believes is unacceptable or in
            violation of the law or these Terms and Conditions.
          </Text>

          <Text my={"10px"} fontWeight={"bold"}>
            13. DELETIONS FROM SERVICE
          </Text>
          <Text>
            Lokolumo reserves the right to delete/deactivate any user account if
            we discover that the terms and conditions of using the website have
            been violated, if the user uploads any phoney or dummy details, or
            if the uploaded materials are irrelevant or not in line with the aim
            of lokolumo. Your right to access lokolumo will immediately cease if
            your account is deactivated and/or terminated/suspended, and we
            reserve the right to remove or delete your user information,
            including but not limited to login credentials, account details, and
            information you have posted. Lokolumo will delete any materials at
            the request of the user who submitted the materials or at the
            request of an advertiser who has decided to "opt-out" of the
            addition of materials to its advertising. Lokolumo reserves the
            right to delete (or to refuse to post to public forums) any
            materials it deems detrimental to the system or is, or in the
            opinion of Lokolumo, may be, defamatory, infringing or violation of
            applicable law. Materials submitted to Lokolumo for publication on
            the Website may be edited for length, clarity and/or consistency
            with Lokolumo's editorial standards.
          </Text>

          <Text my={"10px"} fontWeight={"bold"}>
            14. INDEMNIFICATION
          </Text>
          <Text>
            You agree to indemnify and hold us and (as applicable) our
            proprietor, and employees, harmless from any claim or demand,
            including reasonable attorneys' fees, made by any third party due to
            or arising out of your breach of these Terms, your violation of any
            law, or your violation of the rights of a third party, including the
            infringement by you of any intellectual property or other right of
            any person or entity. These obligations will survive any termination
            of the Terms.
          </Text>

          <Text mt={"20px"} fontWeight={"bold"}>
            SELLER /SERVICES AGREEMENT
          </Text>
          <Divider borderColor="brown" borderWidth="1px" />
          <Text my={"10px"} fontWeight={"bold"}>
            A. LISTING OF PRODUCTS / SERVICES ON LOKOLUMO
          </Text>
          <OrderedList>
            <ListItem>
              You must list goods and / or services on Lokolumo in accordance
              with these Terms and such other criteria as Lokolumo may notify
              from time to time.
            </ListItem>
            <ListItem>
              By using Lokolumo it is understood that you agree and acknowledge
              that you are legally competent and permitted to sell the item(s)
              that you list on Lokolumo ("Product Listing(s)") and have all the
              necessary licenses, certificate, approvals and permits required
              for such sale. Each Item Listing must be made in accordance with
              applicable laws, including without limitation the Consumer
              Protection (E-Commerce) Rules, 2020.
            </ListItem>
            <ListItem>
              Each product listed and offered for sale by you on Lokolumo is
              made available and you will maintain sufficient stock of inventory
              to fulfil the Customer orders.
            </ListItem>
            <ListItem>
              By using Lokolumo you warrant and undertake that descriptions,
              images, and other content pertaining to goods or services provided
              by you on Lokolumo are accurate and correspond directly with the
              appearance, nature, quality, purpose and other general features of
              such goods or services.
            </ListItem>
            <ListItem>
              You will be liable to ensure that each Item Listing and
              advertisements for marketing of goods or services are consistent
              with the actual characteristics, access and usage conditions of
              such goods or services being listed on Lokolumo.
            </ListItem>
            <ListItem>
              You must ensure that all items are listed in the appropriate
              category on Lokolumo.
            </ListItem>
            <ListItem>
              Please note that, you will be liable to ensure that each Item
              Listing explicitly provides:
              <UnorderedList styleType="lower-roman">
                <ListItem>
                  All contractual information required to be disclosed under
                  applicable law.
                </ListItem>
                <ListItem>
                  All mandatory notices and information provided as per
                  applicable laws for the good being offered for sale, where
                  applicable.
                </ListItem>
                <ListItem>
                  Details about you, including the name of your business,
                  whether registered or not, your geographic address, customer
                  care number.
                </ListItem>
              </UnorderedList>
            </ListItem>

            <ListItem>
              Please note that, you shall be liable for providing all Product
              Support for the entirety of the applicable time-period of such
              Product Support.
            </ListItem>
            <ListItem>
              You must at all times ensure that any Item Listing does not
              infringe upon the intellectual property, trade secret or other
              proprietary rights or rights of publicity or privacy rights of
              third parties.
            </ListItem>
            <ListItem>
              You must ensure that the price of the listed item is captured
              correctly
            </ListItem>
            <ListItem>
              You must at all times ensure that all listed items are kept in
              stock for successful fulfilment of sales.
            </ListItem>
            <ListItem>
              Please note that, we have, the right but not the obligation, to
              delete any listings of any items or otherwise the selling of
              products with or without notice and / or cause, at our sole
              discretion.
            </ListItem>
            <ListItem>
              If you are offering promotions/discounts/offers on products sold
              by you on other website (both online and offline), and the same
              products are also listed on Lokolumo, Lokolumo expects you to
              promptly inform it of such promotions/discounts/offers. Lokolumo
              also expects that you would extend the most favourable
              promotions/discounts/offers to the Customers on Lokolumo as well.
            </ListItem>
            <ListItem>
              You shall provide correct images and description of the product
              and monitor whether the same is being reflected correctly on the
              Lokolumo website from time to time.
            </ListItem>
          </OrderedList>
          <Text mt={"20px"} mb={"10px"} fontWeight={"bold"}>
            TERMS OF USE FOR 3D MODELS
          </Text>
          <Text>
            LOKOLUMO (after this - “we”, “our “and “us”) is an Indian website
            that facilitates users to download and use 3D models. For the
            purposes of these Terms, LOKOLUMO and Customer are referred to as
            Parties, and individually - Party.
          </Text>

          <Text my={"10px"} fontWeight={"bold"}>
            1. OUR SERVICE
          </Text>
          <Text>
            LOKOLUMO is a platform where independent sellers/shops (“Sellers”)
            request us to generate and upload 3D models ("Models") of various
            objects. These models are then available for download by
            customers/designers ("Customers") following these Terms. Models
            uploaded to the LOKOLUMO are created by us. Sellers are responsible
            for ensuring that they have all necessary rights to the requested 3D
            Models and do not violate third parties' rights by posting them on
            LOKOLUMO.
            <br />
            LOKOLUMO may not give legal advice or make legal judgments as to
            whether a Model infringes anyone's intellectual property. LOKOLUMO
            may remove materials cited concerning alleged intellectual property
            infringement upon notice.
          </Text>

          <Text my={"10px"} fontWeight={"bold"}>
            2. THE LICENSE TO USE 3D MODELS
          </Text>
          <Text>
            When You, as a Customer, download a Model from the Website, LOKOLUMO
            grants You a non-exclusive, non-sub licensable, non-transferable,
            worldwide, royalty-free, and revocable license (after this - the
            “License” ) and the rights to:
          </Text>
          <Text>
            2.1. Record Models on electronic media, for example, on a CD, DVD,
            flash drive, or portable hard drive, including recording in the
            memory of a computer or mobile phone;
          </Text>
          <Text>
            2.2. Use the downloaded Model to create Derivative Works under the
            Terms, namely
          </Text>
          <Text ml={"10px"}>
            2.2.1. the practical implementation of an architectural, design,
            urban planning, or landscape gardening project using a Model in
            three dimensions or two dimensions, for example, building a house
            whose Model was available in the Website Database;
          </Text>
          <Text ml={"10px"}>
            2.2.2. reworking of the Model only into a two-dimensional work, that
            is, creating a derivative two-dimensional work using the Model and
            your creative contribution, for example, creating an image of a
            project (render) of a room design using the image of the Models, as
            well as using a derived two-dimensional work in all ways
          </Text>
          <Text ml={"10px"}>
            2.2.3. Use the Model as part of a complex object, for example, as
            part of an audio-visual work (film, advertising video, etc.), or a
            theatrical performance, but without the right to use it as part of a
            database;
          </Text>

          <Text my={"10px"} fontWeight={"bold"}>
            3. WHAT NOT TO DO WITH 3D MODELS
          </Text>
          <Text>3.1. The Customer is prohibited from:</Text>
          <Text ml={"10px"}>
            3.1.1. Distribution of Models recorded on electronic media (hard
            disks, etc.);
          </Text>
          <Text ml={"10px"}>
            3.1.2. bringing to the public the Model in three dimensions (in the
            initial form of the 3D model) in such a way that any person can
            access the three-dimensional image of the Model from anywhere and at
            any time of their own free will, for example, uploading the original
            Model downloaded from the Website to the Internet;
          </Text>
          <Text ml={"10px"}>
            3.1.3. conclude licensing (sub-licensing) agreements with third
            parties regarding our 3D models;
          </Text>
          <Text ml={"10px"}>
            3.1.4. Sell or donate electronic media containing our 3D models,
            such as flash drives or hard drives;
          </Text>
          <Text ml={"10px"}>
            3.1.5. rent out or free rent (loan) with our 3D models, such as
            flash drives or hard drives;
          </Text>
          <Text ml={"10px"}>
            3.1.6. upload the 3D models in their original form on the Internet;
          </Text>
          <Text ml={"10px"}>
            3.1.7. Include 3D models in any database catalogues;
          </Text>
          <Text ml={"10px"}>
            3.1.8. Create your 3D models based on our 3D models.
          </Text>
          <Text>
            3.2. A designer or a person of a similar profession can distribute
            the result of rework ONLY if the created works are in a
            two-dimensional final form. Rework involves making your creative
            input.
          </Text>
          <Text>
            3.3. When using the downloaded Models, you must comply with the
            following requirements:
          </Text>
          <Text ml={"10px"}>
            3.3.1. Changing the Model does not make it a separate Derivative
            Work. Thus, the modified copy of the Model remains the Model
            licensed to you and licensed to us.
          </Text>
          <Text ml={"10px"}>
            3.3.2. Combining several Models into one 3D Model will not be
            considered to create a Derivative Work since this does not
            significantly distinguish such a model from the loaded Model and
            does not make the scope of this 3D model broader than that of the
            loaded Model. Therefore, such a composite 3D model should be
            considered a modified copy of the loaded Model or several Models.
          </Text>
          <Text ml={"10px"}>
            3.3.3. The Model may not be sold, transferred, or assigned to
            another person or entity in the form in which it was downloaded from
            the Website Database or in any other form that is not a Derivative
            Work, including but not limited to a modified Model.
          </Text>
          <Text ml={"10px"}>
            3.3.4. Models must not be published, distributed, or made available
            through the infrastructure of any online repository in the form in
            which they are downloaded from the Website Database or in any other
            form that does not constitute a Derivative Work, including but not
            limited to a modified Model.
          </Text>
          <Text ml={"10px"}>
            3.3.5. Models must not be used for any defamatory, pornographic,
            obscene, or racist purpose or to violate the intellectual property
            rights of any person.
          </Text>
          <Text ml={"10px"}>
            3.3.6. Models should not be used to create marks for goods and
            services or a logo for a legal entity.
          </Text>
          <Text ml={"10px"}>
            3.3.7. You must not impersonate the actual creator of the Model.
          </Text>
          <Text>
            3.4. Specific Models containing images, logos, trademarks and
            service marks, brand names, and other intellectual property may
            require additional licenses, rights, and permissions to use. The
            Customer is solely responsible for determining if additional
            licenses, rights, and permissions are required for the intended use
            of the Model before downloading and using any Model.
          </Text>
          <Text>
            3.5. We do not grant ownership of the Models. Therefore, by
            downloading a Model, you do not acquire ownership of the Model
            itself but only a license to use that Model.
          </Text>
          <Text>
            3.6. Models should not be used to create trade or service marks. The
            Terms do not contain consent to register objects of copyright as
            trademarks and service marks.
          </Text>
          <Text>
            3.7. The Customer's use of the Models must not undermine the honor,
            dignity, or reputation of others.
          </Text>
          <Text>
            3.8. The Customer must ensure that their use of the Models does not
            contradict the requirements of Applicable Law and does not violate
            the intellectual property rights of third parties, including, but
            not limited to, copyright and related rights, trademark and service
            marks, appellations of origin, patented invention rights, utility
            models and industrial designs, the right to use images of living and
            deceased people, etc.
          </Text>
          <Text>
            3.9. This License is valid during the term of these Terms.
            Termination of the Terms will result in the revocation of this
            License.
          </Text>
          <Text>
            3.10. If your license is revoked, you must immediately stop using
            the downloaded Models and delete all of them and any copies you have
            made.
          </Text>
          <Text>
            3.11. Breach of the Terms immediately terminates your rights and
            License to use the Models.
          </Text>
          <Text>
            3.1. You are solely responsible for any violation of the terms of
            this License.
          </Text>
          <Divider my={"20px"} borderColor="brown" borderWidth="1px" />
          <Text my={"10px"} fontWeight={"bold"}>
            GOVERNING LAW AND DISPUTE RESOLUTION
          </Text>
          <Text>
            You and Lokolumo shall endeavour to amicably, through discussions,
            settle and resolve any dispute or difference arising out of or in
            relation to this Agreement ("Dispute") within 15 (fifteen) days from
            the commencement of such dispute. In case of such failure, the
            Dispute shall upon the expiry of the aforesaid period, be referred
            to e-arbitration to be conducted by a sole arbitrator, who shall be
            jointly appointed by you and Lokolumo from the panel of available
            e-arbitrators. All arbitration proceedings may be conducted through
            mode available on the internet or any other information and
            communication technology (including but not limited to video call
            system, telephone or mobile, fax, e-mail facilities) which can be
            beneficially used to solve disputes. All Arbitration proceedings
            shall be held in accordance to the provisions of Arbitration and
            conciliation Act, 1996. The Language of Arbitration shall be
            English. The arbitral award shall be final and binding on both the
            Parties. The award may include costs, including reasonable
            advocate’s fees and disbursements.
          </Text>
          <Text my={"10px"}>
            GOVERNING LAW AND DISPUTE RESOLUTION:
          </Text>
          <Text>
            This Agreement shall be governed by the laws of India and, subject
            to the arbitration process above; you agree to submit to the
            exclusive jurisdiction of competent courts at Ernakulam, Kerala.
          </Text>
        </Flex>
      </Container>
      <Footer />
    </Flex>
  );
}

export default TermsConditions;
