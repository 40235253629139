import {
  Text,
  Flex,
  Box,
  Stack,
  Image,
  Button,
  SimpleGrid,
  Input,
  chakra,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import CommonButton from "../../../Components/shared/CommonButton";
import ImageUploading from "react-images-uploading";
import Delete from "../../../Assets/Images/Dashboard/delet icon.png";
import Update from "../../../Assets/Images/Dashboard/update icon.png";
import { ListItem, UnorderedList } from "@chakra-ui/react";
import DateRangePicker from "./DateRangePicker";
import { putStoresDetails } from "../../../Functions/PutApiCalls";
import { useGetShopData } from "../../../Functions/queries";
import { API_BASE_URL } from "../../../Config";
import { useQueryClient } from "@tanstack/react-query";
function ShopOfferForm() {
  const [loading, setLoading] = useState(false);
  const [shopofferimages, setshopofferImages] = useState([]);
  const [mergedData, setMergedData] = useState([]);
  const [offerEdit, setOfferEdit] = useState([]);
  const [offerLimit, setOfferLimit] = useState();
  const toast = useToast();
// for deleting from aws
const [deletedofferImages, setdeletedofferImages] = useState([]);



const handleshopimagesDeleteImage = (index) => {
  RemoveOffer(index);
  // Extract the image URL of the deleted image
  const deletedImageUrl = offerEdit[index].offerImage;

  // Extract the file name from the URL
  const extractedFileName = deletedImageUrl.split("/").pop();

  // Check if the file name already exists in deletedImages
  if (!deletedofferImages.includes(extractedFileName)) {
    setdeletedofferImages([...deletedofferImages, extractedFileName]);
  }
};

const deleteImagesFromS3 = async () => {
  try {
    // Make a DELETE request to your deleteS3Route endpoint
    await axios.delete(`${API_BASE_URL}/delete/s3`, {
      data: deletedofferImages, // Pass the deletedImages array as data
    });
  } catch (error) {
    console.error("Error deleting images from S3:", error);
  }
};
// for deleting from aws ends
  const queryClient = useQueryClient();

  var indexnew = -1;
  const dateRanges = [];
  let dateImageRanges = [];
  const { shop } = useGetShopData();
  useEffect(() => {
    if (shop) {
      setOfferEdit(shop[0]?.offer);
      if (
        shop[0].subscription?.plan === "basic" ||
        shop[0].subscription?.plan === "default"
      ) {
        setOfferLimit(3);
      } else if (shop[0]?.subscription?.plan === "premium") {
        setOfferLimit(10);
      }
    }
  }, [shop]);
  const handleDateSelection = (fromDate, tillDate, index1) => {
    // Check if an object with the given index1 already exists in dateRanges
    const existingDateRange = dateRanges.find(
      (range) => range.index1 === index1
    );

    if (existingDateRange) {
      // If it exists, update its fromDate and tillDate
      existingDateRange.fromDate = fromDate;
      existingDateRange.tillDate = tillDate;
    } else {
      // If it doesn't exist, create a new DateRange object and push it to dateRanges
      const newDateRange = {
        index1: index1,
        From: fromDate,
        To: tillDate,
      };

      dateRanges.push(newDateRange);
    }
  };
  const RemoveOffer = (index) => {
    const newOffer = [...offerEdit];
    newOffer.splice(index, 1);
    setOfferEdit(newOffer);
  };
  // offerimage
  const onChangeshop = (imageList, addUpdateIndex) => {
    setshopofferImages(imageList);
  };
  //
  const handleOfferImageSaveClick = async () => {
    setLoading(true);

    // Map offer images to make file
    const formData = new FormData();
    shopofferimages?.map((image, index2) => {
      formData.append(`file${index2}`, image.file);
    });

    // Make a POST request to upload images to aws
    const response = await axios.post(
      `${API_BASE_URL}/upload/shopOffer`,
      formData
    );

    // Process the response from aws part of server and update state with merged data
    response?.data?.data.map((image, index) => {
      const matchingDateRange = dateRanges.find(
        (range) => range.index1 === index
      );

      if (matchingDateRange) {
        const { From, To } = matchingDateRange;

        // Merge offer date and image
        const mergedObject = {
          From,
          To,
          offerImage: image,
        };

        // Update merged data state
        setMergedData((prevData) => [...prevData, mergedObject]);

        // Update dateImageRanges array
        dateImageRanges.push(mergedObject);
      }
    });

    // Combine dateImageRanges with offerEdit array so that data from edit also obtained
    dateImageRanges = dateImageRanges.concat(offerEdit);

    // Update the server with the merged data
    await putStoresDetails({ offer: dateImageRanges }, toast);

    // Set loading to false as the operation is completed
    setLoading(false);
    // Reload the window after a delay (2 seconds in this case)
    setTimeout(async () => {
      setshopofferImages();
      setOfferEdit();
      await queryClient.invalidateQueries("shop");
      deleteImagesFromS3();
    }, 100);
  };

  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Add Offer Posters
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex
          fontSize={{ base: "15px", md: "15px", lg: "16px" }}
          justifyContent={"center"}
          my={"5%"}
          width={"100%"}
        >
          <Stack
            width={{ base: "100%", md: "100%", lg: "60%" }}
            as={chakra.form}
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission behavior
              handleOfferImageSaveClick(); // Call your custom form submission logic
            }}
            spacing={3}
          >
            <Flex
              p={{ base: "6", md: "6", lg: "0" }}
              flexDir={{ base: "column", md: "comumn", lg: "row" }}
            >
              <Text pr={4} width={{ base: "", md: "", lg: "300px" }}>
                Add Offer Posters
                <br />
                (maximum {offerLimit})
              </Text>
              {/* ..................Offer Image upload............... */}
              <Flex
                maxW={{ base: "100%", md: "100%", lg: "800px" }}
                backgroundColor={"white"}
                boxShadow={"inner"}
                padding={4}
                borderRadius={"10px"}
              >
                <ImageUploading
                  multiple
                  value={shopofferimages}
                  onChange={onChangeshop}
                  maxNumber={offerLimit - offerEdit?.length}
                  maxFileSize={5000000}
                  dataURLKey="data_url"
                  acceptType={["jpg", "png", "jpeg"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors,
                  }) => (
                    // write your building UI

                    <Flex flexDir={"column"} width={"100%"}>
                      <Button
                        fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                        boxShadow={"lg"}
                        border={"1px dashed black"}
                        height={"100px"}
                        width={"100%"}
                        fontWeight={"bold"}
                        style={isDragging ? { color: "red" } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Choose a file or Drag it here.
                      </Button>
                      <Text
                        fontSize={{ base: "10px", md: "12px", lg: "13px" }}
                        my={"5px"}
                      >
                        *Upload a file, not exceeding 5 MB in size, in the
                        formats .jpeg, .png, or .jpg.
                      </Text>
                      {/* <button onClick={onImageRemoveAll}>
                      Remove all images
                    </button> */}
                      {errors && (
                        <Flex
                          p={2}
                          fontSize={"11px"}
                          backgroundColor={"#FDF5F1"}
                          border={"1px solid red"}
                        >
                          <UnorderedList>
                            <ListItem>
                              Only files with the following extensions are
                              allowed: png,jpg,jpeg.
                            </ListItem>
                            <ListItem>
                              The number of files should not exceed the maximum
                              number.
                            </ListItem>
                            <ListItem>
                              The file size should not exceed 5MB.
                            </ListItem>
                          </UnorderedList>
                        </Flex>
                      )}
                      <SimpleGrid columns={[1, null, 1]}>
                        {imageList.map((shopimage, index1) => (
                          <Flex
                            boxShadow={"lg"}
                            borderRadius={"10px"}
                            backgroundColor={"#E1E1E1"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            mt={"10px"}
                            key={index1}
                            flexDir={{ base: "column", md: "row", lg: "row" }}
                          >
                            <Flex
                              justifyContent={"center"}
                              alignItems={"center"}
                              width={{ base: "100%", md: "60%", lg: "60%" }}
                              flexDir={"column"}
                            >
                              <Image
                                mt={"10px"}
                                objectFit={"cover"}
                                borderRadius={"10px"}
                                boxShadow={"lg"}
                                src={shopimage.data_url}
                                alt="images"
                                width={"50%"}
                              ></Image>
                              <Flex p={2}>
                                <Button
                                  height={{
                                    base: "30px",
                                    md: "20px",
                                    lg: "20px",
                                  }}
                                  width={{
                                    base: "5px",
                                    md: "20px",
                                    lg: "20px",
                                  }}
                                  onClick={() => onImageUpdate(index1)}
                                >
                                  <Image src={Update}></Image>
                                </Button>
                                <Button
                                  height={{
                                    base: "30px",
                                    md: "20px",
                                    lg: "20px",
                                  }}
                                  width={{
                                    base: "5px",
                                    md: "20px",
                                    lg: "20px",
                                  }}
                                  onClick={() => onImageRemove(index1)}
                                >
                                  <Image width={"15px"} src={Delete}></Image>
                                </Button>
                              </Flex>
                            </Flex>

                            {/* <DateRangePicker  onDateSelection={handleDateSelection}/> */}
                            <DateRangePicker
                              onDateSelection={(fromDate, tillDate) =>
                                handleDateSelection(fromDate, tillDate, index1)
                              }
                            />
                          </Flex>
                        ))}
                        {offerEdit?.map((offer, index1) => (
                          <Flex
                            boxShadow={"lg"}
                            borderRadius={"10px"}
                            backgroundColor={"#E1E1E1"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            mt={"10px"}
                            key={index1}
                            flexDir={{ base: "column", md: "row", lg: "row" }}
                          >
                            <Flex
                              justifyContent={"center"}
                              alignItems={"center"}
                              width={{ base: "100%", md: "60%", lg: "60%" }}
                              flexDir={"column"}
                            >
                              <Image
                                mt={"10px"}
                                objectFit={"cover"}
                                borderRadius={"10px"}
                                boxShadow={"lg"}
                                src={offer.offerImage}
                                alt="images"
                                width={"50%"}
                              ></Image>
                              <Flex p={2}>
                                {/* <Button
                                  height={{
                                    base: "30px",
                                    md: "20px",
                                    lg: "20px",
                                  }}
                                  width={{
                                    base: "5px",
                                    md: "20px",
                                    lg: "20px",
                                  }}
                                  onClick={() => onImageUpdate(index1)}
                                >
                                  <Image src={Update}></Image>
                                </Button> */}
                                <Button
                                  height={{
                                    base: "30px",
                                    md: "20px",
                                    lg: "20px",
                                  }}
                                  width={{
                                    base: "5px",
                                    md: "20px",
                                    lg: "20px",
                                  }}
                                  // onClick={() => RemoveOffer(index1)}
                                  onClick={() =>
                                    handleshopimagesDeleteImage(index1)
                                  }
                                >
                                  <Image width={"15px"} src={Delete}></Image>
                                </Button>
                              </Flex>
                            </Flex>
                            <Flex
                              flexDir={"column"}
                              gap={4}
                              width={{ base: "85%", md: "80%", lg: "80%" }}
                              justifyContent={"center"}
                            >
                              <Flex
                                w={"80%"}
                                px={4}
                                bg={"white"}
                                justifyContent={"center"}
                                boxShadow={"lg"}
                                borderRadius={"md"}
                                height={"35px"}
                                alignItems={"center"}
                              >
                                {new Date(offer.From).toLocaleDateString()}
                              </Flex>
                              <Flex
                                w={"80%"}
                                px={4}
                                bg={"white"}
                                justifyContent={"center"}
                                boxShadow={"lg"}
                                borderRadius={"md"}
                                height={"35px"}
                                alignItems={"center"}
                              >
                                {new Date(offer.To).toLocaleDateString()}
                              </Flex>
                            </Flex>
                          </Flex>
                        ))}
                      </SimpleGrid>
                    </Flex>
                  )}
                </ImageUploading>
              </Flex>
            </Flex>

            <Flex
              mr={{ base: "20px", md: "20px", lg: "0px" }}
              justifyContent={"end"}
            >
              <CommonButton
                isLoading={loading}
                type={"submit"}
                height="34px"
                width="100px"
                mt="20px"
                fontSize="14px"
                btn={"Save"}
              />
            </Flex>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ShopOfferForm;
