export const furnitureFilterObjects = [
  { name: "Dressing Table" },
  { name: "TV Unit" },
  { name: "Display Rack" },
  { name: "Console" },
  { name: "Wardrobe" },
  {
    name: "Sofa",
    sub1: "Single seater",
    sub2: "2 seater",
    sub3: "3 or more seater",
    sub4: "Corner Sofa",
  },
  { name: "Table", sub1: "Center/ Side table", sub2: "Dining table" },
  { name: "Chair" },
  { name: "Cots" },
  { name: "Side boards" },
  { name: "Chest of Drawers" },
  { name: "Office Furniture" },
  { name: "Furniture set" },
  { name: "Door" },
];
