import {
  Container,
  Flex,
  Grid,
  Spinner,
  Input,
  Button,
  Image,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../Components/shared/Header";
import DesignersHeader from "../../Assets/Images/Headers/DesignersHeader.webp";
import DesignersHeaderMob from "../../Assets/Images/Headers/DesignersHeaderMob.webp";
import CommonFilter from "../../Components/shared/Filter/CommonFilter";
import ReactPaginate from "react-paginate";
import { designers } from "./DummyDesignersData";
import DesignersCollection from "./DesignersCollection";
import Footer from "../../Components/Footer";
import { getDesigners } from "../../Functions/GetApiCalls";
import { DesignerFilter } from "./FilterFunction/DesignerFilter";
import MobileCommonFilter from "../../Components/shared/Filter/MobileCommonFilter";
import Breadcrumbs from "../../Components/shared/Breadcrumbs";
import Search from "../../Assets/Images/search.png";
import Close from "../../Assets/Images/close.png";
function DesignersScreen() {
  // serach
  const [query, setQuery] = useState("");
  const handleClear = () => {
    setQuery("");
  };
  const [loading, setLoading] = useState(false);
  const [district, setDistrict] = useState([]);
  const [profession, setProfession] = useState([]);
  const [designersData, setDesignersData] = useState([]);
  const [filteredDesignerData, setFilteredDesignerData] = useState([]);

  const [pageNumber, setPageNumber] = useState();

  //Pagination Handler
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const designersPerPage = 12;
  const pageCount = Math.ceil(
    district.length == 0 && profession.length == 0
      ? designersData.length / designersPerPage
      : filteredDesignerData.length / designersPerPage
  );

  const pagesVisited = pageNumber * designersPerPage;
  // Display designers based on filters
  const displayDesigners =
    district.length == 0 && profession.length == 0
      ? designersData
          .filter(
            (designer) =>
              (designer.name &&
                designer.name.toLowerCase().includes(query.toLowerCase())) ||
              (designer.profession &&
                designer.profession
                  .toLowerCase()
                  .includes(query.toLowerCase())) ||
              (designer.artistSpecialization &&
                designer.artistSpecialization
                  .toLowerCase()
                  .includes(query.toLowerCase()))
          )
          .slice(pagesVisited, pagesVisited + designersPerPage)
          .map((item, index) => {
            return <DesignersCollection key={index} item={item} />;
          })
      : filteredDesignerData
          .filter(
            (designer) =>
              (designer.name &&
                designer.name.toLowerCase().includes(query.toLowerCase())) ||
              (designer.profession &&
                designer.profession
                  .toLowerCase()
                  .includes(query.toLowerCase())) ||
              (designer.artistSpecialization &&
                designer.artistSpecialization
                  .toLowerCase()
                  .includes(query.toLowerCase()))
          )
          .slice(pagesVisited, pagesVisited + designersPerPage)
          .map((item,index) => {
            return <DesignersCollection key={index} item={item} />;
          });

  // fetch designers data
  useEffect(() => {
    const fetchDesigners = async () => {
      setLoading(true);
      await getDesigners(setDesignersData);
      setLoading(false);
    };
    fetchDesigners();
  }, []);
  // Set initial value for filteredDesignerData
  useEffect(() => {
    setFilteredDesignerData(designersData);
  }, [designersData]);
  // pagenumber
  useEffect(() => {
    if (displayDesigners.length === 0) {
      setPageNumber(0);
    }
  }, [displayDesigners]);

  // filter designers based on selections
  useEffect(() => {
    DesignerFilter(
      designersData,
      setFilteredDesignerData,
      district,
      profession
    );
  }, [district, profession]);

  return (
    <Flex
      flexDir={"column"}
      bg={"linear-gradient(180deg, #FFF 0%, #FFFAF2 29.69%, #D6AC85 100%)"}
    >
      <Container id="designers" maxW="2000px" width={"100vw"} p={0}>
        <Header
          search={false}
          heading="Creators"
          image={DesignersHeader}
          imageMob={DesignersHeaderMob}
        />
        {/* Search */}
        <Flex
          mb={{ base: "25%", md: "20%", lg: "7%" }}
          width={"100%"}
          mt={{ base: "-120px", md: "-180px", lg: "-180px" }}
        >
          {" "}
          <Flex mx={"auto"}>
            <Input
              height={{ base: "34px", md: "40px", lg: "40px" }}
              w={{ base: "210px", md: "300px", lg: "300px" }}
              color={"white"}
              type="text"
              placeholder="Search creator names / specialization..."
              value={query}
              className="newsearch"
              onChange={(e) => setQuery(e.target.value)}
            ></Input>
            {!query && (
              <Button
                height={{ base: "34px", md: "40px", lg: "40px" }}
                backgroundColor={"#FFF3E7"}
                _hover={{ backgroundColor: "white" }}
                onClick={handleClear}
                className="closeButton"
              >
                <Image
                  objectFit={"contain"}
                  width={"20px"}
                  src={Search}
                ></Image>
              </Button>
            )}
            {query && (
              <Button
                height={{ base: "34px", md: "40px", lg: "40px" }}
                _hover={{ backgroundColor: "white" }}
                backgroundColor={"#FFF3E7"}
                onClick={handleClear}
                className="closeButton"
              >
                <Image objectFit={"contain"} width={"20px"} src={Close}></Image>
              </Button>
            )}
          </Flex>
        </Flex>
        {/* Search */}
        <Container maxW="1600px" width={"100vw"}>
          <Flex ml={{ base: "2%", md: "2%", lg: "15%" }}>
            <Breadcrumbs />
          </Flex>
          <Flex
            py={{ base: 0, md: 0, lg: 4 }}
            px={{ base: 0, md: 4, lg: 0, xl: 10, "2xl": 20 }}
            justifyContent={{ base: "center", md: "center", lg: "auto" }}
          >
            <CommonFilter
              FilterTitle="Filter"
              DistrictFilter={1}
              district={district}
              setDistrict={setDistrict}
              ProfessionFilter={1}
              profession={profession}
              setProfession={setProfession}
            />
            <MobileCommonFilter
              FilterTitle="Filter"
              MobDistrictFilter={1}
              district={district}
              setDistrict={setDistrict}
              MobProfessionFilter={1}
              profession={profession}
              setProfession={setProfession}
            />
            <Flex
              flexDir={"column"}
              // m={{ base: 0, md: 2, lg: 2 }}
              // mx={{ base: 0, md: 2, lg: 0, xl: 4, "2xl": 8 }}
              py={{ base: 0, md: 0, lg: 8 }}
              px={{ base: 8, md: 8, lg: 8 }}
              alignItems={"center"}
              justifyContent={"space-between"}
              minW={{ base: "350px", md: "650px", lg: "800px" }}
              minH={{ base: "350px", md: "650px", lg: "800px" }}
            >
              {!loading ? (
                displayDesigners.length !== 0 ||
                filteredDesignerData.length !== 0 ? (
                  <>
                    <Grid
                      templateColumns={[
                        "repeat(1, 1fr)",
                        "repeat(2, 1fr)",
                        "repeat(3, 1fr)",
                        "repeat(3, 1fr)",
                      ]}
                      gap={8}
                    >
                      {displayDesigners}
                    </Grid>
                    <Flex
                      pt={"25px"}
                      alignItems={"center"}
                      justifyContent="center"
                      boxShadow={"lg"}
                      borderRadius="5px"
                      m="20px"
                      width="fit-content"
                      bg={"whiteAlpha.900"}
                    >
                      <ReactPaginate
                        marginPagesDisplayed={0}
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        forcePage={pageNumber}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                      />
                    </Flex>
                  </>
                ) : (
                  <Box
                    minW={{ lg: "480px", xl: "740px", "2xl": "890px" }}
                    align={"center"}
                  >
                    No results found
                  </Box>
                )
              ) : (
                <Box
                  minW={{ lg: "480px", xl: "740px", "2xl": "890px" }}
                  align={"center"}
                >
                  <Spinner />
                </Box>
              )}
            </Flex>
          </Flex>
        </Container>
        <Footer />
      </Container>
    </Flex>
  );
}

export default DesignersScreen;
