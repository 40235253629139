import axios from "axios";
import { State } from "../Context/Context";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_URL } from "../Config";
import { config } from "./Auth";
import { getItemFromLocalStorage } from "../utils/local-storage";
export const useGetShopData = () => {
  const { shop, setShop } = State();
  const client = getItemFromLocalStorage("client");
  const isShop = client?.role === "shopOwner";
  const query = useQuery({
    queryKey: [shop],
    queryFn: async () => {
      const { data } = await axios.get(
        `${API_BASE_URL}/shop/singleShop`,
        config()
      );
      setShop(data);
      return data;
    },
    enabled: isShop,
  });
  return { shop, query };
};

export const useGetUserData = () => {
  const { user, setUser } = State();;
  const query = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const { data } = await axios.get(`${API_BASE_URL}/users`, config());
      setUser(data);
      return data;
    },
    // enabled: isUser,
  });
  return { user, query };
};

export const useGetDesignerData = () => {
  const { designer, setDesigner } = State();
  const client = getItemFromLocalStorage("client");
  const isDesigner = client?.role === "architect";
  const query = useQuery({
    queryKey: ["designer"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${API_BASE_URL}/designer/singleDesigner`,
        config()
      );
      setDesigner(data);
      return data;
    },
    enabled: isDesigner,
  });
  return { designer, query };
};
