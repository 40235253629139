import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import CommonButton from "../../Components/shared/CommonButton.js";
import FormInput from "../../Components/FormInputs/FormInput.js";
import {
  Button,
  Divider,
  AbsoluteCenter,
  Text,
  Flex,
  Box,
  Stack,
  chakra,
  useToast,
  Input,
} from "@chakra-ui/react";
import { API_BASE_URL } from "../../Config/index.js";
import Google from "../../Assets/Images/google.png";
import Eyesclose from "../../Assets/Images/eyeclose.png";
import Eyesopen from "../../Assets/Images/eyeopen.png";
import axiosInstance, { axiosErrorHandler } from "../../Config/axios.js";
import { Image } from "@chakra-ui/react";
import axios from "axios";
import { setItemToLocalStorage } from "../../utils/local-storage.js";
import { useAuth } from "../../auth/index.js";
import { State } from "../../Context/Context.js";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
function SignUpForm({ role, setModalClose }) {
  const [credentialResponseDecoded, setCredentialResponseDecoded] = useState(0);
  const navigate = useNavigate();
  const { token, setToken } = State();
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (newUserData) => {
    setBtnIsLoading(true);
    let email, password;
    if (credentialResponseDecoded.email && credentialResponseDecoded.sub) {
      email = credentialResponseDecoded.email;
      password = credentialResponseDecoded.sub;
    } else if (newUserData.email) {
      email = newUserData.email;
    }
    if (
      !email.trim() &&
      !credentialResponseDecoded.email &&
      !credentialResponseDecoded.sub
    ) {
      setBtnIsLoading(false);
      toast({
        title: "All fields are required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      const { data } = await axios.post(`${API_BASE_URL}/users/`, {
        email,
        role,
        password: password,
      });

      setBtnIsLoading(false);
      if (data?.status === "Success") {
        localStorage.removeItem("activeLink");
        toast({
          title: "Registration Successful.",
          description: password
            ? "User Registered successfully"
            : "Your account has been successfully registered. An email has been sent to your registered email ID with your password. Please follow the instructions in the email.",
          status: "success",
          duration: null,
          isClosable: true,
          position: "top", // Adjust the position to display in the middle of the page
    
        });
        
        setModalClose(true);
        setTimeout(() => {
          setModalClose(false);
        }, 1000);

        // navigation 
        if (password) {
          if (data) {
            if (data.token) {
              setItemToLocalStorage("styhomtoken", data.token);
              setItemToLocalStorage("client", data.currentUser);
              // setModalClose(true);
              setToken(data.token);
          switch (role) {
            case "shopOwner":
              navigate("/shop-dashboard");
              break;
            case "architect":
              navigate("/designer-dashboard");
              break;
            default:
              navigate("/home");
          }
        }}}

      }
    } catch (error) {
      setBtnIsLoading(false);
      const errorValue = error?.response?.data;
      console.log(errorValue);
      if (errorValue) {
        toast({
          title: "Failed",
          description: errorValue?.message,
          status: "error",
          position: "top",
          isClosable: true,
        });

        setCredentialResponseDecoded(0);
      }
    }
  };
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = tokenResponse.access_token;
      // fetching userinfo
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );
      const result = userInfo.data;
      setCredentialResponseDecoded(result);
      // contains name, email & googleId(sub)
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <Stack
      height={"100%"}
      width={"100%"}
      as={chakra.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex
        mx={"auto"}
        width={{ base: "100%", md: "300px", lg: "350px" }}
        flexDir={"column"}
      >
        <Flex
          flexDir={"column"}
          p={2}
          mb={"20px"}
          borderRadius={"5px"}
          border={"1px solid #8E7961"}
        >
          {/*Google Signup  */}
          <Flex flexDir={"column"} width={"100%"} mx={"auto"}>
            {!credentialResponseDecoded.email && (
              <Button
                borderRadius={"5px"}
                backgroundColor={"white"}
                boxShadow={"lg"}
                width={"100%"}
                onClick={() => login()}
              >
                Sign up with Google{" "}
                <Image ml={"10px"} width={"25px"} src={Google}></Image>
              </Button>
            )}
            <Text
              // mt={"5px"}
              _hover={{ bg: "white" }}
              mx={"auto"}
              fontSize={{ base: "11px", md: "12px", lg: "12px" }}
            >
              {credentialResponseDecoded.email}
            </Text>
            {credentialResponseDecoded.email &&
              credentialResponseDecoded.sub && (
                <Text
                  // backgroundColor={"white"}
                  color={"red.800"}
                  p={1}
                  mt={"10px"}
                  _hover={{ bg: "white" }}
                  mx={"auto"}
                  fontSize={{ base: "11px", md: "12px", lg: "12px" }}
                >
                  *Click on the Register button to register your account.
                </Text>
              )}
          </Flex>

          {credentialResponseDecoded === 0 && (
            <Box position="relative" padding="5">
              <Divider border={"1px solid #AB7B4D"} />
              <AbsoluteCenter
                fontSize={{ base: "10px", md: "13px", lg: "14px" }}
                bg="#E8E6E4"
                px="1"
              >
                or Sign Up with Email
              </AbsoluteCenter>
            </Box>
          )}

          {/* real email */}

          {credentialResponseDecoded === 0 && (
            <FormInput
              name="email"
              type="email"
              inputFor="email"
              register={register}
              error={errors.email}
              validator={{
                required: "This field is required",
                pattern: {
                  value: /^\S+@\S+\.[a-z]{2,3}$/i,
                  message: "Not a valid email format",
                },
              }}
              backgroundColor={"white"}
              boxShadow={"lg"}
              placeholder="Email"
            />
          )}
        </Flex>
        <CommonButton
          className="REG"
          isLoading={isSubmitting}
          type={"submit"}
          height="34px"
          width="100%"
          fontSize={{ base: "15px", md: "16px", lg: "16px" }}
          btn={"Register"}
        />
        <Text mt={"20px"} fontSize={{ base: "12px", md: "12px", lg: "13px" }}>
          By continuing, you agree to Lokolumo's{" "}
          <HashLink smooth to="/terms-and-conditions" style={{ color: "teal" }}>
            Conditions of Use
          </HashLink>{" "}
          and{" "}
          <HashLink smooth to={"/privacy-policy"} style={{ color: "teal" }}>
            Privacy Policy
          </HashLink>
          .
        </Text>
      </Flex>
    </Stack>
  );
}

export default SignUpForm;
