import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { State } from "../../Context/Context";
import { furniture } from "./DummyCollections/FurnitureNStorage";
import Collections from "./CollectionsCard/Collections";
import { useLocation, useNavigate } from "react-router-dom";
import { wallArts } from "./DummyCollections/WallArts";
import { getCategoryStores } from "../../Functions/GetApiCalls";
import {
  CollectionTypeFiltering,
  ProductTypeFiltering,
} from "./FilterLogics/CollectionFilters";
import { furnitureFilterObjects } from "../../Components/shared/Filter/FilterObjects/FurnitureFilterObjects";
import { wallartFilterObjects } from "../../Components/shared/Filter/FilterObjects/WallartFilterObjects";
import { lightingFilterObjects } from "../../Components/shared/Filter/FilterObjects/LightingFilterObjects";
import { decorFilterObjects } from "../../Components/shared/Filter/FilterObjects/DecorFilterObjects";
import { furnishingFilterObjects } from "../../Components/shared/Filter/FilterObjects/FurnishingFilterObjects";
import { tileFilterObjects } from "../../Components/shared/Filter/FilterObjects/TileFilterObjects";
import { bathroomFilterObjects } from "../../Components/shared/Filter/FilterObjects/BathroomFilterObjects";
import { furnitureFilterMaterial } from "../../Components/shared/Filter/FilterObjects/FurnitureFilterMaterial";
import { decorFilterMaterial } from "../../Components/shared/Filter/FilterObjects/DecorFilterMaterial";
import { furnitingFilterMaterial } from "../../Components/shared/Filter/FilterObjects/FurnitingFilterMaterial";
import { tileFilterMaterial } from "../../Components/shared/Filter/FilterObjects/TileFilterMaterial";
import { Flex, Grid, Text } from "@chakra-ui/layout";
import MobileCommonFilter from "../../Components/shared/Filter/MobileCommonFilter";
import { Image } from "@chakra-ui/image";
import DiscountWow from "../../Assets/Images/Discount/DiscountWow.webp";
import DiscountOfferCard from "../../Components/shared/DiscountOfferCard";
import { carouselImages } from "../Stores/SingleStores/DummyStoreData";
import CommonFilter from "../../Components/shared/Filter/CommonFilter";
import ReactPaginate from "react-paginate";
import { Spinner } from "@chakra-ui/spinner";
import ItemCollection from "../Stores/SingleStores/ItemCollection/ItemCollection";
import { getStores } from "../../Functions/GetApiCalls";
import { Button } from "@chakra-ui/react";
export const SingleStoreCollectionsScreen = () => {
  const { categ, id } = useParams();
  const { selection, setSelection } = State();
  const [pageNumber, setPageNumber] = useState(0);
  const [collections, setCollections] = useState(furniture);
  const [loading, setLoading] = useState(false);
  const [categoryStoresData, setCategoryStoresData] = useState([]);
  const [filteredcategoryData, setFilteredCategoryData] = useState([]);
  const [filterObjects, setFilterObjects] = useState([]);
  const [materialObjects, setMaterialObjects] = useState([]);
  const [collectionType, setCollectionType] = useState([]);
  const [style, setStyle] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subCollection, setSubCollection] = useState([]);
  const [priceValue, setPriceValue] = useState([0, 100000]);
  const [color, setColor] = useState([]);
  const [texture, setTexture] = useState([]);
  const [material, setMaterial] = useState([]);
  const [categoryProduct, setCategoryProduct] = useState([]);
  const [offerImages, setOfferImages] = useState([]);
  const [storesData, setStoresData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state?.item;
  const filters = location.state.filters;
  useEffect(() => {
    setCollectionType(filters?.collectionType);
    setSubCollection(filters?.subCollection);
    setPriceValue(filters?.priceValue);
    setDistrict(filters?.district);
    setStyle(filters?.style);
    setColor(filters?.color);
    setTexture(filters?.texture);
    setMaterial(filters?.material);
  }, [filters?.collectionType]);
  // Pagination handler
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const collectionsPerPage = 12;
  const pageCount = Math.ceil(
    collectionType.length === 0 &&
      style.length === 0 &&
      district.length === 0 &&
      priceValue.every((val, index) => val === [0, 100000][index])
      ? categoryStoresData.length / collectionsPerPage
      : filteredcategoryData.length / collectionsPerPage
  );
  const pagesVisited = pageNumber * collectionsPerPage;
  const selectionItems = [];
  state?.product?.map((item, index) => {
    item?.category === selection && selectionItems.push(item);
  });
  // Display collections based on filters
  const displayCollections =
    collectionType.length === 0 &&
    style.length === 0 &&
    priceValue.every((val, index) => val === [0, 100000][index]) &&
    color.length === 0 &&
    texture.length === 0 &&
    material.length === 0
      ? selectionItems
          .slice(pagesVisited, pagesVisited + collectionsPerPage)
          .map((item, index) => {
            return (
              <Flex
                onClick={() => {
                  navigate(`/stores/${state?._id}/${item?._id}#product-section`, {
                    state: { item: state, product: item },
                  });
                }}
              >
                <ItemCollection
                  image={item?.productimage[0]}
                  name={item.productname}
                  price={item.price}
                />
              </Flex>
            );
          })
      : filteredcategoryData
          ?.slice(pagesVisited, pagesVisited + collectionsPerPage)
          .map((item) => (
            <Flex
              onClick={() => {
                navigate(`/stores/${state?._id}/${item?._id}#product-section`, {
                  state: { item: state, product: item },
                });
              }}
            >
              <ItemCollection
                image={item.productimage[0]}
                name={item.productname}
                price={item.price}
              />
            </Flex>
          ));

  // pagenumber
  useEffect(() => {
    if (displayCollections.length === 0) {
      setPageNumber(0);
    }
  }, [displayCollections]);
  // offer
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getStores(setStoresData);
      setLoading(false);
    };
    fetchData(); // Call the async function
  }, []);
  useEffect(() => {
    const filteredData = storesData
      .flatMap((item) => {
        return item?.offer?.filter((offer) => {
          return (
            new Date(offer.From) <= new Date() &&
            new Date() <= new Date(offer.To)
          );
        });
      })
      .map((offer) => offer.offerImage);
    setOfferImages(filteredData);
  }, [storesData]);
  // Fetch collections based on selection
  useEffect(() => {
    let selectedCollection = [];
    const newCategoryProduct = state?.product
      .slice(pagesVisited, pagesVisited + collectionsPerPage)
      .filter((item) => item?.category === selection);
    setCategoryProduct(newCategoryProduct);
    switch (selection) {
      case "furniture":
        selectedCollection = furniture;
        break;
      case "wallArt":
        selectedCollection = wallArts;
        break;
      case "lighting":
        selectedCollection = wallArts;
        break;
      case "decoratives":
        selectedCollection = wallArts;
        break;
      case "furnishing":
        selectedCollection = wallArts;
        break;
      case "tile":
        selectedCollection = wallArts;
        break;
      case "veneer":
        selectedCollection = wallArts;
        break;
      case "sanitary":
        selectedCollection = wallArts;
        break;
      case "kitchen":
        selectedCollection = wallArts;
        break;
      default:
        break;
    }

    setCollections(selectedCollection);

    // APi call
    const fetchData = async () => {
      setLoading(true);
      await getCategoryStores(setCategoryStoresData, selection);
      setLoading(false);
    };

    fetchData();
  }, [state?.product, selection]);

  // Set selection from url
  useEffect(() => {
    setSelection(categ);
  }, [categ]);
  // Filter collections based on selected filters
  useEffect(() => {
    ProductTypeFiltering(
      collectionType,
      state?.product,
      setFilteredCategoryData,
      filteredcategoryData,
      style,
      subCollection,
      priceValue,
      color,
      texture,
      material,
      selection
    );
  }, [
    selection,
    collectionType,
    style,
    subCollection,
    priceValue,
    color,
    texture,
    material,
  ]);

  // -------------------count------------------
  let count = 0;
  // -------------------count------------------

  // Set filter objects based on selection
  useEffect(() => {
    let selectedFilterObjects = [];

    switch (selection) {
      case "furniture":
        selectedFilterObjects = furnitureFilterObjects;
        break;
      case "wallArt":
        selectedFilterObjects = wallartFilterObjects;
        break;
      case "lighting":
        selectedFilterObjects = lightingFilterObjects;
        break;
      case "decoratives":
        selectedFilterObjects = decorFilterObjects;
        break;
      case "furnishing":
        selectedFilterObjects = furnishingFilterObjects;
        break;
      case "tile":
        selectedFilterObjects = tileFilterObjects;
        break;
      case "sanitary":
        selectedFilterObjects = bathroomFilterObjects;
        break;
      default:
        break;
    }

    setFilterObjects(selectedFilterObjects);
    setFilteredCategoryData([]);
    // setDistrict([]);
    // setStyle([]);
    // setCollectionType([]);
    // setColor([]);
    // setMaterial([]);
    // setTexture([]);
  }, [selection]);

  // TODO what is this code?
  // // Set material objects based on selection
  useEffect(() => {
    let selectedFilterMaterialObjects = [];

    switch (selection) {
      case "furniture":
        selectedFilterMaterialObjects = furnitureFilterMaterial;
        break;

      case "decoratives":
        selectedFilterMaterialObjects = decorFilterMaterial;
        break;
      case "furnishing":
        selectedFilterMaterialObjects = furnitingFilterMaterial;
        break;
      case "tile":
        selectedFilterMaterialObjects = tileFilterMaterial;
        break;

      default:
        break;
    }

    setMaterialObjects(selectedFilterMaterialObjects);
    setFilteredCategoryData([]);
    // setDistrict([]);
    // setStyle([]);
    // setCollectionType([]);
  }, [selection]);
  return (
    <Flex justifyContent={"center"}>
      <MobileCommonFilter
        MobColorFilter={1}
        MobDistrictFilter={1}
        MobStyleFilter={
          selection === "veneer" || selection === "kitchen" ? 0 : 1
        }
        MobPricefilter={1}
        MobileFilterObjects={filterObjects}
        MobileMaterialObjects={materialObjects}
        MobMaterialfilter={
          selection === "tile" ||
          selection === "decoratives" ||
          selection === "furnishing" ||
          selection === "furniture"
            ? 1
            : 0
        }
        MobProductfilter={
          selection !== "kitchen" && selection !== "veneer" ? 1 : 0
        }
        MobTextureFilter={
          selection === "tile" || selection === "veneer" ? 1 : 0
        }
        collection={collectionType}
        setCollection={setCollectionType}
        style={style}
        setStyle={setStyle}
        subCollection={subCollection}
        setSubCollection={setSubCollection}
        selection={selection}
        priceValue={priceValue}
        setPriceValue={setPriceValue}
        color={color}
        setColor={setColor}
        texture={texture}
        setTexture={setTexture}
        material={material}
        setMaterial={setMaterial}
      />
      <Flex
        py={4}
        px={{ base: 0, md: 4, lg: 0, xl: 10, "2xl": 20 }}
        justifyContent={{ base: "center", md: "center", lg: "auto" }}
        alignItems={{ base: "center", lg: "flex-start" }}
        flexDir={{ base: "column", lg: "row" }}
      >
        <Flex flexDir={"column"} maxWidth={"340px"}>
          {offerImages.length > 0 && (
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              mb={{ base: 8 }}
            >
              <Flex alignItems={"center"} mb={"10px"}>
                <Image src={DiscountWow} width={"70px"} zIndex={0} />
                <Text
                  fontWeight={"bold"}
                  fontSize={"25px"}
                  ml={"-10px"}
                  zIndex={1}
                  color={"yellow.600"}
                >
                  Lokolumo Kin Deals Corner
                </Text>
              </Flex>
              <DiscountOfferCard
                width={{ base: "350px", md: "550px", lg: "400px" }}
                carouselImages={offerImages}
              />
            </Flex>
          )}
          <CommonFilter
            ColorFilter={1}
            FilterObjects={filterObjects}
            MaterialObjects={materialObjects}
            Pricefilter={1}
            StyleFilter={
              selection === "veneer" || selection === "kitchen" ? 0 : 1
            }
            TextureFilter={
              selection === "tile" || selection === "veneer" ? 1 : 0
            }
            MaterialFilter={
              selection === "tile" ||
              selection === "decoratives" ||
              selection === "furnishing" ||
              selection === "furniture"
                ? 1
                : 0
            }
            Productfilter={
              selection !== "kitchen" && selection !== "veneer" ? 1 : 0
            }
            collection={collectionType}
            setCollection={setCollectionType}
            style={style}
            setStyle={setStyle}
            subCollection={subCollection}
            setSubCollection={setSubCollection}
            selection={selection}
            priceValue={priceValue}
            setPriceValue={setPriceValue}
            color={color}
            setColor={setColor}
            texture={texture}
            setTexture={setTexture}
            material={material}
            setMaterial={setMaterial}
            collectionStore={true}
          />
        </Flex>

        <Flex flexDir={"column"}>
          <Button
            bg="green.300"
            height={"35px"}
            width={"300px"}
            ml={{ base: "0", md: "0", lg: "3%" }}
            mb={{ base: "10px", md: "10px", lg: "0px" }}
            onClick={() => {
              navigate(`/collections/${selection}`, {
                state: {
                  filters: {
                    collectionType,
                    district,
                    priceValue,
                    style,
                    subCollection,
                    color,
                    texture,
                    material,
                  },
                },
              });
              // on back button click add count to local storage
              localStorage.setItem("count", count);
            }}
          >
            Back to filtered results
          </Button>
          <Flex
            flexDir={"column"}
            m={{ base: 0, md: 2, lg: 8 }}
            mx={{ base: 0, md: 2, lg: 0, xl: 4, "2xl": 8 }}
            py={8}
            px={{ base: 8, md: 8, lg: 8, xl: 10 }}
            bg={"white"}
            borderRadius={"8px"}
            boxShadow={"2px 4px 4px 0px rgba(0, 0, 0, 0.15) inset"}
            alignItems={"center"}
            justifyContent={"space-between"}
            minW={{ md: "650px", xl: "800px", "2xl": "940px" }}
            minH={{ md: "1000px", "2xl": "1200px" }}
          >
            {!loading ? (
              categoryStoresData.length !== 0 &&
              displayCollections.length != 0 ? (
                <>
                  {" "}
                  <Grid
                    templateColumns={[
                      "repeat(1, 1fr)",
                      "repeat(2, 1fr)",
                      "repeat(2, 1fr)",
                      "repeat(3, 1fr)",
                    ]}
                    gap={28} // Adjust the gap between cards as needed
                  >
                    {displayCollections && displayCollections}
                  </Grid>
                  <Flex
                    pt={"25px"}
                    alignItems={"center"}
                    justifyContent="center"
                    boxShadow={"lg"}
                    borderRadius="5px"
                    m="20px"
                    width="fit-content"
                  >
                    <ReactPaginate
                      marginPagesDisplayed={0}
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      forcePage={pageNumber}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </Flex>
                </>
              ) : (
                <>No results found</>
              )
            ) : (
              <>
                <Spinner />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
