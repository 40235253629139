import React, { useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "./App.css";
import HomeScreen from "./Screens/HomeScreen";

import DesignersScreen from "./Screens/Designers/DesignersScreen";
import SingleDesigner from "./Screens/Designers/SingleDesigner/SingleDesigner";
import ContactUs from "./Screens/ContactUs/ContactUs";
import SingleStores from "./Screens/Stores/SingleStores/SingleStoresScreen";
import StoresListScreen from "./Screens/Stores/StoresListScreen";
import LandingScreen from "./Screens/MainPage/LandingScreen";
import CollectionsLayout from "./Screens/Collections/CollectionsLayout";
import SingleStoresLayout from "./Screens/Stores/SingleStores/SingleStoresLayout";
import SingleStoresScreen from "./Screens/Stores/SingleStores/SingleStoresScreen";
import SingleProductScreen from "./Screens/Stores/SingleStores/SingleProduct/SingleProductScreen";
import ShopDashboard from "./Screens/Dashboard/Shop/ShopDashboard";
import DesignerDashboard from "./Screens/Dashboard/Designer/DesignerDashboard";
import WishlistScreen from "./Screens/WishlistScreen";
import { AuthProvider } from "./auth";
import CartScreen from "./Screens/CartScreen";
import Mainpage from "./Screens/MainPage/Mainpage";
import { SingleStoreCollectionsScreen } from "./Screens/Collections/SingleStoreCollectionsScreen";
import Faq from "./Screens/FAQ/Faq";
import { NumberOfUsersProvider } from "./NumberOfUsersProvider";
import CollectionsScreen from "./Screens/Collections/CollectionsScreen/CollectionsScreen";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import TermsConditions from "./Screens/TermsConditions";

function App() {
  const numberOfDisplayedCarts = 0; // Set your initial value here

  return (
    <BrowserRouter>
    <NumberOfUsersProvider initialValue={numberOfDisplayedCarts}>
      <AuthProvider>
        <Routes>
          <Route index element={<Mainpage />} />
          <Route path="/home" element={<LandingScreen />} />
          {/* 👈 Renders at /app/ */}
          <Route path="/collections/" element={<CollectionsLayout />}>
            <Route path=":categ" element={<CollectionsScreen />} />
            <Route
              path=":categ/:id"
              element={<SingleStoreCollectionsScreen />}
            />
            <Route
              path=":categ/:shopId/:productId"
              element={<SingleProductScreen />}
            />
          </Route>
          <Route path="/creators" element={<DesignersScreen />} />
          <Route path="/creators/:id" element={<SingleDesigner />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/stores" element={<StoresListScreen />} />
          <Route path="/stores/:id" element={<SingleStoresLayout />}>
            <Route index element={<SingleStoresScreen />} />
            <Route path=":id" element={<SingleProductScreen />} />
          </Route>
          <Route path="/shop-dashboard" element={<ShopDashboard />} />
          <Route path="/designer-dashboard" element={<DesignerDashboard />} />
          <Route path="/wishlist" element={<WishlistScreen />} />
          <Route path="/cart" element={<CartScreen />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
        </Routes>

      </AuthProvider>
      </NumberOfUsersProvider>
    </BrowserRouter>
  );
}

export default App;
