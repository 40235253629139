import { furnitureFilterObjects } from "../../../Components/shared/Filter/FilterObjects/FurnitureFilterObjects";

// Filter collection data based on conditions
export const CollectionTypeFiltering = (
  collectionType,
  categoryStoresData,
  setFilteredCategoryData,
  filteredcategoryData,
  selection,
  style,
  subCollection,
  priceValue,
  color,
  texture,
  material,
  district
) => {
  let filteredData = categoryStoresData; // Initialize filtered data with the original category stores data
  let filteredShops = []; // Initialize an array to hold the filtered shops

  // Filter the data based on the conditions provided
  filteredData?.forEach((shop) => {
    // Check if the shop has products that satisfy all conditions
    let hasProducts = shop.product.some((productItem) => {
      let matchesSelection = productItem?.category === selection;
      let matchesCollectionType = collectionType.length === 0 || collectionType.includes(productItem.type);
      let matchesStyle = style.length === 0 || style.includes(productItem.productstyle);
      let matchesColor = color.length === 0 || productItem.productcolor.some((pc) => color.includes(pc));
      let matchesTexture = texture.length === 0 || texture.includes(productItem.texture);
      let matchesMaterial = material.length === 0 || material.includes(productItem.material);
      let matchesPrice = priceValue[0] <= productItem.price && (priceValue[1] >= productItem.price || priceValue[1] === 100000);

      if (subCollection.length > 0) {
        const typesToRemove = [];
        subCollection.forEach((subType) => {
          // Find the associated type (name) from furnitureFilterObjects
          const associatedType = furnitureFilterObjects.find((furnitureItem) =>
            Object.values(furnitureItem).includes(subType)
          );
          if (associatedType) {
            typesToRemove.push(associatedType.name);
          }
        });

        var matchesSubCollectionType =
          subCollection.length === 0 ||
          (subCollection.includes(productItem.subType) && typesToRemove.includes(productItem.type)) ||
          !typesToRemove.includes(productItem.type);

        return (
          matchesSelection &&
          matchesCollectionType &&
          matchesStyle &&
          matchesColor &&
          matchesTexture &&
          matchesMaterial &&
          matchesPrice &&
          matchesSubCollectionType
        );
      } else {
        return (
          matchesSelection &&
          matchesCollectionType &&
          matchesStyle &&
          matchesColor &&
          matchesTexture &&
          matchesMaterial &&
          matchesPrice
        );
      }
    });

    // If the shop has products that satisfy the conditions, add it to the filtered shops
    if (hasProducts && (district.length === 0 || district.includes(shop.addressLine3))) {
      filteredShops.push(shop);
    }
  });

  setFilteredCategoryData(filteredShops); // Update the filtered category data state
  return filteredShops; // Return the filtered shops
};

// Filter products of particular store data based on conditions
export const ProductTypeFiltering = (
  collectionType,
  categoryStoresData,
  setFilteredCategoryData,
  filteredcategoryData,
  style,
  subCollection,
  priceValue,
  color,
  texture,
  material,
  selection
) => {
  let filteredData = categoryStoresData; // Initialize filtered data with the original category stores data

  if (collectionType.length > 0) {
    // Filter by collectionType if it is present
    filteredData = filteredData.filter((item) => {
      return item.category === selection && collectionType.includes(item.type);
    });
  }

  if (style.length > 0) {
    // Filter by style if it is present
    filteredData = filteredData.filter((item) => {
      return item.category === selection && style.includes(item.productstyle);
    });
  }

  if (!priceValue.every((val, index) => val === [0, 100000][index])) {
    // Filter by price range if it is present
    filteredData = filteredData.filter((item) => {
      if (priceValue[1] === 100000) {
        return item.category === selection && priceValue[0] <= item.price;
      } else {
        return item.category === selection && priceValue[0] <= item.price && item.price <= priceValue[1];
      }
    });
  }

  if (subCollection.length > 0) {
    // Find the unique types that match the selected subTypes
    const typesToRemove = new Set();
    subCollection.forEach((subType) => {
      const associatedType = furnitureFilterObjects.find((furnitureItem) =>
        Object.values(furnitureItem).includes(subType)
      );
      if (associatedType) {
        typesToRemove.add(associatedType.name);
      }
    });

    let newDataWithSubTypes = [];
    const updatedProduct = filteredData.filter((productItem) =>
      subCollection.includes(productItem.subType)
    );
    if (updatedProduct.length > 0) {
      newDataWithSubTypes = newDataWithSubTypes.concat(updatedProduct);
    }
    filteredData = filteredData.filter((item) => {
      return !typesToRemove.has(item.type);
    });
    filteredData = [...filteredData, ...newDataWithSubTypes];
  }

  if (color.length > 0) {
    // Filter by color if it is present
    filteredData = filteredData.filter((item) => {
      return (
        item.category === selection &&
        item.productcolor.some((pc) => color.includes(pc))
      );
    });
  }

  if (texture.length > 0) {
    // Filter by texture if it is present
    filteredData = filteredData.filter((item) => {
      return item.category === selection && texture.includes(item.texture);
    });
  }

  if (material.length > 0) {
    // Filter by material if it is present
    filteredData = filteredData.filter((item) => {
      return item.category === selection && material.includes(item.material);
    });
  }

  setFilteredCategoryData(filteredData); // Update the filtered category data state
};
