import { Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import CommonButton from "../../../Components/shared/CommonButton";
import axios from "axios";
import { API_BASE_URL } from "../../../Config";
import { config } from "../../../Functions/Auth";
import { useQueryClient } from "@tanstack/react-query";

export const DeleteConfirm = ({ onClose, productdataitem, offer }) => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const deleteProduct = async () => {
    setLoading(true);
    productdataitem &&
      (await axios.delete(
        `${API_BASE_URL}/shop/deleteProduct/${productdataitem._id}`,
        config()
      ));
    offer &&
      (await axios.delete(
        `${API_BASE_URL}/shop/deleteOffer/${offer._id}`,
        config()
      ));
    setLoading(false);
    onClose(true);
    setTimeout(() => {
      onClose(false);
    }, 1000);
    await queryClient.invalidateQueries("shop");
  };
  return (
    <Flex flexDir={"column"} gap={6}>
      <Text> Are you sure you want to delete the product?</Text>
      <Flex width={"100%"} justifyContent={"flex-end"} gap={4}>
        <CommonButton
          fontSize="14px"
          btn={"No"}
          onClick={() => {
            onClose(true);
            setTimeout(() => {
              onClose(false);
            }, 1000);
          }}
        />
        <CommonButton
          isLoading={loading}
          fontSize="14px"
          btn={"Yes"}
          onClick={() => deleteProduct()}
        />
      </Flex>
    </Flex>
  );
};
