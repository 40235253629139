import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import CommonButton from "./shared/CommonButton";
import {
  clearLocalStorage,
  removeItemFromLocalStorage,
} from "../utils/local-storage";
import { useNavigate } from "react-router-dom";
import { State } from "../Context/Context";

export const LogoutConfirm = (props) => {
  const { shop, setShop, setUser, setDesigner, setToken } = State();
  const navigate = useNavigate();
  const logout = () => {
    removeItemFromLocalStorage("styhomtoken");
    removeItemFromLocalStorage("client");
    navigate("/home");
    setShop();
    setUser();
    setDesigner();
    setToken();
  };
  return (
    <Flex flexDir={"column"} gap={8}>
      <Text fontSize={20} fontWeight={"bold"} color={"brand.Brown"}>
        Are you sure to Log Out?
      </Text>
      <Flex justifyContent={"flex-end"} width={"100%"} gap={4}>
        <CommonButton
          onClick={() => {
            props.onClose(true);
            setTimeout(() => props.onClose(false), 1000);
          }}
          btn={<Text fontSize={16}>No</Text>}
        />
        <CommonButton
          onClick={() => logout()}
          btn={<Text fontSize={16}>Yes</Text>}
        />
      </Flex>
    </Flex>
  );
};
