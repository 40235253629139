import {
  Box,
  Container,
  Flex,
  Image,
  Link,
  Stack,
  Text,
  InputGroup,
  InputLeftAddon,
  chakra,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../../Components/shared/Header";
import ContactUsHeader from "../../Assets/Images/Headers/ContactUsHeader.webp";
import ContactUsHeaderMob from "../../Assets/Images/Headers/ContactUsHeaderMob.webp";
import FormInput from "../../Components/FormInputs/FormInput";
import { useForm } from "react-hook-form";
import TextArea from "../../Components/FormInputs/TextArea";
import CommonButton from "../../Components/shared/CommonButton";
import {
  FaFacebook,
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaAddressBook ,
  FaMailBulk,
  FaPhoneSquare,
} from "react-icons/fa";
import ContactLeaf from "../../Assets/Images/ContactLeaf.png";
import Footer from "../../Components/Footer";
import { ContactUS } from "../../Functions/GetApiCalls.js";

function ContactUs() {

  return (
    <Flex
      flexDir={"column"}
      bg={"linear-gradient(180deg, #FFF 0%, #FFFAF2 29.69%, #FFE9D4 82.81%)"}
      minH={"100vh"}
    >
      <Container id="contactus" maxW="2000px" width={"100vw"} p={0}>
        <Header
          heading="Help"
          bgSize={{ base: "100% 250px", md: "100% 420px", lg: "100% 520px" }}
          height={{ base: "250px", md: "420px", lg: "520px" }}
          image={ContactUsHeader}
          imageMob={ContactUsHeaderMob}
        />
        <Container maxW="1600px" width={"100vw"}>
          <Flex
            width={"100%"}
            justifyContent={"center"}
            mt={{ base: "-110px", md: "-250px", lg: "-350px" }}
            mb={{ base: "50px", md: "150px", lg: "150px" }}
          >
            <Flex
              width={{ base: "100%", md: "80%", lg: "70%", xl: "50%" }}
              bg={"#F6F6F6"}
              borderRadius={"10px"}
              boxShadow={"lg"}
            >
              <Flex
                width={{ base: "100%", md: "60%" }}
                p={{ base: 4, md: 8 }}
                py={{ base: 12, md: 14 }}
                flexDir={"column"}
              >
                <Text fontSize={"20px"}> Help centre</Text>
                <LoginForm />
              </Flex>
              <Flex
                borderRadius={"10px"}
                width={"40%"}
                display={{ base: "none", md: "flex" }}
                bg={"linear-gradient(180deg, #AA9179 0%, #917961 100%)"}
                p={{ base: 4, md: 8 }}
                py={{ base: 4, md: 14 }}
                flexDir={"column"}
                position={"relative"}
              >
                <Text color={"white"} as={"b"} fontSize={"20px"}>Contact Info</Text>
                <Flex mt={"55px"} px={3} flexDir={"row"}>
                  <Box color={"white"}>
                    <FaPhoneSquare size={23} />
                  </Box>
                  <Text color="white" ml={"5px"}>
                  +91 8921844256
                  </Text>
                </Flex>
                <Flex mt={"35px"} px={3} flexDir={"row"}>
                  <Box color={"white"}>
                    <FaMailBulk size={23} />
                  </Box>
                  <Text color="white" ml={"5px"}>
                  help@lokolumo.com
                  </Text>
                </Flex>
                <Flex mt={"35px"} px={3} flexDir={"row"}>
                  <Box color={"white"}>
                    <FaAddressBook size={23} />
                  </Box>
                  <Flex flexDir={"column"}>
                  <Text color="white" ml={"5px"}>
                  Oorakkanadu P. O,
                  </Text>
                  <Text color="white" ml={"5px"}>
                  Kanjirappally,
                
                  </Text>
                  <Text color="white" ml={"5px"}>
                  Kottayam Dt.
                  </Text>
                  <Text color="white" ml={"5px"}>
                 Kerala
                  </Text>
                  <Text color="white" ml={"5px"}>
                  Pin: 686512
                  </Text>
                  </Flex>

                </Flex>
                <Flex  mt={10} width={"100%"} justifyContent={"left"}>
                  <Link isExternal href={"https://www.facebook.com/lokolumo"}>
                    <Flex color={"whiteAlpha.800"} px={3}>
                      <FaFacebookSquare size={24} />
                    </Flex>
                  </Link>
                  <Link isExternal href={"https://www.instagram.com/lokolumo"}>
                    <Flex color={"whiteAlpha.800"} px={3}>
                      <FaInstagramSquare size={24} />
                    </Flex>
                  </Link>
                  <Link isExternal href={"https://www.linkedin.com/company/lokolumo/"}>
                    <Flex color={"whiteAlpha.800"} px={3}>
                      <FaLinkedin size={24} />
                    </Flex>
                  </Link>
                </Flex>
                <Box position={"absolute"} bottom={2} right={2}>
                  <Image src={ContactLeaf} width={"100px"} />
                  <Image
                    mt={4}
                    src={ContactLeaf}
                    width={"70px"}
                    transform="rotate(-45deg)"
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Container>
        <Footer />
      </Container>
    </Flex>
  );
}

const LoginForm = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (e) => {
    // Loading
    setLoading(true);
    try {

      await ContactUS(e.name, e.phone, e.email, e.message);
      setSuccess(true);
      reset(); // Reset the form fields
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      console.error('Sending failed:', error);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Stack
      spacing={4}
      pt={8}
      width={"100%"}
      as={chakra.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormInput
        name="name"
        inputFor="name"
        register={register}
        error={errors.name}
        validator={{
          required: "This field is required",
        }}
        placeholder="Name"
        style={{
          border: "0px",
          borderBottom: "1px solid #D0D5DD",
          borderRadius: 0,
        }}
        handleFocus={
          ({
            outline: "transparent",
          },
          {
            borderColor: "transparent",
          })
        }
      />
        <InputGroup>
                  <InputLeftAddon
                  mt={"9.5px"}
                  border={"0px solid red"}
                     backgroundColor={"#F6F6F6"}
                       height={"38px"}
                  >+91</InputLeftAddon>
      <FormInput
        name="phone"
        inputFor="phone"
        register={register}
        error={errors.phone}
        validator={{
          required: "This field is required",
          pattern: {
            value: /^(\+?\d{10,15})$/,
            message: "Not a valid phone number",
          },
        }}
        placeholder="Phone"
        style={{
          border: "0px",
          borderBottom: "1px solid #D0D5DD",
          borderRadius: 0,
        }}
        handleFocus={
          ({
            outline: "transparent",
          },
          {
            borderColor: "transparent",
          })
        }
      />
      </InputGroup>
      <FormInput
        name="email"
        inputFor="email"
        register={register}
        error={errors.email}
        validator={{
          required: "This field is required",
          pattern: {
            value: /^\S+@\S+\.[a-z]{2,3}$/i,
            message: "Not a valid email format",
          },
        }}
        placeholder="Email"
        style={{
          border: "0px",
          borderBottom: "1px solid #D0D5DD",
          borderRadius: 0,
        }}
        handleFocus={
          ({
            outline: "transparent",
          },
          {
            borderColor: "transparent",
          })
        }
      />
      <TextArea
        name="message"
        inputFor="message"
        register={register}
        error={errors.deliveryNote}
        placeholder="How can we help?"
        _placeholder={{ fontSize: "16px" }}
        // iconLeft={"£"}
        style={{
          border: "0px",
          borderBottom: "1px solid #D0D5DD",
          maxHeight: "38px",
          borderRadius: "0px",
        }}
        handleFocus={
          ({
            outline: "transparent",
          },
          {
            borderColor: "transparent",
          })
        }
      />
      <CommonButton
        isLoading={loading}
        btn={"Submit"}
        fontSize={"12px"}
        alignSelf={"flex-end"}
        type={"submit"}
      />
      {loading && (
        <Text mt={2} color="gray.600" fontWeight={"bold"}>
          Please wait...
        </Text>
      )}

      {success && (
        <Text mt={2} color="green.500" fontWeight={"bold"}>
          Successfully submitted.
        </Text>
      )}
    </Stack>
  );
};
export default ContactUs;
