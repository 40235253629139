import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";

import CommonFilter from "../../../Components/shared/Filter/CommonFilter";
import DiscountSection from "./DiscountSection";
import { State } from "../../../Context/Context";
import {
  furnitureFilterObjects,
  wallartFilterObjects,
  lightingFilterObjects,
  decorFilterObjects,
  furnishingFilterObjects,
  tileFilterObjects,
  bathroomFilterObjects,
  furnitureFilterMaterial,
  decorFilterMaterial,
  furnitingFilterMaterial,
  tileFilterMaterial,
} from "../../../Components/shared/Filter/FilterObjects";
import CollectionsMaincontent from "./CollectionsMaincontent";
import MobileCommonFilter from "../../../Components/shared/Filter/MobileCommonFilter";

/**
 * The CollectionsScreen component is the main screen for the collections section of the application. It handles the state management and filtering of collections based on various criteria such as category, collection type, style, district, sub-collection, price range, color, texture, and material.
 *
 * The component uses the `useParams` hook to get the category from the URL parameters, and then updates the `selection` state accordingly. It then defines the appropriate filter objects and material objects based on the selected category, and updates the `filterObjects` and `materialObjects` states.
 *
 * The component renders the `MobileCommonFilter` and `CommonFilter` components to allow the user to filter the collections based on the various criteria. It also renders the `CollectionsMaincontent` component to display the filtered collections.
 *
 * @returns {JSX.Element} The CollectionsScreen component
 */
const CollectionsScreen = () => {
  // Get category from URL parameters
  const { categ } = useParams();

  // State management

  const { selection, setSelection } = State();
  const [filterObjects, setFilterObjects] = useState([]);
  const [materialObjects, setMaterialObjects] = useState([]);

  // filtering states
  const [collectionType, setCollectionType] = useState([]);
  const [style, setStyle] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subCollection, setSubCollection] = useState([]);
  const [priceValue, setPriceValue] = useState([0, 100000]);
  const [color, setColor] = useState([]);
  const [texture, setTexture] = useState([]);
  const [material, setMaterial] = useState([]);

  const location = useLocation();
  let filters = location?.state?.filters;
  
  useEffect(() => {
    if (filters) {
      setCollectionType(filters.collectionType);
      setStyle(filters.style);
      setDistrict(filters.district);
      setSubCollection(filters.subCollection);
      setPriceValue(filters.priceValue);
      setColor(filters.color);
      setTexture(filters.texture);
      setMaterial(filters.material);
    } else {
      setCollectionType([]);
      setStyle([]);
      setDistrict([]);
      setSubCollection([]);
      setPriceValue([0, 100000]);
      setColor([]);
      setTexture([]);
      setMaterial([]);
    }
  }, [filters]);
  let count = 0;

  // -----------------count variable handling-------------------
  useEffect(() => {
    //increment count in local storage
    if (filters) {
      count = localStorage.getItem("count");
      localStorage.setItem("count", Number(count) + 1);
    }
    // if no state passed from back button clear local storage
    if (!filters) localStorage.removeItem("count");
    // if count is incremented, refresh happened, so clear filter
    if (count > 0) {
      location.state = {};
    }
  }, []);
  // -----------------count variable handling-------------------

  // Update filter objects based on selection
  useEffect(() => {
    let selectedFilterObjects = [];
    let selectedMaterialObjects = [];

    // Define filter objects and material objects for each selection
    switch (selection) {
      case "furniture":
        selectedFilterObjects = furnitureFilterObjects;
        selectedMaterialObjects = furnitureFilterMaterial;
        break;
      case "wallArt":
        selectedFilterObjects = wallartFilterObjects;
        break;
      case "lighting":
        selectedFilterObjects = lightingFilterObjects;
        break;
      case "decoratives":
        selectedFilterObjects = decorFilterObjects;
        selectedMaterialObjects = decorFilterMaterial;
        break;
      case "furnishing":
        selectedFilterObjects = furnishingFilterObjects;
        selectedMaterialObjects = furnitingFilterMaterial;
        break;
      case "tile":
        selectedFilterObjects = tileFilterObjects;
        selectedMaterialObjects = tileFilterMaterial;
        break;
      case "sanitary":
        selectedFilterObjects = bathroomFilterObjects;
        break;
      default:
        break;
    }

    // filter by products and filter by material
    setFilterObjects(selectedFilterObjects);
    setMaterialObjects(selectedMaterialObjects);
  }, [selection]);
  // Update selection when category (params) changes
  useEffect(() => {
    setSelection(categ);
  }, [categ]);

  return (
    <Flex>
      {/* Main content area */}
      <MobileCommonFilter
        MobColorFilter={1}
        MobDistrictFilter={1}
        MobStyleFilter={selection === "veneer" || selection === "kitchen" ? 0 : 1}
        MobPricefilter={1}
        MobileFilterObjects={filterObjects}
        MobileMaterialObjects={materialObjects}
        MobMaterialfilter={
          selection === "tile" ||
          selection === "decoratives" ||
          selection === "furnishing" ||
          selection === "furniture"
            ? 1
            : 0
        }
        MobProductfilter={
          selection !== "kitchen" && selection !== "veneer" ? 1 : 0
        }
        MobTextureFilter={
          selection === "tile" || selection === "veneer" ? 1 : 0
        }
        collection={collectionType}
        setCollection={setCollectionType}
        style={style}
        setStyle={setStyle}
        district={district}
        setDistrict={setDistrict}
        subCollection={subCollection}
        setSubCollection={setSubCollection}
        selection={selection}
        priceValue={priceValue}
        setPriceValue={setPriceValue}
        color={color}
        setColor={setColor}
        texture={texture}
        setTexture={setTexture}
        material={material}
        setMaterial={setMaterial}
      />
      <Flex
        mx="auto"
        py={4}
        px={{ base: 0, md: 4, lg: 0, xl: 10, "2xl": 20 }}
        justifyContent={{ base: "center", md: "center", lg: "auto" }}
        alignItems={{ base: "center", md: "center", lg: "flex-start" }}
        flexDir={{ base: "column", lg: "row" }}
      >
        {/* Sidebar with discount section and common filter */}
        <Flex flexDir="column" maxWidth="340px">
          <DiscountSection />
          <CommonFilter
            StyleFilter={selection === "veneer" || selection === "kitchen" ? 0 : 1}
            TextureFilter={
              selection === "tile" || selection === "veneer" ? 1 : 0
            }
            MaterialFilter={
              ["tile", "decoratives", "furnishing", "furniture"].includes(
                selection
              )
                ? 1
                : 0
            }
            Productfilter={!["kitchen", "veneer"].includes(selection) ? 1 : 0}
            ColorFilter={1}
            Pricefilter={1}
            DistrictFilter={1}
            FilterObjects={filterObjects}
            MaterialObjects={materialObjects}
            collection={collectionType}
            setCollection={setCollectionType}
            style={style}
            setStyle={setStyle}
            subCollection={subCollection}
            setSubCollection={setSubCollection}
            selection={selection}
            priceValue={priceValue}
            setPriceValue={setPriceValue}
            color={color}
            setColor={setColor}
            texture={texture}
            setTexture={setTexture}
            material={material}
            setMaterial={setMaterial}
            district={district}
            setDistrict={setDistrict}
          />
        </Flex>

        {/* Main content area with category stores data */}
        <CollectionsMaincontent
          selection={selection}
          collectionType={collectionType}
          style={style}
          subCollection={subCollection}
          priceValue={priceValue}
          color={color}
          texture={texture}
          material={material}
          district={district}
        />
      </Flex>
    </Flex>
  );
};

export default CollectionsScreen;
