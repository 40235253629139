import { API_BASE_URL } from "../Config";
import axios from "axios";
import { config } from "./Auth";




// get my order details from DB
export const getOrders = async (setOrderData) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/razorpay/list-my-orders`,
      config()
    );
    setOrderData(response?.data);
    // return response?.data[0];
  } catch (error) {
    console.log(error);
  }
};


// get all stores data from DB
export const getStores = async (setStoresData) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/shop`);
    setStoresData(response.data);
  } catch (error) {
    console.log(error);
  }
};

// get single stores data from DB
export const getSingleStores = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/shop/singleShop`,
      config()
    );
    return response?.data[0];
  } catch (error) {
    console.log(error);
  }
};

// get stores by category from db
export const getCategoryStores = async (setCategoryStoresData, selection) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/shop/${selection}`);
    if (response.data) setCategoryStoresData(response.data);
    else setCategoryStoresData([]);
  } catch (error) {
    console.log(error);
  }
};

//Post Subscribers mails to DB
export const SubscribeMailPost = async (email) => {
  await axios.post(
    `${API_BASE_URL}/subscribe/`,
    { email },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const ContactUS = async (name, phone, email, message) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/contactus/`,
      { name, phone, email, message },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
    } else {
      console.error("Contact sending failed.");
    }
  } catch (error) {
    console.error("Failed to sent the contact:", error);
  }
};

// get all designers data from db
export const getDesigners = async (setDesignersData) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/designer`);
    setDesignersData(response.data);
  } catch (error) {
    console.log(error);
  }
};

// get FAQ data
export const getFaq = async (setFaq) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/faq`);
    setFaq(response?.data?.data);
  } catch (error) {
    console.log(error);
  }
};

// get Lokolumo pics

export const getPics = async (setPics) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/latestHappenings`);
    setPics(response?.data?.data[0]);
  } catch (error) {
    console.log(error);
  }
};

// get related products
export const getRelatedProducts = async (
  setRelatedProducts,
  type,
  productId
) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/shop/product/${productId}/${type}`
    );
    setRelatedProducts(response.data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
