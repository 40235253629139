import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";

function TextArea(props) {
  const {
    require,
    name,
    label,
    type,
    inputFor,
    register,
    error,
    validator,
    style = {
      border: "1px solid #D0D5DD",
      maxHeight: "38px",
      backgroundColor: "white",
      borderRadius: "8px",
    },
    handleFocus,
    ...rest
  } = props;
  const [charCount, setCharCount] = useState(0);

  const handleTextareaChange = (event) => {
    const text = event.target.value;
    setCharCount(text.length);
  };

  const isCharLimitExceeded = charCount > 500;
  return (
    <FormControl isInvalid={error || isCharLimitExceeded} mb={"15px"}>
      <FormLabel htmlFor={name} color="brand.formFont" fontSize={"14px"}>
        <Flex direction={"row"}>
          {label}
          {require && <Text color={"red"}>*</Text>}
        </Flex>
      </FormLabel>
      <Textarea
        fontSize={"12px"}
        style={style}
        type={type}
        id={name}
        name={name}
        _focus={handleFocus}
        _focusVisible={handleFocus}
        {...register(inputFor, validator)}
        onChange={handleTextareaChange}
        {...rest}
      />
      <Text fontSize="12px" color={isCharLimitExceeded ? "red" : "black"}>
        Character Count: {charCount} / 500
      </Text>
      {isCharLimitExceeded && (
        <FormErrorMessage>
          Character limit exceeded (Maximum 500 characters).
        </FormErrorMessage>
      )}
    <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}

export default TextArea;
