export const Primary = [
  { value: "furniture", label: "Furniture & Storage" },
  { value: "wallArt", label: "WallArt" },
  { value: "lighting", label: "Lighting & Fan" },
  { value: "decoratives", label: "Decoratives" },
  { value: "furnishing", label: "Furnishing" },
  { value: "tile", label: "Tile" },
  { value: "veneer", label: "Veneer/Laminate" },
  { value: "sanitary", label: "Sanitary" },
  { value: "kitchen", label: "Kitchen" },
];
export const DecorMaterial = [
  { value: "Ceramic", label: "Ceramic" },
  { value: "Wood", label: "Wood" },
  { value: "Metal", label: "Metal" },
  { value: "Glass", label: "Glass" },
  { value: "Terracotta", label: "Terracotta" },
  { value: "Plastic", label: "Plastic" },
  { value: "Other decorative material", label: "Other" },
];
export const FurnitingMaterial = [
  { value: "Cotton", label: "Cotton" },
  { value: "Velvet", label: "Velvet" },
  { value: "Linen", label: "Linen" },
  { value: "Lace", label: "Lace" },
  { value: "Silk", label: "Silk" },
  { value: "Polyster", label: "Polyster" },
  { value: "Jute", label: "Jute" },
  { value: "Crochet", label: "Crochet" },
  { value: "Wool", label: "Wool" },
  { value: "Satin", label: "Satin" },
  { value: "Microfiber", label: "Microfiber" },
  { value: "Other Furniting material", label: "Other" },
];
export const FurnitureMaterial = [
  { value: "Wood/Processed Wood", label: "Wood/Processed Wood" },
  { value: "Glass", label: "Glass" },
  { value: "Fabric", label: "Fabric" },
  { value: "Leather", label: "Leather" },
  { value: "Metal", label: "Metal" },
  { value: "Plastic", label: "Plastic" },
  { value: "Rattan", label: "Rattan" },
  { value: "Stone", label: "Stone" },
  { value: "Ceramic", label: "Ceramic" },
  { value: "Other Furniture material", label: "Other" },
];
export const TileMaterial = [
  { value: "Ceramic", label: "Ceramic" },
  { value: "Marble", label: "Marble" },
  { value: "Granite", label: "Granite" },

  { value: "Vitrified", label: "Vitrified" },
  { value: "Terracotta", label: "Terracotta" },
  { value: "Other Tile material", label: "Other" },
];
export const Texture = [
  { value: "Glossy", label: "Glossy" },
  { value: "Matt", label: "Matt" },
];

export const color = [
  { value: "White", label: "White" },
  { value: "Grey", label: "Grey" },
  { value: "Black", label: "Black" },
  { value: "Brown", label: "Brown" },
  { value: "Red", label: "Red" },
  { value: "Orange", label: "Orange" },
  { value: "Green", label: "Green" },
  { value: "Blue", label: "Blue" },

  { value: "Beige", label: "Beige" },
  { value: "Yellow", label: "Yellow" },
  { value: "Purple", label: "Purple" },
  { value: "Pink", label: "Pink" },
  { value: "Gold", label: "Gold" },
  { value: "Multicolor", label: "Multicolor" },
  { value: "Silver", label: "Silver" },

  { value: "Wooden", label: "Wooden" },
  { value: "Other color", label: "Other" },
];
export const Furniture = [
  { value: "Dressing Table", label: "Dressing Table" },
  { value: "TV Unit", label: "TV Unit" },
  { value: "Display Rack", label: "Display Rack" },
  { value: "Console", label: "Console" },
  { value: "Wardrobe", label: "Wardrobe" },
  { value: "Sofa", label: "Sofa" },
  { value: "Table", label: "Table" },
  { value: "Chair", label: "Chair" },
  { value: "Cots", label: "Cots" },
  { value: "Side boards", label: "Side boards " },
  { value: "Chest of Drawers", label: "Chest of Drawers" },
  { value: "Office Furniture", label: "Office Furniture" },
  { value: "Furniture set", label: "Furniture set" },
  { value: "Door", label: "Door" },

  // Add more options for Storage as needed
];
export const WallArt = [
  { value: "3D Panel", label: "3D Panel" },
  { value: "Wall Paper", label: "Wall Paper" },
  { value: "Cladding Stone", label: "Cladding Stone" },
  { value: "Paint", label: "Paint" },
  { value: "Texture Paint", label: "Texture Paint" },
];
export const Lighting = [
  { value: "Pendant Light", label: "Pendant Light" },
  { value: "Shandelier", label: "Shandelier" },
  { value: "Wall Light", label: "Wall Light" },
  { value: "Ceiling Light", label: "Ceiling Light" },
  { value: "Floor Light", label: "Floor Light" },
  { value: "Table lamp", label: "Table lamp" },
  { value: "Ceiling Fan", label: "Ceiling Fan" },
  { value: "Pedestal Fan", label: "Pedestal Fan" },
];
export const Decoratives = [
  { value: "Vases", label: "Vases" },
  { value: "Sculptures", label: "Sculptures" },
  { value: "Clocks", label: "Clocks" },
  { value: "Wall Decor items", label: "Wall Decor items" },
  { value: "Paintings", label: "Paintings" },
  { value: "Mirrors", label: "Mirrors" },
  { value: "Other Decoratives", label: "Other Decoratives" },
  { value: "Artificial Flowers", label: "Artificial Flowers" },
  { value: "Natural Plants", label: "Natural Plants" },
];
export const Furniting = [
  { value: "Curtains", label: "Curtains" },
  { value: "Cushions", label: "Cushions" },
  { value: "Pillows", label: "Pillows" },
  { value: "Bedspread", label: "Bedspread" },
  { value: "Bed Sheet", label: "Bed Sheet" },
  { value: "Carpets", label: "Carpets/Rugs" },
  { value: "Table Runners", label: "Table Runners" },
  { value: "Quilt", label: "Quilt" },
  { value: "Fabric Material", label: "Fabric Material" },
  { value: "Leather Material", label: "Leather Material" },
];
export const Tile = [
  { value: "Wall Tile", label: "Wall Tile" },
  { value: "Floor Tile", label: "Floor Tile" },
  { value: "Decorative Tile", label: "Decorative Tile" },
  { value: "Kitchen Counters", label: "Kitchen Counters" },
  { value: "Bathroom Wall Tile", label: "Bathroom Wall Tile" },
  { value: "Bathroom Floor Tile", label: "Bathroom Floor Tile" },
];
export const Sanitary = [
  { value: "Wash Basin", label: "Wash Basin" },
  { value: "Bath Tub", label: "Bath Tub" },
  { value: "Shower", label: "Shower" },
  { value: "Health Faucet", label: "Health Faucet" },
  { value: "Water Closet", label: "Water Closet" },
  { value: "Towel Rail", label: "Towel Rail" },
  { value: "Bath Storage", label: "Bath Storage" },
];
export const Sofa = [
  { value: "Single seater", label: "Single seater" },
  { value: "2 seater", label: "2 seater" },
  { value: "3 or more seater", label: "3 or more seater" },
  { value: "Corner Sofa", label: "Corner Sofa" },
];
export const Table = [
  { value: "Center/ Side table", label: "Center/ Side table" },
  { value: "Dining table", label: "Dining table" },
];
export const styles = [
  { value: "Ethnic", label: "Ethnic" },
  { value: "Modern", label: "Modern" },
  { value: "Classic", label: "Classic" },
];

export const priceper = [
  { value: "sqft", label: "sqft" },
  { value: "piece", label: "piece" },
  { value: "meter", label: "meter" },
  { value: "set", label: "set" },
];
