export const districtFilterObjects = [
  { name: "Alappuzha" },
  { name: "Ernakulam" },
  { name: "Idukki" },
  { name: "Kannur" },
  { name: "Kasaragod" },
  { name: "Kollam" },
  { name: "Kottayam" },
  { name: "Kozhikode" },
  { name: "Malappuram" },
  { name: "Palakkad" },
  { name: "Pathanamthitta" },
  { name: "Thiruvananthapuram" },
  { name: "Thrissur" },
  { name: "Wayanad" },
];
