import {
  Box,
  Divider,
  Flex,
  Image,
  Img,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import AboutUsVector from "../../../Assets/Images/MainPage/AboutUsVector.png";
import AboutUsImage from "../../../Assets/Images/MainPage/AboutUsImage.webp";
function AboutUs() {
  return (
    <Flex
      id="AboutUs"
      justifyContent={"center"}
      alignItems={"center"}
      bgImage={AboutUsVector}
      bgSize={"cover"}
      bgRepeat={"no-repeat"}
      width={"100%"}
      flexDir={"column"}
    >
      <Flex
        maxWidth={{ base: "200px", md: "300px", lg: "300px" }}
        flexDirection={"column"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text
          mt={"50px"}
          fontSize={{ base: "22px", md: "30px", lg: "32px" }}
          fontFamily={"heading"}
        >
          About Lokolumo
        </Text>
        <Divider border={"2px solid #E3B78B"} />
      </Flex>
      <Flex
      
        // my={{ base: 8, lg: 20 }}
        mb={{ base: 4, lg: 40, xl: 60 }}
        justifyContent={"flex-end"}
        alignItems={"center"}
        position={"relative"}
        width={{ base: "90%", lg: "70%" }}
        flexDir={{ base: "column-reverse", lg: "row" }}
      >
        <Box
        
          bg={"white"}
          p={{ base: 1, lg: 8, xl: 14 }}
          pt={{ base: 14, md: "100px", lg: "auto" }}
          mt={{ base: "-45px", md: "-50px", lg: "50px" }}
          borderRadius={{ base: 16, lg: 2 }}
          boxShadow={"2px 4px 4px 0px rgba(0, 0, 0, 0.25);"}
          width={{ base: "97%",md:"70%", lg: "75%" }}
          height={"fit-content"}
          // position={{ base: "relative", lg: "absolute" }}
          left={0}
          bottom={{ lg: "100px" }}
          zIndex={{ base: 0, lg: 1 }}
        >
          <Text
            fontSize={{ base: "", lg: "17px", xl: "20px" }}
            p={{ base: 10, md: 8, lg: 5 }}
          >
            LOKOLUMO is the destination where you can locate the perfect
            elements and inspiration to create a luminous atmosphere in your
            home. It is an online platform which connects between a seller, a
            creator and a consumer. We are location centric, which helps a
            customer to identify the nearest store/creator to fulfil their
            needs. LOKOLUMO evolved from the brains of Ms.Geethu Elizabeth Jose,
            an Interior Designer, who thinks differently and aims to help the
            creative community with her unique idea. The spark triggered during
            her career as a designer. “How to design a space with readily
            available products, that too near to the worksite?”, “How do a
            customer can locate the stores/items nearby?” , "How to incorporate
            the creators around to get the best results?"
          </Text>

          <Text
            fontSize={{ base: "", lg: "17px", xl: "20px" }}
            px={{ base: 10, md: 8, lg: 5 }}
          >
            What lokolumo do:
          </Text>
          <Text
            fontSize={{ base: "", lg: "17px", xl: "20px" }}
            px={{ base: 10, md: 8, lg: 5 }}
          >
            <UnorderedList mb={"20px"}>
              <ListItem>
                Consumer : Helps locate the nearest store/vendor to cater his
                requirements. Locate, Visit, Verify and Buy.
              </ListItem>
              <ListItem>
                Seller : Publicize your products to the one who is actually
                looking for. Sell More.
              </ListItem>
              <ListItem>
                Creator : Get yourself introduced to the Public in a domain
                where dreams meet reality.
              </ListItem>
            </UnorderedList>
            "Locate Your Luminous Style with LOKOLUMO!"
          </Text>
        </Box>
        <Image
        ml={{base:"",md:"",lg:"-200px"}}
        mt={{base:"20px",md:"",lg:"200px"}}
          src={AboutUsImage}
          width={{ base: "200px", md: "300px", lg: "55%" }}
          height={{ base: "100px", md: "200px", lg: "600px" }}
          borderRadius={{ base: 8, lg: 16 }}
          boxShadow={"2px 4px 4px 0px rgba(0, 0, 0, 0.25);"}
          zIndex={{ base: 1, lg: 0 }}
          objectFit={"cover"}
        />
      </Flex>
    </Flex>
  );
}

export default AboutUs;
