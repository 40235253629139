import React, { useState } from "react";
import {
  Flex,
  SimpleGrid,
  Image,
  Box,
  Text,
  Stack,
  chakra,
  Link,
  Divider,
  AbsoluteCenter,
  useToast,
} from "@chakra-ui/react";
import Logo1 from "../Assets/Images/footerlogo.png";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
} from "react-icons/fa";
import CommonButton from "../Components/shared/CommonButton.js";
import { SubscribeMailPost } from "../Functions/GetApiCalls.js";
import { HashLink } from "react-router-hash-link";
import FormInput from "./FormInputs/FormInput.js";
import { useForm } from "react-hook-form";
import Phoneicon from "../Assets/Images/phone.png";
import Mailicon from "../Assets/Images/mail.png";
import { AddIcon } from "@chakra-ui/icons";
function Footer() {
  const currentYear = new Date().getFullYear();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const toast = useToast();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onSubmit = async (e) => {
    setLoading(true);
    try {
      await SubscribeMailPost(e.email);
      setEmail("");
      setSubscriptionSuccess(true);
      reset(); // Reset the form fields
      setTimeout(() => {
        setSubscriptionSuccess(false); // Reset subscription success after 10 seconds
      }, 5000);
    } catch (error) {
      toast({
        title: error?.response?.data || "Error in subscription",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setSubscriptionSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex width={"100%"}>
      <Flex
        backgroundColor={"#EED5BE"}
        justifyContent={"center"}
        width={"100%"}
        flexDir={"column"}
      >
        <SimpleGrid
          mx={"auto"}
          width={"80%"}
          color={"brand.Brown"}
          py={{ base: "20px", md: "20px", lg: "30px" }}
          columns={{ base: 1, md: 3, lg: 3 }}
          spacing={{ base: 5, md: 0, lg: 40 }}
        >
          {/* Logo section */}
          <Box p={5}>
            <Image
              ml="auto"
              mr="auto"
              src={Logo1}
              width={{ base: "190px", md: "160px", lg: "220px" }}
            ></Image>
            <Flex width={"100%"} justifyContent={"center"} color={"#8B6038"}>
              <Link isExternal href="https://www.facebook.com/lokolumo">
                {" "}
                <Box px="5px">
                  <FaFacebookSquare size={23} />
                </Box>
              </Link>
              <Link isExternal href="https://www.instagram.com/lokolumo">
                {" "}
                <Box px="5px">
                  <FaInstagramSquare size={23} />
                </Box>{" "}
              </Link>
              <Link
                isExternal
                href="https://www.linkedin.com/company/lokolumo/"
              >
                <Box px="5px">
                  <FaLinkedin size={23} />
                </Box>
              </Link>{" "}
            </Flex>
          </Box>

          {/* Information section  */}
          <Box p={5} mt={{ base: "0px", md: "20px", lg: "20px" }}>
            <Text fontSize={{ base: "15px", md: "15px", lg: "18px" }} as={"b"}>
              Information
            </Text>
            <Flex
              fontSize={{ base: "14px", md: "14px", lg: "16px" }}
              mt={"20px"}
              flexDirection={"column"}
            >
              <HashLink smooth to={"/home#AboutUs"}>
                {" "}
                <Text>About Us</Text>
              </HashLink>
              <HashLink smooth to={"/collections/furniture/#collections"}>
                {" "}
                <Text mt={"5px"}>Collections</Text>
              </HashLink>
              <HashLink smooth to={"/stores/#stores"}>
                {" "}
                <Text mt={"5px"}>Stores</Text>
              </HashLink>
              <HashLink smooth to={"/creators/#designers"}>
                {" "}
                <Text mt={"5px"}>Creators</Text>
              </HashLink>
              <HashLink smooth to={"/contactUs/#contactus"}>
                {" "}
                <Text mt={"5px"}>Help</Text>
              </HashLink>
            </Flex>
          </Box>
          {/* Subscribe Section */}
          <Box
            ml={{ lg: "-60px" }}
            p={5}
            fontSize={{ base: "15px", md: "15px", lg: "18px" }}
            mt={{ base: "0px", md: "20px", lg: "20px" }}
          >
            <Text as={"b"}>Contact Info</Text>
            <Flex
              mb={{ base: "50px", md: "20px", lg: "20px" }}
              flexDirection={"column"}
            >
              <Flex>
                <Image
                  objectFit={"contain"}
                  width={"25px"}
                  mr={"10px"}
                  src={Mailicon}
                ></Image>
                <Text
                  mt={"5px"}
                  fontSize={{ base: "14px", md: "14px", lg: "16px" }}
                >
                  help@lokolumo.com
                </Text>
              </Flex>

              <Flex>
                <Image
                  objectFit={"contain"}
                  width={"20px"}
                  mr={"10px"}
                  src={Phoneicon}
                ></Image>
                <Text
                  mt={"5px"}
                  fontSize={{ base: "14px", md: "14px", lg: "16px" }}
                >
                  +91 8921844256
                </Text>
              </Flex>
            </Flex>

            <Text fontSize={{ base: "15px", md: "15px", lg: "18px" }} as={"b"}>
              Subscribe
            </Text>
            <Flex flexDirection={"column"}>
              {/* Subscription form */}
              {/* <Flex mt={"5px"}>
                <Input variant="filled" placeholder="Email Address" type="email" value={email}
                  onChange={handleEmailChange} />


                <CommonButton mx={3} isLoading={loading} btn={"Subscribe"} onClick={handleSubmit} />

              </Flex> */}
              {/* Subscription form */}
              {/* ....Subscription form.... */}
              <Flex
                // spacing={4}
                // pt={8}
                mt={"5px"}
                // width={"100%"}
                as={chakra.form}
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormInput
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                  name="email"
                  inputFor="email"
                  register={register}
                  error={errors.email}
                  validator={{
                    required: "This field is required",
                    pattern: {
                      value: /^\S+@\S+\.[a-z]{2,3}$/i,
                      message: "Not a valid email format",
                    },
                  }}
                  placeholder="Email Address"
                  style={{
                    border: "0px",
                    borderBottom: "1px solid #D0D5DD",
                    borderRadius: 5,
                  }}
                  handleFocus={
                    ({
                      outline: "transparent",
                    },
                    {
                      borderColor: "transparent",
                    })
                  }
                />

                <CommonButton
                  mx={3}
                  mt="10px"
                  isLoading={loading}
                  btn={"Subscribe"}
                  fontSize={"10px"}
                  // alignSelf={"flex-end"}
                  type={"submit"}
                />
              </Flex>
              {loading && (
                <Text mt={2} color="gray.600" fontWeight={"bold"}>
                  Please wait...
                </Text>
              )}
              {/* .....Subscription form..... */}
              {subscriptionSuccess && (
                <Text color="green" fontSize={15} pt={2}>
                  Successfully subscribed!
                </Text>
              )}
              <Text
                mt={"5px"}
                fontSize={{ base: "14px", md: "14px", lg: "16px" }}
              >
                Get the latest updates via email
              </Text>
            </Flex>
          </Box>
        </SimpleGrid>

        <Box mt="20px" position="relative" padding="7">
          <Divider />
          <AbsoluteCenter
            fontSize={{ base: "10px", md: "12px", lg: "13px" }}
            color={"brand.Brown"}
            bg={"#EED5BE"}
            px={{ base: 1, md: 1, lg: 4 }}
          >
            &copy; {currentYear} Lokolumo. All rights reserved.
          </AbsoluteCenter>
        </Box>
        <Flex mb={"10px"} mx={"auto"}>
          <HashLink  smooth to={"/terms-and-conditions#termsandconditions"}>
            <Text
              _hover={{ color: "teal" }}
              color={"brand.Brown"}
              fontSize={{ base: "10px", md: "12px", lg: "13px" }}
            >
              Terms and Conditions
            </Text>
          </HashLink>
          <HashLink smooth to={"/privacy-policy#privacypolicy"}>
            {" "}
            <Text
              _hover={{ color: "teal" }}
              color={"brand.Brown"}
              fontSize={{ base: "10px", md: "12px", lg: "13px" }}
              ml={"30px"}
            >
              Privacy Policy
            </Text>
          </HashLink>{" "}
          <Link isExternal href={"https://styhom-s3-demo.s3.ap-south-1.amazonaws.com/cancellation+and+refund+policy-2.pdf"}>
            {" "}
            <Text
              _hover={{ color: "teal" }}
              color={"brand.Brown"}
              fontSize={{ base: "10px", md: "12px", lg: "13px" }}
              ml={"30px"}
            >
              Cancellation and Refund Policy
            </Text>
          </Link>{" "}
          <Link isExternal href={"https://styhom-s3-demo.s3.ap-south-1.amazonaws.com/pricing_updated.pdf"}>
            {" "}
            <Text
              _hover={{ color: "teal" }}
              color={"brand.Brown"}
              fontSize={{ base: "10px", md: "12px", lg: "13px" }}
              ml={"30px"}
            >
             Pricing
            </Text>
          </Link>{" "}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Footer;
