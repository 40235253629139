import {
  Container,
  Flex,
  Grid,
  Button,
  Text,
  Divider,
  Link,
  Input,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../..//Components/shared/Header";
import ShopDashboardHeader from "../../../../src/Assets/Images/Headers/StoreDashboardHeader.webp";
import MobShopDashboardHeader from "../../../../src/Assets/Images/Headers/MobStoreDashHeader.webp";
import { shopnavbar } from "../Shop/Shopnavbardata.js";
import UpgradeButton from "../Components/UpgradeButton.js";
import CommonButton from "../../../Components/shared/CommonButton.js";
import Footer from "../../../Components/Footer";

import ShopDetailsForm from "./ShopDetailsForm";
import ShopImagesForm from "./ShopImagesForm";
import ShopOfferForm from "./ShopOfferForm";
import ShopCatalogForm from "./ShopCatalogForm";
import AddProductForm from "./AddProduct/AddProductForm.js";
import ViewProduct from "./ViewProduct";
import { getSingleStores } from "../../../Functions/GetApiCalls.js";
import { useGetShopData } from "../../../Functions/queries.js";
import { State } from "../../../Context/Context.js";
import DesignerSubscription from "../DesignerSubscription.js";
import ShopSubscription from "../ShopSubscription.js";
import PasswordSettings from "../PasswordSettings.js";
import axios from "axios";
import { API_BASE_URL } from "../../../Config/index.js";
import { config } from "../../../Functions/Auth.js";
import { useNavigate } from "react-router-dom";
import NotSubscribed from "../Designer/NotSubscribed.js";
import ShopVideo from "./ShopVideo.js";
import ShopOrdersInvoice from "./ShopOrdersInvoice.js";
function ShopDashboard() {
  const [subscription, setSubscription] = useState(null);
  const [productLimit, setProductLimit] = useState();
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState();
  const {
    isOpen: isOpenMob,
    onOpen: onOpenMob,
    onClose: onCloseMob,
  } = useDisclosure();

  // Second useDisclosure for the second modal
  const {
    isOpen: isOpenDes,
    onOpen: onOpenDes,
    onClose: onCloseDes,
  } = useDisclosure();
  const navigate = useNavigate();
  // for desktop
  const handleCloseModal = () => {
    onCloseDes(); // Close the modal
    // navigate('/cart'); // Navigate to the cart page
  };
  // for mobile
  const handleCloseModalmob = () => {
    onCloseMob(); // Close the modal
    // navigate('/cart'); // Navigate to the cart page
  };
  // upgrade button modal for mobile
  const {
    isOpen: isOpenUp,
    onOpen: onOpenUp,
    onClose: onCloseUp,
  } = useDisclosure();

  // upgrade button modal for desktop
  const {
    isOpen: isOpenDesUp,
    onOpen: onOpenDesUp,
    onClose: onCloseDesUp,
  } = useDisclosure();

  const { shop } = useGetShopData();
  useEffect(() => {
    if (shop) {
      const subscriptionDetails = shop && shop[0]?.subscription;
      if (
        subscriptionDetails?.to &&
        new Date(subscriptionDetails.to) > new Date()
      ) {
        if (subscriptionDetails?.plan === "default") {
          setSubscription("default");
        } else if (subscriptionDetails?.plan === "basic") {
          setSubscription("basic");
        } else if (subscriptionDetails?.plan === "premium") {
          setSubscription("premium");
        }
      } else {
        setSubscription(null);
      }
      if (subscription === "basic" || subscription === "default") {
        // TODO:change product limit of basic and premium plan
        setProductLimit(45);
      } else if (subscription === "premium") {
        // TODO:change product limit of basic and premium plan
        setProductLimit(100);
      }
      shop[0]?.offer?.map(async (offer) => {
        if (new Date(offer?.To) < new Date()) {
          try {
            await axios.delete(
              `${API_BASE_URL}/shop/deleteOffer/${offer._id}`,
              config()
            );
          } catch (err) {
            console.log(err);
          }
        }
      });
    }
    if (!shop) {
      setSubscription("noShop");
    }
  }, [shop, subscription]);

  // const [activeLink, setActiveLink] = useState(1);
  const [activeLink, setActiveLink] = useState(
    parseInt(localStorage.getItem("activeLink")) || 1
  );

  const handleLinkClick = (link) => {
    setActiveLink(link);
    localStorage.setItem("activeLink", link);
  };

  return (
    <Flex
      flexDir={"column"}
      bg={"linear-gradient(180deg, #FFF 0%, #FFFAF2 29.69%, #D6AC85 100%)"}
    >
      <Container maxW="2000px" width={"100vw"} p={0}>
        <Header
          mt={{ base: "0px", md: "30px", lg: "60px" }}
          heading="Dashboard"
          image={ShopDashboardHeader}
          imageMob={MobShopDashboardHeader}
          dashboard={true}
          subscription={subscription}
          enddate={shop && shop.length > 0 ? shop[0]?.subscription.to : null}
        />
        <Flex
          width={"100%"}
          flexDir={{ base: "column", md: "column", lg: "row" }}
        >
          <Flex width={{ lg: "23%" }}>
            <Flex width={"100%"} my={"20px"} px={"10px"}>
              {/* sidenav for mobile and tab */}
              <Flex
                width={"100%"}
                display={{ base: "block", md: "block", lg: "none" }}
                overflow={"scroll"}
                p={3}
                boxShadow={"lg"}
                borderRadius={"10px"}
                flexDir={"row"}
                backgroundColor={"#F1D0AE"}
              >
                <Flex
                  style={{
                    border:
                      activeLink === 6 ? "4px solid white" : "solid #F1D0AE",
                  }}
                  borderRadius={"20px"}
                  width={"fit-content"}
                  height={"fit-content"}
                >
                  <UpgradeButton
                    isDisabled={shop ? false : true}
                    width="130px"
                    height="35px"
                    btn={"Upgrade to PRO"}
                    bg={
                      activeLink === 6
                        ? "#215319"
                        : " linear-gradient(180deg, #92E584 0%, #42994D 54.17%, #227D41 100%)"
                    }
                    onClick={() => handleLinkClick(6)}
                  />
                </Flex>
                <Flex mt={"20px"}>
                  {shopnavbar.map((shopnavbaritem, indexs) => (
                    <Flex px={2} key={indexs}>
                      <Link
                        onClick={() => handleLinkClick(shopnavbaritem.navnum)}
                        style={
                          (subscription === null ||
                            subscription === "noShop") &&
                          indexs != 0 &&
                          indexs !== shopnavbar.length - 1
                            ? { pointerEvents: "none" }
                            : undefined
                        }
                      >
                        <Button
                          style={{
                            backgroundColor:
                              activeLink === shopnavbaritem.navnum
                                ? "#E7E2DC"
                                : "white",
                            boxShadow:
                              activeLink === shopnavbaritem.navnum
                                ? "0 0 1px 2px rgba(145, 121, 97, .75), 0 1px 1px rgba(0, 0, 0, .15)"
                                : "none",
                          }}
                          transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                          _active={{
                            transform: "scale(0.98)",
                          }}
                          boxShadow={"md"}
                          borderRadius={"40px"}
                          height={"36px"}
                        >
                          {shopnavbaritem.navname}
                        </Button>
                      </Link>
                    </Flex>
                  ))}
                  <Flex flexDir={"column"} pr={"10px"}>
                    <CommonButton
                      isDisabled={
                        shop && shop[0].product?.length < productLimit
                          ? false
                          : true
                      }
                      icon={true}
                      onClick={() => onOpenMob()}
                      width={"130px"}
                      fontSize={"13px"}
                      btn={"Add Product"}
                    />
                    <Text
                      display={
                        shop && shop[0].product?.length < productLimit
                          ? "none"
                          : "block"
                      }
                      fontSize={"11px"}
                      textAlign={"center"}
                      color={"red.500"}
                      mx={"auto"}
                    >
                      {productLimit === 45
                        ? "*Upgrade your plan to add more"
                        : productLimit === 100
                        ? "*You have reached the maximum product limit of 100."
                        : ""}
                    </Text>
                  </Flex>
                  <Modal isOpen={isOpenMob} onClose={handleCloseModalmob}>
                    <ModalOverlay />
                    <ModalContent
                      display={{ base: "block", md: "block", lg: "none" }}
                      maxWidth="95%"
                      height="fit-content"
                    >
                      <ModalHeader
                        borderRadius={"5px"}
                        backgroundColor={"#BAAA9B"}
                      >
                        <Text
                          fontSize={{ base: "18px", md: "18px", lg: "20px" }}
                          fontFamily={"heading"}
                        >
                          {" "}
                          Add Product Details
                        </Text>
                        <Text
                          fontSize={{ base: "12px", md: "12px", lg: "13px" }}
                        >
                          *All users have access to view the product's{" "}
                          <b>NAME</b>, <b>PRICE</b>, <b>DESCRIPTION</b>,{" "}
                          <b>LINK</b>, and <b>DIMENSIONS</b>. Please ensure you
                          provide accurate and detailed descriptions.
                        </Text>
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <AddProductForm
                          productLimit={productLimit}
                          setProductLimit={setProductLimit}
                          onClose={handleCloseModalmob}
                        />
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Flex>
              </Flex>

              {/* side nav for desktop */}
              <Flex
                display={{ base: "none", md: "none", lg: "block" }}
                width={"100%"}
                height={"100%"}
                px={"30px"}
                py={3}
                boxShadow={"lg"}
                borderRadius={"10px"}
                flexDir={"column"}
                backgroundColor={"#F1D0AE"}
              >
                <Flex my={"30px"} justifyContent={"center"} width={"100%"}>
                  <Flex
                    style={{
                      border:
                        activeLink === 6 ? "4px solid white" : "solid #F1D0AE",
                    }}
                    borderRadius={"20px"}
                    width={"fit-content"}
                    height={"fit-content"}
                  >
                    <UpgradeButton
                      isDisabled={shop ? false : true}
                      bg={
                        activeLink === 6
                          ? "#215319"
                          : " linear-gradient(180deg, #92E584 0%, #42994D 54.17%, #227D41 100%)"
                      }
                      onClick={() => handleLinkClick(6)}
                      btn={"Upgrade to PRO"}
                    />
                  </Flex>
                </Flex>
                {shopnavbar.map((shopnavbaritem, indexx) => (
                  <Link
                    key={indexx}
                    onClick={() => handleLinkClick(shopnavbaritem.navnum)}
                    isDisabled={"true"}
                    style={
                      (subscription === null || subscription === "noShop") &&
                      indexx != 0 &&
                      indexx !== shopnavbar.length - 1
                        ? { pointerEvents: "none" }
                        : undefined
                    }
                  >
                    <Flex
                      style={{
                        backgroundColor:
                          activeLink === shopnavbaritem.navnum
                            ? "#E7E2DC"
                            : "transparent",
                        boxShadow:
                          activeLink === shopnavbaritem.navnum
                            ? "0 2px 4px rgba(0, 0, 0, 0.1)"
                            : "none",
                      }}
                      _hover={{ bg: "#BEA285" }}
                      width={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDir={"column"}
                      key={indexx}
                      color={
                        (subscription === null || subscription === "noShop") &&
                        indexx != 0 &&
                        indexx !== shopnavbar.length - 1
                          ? "blackAlpha.300"
                          : "black"
                      }
                    >
                      <Text p={4}>{shopnavbaritem.navname}</Text>
                      <Divider />
                    </Flex>
                  </Link>
                ))}
                <Flex
                  my={"20px"}
                  // flexDir={"column"}
                  justifyContent={"center"}
                  width={"100%"}
                >
                  <CommonButton
                    isDisabled={
                      shop && shop[0].product?.length < productLimit
                        ? false
                        : true
                    }
                    icon={true}
                    onClick={onOpenDes}
                    width={"170px"}
                    fontSize={"13px"}
                    btn={"Add Product"}
                  />

                  {/* .............add Product Modal................ */}
                </Flex>
                <Text
                  display={
                    shop && shop[0].product?.length < productLimit
                      ? "none"
                      : "block"
                  }
                  fontSize={"13px"}
                  textAlign={"center"}
                  color={"red.500"}
                  mx={"auto"}
                >
                  {productLimit === 45
                    ? "*Upgrade your plan to add more"
                    : productLimit === 100
                    ? "*You have reached the maximum product limit of 100."
                    : ""}
                </Text>
                <Modal isOpen={isOpenDes} onClose={handleCloseModal}>
                  <ModalOverlay />
                  <ModalContent
                    display={{ base: "none", md: "none", lg: "block" }}
                    maxWidth="80%"
                    height="fit-content"
                  >
                    <ModalHeader
                      borderRadius={"5px"}
                      backgroundColor={"#BAAA9B"}
                    >
                      <Text
                        fontSize={{ base: "18px", md: "18px", lg: "20px" }}
                        fontFamily={"heading"}
                      >
                        {" "}
                        Add Product Details
                      </Text>
                      <Text fontSize={{ base: "12px", md: "12px", lg: "13px" }}>
                        *All users have access to view the product's <b>NAME</b>
                        , <b>PRICE</b>, <b>DESCRIPTION</b>, <b>LINK</b>, and{" "}
                        <b>DIMENSIONS</b>. Please ensure you provide accurate
                        and detailed descriptions.
                      </Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <AddProductForm
                        productLimit={productLimit}
                        setProductLimit={setProductLimit}
                        onClose={handleCloseModal}
                      />
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Flex>
            </Flex>
          </Flex>
          {/* .................Form Part...................................... */}

          {activeLink === 1 && (
            <Flex width={{ lg: "75%" }}>
              <ShopDetailsForm />
            </Flex>
          )}
          {activeLink === 2 && (
            <Flex width={{ lg: "75%" }}>
              <ShopImagesForm subscription={subscription} />
            </Flex>
          )}
          {activeLink === 3 && (
            <Flex width={{ lg: "75%" }}>
              <ShopOfferForm />
            </Flex>
          )}
          {activeLink === 4 && (
            <Flex width={{ lg: "75%" }}>
              <ShopCatalogForm />
            </Flex>
          )}
          {activeLink === 5 && (
            <Flex width={{ lg: "75%" }}>
              <ViewProduct />
            </Flex>
          )}
          {activeLink === 6 && (
            <Flex width={{ lg: "75%" }}>
              <ShopSubscription subscription={subscription} />
            </Flex>
          )}
          {activeLink === 7 && (
            <Flex width={{ lg: "75%" }}>
              <PasswordSettings />
            </Flex>
          )}
          {activeLink === 8 && (
            <Flex width={{ lg: "75%" }}>
              {subscription === "premium" ? <ShopVideo /> : <NotSubscribed />}
            </Flex>
          )}
           {activeLink === 9 && (
            <Flex width={{ lg: "75%" }}>
           <ShopOrdersInvoice/>
            </Flex>
          )}
        </Flex>
        <Footer />
      </Container>
    </Flex>
  );
}

export default ShopDashboard;
