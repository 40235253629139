
import { createContext, useState, useContext } from "react";

const NumberOfUsersContext = createContext();

export const NumberOfUsersProvider = ({ children, initialValue }) => {
  const [numberOfDisplayedCarts, setnumberOfDisplayedCarts] = useState(initialValue.numberOfDisplayedCarts);
  const [wishlistNumber, setWishlistNumber] = useState(initialValue.wishlistNumber);

  return (
    <NumberOfUsersContext.Provider value={{ 
      numberOfDisplayedCarts, 
      setnumberOfDisplayedCarts,
      wishlistNumber,
      setWishlistNumber
    }}>
      {children}
    </NumberOfUsersContext.Provider>
  );
};

export const useNumberOfUsers = () => useContext(NumberOfUsersContext);
