import { Flex, Text, Box, Container } from "@chakra-ui/layout";
import { Divider, AbsoluteCenter, Image } from "@chakra-ui/react";
import React from "react";
import whatwedo from "../../../Assets/Images/MainPage/consumer.mp4";
import video1 from "../../../Assets/Images/MainPage/sellers.mp4";
import video2 from "../../../Assets/Images/MainPage/creators.mp4";
import { Swiper, SwiperSlide } from "swiper/react";
import circle1 from "../../../Assets/Images/MainPage/circle1.png";
import circle2 from "../../../Assets/Images/MainPage/circle2.png";
import circle3 from "../../../Assets/Images/MainPage/circle3.png";
import circle4 from "../../../Assets/Images/MainPage/circle4.png";
import circle5 from "../../../Assets/Images/MainPage/circle5.png";

import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { useRef } from "react";

function WhatWeDo() {
  const swiperRef = useRef(null);

  const pauseVideosInSlide = (slide) => {
    const videos = slide.querySelectorAll('video');
    videos.forEach(video => {
      if (!video.paused) {
        video.pause();
      }
    });
  };

  const onSwiper = (swiper) => {
    swiperRef.current = swiper;
    swiper.on('slideChange', () => {
      const previousSlide = swiper.slides[swiper.previousIndex];
      pauseVideosInSlide(previousSlide);
    });
  };
  return (
    <Flex
 
    id="whatwedo"
      width={"100%"}
      flexDirection={"column"}
      bgRepeat={"no-repeat"}
      backgroundImage={whatwedo}
    >
      <Image
        display={{ base: "none", md: "block", lg: "block" }}
        data-aos-easing="linear"
        data-aos-duration="1200"
        data-aos="flip-up"
        ml={"5%"}
        width={"100px"}
        mt={"300px"}
        position={"absolute"}
        src={circle1}
      ></Image>
      <Image
        data-aos-easing="linear"
        data-aos-duration="1200"
        data-aos="flip-up"
        ml={{ base: "50%", md: "80%", lg: "80%" }}
        width={{ base: "50px", md: "100px", lg: "100px" }}
        mt={"100px"}
        position={"absolute"}
        src={circle2}
      ></Image>
      <Image
        data-aos-easing="linear"
        data-aos-duration="1200"
        data-aos="flip-up"
        ml={"75%"}
        width={{ base: "60px", md: "100px", lg: "100px" }}
        mt={{ base: "560px", md: "470px", lg: "470px" }}
        position={"absolute"}
        src={circle3}
      ></Image>
      <Image
        data-aos-easing="linear"
        data-aos-duration="1200"
        data-aos="flip-up"
        ml={"15%"}
        width={{ base: "80px", md: "110px", lg: "110px" }}
        mt={"530px"}
        position={"absolute"}
        src={circle4}
      ></Image>
      <Image
        data-aos-easing="linear"
        data-aos-duration="1200"
        data-aos="flip-up"
        ml={"25%"}
        width={{ base: "70px", md: "100px", lg: "100px" }}
        mt={"160px"}
        position={"absolute"}
        src={circle5}
      ></Image>
      {/* .....Heading.... */}
      <Flex width={"100%"} zIndex={2} flexDir={"column"}  >
    
        <Flex width={"100%"} justifyContent={"center"}>
          <Flex
            maxWidth={{ base: "200px", md: "400px", lg: "400px" }}
            flexDirection={"column"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Text
              mt={"50px"}
              fontSize={{ base: "22px", md: "30px", lg: "32px" }}
              fontFamily={"heading"}
            >
              What We Do
            </Text>
            <Divider border={"2px solid #E3B78B"} />
          </Flex>
        </Flex>
        {/* ..........What we do............. */}
        <Flex
    
       
          boxShadow={"lg"}
          borderRadius={"20px"}
          backgroundColor={"white"}
          mt={{base:"100px",md:"20px",lg:"20px"}}
          mb={"50px"}
          mx={"auto"}
          width={{base:"95%",md:"90%",lg:"60%"}}
        >
          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper"
            onSwiper={onSwiper}
          >
            <SwiperSlide>
              <Flex m={"50px"} flexDir={"column"}>
                <Flex  borderRadius={"10px"} p={3} pb={"30px"}>
                  <div style={{ marginLeft:"auto", marginRight:"auto"}}>
                    <video
                      playsInline
                      className="videobox"
                      src={whatwedo}
                      style={{ borderRadius: "10px" }}
                      autoPlay={true}
                      muted={true}
                      controls={true}
                      loop
                    />
                  </div>
                </Flex>
                <Box
               
             
                    fontSize={{ base: "15px", md: "17px", lg: "18px" }}
                  position="relative"
                  py={{base:"10",md:"10",lg:"10"}}
                  px={{base:"0",md:"10",lg:"170px"}}
                >
                  <Divider border={"2px solid #E3B78B"} />
                  <AbsoluteCenter textAlign={"center"} bg="white" px={{base:"0px",md:"20px",lg:"20px"}}>
                  Lokolumo for Consumers
                  </AbsoluteCenter>
                </Box>
              </Flex>
            </SwiperSlide>

            <SwiperSlide>
              <Flex m={"50px"} flexDir={"column"}>
                <Flex borderRadius={"10px"} p={3} pb={"30px"}>
                  <div style={{ marginLeft:"auto", marginRight:"auto"}}>
                    <video
                      playsInline
                      className="videobox"
                      src={video2}
                      style={{ borderRadius: "10px" }}
                      autoPlay={true}
                      muted={true}
                      controls={true}
                      loop
                    />
                  </div>
                </Flex>
                <Box
             fontSize={{ base: "15px", md: "17px", lg: "18px" }}
                  position="relative"
                  py={{base:"10",md:"10",lg:"10"}}
                  px={{base:"0",md:"10",lg:"170px"}}
                >
                  <Divider border={"2px solid #E3B78B"} />
                  <AbsoluteCenter textAlign={"center"} bg="white"  px={{base:"5px",md:"20px",lg:"20px"}}>
                  Lokolumo for Creators
                  </AbsoluteCenter>
                </Box>
              </Flex>
            </SwiperSlide>

            <SwiperSlide>
              <Flex m={"50px"} flexDir={"column"}>
                <Flex borderRadius={"10px"} p={3} pb={"30px"}>
                  <div style={{ marginLeft:"auto", marginRight:"auto"}}>
                    <video
                      playsInline
                      className="videobox"
                      src={video1}
                      style={{ borderRadius: "10px" }}
                      autoPlay={true}
                      muted={true}
                      controls={true}
                      loop
                    />
                  </div>
                </Flex>
                <Box
              
                  fontSize={{ base: "15px", md: "17px", lg: "18px" }}
                  position="relative"
                  py={{base:"10",md:"10",lg:"10"}}
                  px={{base:"0",md:"10",lg:"170px"}}
                >
                  <Divider border={"2px solid #E3B78B"} />
                  <AbsoluteCenter textAlign={"center"} bg="white" px={{base:"5px",md:"20px",lg:"20px"}}>
                  Lokolumo for Sellers
                  </AbsoluteCenter>
                </Box>
              </Flex>
            </SwiperSlide>
          </Swiper>
        </Flex>
 
      </Flex>
    </Flex>
  );
}

export default WhatWeDo;
