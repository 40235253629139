import { Flex, Link, Text } from "@chakra-ui/layout";
import React from "react";
import { collectionObjects } from "./CollectionObjects";
import { Image } from "@chakra-ui/image";
import { State } from "../../../Context/Context";
import { useNavigate } from "react-router-dom";

/**
 * Renders the CollectionSelection component, which displays a collection of collection items that the user can select from.
 *
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element} - The rendered CollectionSelection component.
 */
function CollectionSelection(props) {
  const navigate = useNavigate();
  const { selection, setSelection } = State();

  // Navigates to the collection page for the specified collection ID.
  const CollectionSelect = (id) => {
    setSelection(id);
    navigate(`/collections/${id}`);
  };

  return (
    <Flex
      width={"100%"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {/* Collection container with gradient background */}
      <Flex
        width={{ base: "100%", md: "90%" }}
        bg={"linear-gradient(180deg, #FFE4BB 0%, #B1B1B1 100%)"}
        p={4}
        px={{ base: 0, md: 0, lg: 4 }}
        borderRadius={{ base: "0", md: "10px" }}
        boxShadow={{ base: "", md: "-3px 18px 29px -4px rgba(0, 0, 0, 0.30)" }}
        justifyContent={"center"}
      >
        {/* Collection items container */}
        <Flex
          minW={"85%"}
          maxW={"95%"}
          overflowX={"auto"}
          justifyContent={"space-between"}
        >
          {collectionObjects.map((item, index) => (
            <Flex
              key={index}
              boxShadow={"2px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"}
              bg={item.id === selection ? "brand.LightBrown" : "white"}
              p={4}
              borderRadius={"50%"}
              minW={{ base: "70px", md: "90px" }}
              maxW={{ base: "70px", md: "90px" }}
              height={{ base: "70px", md: "90px" }}
              justifyContent={"center"}
              alignItems={"center"}
              mx={{ base: 1, md: 4 }}
              flexDir={"column"}
              cursor={"pointer"}
              onClick={() => CollectionSelect(item.id)}
            >
              {/* Collection item image */}
              <Image src={item.image} width={"40px"} />
              {/* Collection item name */}
              <Text
                fontSize={{ base: 8, md: 12 }}
                fontWeight={"semibold"}
                width={{ base: "60px", md: "80px" }}
                textAlign={"center"}
              >
                {item.name}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CollectionSelection;
