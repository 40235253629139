import FormatIndianRupees from "../../../../Functions/FormatIndianRupees";

export const getPrice = (typeValue, primaryValue, styleValue) => {
  if (primaryValue === "furniture" && styleValue === "Modern") {
    return FormatIndianRupees(500);
  } else if (
    primaryValue === "furniture" &&
    (styleValue === "Classic" || styleValue === "Ethnic")
  ) {
    return FormatIndianRupees(800);
  } else if (typeValue === "Shandelier") {
    return FormatIndianRupees(1000);
  } else if (
    (typeValue === "Pendant Light" ||
      typeValue === "Wall Light" ||
      typeValue === "Ceiling Light" ||
      typeValue === "Floor Light" ||
      typeValue === "Table lamp" ||
      typeValue === "Ceiling Fan" ||
      typeValue === "Pedestal Fan") &&
    styleValue === "Modern"
  ) {
    return FormatIndianRupees(500);
  } else if (
    (typeValue === "Pendant Light" ||
      typeValue === "Wall Light" ||
      typeValue === "Ceiling Light" ||
      typeValue === "Floor Light" ||
      typeValue === "Table lamp" ||
      typeValue === "Ceiling Fan" ||
      typeValue === "Pedestal Fan") &&
    (styleValue === "Classic" || styleValue === "Ethnic")
  ) {
    return FormatIndianRupees(800);
  } else if (primaryValue === "furnishing") {
    return FormatIndianRupees(300);
  } else if (typeValue === "Water Closet" || typeValue === "Wash Basin") {
    return FormatIndianRupees(500);
  } else if (
    typeValue === "Bath Tub" ||
    typeValue === "Shower" ||
    typeValue === "Health Faucet" ||
    typeValue === "Towel Rail" ||
    typeValue === "Bath Storage"
  ) {
    return FormatIndianRupees(300);
  } else if (typeValue === "Paint" || typeValue === "3D Panel") {
    return FormatIndianRupees(180);
  }
};
