import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../Components/shared/CommonButton.js";
import FormInput from "../../Components/FormInputs/FormInput.js";
import {
  Button,
  Divider,
  AbsoluteCenter,
  Text,
  Flex,
  Box,
  Stack,
  chakra,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuth } from "../../auth/index.js";
import Google from "../../Assets/Images/google.png";
import Eyesclose from "../../Assets/Images/eyeclose.png";
import Eyesopen from "../../Assets/Images/eyeopen.png";
import { Image } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import axiosInstance, { axiosErrorHandler } from "../../Config/axios.js";
import { setItemToLocalStorage } from "../../utils/local-storage.js";
import { API_BASE_URL } from "../../Config/index.js";
import { useNavigate } from "react-router-dom";
import { State } from "../../Context/Context.js";
// import { GoogleLogin } from "@react-oauth/google";
// import { jwtDecode } from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";

import axios from "axios";
import ForgotPassword from "./forgot-password.js";
function LoginForm({ setModalClose, wish }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let auth = useAuth();
  const { token, setToken } = State();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const toast = useToast();
  const navigate = useNavigate();
  const {
    // handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [credentialResponseDecoded, setCredentialResponseDecoded] = useState(0);
  // const onSubmit = async (e) => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    let { email, password } = event.target.elements;
    if (credentialResponseDecoded.email && credentialResponseDecoded.sub) {
      email = credentialResponseDecoded.email;
      password = credentialResponseDecoded.sub;
    } else if (email && password) {
      email = email.value;
      password = password.value;
    }

    try {
      const { data } = await axiosInstance.post(`${API_BASE_URL}/users/login`, {
        email,
        password,
      });
      if (data) {
        if (data.token) {
          setItemToLocalStorage("styhomtoken", data.token);
          setItemToLocalStorage("client", data.currentUser);
        }
        //* navigate to the old url from where it is redirected
        auth.signIn(data.token, () => {
          if (!wish) {
            if (data.currentUser.role === "shopOwner") {
              localStorage.removeItem("activeLink");
              navigate("/shop-dashboard");
            } else if (data.currentUser.role === "architect") {
              localStorage.removeItem("activeLink");
              navigate("/designer-dashboard");
            } else if (data.currentUser.role === "user") {
              navigate("/");
            }
          }
        });
        setModalClose(true);
        setToken(data.token);
        setTimeout(() => {
          setModalClose(false);
        }, 1000);
      }
      // * a toast to provide response to user
    } catch (error) {
      // error toast
      const errorValue = error?.response;
      if (errorValue) {
        toast({
          title: "Error",
          description: errorValue?.message || errorValue?.data?.message,
          status: "error",
          position: "top",
          isClosable: true,
        });
        setCredentialResponseDecoded(0);
      }
    }
  };
  // };
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = tokenResponse.access_token;
      // fetching userinfo
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );
      const result = userInfo.data;
      setCredentialResponseDecoded(result);
      // contains name, email & googleId(sub)
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <Stack height={"100%"} mx={"auto"} as={chakra.form} onSubmit={handleSubmit}>
      {/* <Box height={{ base: "20px", md: "120px", lg: "110px" }}></Box> */}
      <Flex
        // my={"20px"}
        width={{ base: "100%", md: "300px", lg: "350px" }}
        flexDir={"column"}
      >
        <Text
          mt={{ base: "10px", md: "40px", lg: "40px" }}
          mb={{ base: "10px", md: "90px", lg: "90px" }}
          ml={"auto"}
          mr={"auto"}
          fontFamily={"heading"}
          fontSize={{ base: "16px", md: "18px", lg: "22px" }}
          color={"#AB7B4D"}
        >
          Welcome Back
        </Text>

        <Flex
          p={5}
          mb={"20px"}
          borderRadius={"5px"}
          border={"1px solid #8E7961"}
          flexDir={"column"}
        >
          <Flex flexDir={"column"} width={"100%"} mx={"auto"}>
          {!credentialResponseDecoded.email && (   <Button
              borderRadius={"5px"}
              backgroundColor={"white"}
              boxShadow={"lg"}
              width={"100%"}
              onClick={() => login()}
            >
              Login with Google{" "}
              <Image ml={"10px"} width={"25px"} src={Google}></Image>
            </Button>
          )}
            <Text
              mt={"10px"}
              _hover={{ bg: "white" }}
              mx={"auto"}
              fontSize={{ base: "11px", md: "12px", lg: "16px" }}
            >
              {credentialResponseDecoded.email}
            </Text>
            {credentialResponseDecoded.email && credentialResponseDecoded.sub && (
            <Text
            backgroundColor={"white"}
            p={1}
              mt={"10px"}
              _hover={{ bg: "white" }}
              mx={"auto"}
              fontSize={{ base: "11px", md: "12px", lg: "12px" }}
            >
             Click on the login button to sign in.
            </Text> )}
          </Flex>
          {!credentialResponseDecoded.email && (  <Box position="relative" padding="10">
            <Divider border={"1px solid #AB7B4D"} />
            <AbsoluteCenter
              fontSize={{ base: "10px", md: "13px", lg: "14px" }}
              bg="#E8E6E4"
              px="1"
            >
              or Login with Email
            </AbsoluteCenter>
          </Box>
          )}
              {!credentialResponseDecoded.email && ( 
          <FormInput
            name="email"
            inputFor="email"
            register={register}
            error={errors.email}
            validator={{
              required: "This field is required",
              pattern: {
                value: /^\S+@\S+\.[a-z]{2,3}$/i,
                message: "Not a valid email format",
              },
            }}
            backgroundColor={"white"}
            boxShadow={"lg"}
            type="text"
            placeholder="Email"
          />
          )}
              {!credentialResponseDecoded.email && ( 
          <Flex position={"relative"}>
            <FormInput
              mt="-25px"
              name="password"
              inputFor="password"
              register={register}
              error={errors.password}
              validator={{
                required: "This field is required",
              }}
              backgroundColor={"white"}
              boxShadow={"lg"}
              type={show ? "text" : "password"}
              placeholder="Password"
            />{" "}
            <Button
              // ml={{ base: "12rem", md: "200px", lg: "240px" }}
              position={"absolute"}
              right={2}
              mt={"10px"}
              width={"50px"}
              height={"20px"}
              onClick={handleClick}
              zIndex={1}
            >
              <Image mt={"5px"} src={show ? Eyesclose : Eyesopen}></Image>
            </Button>
          </Flex>
              )}
        </Flex>

        <CommonButton
          isLoading={loading}
          type={"submit"}
          height="34px"
          width="100%"
          fontSize={{ base: "15px", md: "16px", lg: "16px" }}
          btn={"Login"}
        />

        <Flex mt={"5px"} fontSize={{ base: "10px", md: "12px", lg: "12px" }}>
          <Text onClick={onOpen} cursor={"pointer"} ml={"auto"}>
            Forgot Password?
          </Text>
        </Flex>
        {/* forgot password modal */}
        <Modal
          size={{ base: "sm", md: "md", lg: "md" }}
          isOpen={isOpen}
          onClose={onClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Forgot password?</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <ForgotPassword onClose={onClose} />
            </ModalBody>

            {/* <ModalFooter>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
        <Box height={{ base: "20px", md: "140px", lg: "140px" }}></Box>
      </Flex>
    </Stack>
  );
}

export default LoginForm;
