import {
  Text,
  Flex,
  Image,
  Box,
  Button,
  chakra,
  useToast,
} from "@chakra-ui/react";
import Eyesclose from "../../Assets/Images/eyeclose.png";
import Eyesopen from "../../Assets/Images/eyeopen.png";
import React from "react";
import FormInput from "../../Components/FormInputs/CreateFormInput";
import axiosInstance from "../../Config/axios";
import { API_BASE_URL } from "../../Config";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import ForgotPassword from "../LoginFroms/forgot-password";
function PasswordSettings() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = React.useState(false);
  const [shownew, setShownew] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handlenewClick = () => setShownew(!shownew);
  const toast = useToast();
  const onCancel = (event) => {
    // Prevent the default form submission behavior
    event.preventDefault();

    // Reset the input fields to their initial state
    const oldPasswordInput = document.getElementById("oldPassword");
    const newPasswordInput = document.getElementById("newPassword");

    oldPasswordInput.value = "";
    newPasswordInput.value = "";
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { oldPassword, newPassword } = event.target.elements;
      let currentPassword = oldPassword.value;
      let password = newPassword.value;
      const response = await axiosInstance.patch(
        `${API_BASE_URL}/users/password`,
        {
          oldPassword: currentPassword,
          newPassword: password,
        }
      );
      // onClose();
      if (response && response !== null) {
        toast({
          title: "Updated",
          description: "Password Updated Successfully",
          status: "success",
          position: "top",
          isClosable: true,
        });
      }
      const oldPasswordInput = document.getElementById("oldPassword");
      const newPasswordInput = document.getElementById("newPassword");

      oldPasswordInput.value = "";
      newPasswordInput.value = "";
    } catch (error) {
      // a toast if the vehicle is not added to db
      const errorValue = error?.response?.data;
      if (errorValue) {
        toast({
          title: "Error",
          description: errorValue?.message,
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    }
  };
  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Reset Password
            </Text>
          </Flex>
        </Box>
        {/* Form area */}

        <Flex
          color="white"
          height={"100%"}
          direction={"column"}
          width={"80%"}
          px={"5%"}
          justifySelf="center"
          alignSelf={"center"}
          justifyContent={"center"}
          gap="2"
          as={chakra.form}
          onSubmit={handleSubmit}
        >
          <Flex>
            <FormInput
              type={show ? "text" : "password"}
              id="oldPassword"
              name="oldPassword"
              isRequired
              bg="blackAlpha.50"
              color="black"
              _placeholder={{ color: "gray.500" }}
              labelProps={{ color: "black" }}
            >
              Current Password
            </FormInput>

            <Button
              mt={"43px"}
              ml={"-60px"}
              width={"50px"}
              height={"20px"}
              onClick={handleClick}
              zIndex={1}
            >
              <Image mt={"5px"} src={show ? Eyesclose : Eyesopen}></Image>
            </Button>
          </Flex>

          <Flex>
            <FormInput
              type={shownew ? "text" : "password"}
              id="newPassword"
              name="newPassword"
              isRequired
              bg="blackAlpha.50"
              color="black"
              // _placeholder={{ color: 'gray.500' }}
              labelProps={{ color: "black" }}
            >
              New Password
            </FormInput>
            <Button
              mt={"43px"}
              ml={"-60px"}
              width={"50px"}
              height={"20px"}
              onClick={handlenewClick}
              zIndex={1}
            >
              <Image mt={"5px"} src={shownew ? Eyesclose : Eyesopen}></Image>
            </Button>
          </Flex>

          <Flex mt={"5px"} fontSize={{ base: "10px", md: "12px", lg: "12px" }}>
          <Text color={"brown"} onClick={onOpen} cursor={"pointer"} ml={"auto"}>
            Forgot current password?
          </Text>
        </Flex>
        {/* forgot password modal */}
        <Modal
          size={{ base: "sm", md: "md", lg: "md" }}
          isOpen={isOpen}
          onClose={onClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Forgot password?</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <ForgotPassword onClose={onClose} />
            </ModalBody>

            {/* <ModalFooter>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>

          <Flex gap="4" alignSelf="flex-end" mt="4">
            <Button
              type="button"
              colorScheme="yellow"
              mt="4"
              px={{ base: "4", md: "6" }}
              spinnerPlacement="start"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              px={{ base: "4", md: "6" }}
              bg="brand.btnBg"
              type="submit"
              mt="4"
              color="white"
              _hover={{ bg: "brand.loginBg" }}
              // isLoading={btnIsLoading}
              loadingText="Creating"
              spinnerPlacement="start"
            >
              Submit
            </Button>
          </Flex>
        </Flex>
        {/* include form here */}
      </Flex>
    </Flex>
    // </Flex>
  );
}

export default PasswordSettings;
