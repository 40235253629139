import { Flex, Text } from "@chakra-ui/layout";
import FormatIndianRupees from "../Functions/FormatIndianRupees";
import {
  Image,
  Button,
  Box,

} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import heart from '../Assets/Images/StorePage/hearticon.png';
import modal from '../Assets/Images/StorePage/modalicon.png';
import texture from '../Assets/Images/StorePage/textureicon.png';
import productring from '../Assets/Images/StorePage/ProductRing.webp';
import ProductImage from '../Assets/Images/StorePage/chair.webp';
import Modelenv from '../Assets/Images/model.jpg';
import { useQueryClient } from "@tanstack/react-query";
import model from '../Assets/Images/StorePage/3D.png';

// import modelUrl from "../Assets/Images/StorePage/chair.glb";
import axios from "axios";
import { API_BASE_URL } from "../Config";
import { config } from "../Functions/Auth";
import { getItemFromLocalStorage } from "../utils/local-storage";
import { useGetUserData } from "../Functions/queries";
import { State } from "../Context/Context";
import Login from "../Screens/Login";
import CommonModal from "./CommonModal/CommonModal";
import { Link } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useNumberOfUsers } from "../NumberOfUsersProvider";
// TODO show 3d model viewer only for those who enable 3d model
function ProductSection(props) {
  const { numberOfDisplayedCarts, wishlistNumber, setWishlistNumber } =
  useNumberOfUsers();
  // zoom
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImagezoomClick = () => {
    setIsZoomed(!isZoomed);
  };

  const zoomStyle = {
    cursor: isZoomed ? "zoom-out" : "zoom-in",
    transition: "transform 0.3s ease-in-out",
    transform: isZoomed ? "scale(1.2)" : "scale(1)",
  };
  // zoom
  const [modalClose, setModalClose] = useState();

  const {
   
    productname = "Chair",
    productLink,
    // productLink = 'https://flipkart.com',
    price = 2000,
    productimage = ProductImage,
    modelUrl,
    priceper = "piece",
    productdesc = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    measurement = "54cm X 66cm",
    generate3d = false,
    generatetexture = false,
    textureUrl,
    lowpolyUrl,
  } = props?.product;
  // popup
  const [file, setFile] = useState(null);
  //  model view
  const [isImageVisible, setIsImageVisible] = useState(true);
  const [wishlist, setWishlist] = useState();
  const toggleImageVisibility = () => {
    setIsImageVisible(false);
  };
  const images = productimage;
  const [selectedImage, setSelectedImage] = useState(images[0]);

  // model problem solution
  useEffect(() => {
    setIsImageVisible(true);
  }, [selectedImage]);
  console.log("selectedImage",selectedImage)
  const [zoomKey, setZoomKey] = useState(0); // Add a key to force Zoom component to re-render
  // Function to handle image click
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsImageVisible(true);
    setZoomKey((prevKey) => prevKey + 1); // Increment the key to re-render Zoom
  };

  // to scroll smoothly to product section starts
  const location = useLocation();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  // to scroll smoothly to product section ends

  // refresh the display image when set of images change, that is when changed from related products
  useEffect(() => {
    setSelectedImage(images[0]);
  }, [images]);

  const { user } = useGetUserData();
  const addToWishList = async (props) => {
    let prodId = props.product._id;
    await axios.post(`${API_BASE_URL}/shop/wishlist`, { prodId }, config());
    queryClient.invalidateQueries(['user']);
  
  };

  useEffect(() => {
    setWishlistNumber(user?.data?.wishlist?.length);
  }, [user]);
  useEffect(() => {
    user?.data?.wishlist?.includes(props.product._id)
      ? setWishlist(true)
      : setWishlist(false);
  }, [user]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url; // Set the URL of the file to download
    link.download = url; // Set the desired filename

    // Append the link to the document and trigger a click event
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };
  const { token } = State();
  const WishBtn = () => {
    return (
      <Button
        mt={"10px"}
        fontSize={{ base: "15px", md: "15px", lg: "17px" }}
        height={"50px"}
        bg={"brand.btnBg"}
        _hover={"#75624E"}
        color={"white"}
        width={"300px"}
        borderRadius={"6px"}
        boxShadow={"2px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
      >
        Add to Wishlist
        <Image ml={"39px"} width={"22px"} src={heart}></Image>
      </Button>
    );
  };

  const ModelBtn = () => {
    return (
      <Button
        mt={"50px"}
        fontSize={{ base: "15px", md: "15px", lg: "17px" }}
        height={"50px"}
        bg={"brand.btnBg"}
        _hover={"#75624E"}
        color={"white"}
        width={"300px"}
        borderRadius={"6px"}
        boxShadow={"2px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
      >
        Download 3D Model
        <Image ml={"7px"} width={"22px"} src={modal}></Image>
      </Button>
    );
  };

  const TextureBtn = () => {
    return (
      <Button
        mt={"50px"}
        fontSize={{ base: "15px", md: "15px", lg: "17px" }}
        height={"50px"}
        bg={"brand.btnBg"}
        _hover={"#75624E"}
        color={"white"}
        width={"300px"}
        borderRadius={"6px"}
        boxShadow={"2px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
      >
        Download Texture
        <Image ml={"20px"} width={"22px"} src={texture}></Image>
      </Button>
    );
  };
  return (
    <Flex id="product-section" width="100%" flexDir={"column"}>
      <Flex
        flexDir={{ base: "column-reverse", md: "row", lg: "row" }}
        width="100%"
      >
        {/* Product Discription */}
        <Flex width={"100%"}>
          <Flex
            p={{ base: "30px" }}
            mb={"20px"}
            ml={"auto"}
            mr={{ base: "auto", md: "auto", lg: "7%" }}
            justifyContent={"center"}
            flexDir={"column"}
          >
            <Text
              fontSize={{ base: "30px", md: "30px", lg: "30px" }}
              fontFamily={"heading"}
            >
              {productname}
            </Text>
            <Text
              mt={"10px"}
              fontSize={{ base: "32px", md: "32px", lg: "35px" }}
              fontFamily={"heading"}
            >
              {" "}
              {FormatIndianRupees(Number(price))}
            </Text>
            <Text>per {priceper}</Text>
            <Text
              mt={"10px"}
              maxW={{ base: "400px", md: "400px", lg: "500px" }}
              fontSize={{ base: "15px", md: "15px", lg: "16px" }}
              whiteSpace="pre-line"
            >
              {productdesc}
            </Text>
            {productLink && (
              <Link
                color={"blue"}
                textDecor={"underline"}
                mt={"10px"}
                maxW={{ base: "300px", md: "300px", lg: "600px" }}
                maxH={{ base: "100px", md: "100px", lg: "100px" }}
                overflowY={"auto"}
                fontSize={{ base: "13px", md: "13px", lg: "13px" }}
                href={productLink}
                isExternal
              >
                Product Link
              </Link>
            )}
            <Text py={4}>Dimensions: {measurement}</Text>
            {/* button without login */}
            {!token && (
              <CommonModal
                modalState={modalClose}
                btn={{
                  text: "red",
                  bg: "transparent",
                  hoverBg: "transparent",
                }}
                btnContent={WishBtn()}
                modalContent={
                  <Login wish={true} setModalClose={setModalClose} />
                }
              />
            )}
            {!token && modelUrl && (
              <CommonModal
                modalState={modalClose}
                btn={{
                  text: "red",
                  bg: "transparent",
                  hoverBg: "transparent",
                }}
                btnContent={ModelBtn()}
                modalContent={<Login setModalClose={setModalClose} />}
              />
            )}
            {!token && textureUrl && (
              <CommonModal
                modalState={modalClose}
                btn={{
                  text: "red",
                  bg: "transparent",
                  hoverBg: "transparent",
                }}
                btnContent={TextureBtn()}
                modalContent={<Login setModalClose={setModalClose} />}
              />
            )}

            {/* no token  button */}
            {token && (
              <Button
                mt={"10px"}
                fontSize={{ base: "15px", md: "15px", lg: "17px" }}
                height={"50px"}
                bg={"brand.btnBg"}
                _hover={"#75624E"}
                color={"white"}
                width={"300px"}
                borderRadius={"6px"}
                boxShadow={"2px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
                onClick={async () => {
                  await addToWishList(props);
                  setWishlist(!wishlist);
                  // window.location.reload(); // Refresh the page
                }}>
                { user?.data?.wishlist?.includes(props.product._id) ? 'Remove from Wishlist' : 'Add to Wishlist'}
                <Image ml={'39px'} width={'22px'} src={heart}></Image>
              </Button>
            )}
            {token && modelUrl && (
              <Button
                mt={"10px"}
                fontSize={{ base: "15px", md: "15px", lg: "17px" }}
                height={"50px"}
                bg={"brand.btnBg"}
                _hover={"#75624E"}
                color={"white"}
                width={"300px"}
                borderRadius={"6px"}
                boxShadow={"2px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
                onClick={() => handleDownload(modelUrl)}
              >
                Download 3D Model
                <Image ml={"7px"} width={"22px"} src={modal}></Image>
              </Button>
            )}
            {token && textureUrl && (
              <Button
                mt={"10px"}
                fontSize={{ base: "15px", md: "15px", lg: "17px" }}
                height={"50px"}
                bg={"brand.btnBg"}
                _hover={"#75624E"}
                color={"white"}
                width={"300px"}
                borderRadius={"6px"}
                boxShadow={"2px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
                onClick={() => handleDownload(textureUrl)}
              >
                Download Texture
                <Image ml={"20px"} width={"22px"} src={texture}></Image>
              </Button>
            )}
          </Flex>
        </Flex>
        {/* Product Image details*/}
        <Flex width={"100%"}>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            flexDir={"column"}
          >
            <Image width={"700px"} src={productring}></Image>

            {selectedImage && (
              <Flex
                mb={{ base: "30px", md: "30px" }}
                display={isImageVisible ? "block" : "none"}
                mt={"-30px"}
                className="img-container"
                boxShadow={{ base: "dark-lg", md: "dark-lg", lg: "none" }}
                borderRadius={{ base: "10px", md: "20px", lg: "20px" }}
                overflow={"hidden"}
                justifyContent={"center"}
                alignItems={"center"}
                position={"absolute"}
              >
                <Image
                  onClick={() => setFile(selectedImage)}
                  _hover={{ transform: "scale(1.02)" }}
                  objectFit={""}
                  border={"4px solid white"}
                  borderRadius={{ base: "10px", md: "20px", lg: "20px" }}
                  display={{ base: "flex", md: "flex", lg: "flex" }}
                  width={{ base: "320px", md: "300px", lg: "470px" }}
                  height={{ base: "320px", md: "300px", lg: "450px" }}
                  src={selectedImage}
                ></Image>
                {/* on click zoom the image */}
                <Flex display={file ? "block" : "none"} className="popup-media">
                  <span onClick={() => setFile(null)}>&times;</span>
                  <Image
                    mx={"auto"}
                    style={zoomStyle}
                    onClick={handleImagezoomClick}
                    alt="Zoomable Image"
                    className="popupimage"
                    src={selectedImage}
                  ></Image>
                </Flex>
              </Flex>
            )}
            {/* Model view */}
            <Flex
              className="modelviewer"
              mt={{ base: "-70px" }}
              position={"absolute"}
              display={isImageVisible ? "none" : "block"}
            >
              <model-viewer
                src={lowpolyUrl}
                tone-mapping="neutral" 
                ar-modes="webxr scene-viewer quick-look"
                camera-controls
                poster="poster.webp"
                shadow-intensity="1"
                environment-image={Modelenv}
                exposure="2"
              ></model-viewer>
            </Flex>
            <Flex
              mb={{ base: "0px", md: "0px", lg: "40px" }}
              mt={{ base: "30px", md: "30px", lg: "40px" }}
            >
              {images?.map((image, index) => (
                <Image
                  className="subimages"
                  objectFit={""}
                  ml={{ base: "15px", md: "20px", lg: "20px" }}
                  border={"2px solid white"}
                  boxShadow={"dark-lg"}
                  width={{ base: "50px", md: "50px", lg: "80px" }}
                  height={{ base: "50px", md: "50px", lg: "80px" }}
                  borderRadius={"10px"}
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => handleImageClick(image)}
                ></Image>
              ))}
              {/* 3d model view thumbnail */}

              {modelUrl && (
                <Box>
                  <Image
                    className="subimages"
                    objectFit={"cover"}
                    ml={"20px"}
                    border={"2px solid white"}
                    boxShadow={"dark-lg"}
                    width={{ base: "50px", md: "50px", lg: "80px" }}
                    height={{ base: "50px", md: "50px", lg: "80px" }}
                    borderRadius={"10px"}
                    src={images[0]}
                  ></Image>
                  <Image
                    onClick={toggleImageVisibility}
                    className="subimages"
                    width={{ base: "50px", md: "50px", lg: "80px" }}
                    height={{ base: "50px", md: "50px", lg: "80px" }}
                    borderRadius={"10px"}
                    ml={"20px"}
                    mt={{ base: "-50px", md: "-50px", lg: "-80px" }}
                    position={"absolute"}
                    src={model}
                  ></Image>
                </Box>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ProductSection;
