import {
  Container,
  Flex,
  Button,
  Divider,
  Link,
  useToast,
  Box,
  Image,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import Header from "../../..//Components/shared/Header";
import ShopDashboardHeader from "../../../../src/Assets/Images/Headers/StoreDashboardHeader.webp";
import MobShopDashboardHeader from "../../../../src/Assets/Images/Headers/MobStoreDashHeader.webp";
import { designernavbar } from "../Designer/DesignerNavbar.js";
import axios from "axios";
import CommonButton from "../../../Components/shared/CommonButton.js";
import Footer from "../../../Components/Footer";
import DesignerProfileForm from "./DesignerProfileForm";
import DesignerGallery from "./DesignerGallery";
import { useGetDesignerData } from "../../../Functions/queries.js";
import ImageUpload from "./ImageUpload.js";
import UpgradeButton from "../Components/UpgradeButton.js";
import DesignerSubscription from "../DesignerSubscription.js";
import { API_BASE_URL } from "../../../Config/index.js";
import PasswordSettings from "../PasswordSettings.js";
import DesignerVideo from "./DesignerVideo.js";
import NotSubscribed from "./NotSubscribed.js";
import DesignerOrderInvoice from "./DesignerOrderInvoice.js";

function DesignerDashboard() {
  const { designer } = useGetDesignerData();
  const [subscription, setSubscription] = useState(null);
  const [activeLink, setActiveLink] = useState(
    parseInt(localStorage.getItem("activeLink")) || 1
  );
  const [loading, setLoading] = useState();
  const [myImage, setMyImage] = useState("");
  const [editImage, setEditImage] = useState();
  const [uploadImageUrl, setUploadedImageUrl] = useState();
  const toast = useToast();
  const handleLinkClick = (link) => {
    setActiveLink(link);
    localStorage.setItem("activeLink", link);
  };
  const handleImageUpload = async () => {
    const formData = new FormData();
    formData.append("file", myImage);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/upload/designer/${designer._id}`,
        formData
      );
      setLoading(false);
      toast({
        title: "Success",
        description: "Details Added Successfully",
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      console.error("Error uploading Cover image:", error);
      throw error;
      // toast('Image upload failed');
    }
  };
  useEffect(() => {
    setUploadedImageUrl(designer?.designerPhoto);
    setEditImage(designer?.designerPhoto);

    // Update subscription value in the Header component
    setSubscription(
      new Date(designer?.subscription?.to) > new Date() &&
        designer?.subscription?.plan
    );
  }, [designer, subscription]);
  return (
    <Flex
      flexDir={"column"}
      bg={"linear-gradient(180deg, #FFF 0%, #FFFAF2 29.69%, #D6AC85 100%)"}
    >
      <Container maxW="2000px" width={"100vw"} p={0}>
        <Header
          mt={{ base: "0px", md: "30px", lg: "60px" }}
          heading="Dashboard"
          image={ShopDashboardHeader}
          imageMob={MobShopDashboardHeader}
          // subscription={
          //   new Date(designer?.subscription?.to) > new Date() &&
          //   designer?.subscription?.plan
          // }
          subscription={subscription}
          dashboard={true}
          designer={true}
          enddate={designer?.subscription?.to}
        />

        <Flex
          width={"100%"}
          flexDir={{ base: "column", md: "column", lg: "row" }}
        >
          <Flex width={{ lg: "23%" }}>
            <Flex width={"100%"} my={"20px"} px={"10px"}>
              {/* sidenav for mobile and tab */}
              <Flex
                width={"100%"}
                alignItems={"center"}
                // display={{ base: "block", md: "block", lg: "none" }}
                overflow={"scroll"}
                p={3}
                boxShadow={"lg"}
                borderRadius={"10px"}
                flexDir={"row"}
                backgroundColor={"#F1D0AE"}
                flexDirection={"column"}
              >
                <ImageUpload
                  toast={toast}
                  designer={designer}
                  editImage={editImage}
                  setImage={setMyImage}
                  width="150px"
                  height="150px"
                  shape="circle"
                  color={"red"}
                />
                <CommonButton
                  mt="5px"
                  btn={"Save"}
                  height="25px"
                  onClick={handleImageUpload}
                />

                <Flex
                  mt={{ base: "15px", md: "15px", lg: "30px" }}
                  style={{
                    border:
                      activeLink === 3 ? "4px solid white" : "solid #F1D0AE",
                  }}
                  borderRadius={"20px"}
                  width={"fit-content"}
                  height={"fit-content"}
                >
                  <UpgradeButton
                    width="130px"
                    height="35px"
                    btn={"Upgrade to PRO"}
                    bg={
                      activeLink === 3
                        ? "#215319"
                        : " linear-gradient(180deg, #92E584 0%, #42994D 54.17%, #227D41 100%)"
                    }
                    onClick={() => handleLinkClick(3)}
                  />
                </Flex>
                <Flex
                  width={"100%"}
                  // overflow={"scroll"}
                  // height={"60px"}
                  flexDir={{ base: "row", md: "row", lg: "column" }}
                  mt={"20px"}
                >
                  {designernavbar.map((designernavbaritem, indexs) => (
                    <Flex
                      mx={"auto"}
                      flexDir={"column"}
                      mt={{ base: "10px", md: "10px", lg: "20px" }}
                      px={2}
                      key={indexs}
                    >
                      <Link
                        onClick={() =>
                          handleLinkClick(designernavbaritem.navnum)
                        }
                      >
                        <Button
                          style={{
                            backgroundColor:
                              activeLink === designernavbaritem.navnum
                                ? "#E7E2DC"
                                : "white",
                            boxShadow:
                              activeLink === designernavbaritem.navnum
                                ? "0 0 1px 2px rgba(145, 121, 97, .75), 0 1px 1px rgba(0, 0, 0, .15)"
                                : "none",
                          }}
                          isDisabled={
                            designernavbaritem.navnum == 1 ||
                            designernavbaritem.navnum == 4
                              ? false
                              : designer
                              ? false
                              : true
                          }
                          transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                          _active={{
                            transform: "scale(0.98)",
                          }}
                          width={{ lg: "190px" }}
                          boxShadow={"md"}
                          borderRadius={"40px"}
                          height={"36px"}
                          _focus={{
                            boxShadow:
                              "0 0 1px 2px rgba(145, 121, 97, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                          }}
                        >
                          {designernavbaritem.navname}
                        </Button>
                      </Link>
                      <Divider
                        display={{ base: "none", md: "none", lg: "block" }}
                        mt={"20px"}
                      />
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          {/* .................Form Part...................................... */}

          {activeLink === 1 && (
            <Flex width={{ lg: "75%" }}>
              <DesignerProfileForm />
            </Flex>
          )}
          {activeLink === 2 && (
            <Flex width={{ lg: "75%" }}>
              <DesignerGallery />
            </Flex>
          )}
          {activeLink === 3 && (
            <Flex width={{ lg: "75%" }}>
              <DesignerSubscription />
            </Flex>
          )}
             {activeLink === 6 && (
            <Flex width={{ lg: "75%" }}>
              <DesignerOrderInvoice />
            </Flex>
          )}
          {activeLink === 5 && (
            <Flex width={{ lg: "75%" }}>
              {new Date(designer?.subscription?.to) > new Date() &&
              designer?.subscription?.plan ? (
                <DesignerVideo />
              ) : (
                <NotSubscribed />
              )}
            </Flex>
          )}
          {activeLink === 4 && (
            <Flex width={{ lg: "75%" }}>
              <PasswordSettings />
            </Flex>
          )}
        </Flex>
        <Footer />
      </Container>
    </Flex>
  );
}

export default DesignerDashboard;
