import {
  Text,
  Flex,
  Box,
  Stack,
  chakra,
  useToast,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormInput from "../../../Components/FormInputs/FormInput.js";
import React, { useEffect, useState } from "react";
import CommonButton from "../../../Components/shared/CommonButton";
import TextArea from "../../../Components/FormInputs/TextArea.js";
import Selectform from "../../../Components/FormInputs/Selectform.js";
import { postDesignerDetails } from "../../../Functions/PostApiCalls";
import { useGetDesignerData } from "../../../Functions/queries.js";
import { putDesignerDetails } from "../../../Functions/PutApiCalls.js";

const options = [
  { value: "Alappuzha", label: "Alappuzha" },
  { value: "Ernakulam", label: "Ernakulam" },
  { value: "Idukki", label: "Idukki" },
  { value: "Kannur", label: "Kannur" },
  { value: "Kasaragod", label: "Kasaragod" },
  { value: "Kollam", label: "Kollam" },
  { value: "Kottayam", label: "Kottayam" },
  { value: "Kozhikode", label: "Kozhikode" },
  { value: "Malappuram", label: "Malappuram" },
  { value: "Palakkad", label: "Palakkad" },
  { value: "Pathanamthitta", label: "Pathanamthitta" },
  { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
  { value: "Thrissur", label: "Thrissur" },
  { value: "Wayanad", label: "Wayanad" },
];
const prof = [
  { value: "Artist", label: "Artist" },
  { value: "Interior Designer", label: "Interior Designer" },
  { value: "Architect", label: "Architect" },
];

function DesignerProfileForm() {
  const { designer, query } = useGetDesignerData();
  const [selectedProfession, setSelectedProfession] = useState("");
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const handleProfessionChange = (event) => {
    setSelectedProfession(event.target.value);
  };

  const onSubmit = async (e) => {
    setLoading(true);
    if (designer) await putDesignerDetails(e, designer._id, toast);
    else await postDesignerDetails(e, toast, reset);
    query.refetch();
    setLoading(false);
  };
  useEffect(() => {
    if (designer) {
      const fields = [
        "name",
        "profession",
        "about",
        "designeraddress",
        "phone",
        "phoneSub",
        "artistSpecialization",
        "district",
        "linkedinlink",
        "instagramlink",
        "facebooklink",
      ];
      fields.forEach((field) => setValue(field, designer[field]));
    }
  }, [designer]);

  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Add Profile Details
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex
          fontSize={{ base: "15px", md: "15px", lg: "16px" }}
          justifyContent={"center"}
          my={"5%"}
          width={"100%"}
        >
          <Stack
            as={chakra.form}
            onSubmit={handleSubmit(onSubmit)}
            width={{ base: "90%", md: "70%", lg: "60%" }}
            spacing={2}
          >
            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Name*
              </Text>
              <FormInput
                name="name"
                inputFor="name"
                register={register}
                error={errors.name}
                validator={{
                  required: "This field is required",
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Profession*
              </Text>

              <Selectform
                isDisabled={designer?.profession ? true : false}
                options={prof}
                name="profession"
                onChange={handleProfessionChange}
                inputFor="profession"
                register={register}
                error={errors.profession}
                validator={{
                  required: "This field is required",
                }}
                style={{
                  border: "0px",
                  borderBottom: "1px solid #D0D5DD",
                  maxHeight: "38px",
                  borderRadius: "10px",
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
                color="#848484"
                placeholder="Select your profession"
              />
            </Flex>

            {/* specialization */}
            {(selectedProfession === "Artist" ||
              designer?.profession === "Artist") && (
              <Flex>
                <Text mt={"auto"} mb={"auto"} width={"300px"}>
                  Specialization*
                </Text>
                <FormInput
                  placeholder="e.g. Sculpting, Painting etc."
                  name="artistSpecialization"
                  inputFor="artistSpecialization"
                  register={register}
                  error={errors.artistSpecialization}
                  validator={{
                    required: "This field is required",
                  }}
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                  type="text"
                />
              </Flex>
            )}
            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Description*
              </Text>
              <TextArea
                name="about"
                inputFor="about"
                register={register}
                validator={{
                  required: "This field is required",
                  pattern: {
                    value: /^[\s\S]{0,500}$/,
                    message: "Exceeds 500 character limit",
                  },
                }}
                style={{
                  border: "0px",
                  borderBottom: "1px solid #D0D5DD",
                  maxHeight: "38px",
                  borderRadius: "10px",
                }}
                error={errors.about}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            {/* address designer */}
            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Address
              </Text>
              <FormInput
                name="designeraddress"
                inputFor="designeraddress"
                register={register}
                error={errors.designeraddress}
                validator={{}}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Phone Number*
              </Text>
              <InputGroup>
                <InputLeftAddon
                  mt={"9px"}
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                  height={"37px"}
                >
                  +91
                </InputLeftAddon>
                <FormInput
                  name="phone"
                  inputFor="phone"
                  register={register}
                  error={errors.phone}
                  validator={{
                    required: "This field is required",
                    pattern: {
                      value: /^(\+?\d{10,11})$/,
                      message: "Not a valid phone number",
                    },
                  }}
                  type="tel"
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                />
              </InputGroup>
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Alternate Phone Number
              </Text>
              <InputGroup>
                <InputLeftAddon
                  mt={"9px"}
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                  height={"37px"}
                >
                  +91
                </InputLeftAddon>
                <FormInput
                  name="phoneSub"
                  inputFor="phoneSub"
                  register={register}
                  error={errors.phoneSub}
                  validator={{
                    pattern: {
                      value:/^(\+?\d{10,11})$/,
                      message: "Not a valid phone number",
                    },
                  }}
                  type="tel"
                  backgroundColor={"white"}
                  boxShadow={"lg"}
                />
              </InputGroup>
            </Flex>
            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                District*
              </Text>

              <Selectform
                options={options}
                name="district"
                inputFor="district"
                register={register}
                error={errors.district}
                validator={{
                  required: "This field is required",
                }}
                style={{
                  border: "0px",
                  borderBottom: "1px solid #D0D5DD",
                  maxHeight: "38px",
                  borderRadius: "10px",
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
                color="#848484"
                placeholder="Select your district"
              />
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Linkedin Profile
              </Text>
              <FormInput
                name="linkedinlink"
                inputFor="linkedinlink"
                register={register}
                error={errors.linkedinlink}
                validator={{
                  pattern: {
                    value: /https?:\/\/([\w.]+\/?)\S*|www\.\w+\.\w+(\/\S*)?/,
                    message: "Not a valid link",
                  },
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>
            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Facebook Profile
              </Text>
              <FormInput
                name="facebooklink"
                inputFor="facebooklink"
                register={register}
                error={errors.facebooklink}
                validator={{
                  pattern: {
                    value: /https?:\/\/([\w.]+\/?)\S*|www\.\w+\.\w+(\/\S*)?/,
                    message: "Not a valid link",
                  },
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            <Flex>
              <Text mt={"auto"} mb={"auto"} width={"300px"}>
                Instagram Profile
              </Text>
              <FormInput
                name="instagramlink"
                inputFor="instagramlink"
                register={register}
                error={errors.instagramlink}
                validator={{
                  pattern: {
                    value: /https?:\/\/([\w.]+\/?)\S*|www\.\w+\.\w+(\/\S*)?/,
                    message: "Not a valid link",
                  },
                }}
                backgroundColor={"white"}
                boxShadow={"lg"}
                type="text"
              />
            </Flex>

            <Flex
              flexDir={"column"}
              width={"100%"}
              alignItems={"end"}
              justifyContent={"end"}
            >
              <CommonButton
                isLoading={loading}
                type={"submit"}
                height="34px"
                width="100px"
                mt="20px"
                fontSize="14px"
                btn={"Save"}
              />
            </Flex>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default DesignerProfileForm;
