import { Text, Flex, Container, Divider } from "@chakra-ui/layout";
import { List, ListItem, ListIcon, OrderedList } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import React from "react";
import WishlistHeader from "../../src/Assets/Images/Headers/policyHeader.webp";
import WishlistHeaderMob from "../../src/Assets/Images/Headers/MobPolicyHeader.webp";
import Navbar from "../Components/Navbar";
import Header from "../Components/shared/Header";
import Footer from "../Components/Footer";
import { MdCheckCircle } from "react-icons/md";

function PrivacyPolicy() {
  return (
    <Flex flexDir={"column"} id="privacypolicy">
      <Container maxW="2000px" width={"100vw"} p={0}>
        <Header
          mt={{ base: "10px", md: "10px", lg: "50px" }}
          heading="Privacy Policy"
          image={WishlistHeader}
          imageMob={WishlistHeaderMob}
        />
        <Flex
          my={"20px"}
          fontSize={{ base: "13px", md: "13px", lg: "15px" }}
          flexDir={"column"}
          mx={"auto"}
          borderRadius={"10px"}
          boxShadow={"lg"}
          p={"20px"}
          backgroundColor={"#F2DCC7"}
          width={{ base: "95%", md: "95%", lg: "90%" }}
        >
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              This Privacy Policy is an electronic agreement formed under the
              Information Technology Act, 2000 and the rules and regulations
              made there under (as amended till date) including the Information
              Technology (Reasonable security practices and procedures and
              sensitive personal data or information) Rules, 2011 & the
              Information Technology (Intermediary Guidelines and Digital Media
              Ethics Code) Rules, 2021. It does not require any physical or
              digital signatures to make the terms of this policy binding. This
              privacy policy is a legally binding document. The terms of this
              privacy policy will be effective upon your use of our Platform
              /service. Please read this policy carefully, together with our
              Terms of Services.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              By visiting our Platform, providing your information or availing
              our service, you expressly agree to be bound by the terms of this
              Privacy Policy and the applicable Terms of service. While
              accessing or using our platform/services, you have given explicit
              consent to collect, use, share or otherwise process your
              information in accordance with this Privacy Policy. If you do not
              agree, please do not access or use our Platform or service.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              You acknowledge that you are disclosing Personal Information
              voluntarily. Prior to the completion of any registration process
              on our platform or prior to availing of any services offered on
              our platform if you wish not to disclose any Personal Information
              you may refrain from doing so; however if you don't provide
              information that is requested, it is possible that the
              registration process would be incomplete and/or you would not be
              able to avail of the our services.
            </ListItem>
          </List>
          <Text my={"10px"} fontWeight={"bold"}>
            Collection of Information:
          </Text>
          <Text>
            In the course of carrying out its various functions and activities,
            Lokolumo collects information from individuals & third parties and
            generates a wide range of information which is stored at our
            platform. This information can take different forms, such as,
            financial records, letters received from third parties, invoices,
            completed application forms, contact lists, email communications and
            attachments, photos etc. Lokolumo collects information in order to
            provide and continually improve its products & services. The
            information may be collected online or offline. Regardless of the
            collection method, the same privacy protection shall apply to all
            data/information including personal information.
          </Text>
          <Text my={"10px"} fontWeight={"bold"}>
            Types of information Collected by Lokolumo:
          </Text>

          <TableContainer >
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Sr. No.</Th>
                  <Th>Source of Information</Th>
                  <Th >Nature of Information Collected</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>1.</Td>
                  <Td>Users/Customers</Td>
                  <Td>
                    <OrderedList >
                      <ListItem>
                        Personal Identifying information such as name, address
                        and phone numbers; email Id, etc.
                      </ListItem>
                      <ListItem>location information.</ListItem>
                      <ListItem>IP address.</ListItem>
                      <ListItem>
                        credit usage, login detail, device log files etc., while
                        using our platform.
                      </ListItem>
                    </OrderedList>
                  </Td>
                </Tr>
                <Tr>
                  <Td>2.</Td>
                  <Td>Vendors/Sellers/Artists</Td>
                  <Td>
                    <OrderedList>
                      <ListItem>
                        Personal Identifying information such as name, address
                        and phone numbers; email Id, personal description,
                        profile photograph etc.,.
                      </ListItem>
                      <ListItem>Payment information.</ListItem>
                      <ListItem>Location information.</ListItem>
                      <ListItem>IP address.</ListItem>
                      <ListItem>content of reviews and e-mails to us.</ListItem>
                      <ListItem>
                        images, videos and other content collected or stored in
                        connection with our Services.
                      </ListItem>
                      <ListItem>
                        corporate and financial information, and
                      </ListItem>
                      <ListItem>
                        device log files and configurations etc.,.
                      </ListItem>
                    </OrderedList>
                  </Td>
                </Tr>
                <Tr>
                  <Td>3.</Td>
                  <Td>Automatic Information</Td>
                  <Td>
                    <OrderedList>
                      <ListItem>
                        IP address of your device connected to our platform.
                      </ListItem>
                      <ListItem>
                        Login details, e-mail address, and password, device log
                        files etc.,.
                      </ListItem>
                      <ListItem>location of device/computer.</ListItem>

                      <ListItem>
                        content interaction information, downloads, streaming of
                        video, network details etc.,.
                      </ListItem>
                      <ListItem>
                        device metrics, application usage, connectivity data,
                        and any errors or event failures.
                      </ListItem>
                      <ListItem>
                        our Services metrics, any technical errors, interactions
                        with our service features and content, settings
                        preferences and backup information, location of device,
                        file name, dates, times etc while using our service.
                      </ListItem>

                      <ListItem>content use history.</ListItem>
                      <ListItem>
                        URLs including date & time; products & contents viewed
                        or searched for; page response times, download errors,
                        length of visits to certain pages, and page interaction
                        information etc.,.
                      </ListItem>
                      <ListItem>phone numbers used to call to us.</ListItem>
                      <ListItem>
                        Images/videos while visiting our platforms..
                      </ListItem>
                      <ListItem>
                        device identifiers, cookies, browsing history, usage
                        history, and/or other technical information.
                      </ListItem>
                    </OrderedList>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <Text my={"10px"} fontWeight={"bold"}>
            Usage of Information:
          </Text>
          <Text>
            The data/information collected shall be used for the purpose for
            which it has been collected. In addition, the Lokolumo uses the data
            for the following purposes:
          </Text>
          <OrderedList>
            <ListItem>To provide services.</ListItem>
            <ListItem>
              To fulfill your requests for products and services and communicate
              with you about those requests. If you search the products or
              service from our platform without logged in or sought the
              information by calling our hotline, we collect mobile number and
              generate lead with our vendors/service providers, as per your
              choice/request. If you search the products or service from our
              platform with logged in, we share your information (i.e., name,
              mobile, email etc.,) as available with us with our vendors/service
              providers to fulfil your choice/request.
            </ListItem>
            <ListItem>
              To administer contests, sweepstakes, promotions, and surveys.
            </ListItem>
            <ListItem>
              To respond to reviews, comments, or other feedback you provide us.
            </ListItem>
            <ListItem>
              To help us personalize our service offerings, websites, mobile
              services, and advertising.
            </ListItem>
            <ListItem>
              To provide functionality, analyze performance, fix errors, and
              improve the usability and effectiveness of the Services.
            </ListItem>
            <ListItem>
              To recommend features, products, and services that might be of
              interest to you, identify your preferences, and personalize your
              experience with Lokolumo Services.
            </ListItem>
            <ListItem>
              To operate, provide, develop, and improve the products and
              services that we offer our customers.
            </ListItem>
            <ListItem>
              To provide our services and to support our core business
              functions. These include order or service fulfilment, internal
              business processes, marketing, authentication, loss and fraud
              prevention, public safety and legal functions.
            </ListItem>
            <ListItem>
              To display interest-based ads for features, products, and services
              that might be of interest to you.
            </ListItem>
            <ListItem>
              To prevent and detect fraud and abuse in order to protect the
              security of our customers, Lokolumo, and others.
            </ListItem>
            <ListItem>Communicate with you.</ListItem>
            <ListItem>Contacting with you for promoting services.</ListItem>
            <ListItem>
              The User's Personal Data may be used for legal purposes by
              Lokolumo in legal proceeding at the stages leading to possible
              legal action arising from improper use of Lokolumo services.
            </ListItem>
            <ListItem>For operation and maintenance purposes.</ListItem>
            <ListItem>
              We use your voice input, images, videos, and other personal
              information to respond to your requests, provide the requested
              service to you, and improve our services.
            </ListItem>
            <ListItem>
              It helps us in operating, providing, improving, understanding,
              customizing, supporting, and marketing our Services.
            </ListItem>
            <ListItem>
              Comply with legal obligations. In certain cases, we collect and
              use your personal information to comply with laws. For instance,
              we collect from seller’s information regarding place of
              establishment and bank account information for identity
              verification and other purposes.
            </ListItem>
          </OrderedList>

          <Text mt={"20px"}>
            We care to ensure the security of your Personal Data. We follow
            generally accepted industry standards to protect the information
            submitted to us, both during transmission and once we receive it. We
            maintain technical, physical, and administrative security measures
            to provide reasonable protection for your Personal Data. When we or
            our contractors process your information, we also make sure that
            your information is protected from unauthorized access, loss,
            manipulation, falsification, destruction, or unauthorized
            disclosure. This is done through appropriate administrative,
            technical, and physical measures.
          </Text>
        </Flex>
      </Container>
      <Footer />
    </Flex>
  );
}

export default PrivacyPolicy;
