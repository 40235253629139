import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import CommonModal from "../../../Components/CommonModal/CommonModal";
import { AiOutlineFileImage } from "react-icons/ai";
import CommonButton from "../../../Components/shared/CommonButton";
import GalleryContent from "./GalleryContent";
import { gallery } from "./DummyGallery";
function ShopGallery(props) {
  // TODO fetch data from backend
  const { shopImage, gallery } = props;

  const ShopGalleryBtn = () => {
    return (
      <CommonButton
        width={"200px"}
        p={0}
        btn={
          <Flex
            px={8}
            width={"100%"}
            justifyContent={"space-between"}
            fontSize={"14px"}
          >
            <Text color={"white"}> Shop Gallery</Text>
            <AiOutlineFileImage color={"white"} />
          </Flex>
        }
      />
    );
  };
  return (
    <Flex
      mt={4}
      width={"300px"}
      height={"200px"}
      bgImage={shopImage}
      bgSize={"cover"}
      borderRadius={"10px"}
    >
      <Flex
        width={"100%"}
        height={"100%"}
        bg={"blackAlpha.600"}
        borderRadius={"10px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CommonModal
          size={"lg"}
          btnContent={ShopGalleryBtn()}
          modalContent={<GalleryContent Gallery={gallery} />}
          modalAttr={{ width: "100vw", height: "90vh", bg: "whiteAlpha.400" }}
        />
      </Flex>
    </Flex>
  );
}

export default ShopGallery;
