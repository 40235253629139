// Filter designers based on selected districts and professions, and update the filtered data.

export const DesignerFilter = (
  designersData,
  setFilteredDesignerData,
  district,
  profession
) => {
  // Initialize the filtered data with the original designer data
  let filteredData = designersData;

  // Filter by selected districts, if any
  if (district.length > 0) {
    filteredData = filteredData.filter((item) =>
      district.includes(item.district)
    );
  }

  // Filter by selected professions, if any
  if (profession.length > 0) {
    filteredData = filteredData.filter((item) =>
      profession.includes(item.profession)
    );
  }

  // Update the filtered designer data in the state
  setFilteredDesignerData(filteredData);
};
