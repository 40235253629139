import { Image } from "@chakra-ui/image";
import { Box, Flex, Link, Text } from "@chakra-ui/layout";
import React from "react";
import Man from "../../Assets/DummyCollections/designer.png";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaInstagramSquare,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
function DesignersCollection(props) {
  const {
    designerPhoto = Man,
    name = "Mr.George Thomas",
    district = "Kanjirapally",
    profession,
    artistSpecialization,
    facebooklink,
    linkedinlink,
    instagramlink,
    subscription,
    _id,
  } = props?.item;
  const navigate = useNavigate();
  return (
    <Flex
  
   height={{base:"220px",md:"220px",lg:"220px"}}
   w={{base:"220px",md:"220px",lg:"220px"}}
      flexDir={"column"}
      p={{base:"0",md:"2",lg:4}}
     
      bg={"linear-gradient(180deg, #FFF 0%, #F9F1E9 100%)"}
      border={"3px solid #E3B78B"}
      borderRadius={"16px"}
      alignItems={"center"}
      boxShadow={"lg"}
    >
      <Box
      mt={{base:"10px",md:"10px",lg:"0px"}}
       _hover={{ transform: "scale(1.02)" }}
       border={"2px solid white"}
        width={{ base: "100px",md:"100px",lg:"100px"  }}
        height={{ base: "100px", md:"100px",lg:"100px" }}
        borderRadius="50%"
        overflow="hidden"
        boxShadow={"lg"}
        onClick={() => {
          window.scrollTo(0, 0);
          navigate(`/creators/${name}`, { state: props?.item });
        }}
        cursor={"pointer"}
      >
        <Image
          src={designerPhoto}
          width={"100%"}
          height={"100%"}
          objectFit={"cover"}
        />
      </Box>
      <Flex
       
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Text textAlign={"center"} mt={"5px"} maxW={"150px"} fontWeight={"extrabold"} fontSize={{base:"14px",md:"15px",lg:"15px"}}>
          {name}
        </Text>
        <Text fontSize={{base:"13px",md:"14px",lg:"14px"}}>
        {profession === "Artist" ? artistSpecialization : profession}
        </Text>
        {/* if subscription */}
        {subscription && subscription.to && new Date(subscription.to) > new Date() ? (
  <Flex mt={"5px"}>
    {facebooklink && (
      <Link isExternal color={"brand.btnBg"} href={facebooklink} mx={1}>
        <FaFacebookSquare size={"22px"} />
      </Link>
    )}
    {linkedinlink && (
      <Link isExternal color={"brand.btnBg"} href={linkedinlink} mx={1}>
        <FaLinkedin size={"22px"} />
      </Link>
    )}
    {instagramlink && (
      <Link isExternal color={"brand.btnBg"} href={instagramlink} mx={1}>
        <FaInstagramSquare size={"22px"} />
      </Link>
    )}
  </Flex>
) : (
  <Flex mt={"5px"}>
    {facebooklink && (
      <Link color={"#C9C9C9"}  mx={1}>
        <FaFacebookSquare size={"22px"} />
      </Link>
    )}
    {linkedinlink && (
      <Link color={"#C9C9C9"}  mx={1}>
        <FaLinkedin size={"22px"} />
      </Link>
    )}
    {instagramlink && (
      <Link color={"#C9C9C9"} mx={1}>
        <FaInstagramSquare size={"22px"} />
      </Link>
    )}
  </Flex>
)}

      </Flex>
    </Flex>
  );
}

export default DesignersCollection;
