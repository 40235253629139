import {
  Text,
  Flex,
  Container,
  Image,
  Box,
  Spinner,
  Button,
  useToast,
} from "@chakra-ui/react";
import Logo from "../../src/Assets/Images/StylHomLOGOpng.webp";
import axios from "axios";

import Header from "../Components/shared/Header";
import React, { useState, useEffect } from "react";
import DeleteIcon from "../Assets/Images/Dashboard/deleticon.png";
import EditIcon from "../Assets/Images/Dashboard/editicon.png";
import { productsdata } from "../Screens/Dashboard/Shop/ViewProductdata.js";
import WishlistHeader from "../../src/Assets/Images/Headers/wishlistHeader.webp";
import WishlistHeaderMob from "../../src/Assets/Images/Headers/MobwishlistHeader.webp";
import { useGetShopData } from "../../src/Functions/queries";
import { State } from "../../src/Context/Context.js";
import CommonButton from "../Components/shared/CommonButton.js";
import FormatIndianRupees from "../../src/Functions/FormatIndianRupees.js";
import { API_BASE_URL } from "../Config/index.js";
import { config } from "../Functions/Auth.js";
import { useNavigate } from "react-router-dom";
import { useNumberOfUsers } from "../NumberOfUsersProvider";
import Footer from "../Components/Footer.js";
import { getItemFromLocalStorage } from "../utils/local-storage.js";

function CartScreen() {
  const [orderAmount, setOrderAmount] = useState(0);
  const [orders, setOrders] = useState([]);
  const client = getItemFromLocalStorage("client");
  const toast = useToast();
  const navigate = useNavigate();
  async function fetchOrders() {
    const { data } = await axios.get(`${API_BASE_URL}/razorpay/list-orders`);
    setOrders(data);
  }
  useEffect(() => {
    fetchOrders();
  }, []);
  function loadRazorpay() {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onerror = () => {
      alert("Razorpay SDK failed to load. Are you online?");
    };
    script.onload = async () => {
      try {
        setLoading(true);
        const result = await axios.post(
          `${API_BASE_URL}/razorpay/create-order`,
          {
            amount: totalAmount + "00",
          }
        );
        const { amount, id: order_id, currency } = result.data;
        const {
          data: { key: razorpayKey },
        } = await axios.get(`${API_BASE_URL}/razorpay/get-razorpay-key`);

        const options = {
          key: razorpayKey,
          amount: amount.toString(),
          currency: currency,
          name: "Lokolumo",
          image: Logo,
          description: "Thanks for purchasing",
          order_id: order_id,
          handler: async function (response) {
            // get payment details
            const paymentId = response.razorpay_payment_id;
            const paymentDetails = await axios.get(
              `${API_BASE_URL}/razorpay/payment-details/${paymentId}`
            );
            const result = await axios.post(
              `${API_BASE_URL}/razorpay/pay-order`,
              {
                amount: amount,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
                paymentDate: Date.now(),
                items: order,
                prices: pricelist,
                userId: client._id,
                shop: true,
                method: paymentDetails?.data?.method,
              },
              config()
            );
            if (result.status == 200) {
              const response = await axios.post(
                `${API_BASE_URL}/shop/productPay/${shop[0]?._id}`,
                {},
                config()
              );
              if (response) {
                const date = new Date(response.data.endDate);
                const formattedDate = date.toLocaleDateString("en-GB");
                toast({
                  title: "Payment success",
                  description: `Your Model/Texture will be ready by ${formattedDate}`,
                  status: "success",
                  position: "top",
                  isClosable: true,
                });
                navigate("/shop-dashboard");
              }
            }
          },
          prefill: {
            name: "",
            email: "",
            contact: "",
          },
          notes: {
            address: "",
          },
          theme: {
            color: "#C69C6D",
          },
        };

        setLoading(false);
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };
    document.body.appendChild(script);
  }

  var totalAmount = 0;
  const [storeData, setStoreData] = useState([]);
  const { shop } = useGetShopData();
  const [loading, setLoading] = useState(false);
  const [modalClose, setModalClose] = useState();

  const { numberOfDisplayedCarts, setnumberOfDisplayedCarts } =
    useNumberOfUsers();
  const order = [];
  const pricelist = [];
  console.log("p",pricelist);
  // for getting number of boxes in cartscreen
  useEffect(() => {
    const calculatedNumberOfUsers =
      shop && shop[0]?.product
        ? shop[0]?.product.reduce((count, productdataitem) => {
            if (
              !productdataitem.payment &&
              (productdataitem.generate3d || productdataitem.generatetexture)
            ) {
              return count + 1;
            }
            return count;
          }, 0)
        : 0;
    setnumberOfDisplayedCarts(calculatedNumberOfUsers);
  }, [setnumberOfDisplayedCarts, shop]);

  const displayUsers =
    shop && shop[0]?.product && shop[0]?.product.length > 0 ? (
      shop[0]?.product
        // .slice(pagesVisited, pagesVisited + usersPerPage)
        .map((productdataitem, index) => {
          if (!productdataitem.payment) {
            totalAmount += productdataitem.modelTexturePrice;
          }
          if (!productdataitem.generate3d && !productdataitem.generatetexture) {
            productdataitem.modelTexturePrice = 0;
          }
          return (
            <Flex key={index}>
              {!productdataitem.payment &&
                (productdataitem.generate3d ||
                  productdataitem.generatetexture) && (
                  <>
                    <Text ml={"20px"} my={"auto"}>
                      {" "}
                      {order.push(productdataitem?.productname)}
                     
                    </Text>
                    <Flex
                      mt={"10px"}
                      flexDir={{ base: "column", md: "row", lg: "row" }}
                      fontSize={{ base: "13px", md: "13px", lg: "15px" }}
                      alignItems={"center"}
                      key={index}
                      px={{ base: "1", md: "3", lg: "3" }}
                      py={2}
                      justifyContent={"center"}
                    >
                      <Box width={{ base: "160px", md: "70px", lg: "120px" }}>
                        <Image
                          mx={"auto"}
                          borderRadius={"10px"}
                          width={"60px"}
                          height={"60px"}
                          objectFit={"cover"}
                          src={productdataitem.productimage[0]}
                        ></Image>
                      </Box>

                      <Text
                        textAlign={"center"}
                        mt={{ base: "10px", md: "0", lg: "0" }}
                        alignSelf={"center"}
                        width={{ base: "160px", md: "70px", lg: "120px" }}
                      >
                        {productdataitem.productname}
                      </Text>
                      <Text
                        textAlign={"center"}
                        width={{ base: "160px", md: "70px", lg: "120px" }}
                      >
                        {productdataitem.category}
                      </Text>
                      {productdataitem.generatetexture && (
                        <Text
                          textAlign={"center"}
                          width={{ base: "160px", md: "70px", lg: "120px" }}
                        >
                          Texture
                        </Text>
                      )}
                      {productdataitem.generate3d && (
                        <Text
                          textAlign={"center"}
                          width={{ base: "160px", md: "70px", lg: "120px" }}
                        >
                          3D Model
                        </Text>
                      )}

                      <Text
                        textAlign={"center"}
                        width={{ base: "160px", md: "70px", lg: "120px" }}
                      >
                        {FormatIndianRupees(productdataitem.modelTexturePrice)}
                      </Text>
                      {pricelist.push(productdataitem?.modelTexturePrice)}
                    </Flex>
                  </>
                )}
            </Flex>
          );
        })
    ) : (
      <>No results found</>
    );
  return (
    <Flex>
      {/* header */}
      <Flex
        flexDir={"column"}
        bg={
          "linear-gradient(180deg, #F2E1D0 20.87%, rgba(255, 255, 255, 0.99) 100%)"
        }
      >
        <Container mb={"50px"} maxW="2000px" height={"100vh"} width={"100vw"} p={0}>
          <Header
            heading="My Cart"
            image={WishlistHeader}
            imageMob={WishlistHeaderMob}
          />
<Flex
      fontWeight={"bold"}
        justifyContent={"center"}
        borderRadius={"10px"}
        border={"2px solid #EDD0AE"}
        alignItems={"center"}
        width={"fit-content"}
        p={{base:"10px",md:"10px",lg:"30px"}}
        backgroundColor={"white"}
        height={"fit-content"}
        my={"auto"}
        mx={"auto"}
        boxShadow={"lg"}
      >
        <Text>The Cart service for ordering 3D models/ Textures is coming soon...</Text>
      </Flex>
          {/* Form area */}
          {(pricelist.length > 0) &&(
          <Flex flexDir={"column"}>
            <Flex
              fontSize={{ base: "13px", md: "13px", lg: "15px" }}
              display={{ base: "none", md: "flex", lg: "flex" }}
              justifyContent={"center"}
              width={"100%"}
              p={4}
            >
              <Flex justifyContent={"center"} fontFamily={"heading"}>
                <Text
                  textAlign={"center"}
                  width={{ base: "160px", md: "70px", lg: "120px" }}
                >
                  Product
                </Text>
                <Text
                  textAlign={"center"}
                  width={{ base: "160px", md: "70px", lg: "120px" }}
                >
                  Name
                </Text>
                <Text
                  textAlign={"center"}
                  width={{ base: "160px", md: "70px", lg: "120px" }}
                >
                  Category
                </Text>
                <Text
                  textAlign={"center"}
                  width={{ base: "160px", md: "70px", lg: "120px" }}
                >
                  Order Type
                </Text>
                <Text
                  width={{ base: "160px", md: "70px", lg: "120px" }}
                  textAlign={"center"}
                >
                  Price
                </Text>
              </Flex>
            </Flex>
            {loading ? (
              <Flex width={"100%"} justifyContent={"center"}>
                <Spinner />
              </Flex>
            ) : (
              <Flex
                maxH={totalAmount !== 0 ? "500px" : "120px"}
                overflowY={"scroll"}
                justifyContent={"center"}
                alignItems={"center"}
                mb={"20px"}
              >
                <Flex
                  mb={"auto"}
                  backgroundColor={"#F3F0ED"}
                  flexDir={"column"}
                  borderRadius={"10px"}
                  boxShadow={"lg"}
                >
                  {displayUsers}
                </Flex>
              </Flex>
            )}
            <Flex
              mt={"20px"}
              border={"2px solid #917961"}
              p={4}
              mx={"auto"}
              alignItems={"center"}
              borderRadius={"5px"}
            >
              <Text fontSize={{ base: "13px", md: "14px", lg: "17px" }}>
                Total Amount: {FormatIndianRupees(totalAmount)}
              </Text>
              {totalAmount !== 0 && (
                <CommonButton
                  width={{ base: "90px", md: "", lg: "110px" }}
                  fontSize={{ base: "14px", md: "14px", lg: "15px" }}
                  ml={{ base: "20px", md: "40px", lg: "40px" }}
                  btn={"Pay"}
                  isLoading={loading}
                  onClick={loadRazorpay}
                />
              )}
            </Flex>
          </Flex>
          )}
        </Container>
        <Footer />
      </Flex>
    </Flex>
  );
}

export default CartScreen;
