import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import PropTypes from "prop-types";

import React from "react";

const FormInput = ({ children, isRequired = false, labelProps, ...rest }) => {
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel
        htmlFor="input label"
        textTransform="capitalize"
        {...labelProps}
      >
        {children}
      </FormLabel>
      <Input
        bg="blackAlpha.50"
        w="full"
        type="text"
        _placeholder={{ opacity: 0.9, color: "gray.500" }}
        {...rest}
      />
    </FormControl>
  );
};

FormInput.propTypes = {
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  labelProps: PropTypes.object,
};

export default FormInput;
