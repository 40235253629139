import { Image } from "@chakra-ui/image";
import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import Store from "../../../Assets/DummyCollections/Rectangle 7.png";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";

function Collections(props) {
  const navigate = useNavigate();
  const {
    bg = "brand.cardBg",
    imageP = 2,
    src = Store,
    imageWidth = "250px",
    imageHeight = "220px",
    borderRadius = "4px",
    imageBorderRadius = "4px",
    boxShadow = "lg",
    imageBoxShadow = "lg",
    nameCardBg = "#F8EEE3",
    nameCardP = 0,
    headingSize = "18px",
    descriptionSize = "12px",
    heading = "Shop Name",
    description = "Ernakulam",
    ratingValue = 2,
    selection,
    collectionType,
    district,
    priceValue,
    style,
    subCollection,
    color,
    texture,
    material,
    ...rest
  } = props;
  return (
    <Flex flexDir={"column"} width={"fit-content"}>
      <Flex
        bg={bg}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        flexDir={"column"}
        onClick={() => {
          navigate(`/collections/${selection}/${props?.item?.shopname}`, {
            state: {
              item: props?.item,
              filters: {
                collectionType,
                district,
                priceValue,
                style,
                subCollection,
                color,
                texture,
                material,
              },
            },
          });
        }}
        cursor={"pointer"}
      >
        <Flex p={imageP}>
          <Image
            src={src}
            width={imageWidth}
            height={imageHeight}
            objectFit={"cover"}
            borderRadius={imageBorderRadius}
            boxShadow={imageBoxShadow}
          />
        </Flex>
        <Flex p={nameCardP} bg={nameCardBg} borderBottomRadius={borderRadius}>
          <Flex px={4} pb={1} flexDir={"column"}>
            <Text fontWeight={"semibold"} fontSize={headingSize}>
              {heading}
            </Text>
            <Text fontSize={descriptionSize}>{description}</Text>
            {/* <ReactStars count={5} value={ratingValue} edit={false} /> */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Collections;
