import React, { useState } from "react";


import { useNavigate } from "react-router-dom";
import StyhomSplash from "./StyhomSplash";


function Mainpage(props) {
 
const [loading, setLoading] = useState(true);
const navigate = useNavigate();

setTimeout(() => {
  setLoading(false)
},3700)

  return (
    <div>
      {/* <Home/> */}
       {loading ? (
        <StyhomSplash/>        
      ) : (
        navigate("/home",{replace:true})
      )} 
     
    </div>
  );
}

export default Mainpage;
