import {
  Box,
  Divider,
  Flex,
  Image,
  Img,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AboutUsVector from "../../../Assets/Images/MainPage/AboutUsVector.png";
import AboutUsImage from "../../../Assets/Images/MainPage/AboutUsImage.webp";
import { getPics } from "../../../Functions/GetApiCalls";
import { Grid, GridItem } from "@chakra-ui/react";
import "../../../App.css";
function LatestHappenings() {
  const [Pics, setPics] = useState();
  const [loading, setLoading] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  // popup
  const [file, setFile] = useState(null);
  // zoom
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImagezoomClick = () => {
    setIsZoomed(!isZoomed);
  };

  const zoomStyle = {
    cursor: isZoomed ? "zoom-out" : "zoom-in",
    transition: "transform 0.3s ease-in-out",
    transform: isZoomed ? "scale(1.2)" : "scale(1)",
  };
  // zoom
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getPics(setPics);
      setLoading(false);
    };
    fetchData();
  }, []);
  return (
    <Flex
      id="latest-pics"
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      flexDir={"column"}
    >
      {/* heading */}
      <Flex
        maxWidth={{ base: "200px", md: "300px", lg: "300px" }}
        flexDirection={"column"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text
          mt={"50px"}
          textAlign={"center"}
          fontSize={{ base: "20px", md: "30px", lg: "30px" }}
          fontFamily={"heading"}
        >
          Lokolumo Updates
        </Text>
        <Divider border={"2px solid #E3B78B"} />
      </Flex>

      {/* content */}
      <Flex
        my={{ base: "30px", md: "30px", lg: "60px" }}
        px={{ base: "5%", md: "5%", lg: "10%" }}
        width={"100%"}
      >
        <Flex justifyContent={"center"} mx={"auto"}>
          <SimpleGrid columns={[2, null, 4]} spacing="10px">
            {Pics?.latestHappenings.map((image, index) => (
              <Flex key={index}>
                <Image
                  cursor={"pointer"}
                  onClick={() => setSelectedImage(image)}
                  _hover={{ transform: "scale(1.02)" }}
                  borderRadius={"10px"}
                  boxShadow={"lg"}
                  src={image}
                  height={"300px"}
                  w={"300px"}
                  border={"4px solid white"}
                  objectFit={"cover"}
                />
              </Flex>
            ))}
          </SimpleGrid>
          {selectedImage && (
            <Flex className="popup-media">
              <span onClick={() => setSelectedImage(null)}>&times;</span>
              <Image
                mx={"auto"}
                style={zoomStyle}
                onClick={handleImagezoomClick}
                // Add any necessary styles for the popup
                alt="Zoomable Image"
                className="popupimagegallery"
                src={selectedImage}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LatestHappenings;
