import { Flex, Text, Box, Container } from "@chakra-ui/layout";
import { Divider, AbsoluteCenter, Image, Link } from "@chakra-ui/react";
import { SimpleGrid } from "@chakra-ui/react";
import { collectionObjects } from "../../Collections/CollectionSelection/CollectionObjects";

function ExploreCollections() {
  return (
    <Flex
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      {/* .....Heading.... */}
      <Flex
        borderRadius={"30px"}
        p={5}
        width={{ base: "95%", md: "95%", lg: "95%" }}
        bg={
          "linear-gradient(180deg, #F2DBC4 0%, rgba(217, 217, 217, 0.00) 100%)"
        }
        flexDirection={"column"}
      >
        <Flex width={"100%"} justifyContent={"center"}>
          <Flex
            maxWidth={{ base: "300px", md: "470px", lg: "470px" }}
            flexDirection={"column"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Text
              justifyContent={"center"}
              textAlign={"center"}
              mt={"50px"}
              fontSize={{ base: "22px", md: "30px", lg: "32px" }}
              fontFamily={"heading"}
            >
              Explore Our Collections
            </Text>
            <Divider border={"2px solid #E3B78B"} />
          </Flex>
        </Flex>
        {/* ..........Grid............. */}
        <Flex mt={"60px"} justifyContent={"center"}>
          <SimpleGrid
            columns={[2, null, 4]}
            spacing={{ base: "10px", md: "10px", lg: "30px" }}
          >
            {collectionObjects.map((collectionitem, index) => (
              <Flex mt={"100px"} key={index}>
                  <Link href={`/collections/${collectionitem.id}`}>
                <Box
                  data-aos-easing="linear"
                  data-aos-duration="1200"
                  data-aos="fade-up"
                  fontSize={{ base: "12px", md: "14px", lg: "15px" }}
                  borderRadius={"20px"}
                  boxShadow={"lg"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={{ base: "100px", md: "120px", lg: "130px" }}
                  width={{ base: "150px", md: "160px", lg: "200px" }}
                  backgroundColor={"white"}
                  p={4}
                >
                  <Flex
                    ml={"auto"}
                    mr={"auto"}
                    height={{ base: "30px", md: "30px", lg: "60px" }}
                    width={{ base: "120px", md: "120px", lg: "150px" }}
                  >
                    {" "}
                    <Image
                    
                    objectFit={"contain"}
                      mt={{ base: "-75px", md: "-80px", lg: "-100px" }}
                      ml={"auto"}
                      mr={"auto"}
                      src={collectionitem.mainimage}
                    ></Image>
                  </Flex>
                  <Text fontFamily={"heading"} mt={"10px"} textAlign={"center"}>
                    {collectionitem.name}
                  </Text>
                </Box>
                </Link>
              </Flex>
            ))}
          </SimpleGrid>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ExploreCollections;
