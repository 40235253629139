import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import React from "react";

function Selectform(props) {
  const {
    require,
    name,
    label,
    inputFor,
    register,
    error,
    validator,
    options,
    optionTextColor,
    style = {
      border: "1px solid #D0D5DD",
      maxHeight: "38px",
      backgroundColor: "white",
      borderRadius: "8px",
    },
    handleFocus,
    value,
    ...rest
  } = props;

  return (
    <FormControl isInvalid={error} mb={"15px"}>
      <FormLabel htmlFor={name} color="brand.formFont" fontSize={"14px"}>
        <Flex direction={"row"}>
          {label}
          {require && <Text color={"red"}>*</Text>}
        </Flex>
      </FormLabel>
      <Select
        fontSize={"15px"}
        style={{
          ...style,
          color: optionTextColor,
        }}
        id={name}
        name={name}
        _focus={handleFocus}
        _focusVisible={handleFocus}
        {...register(inputFor, validator)}
        {...rest}
      >
        {options.map((option) => (
          <option
            style={{ backgroundColor: option.value }}
            key={option.value}
            value={value || option.value}
          >
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}

export default Selectform;
