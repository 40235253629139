import { Text, Flex, Input } from "@chakra-ui/react";

import React, { useState } from "react";

function DateRangePicker({onDateSelection}) {
  const [fromDate, setFromDate] = useState("");
  const [tillDate, setTillDate] = useState("");

  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toLocaleDateString('en-CA');
  const isFromDateValid = fromDate && fromDate >= formattedCurrentDate;
  const isTillDateValid = tillDate && tillDate > fromDate;
  const isDateRangeValid = isFromDateValid && isTillDateValid;
  const isBothDatesNull = !fromDate && !tillDate;
  if (isDateRangeValid) {
    onDateSelection(fromDate, tillDate);
  }

  return (
    <Flex
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDir={"column"}
    >
     
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        width={{ base: "85%", md: "80%", lg: "80%" }}
        flexDir={"column"}
      >
        <Text fontSize={{ base: "10px", md: "12px", lg: "13px" }} my={"10px"}>
          Valid From (Choose date)
        </Text>
        <Input
          placeholder="Select Date"
          ml={"10px"}
          size="md"
          width={"100%"}
          backgroundColor={"white"}
          boxShadow={"lg"}
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
        {!isFromDateValid && fromDate && (
          <Text fontSize={{ base: "10px", md: "12px", lg: "13px" }}  color="red">Select a valid future date.</Text>
        )}
      </Flex>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        width={{ base: "85%", md: "80%", lg: "80%" }}
        flexDir={"column"}
      >
        <Text fontSize={{ base: "10px", md: "12px", lg: "13px" }} my={"10px"}>
          Valid To (Choose date)
        </Text>
        <Input
          placeholder="Select Date"
    
          ml={"10px"}
          size="md"
          width={"100%"}
          backgroundColor={"white"}
          boxShadow={"lg"}
          type="date"
          value={tillDate}
          onChange={(e) => setTillDate(e.target.value)}
        />
       
        {!isTillDateValid && tillDate && (
          <Text fontSize={{ base: "10px", md: "12px", lg: "12px" }} color="red">
           Select a date that is later than the From Date.
          </Text>
        )}
         {isBothDatesNull && (
        <Text fontSize={{ base: "10px", md: "12px", lg: "13px" }} color="red">
          Please select valid From and Till dates.
        </Text>
      )}
        {/* {!isDateRangeValid && isFromDateValid && (
          <Text fontSize={{ base: "10px", md: "12px", lg: "13px" }} color="red">
            Till Date should be greater than From Date
          </Text>
        )} */}
    
      </Flex>
    </Flex>
  );
}

export default DateRangePicker;
