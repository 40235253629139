import { Flex, Text, Box, Container } from "@chakra-ui/layout";
import { Divider, AbsoluteCenter, Image, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { designers } from "../../Designers/DummyDesignersData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { getDesigners } from "../../../Functions/GetApiCalls";
import { useNavigate } from "react-router-dom";

function PopularArtist() {
  const [loading, setLoading] = useState();
  const [designersData, setDesignersData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getDesigners(setDesignersData);
      setLoading(false);
    };
    fetchData();
  }, []);
  return (
    <Flex  width={"100%"} flexDirection={"column"}>
      {/* .....Heading.... */}
  
        <Flex  width={"100%"} justifyContent={"center"}>
          <Flex
            maxWidth={{ base: "200px", md: "800px", lg: "800px" }}
            flexDirection={"column"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Text
              justifyContent={"center"}
              textAlign={"center"}
              mt={"50px"}
              fontSize={{ base: "22px", md: "30px", lg: "32px" }}
              fontFamily={"heading"}
            >
              Popular Creators
            </Text>
            <Divider border={"2px solid #E3B78B"} />
          </Flex>
        </Flex>
        {/* ..........carousel............. */}
        <Flex
       
          my={"100px"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {loading ? (
            <Spinner />
          ) : (
            <Flex
            
            width={"82vw"}>
              <Swiper
              loop={"true"}
                className="designerSlider"
                slidesPerView={4}
                navigation={true}
                pagination={false}
                mousewheel={false}
                keyboard={true}
                breakpoints={{
                  1629: {
                    slidesPerView: 4,
                  },
                  1340: {
                    slidesPerView: 4,
                  },

                  1051: {
                    slidesPerView: 2,
                  },
                  833: {
                    slidesPerView: 2,
                  },
                  390: {
                    slidesPerView: 1,
                  },
                  375: {
                    slidesPerView: 1,
                  },
                  280: {
                    slidesPerView: 1,
                  },
                }}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              >
                {designersData?.map((designeritem, index) => (
                  <SwiperSlide key={index}>
                    <Flex
                    
                    width={{base:"210px",md:"210px",lg:"260px"}}
                    mx={"auto"}
                      fontSize={{ base: "19px", md: "20px", lg: "20px" }}
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDir={"column"}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(`/creators/${designeritem.name}`, {
                          state: designeritem,
                        });
                      }}
                      cursor={"pointer"}
                    >
                      <Image
                        mt={"20px"}
                        boxShadow={"dark-lg"}
                        width={"200px"}
                        height={"200px"}
                        objectFit={"cover"}
                        borderRadius={"50%"}
                        src={designeritem.designerPhoto}
                      ></Image>
                      <Text mt={"20px"}>{designeritem.name}</Text>
                      <Text mt={"4px"}>
                      {designeritem.profession === "Artist" ? designeritem.artistSpecialization : designeritem.profession}
                      </Text>
                    </Flex>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Flex>
          )}
        </Flex>
       
    </Flex>
  );
}

export default PopularArtist;
